import React, {useEffect, useState} from 'react';
import {Card, Row, Col, Descriptions} from 'antd';
import {Doughnut, Pie} from 'react-chartjs-2'
import DashboardItem from './DashboardItem'
import { PageTitle, Spinner} from '../../../components';
import {SentralModel} from '../../../models/SentralModel'

const Employee = () => {
    const [loaded, setLoaded] = useState(true);
    const [employees, setEmployees] = useState({});

    const fetchEmployee = () => {
        setLoaded(false)
        SentralModel.action('Dashboard', 'employee', {}, 0).then((res) => {
            setEmployees(res.data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchEmployee()
    }, []);

    const backgroundColor= [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)'
    ];
    const borderColor =[
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)'
    ];

    const chartStatus= {
        labels: employees.by_status && employees.by_status.map(v => (v.description)),
        datasets: [{
            label: '',
            data: employees.by_status && employees.by_status.map(v => (v.total)),
            backgroundColor: backgroundColor,
            borderColor: borderColor,
            borderWidth: 1
        }]
    }

    const chartGender= {
        labels: employees.by_gender && employees.by_gender.map(v => (v.description)),
        datasets: [{
            label: '',
            data: employees.by_gender && employees.by_gender.map(v => (v.total)),
            backgroundColor: backgroundColor,
            borderColor: borderColor,
            borderWidth: 1
        }]
    }
    
    const chartAge= {
        labels: employees.by_age && employees.by_age.map(v => (v.description)),
        datasets: [{
            label: '',
            data: employees.by_age && employees.by_age.map(v => (v.total)),
            backgroundColor: backgroundColor,
            borderColor: borderColor,
            borderWidth: 1
        }]
    }

    // const chartDivision= {
    //     labels: employees.by_division && employees.by_division.map(v => (v.division_name)),
    //     datasets: [{
    //         label: '',
    //         data: employees.by_division && employees.by_division.map(v => (v.total)),
    //         backgroundColor: backgroundColor,
    //         borderColor: borderColor,
    //         borderWidth: 1
    //     }]
    // }

    return (
        <Card className="content-container">
            <PageTitle title="Employee" breadcrumbs={[['Employee']]}></PageTitle>
            {
                loaded ?
                <Row className="mb-3" justify="space-between">
                    <Col span={7} className="p-1">
                        <DashboardItem title="Employment Status" height={450}>
                            <Row className="pt-2">
                                <Col span={24}>
                                    <Doughnut
                                        data={chartStatus}
                                        height={230}
                                        options={{ maintainAspectRatio: false, legend: {display: false} }}
                                        />
                                </Col>
                                <Col span={24} className="mt-2">
                                    <Descriptions bordered size="small" layout="horizontal" column={1}>
                                        {
                                            employees.by_status && employees.by_status.map((status, index) => (
                                                <Descriptions.Item key={'status_' + index} label={status.description}>{status.total}</Descriptions.Item>
                                            ))
                                        }
                                    </Descriptions>
                                </Col>
                            </Row>
                        </DashboardItem>
                    </Col>
                    <Col span={7} className="p-1">
                        <DashboardItem title="Employee by Gender" height={450}>
                            <Row className="pt-2">
                                <Col span={24}>
                                    <Pie
                                        data={chartGender}
                                        height={230}
                                        options={{ maintainAspectRatio: false, legend: {display: false} }}
                                        />
                                </Col>
                                <Col span={24} className="mt-2">
                                    <Descriptions bordered size="small" layout="horizontal" column={1}>
                                        {
                                            employees.by_gender && employees.by_gender.map((gender, index) => (
                                                <Descriptions.Item key={'gender_' + index} label={gender.description}>{gender.total}</Descriptions.Item>
                                            ))
                                        }
                                    </Descriptions>
                                </Col>
                            </Row>
                        </DashboardItem>
                    </Col>
                    <Col span={7} className="p-1">
                        <DashboardItem title="Employee by Ages" height={450}>
                            <Row className="pt-2">
                                <Col span={24}>
                                    <Pie
                                        data={chartAge}
                                        height={200}
                                        options={{ maintainAspectRatio: false, legend: {display: false} }}
                                        />
                                </Col>
                                <Col span={24} className="mt-2">
                                    <Descriptions bordered size="small" layout="horizontal" column={1}>
                                        {
                                            employees.by_age && employees.by_age.map((age, index) => (
                                                <Descriptions.Item key={'age_' + index} label={age.description}>{age.total}</Descriptions.Item>
                                            ))
                                        }
                                    </Descriptions>
                                </Col>
                            </Row>
                        </DashboardItem>
                    </Col>
                    {/* <Col span={8} className="p-1">
                        <DashboardItem title="Employee by Division">
                            <Row className="pt-2">
                                <Col span={14}>
                                    <Pie
                                        data={chartDivision}
                                        height={230}
                                        options={{ maintainAspectRatio: false, legend: {display: false} }}
                                        />
                                </Col>
                                <Col span={10}>
                                    <Descriptions bordered size="small" layout="horizontal" column={1}>
                                        {
                                            employees.by_division && employees.by_division.map((division, index) => (
                                                <Descriptions.Item key={'division_' + index} label={division.division_name}>{division.total}</Descriptions.Item>
                                            ))
                                        }
                                    </Descriptions>
                                </Col>
                            </Row>
                        </DashboardItem>
                    </Col> */}
                </Row>
                : <Card><Spinner /></Card>
            }
        </Card>
    );
}

export default Employee;
