import React, {useEffect, useState} from 'react';
import {Card, Form, Row, Col, Input, Space, Typography, Switch, Tag, message} from 'antd';
import {PageTitle, SubmitButton, AddButton, EditButton, DeleteButton, DataTable, Spinner, FormModal} from '../../../components'
import {SentralModel} from '../../../models/SentralModel'
import {/*useHistory,*/ useParams} from 'react-router-dom'

const {Text} = Typography

const Detail = () => {
    // const history = useHistory()
    const {generalCode} = useParams()
    const [genCode, setGenCode] = useState(generalCode);
    const [loaded, setLoaded] = useState(false)
    const [saving, setSaving] = useState(false)
    const [form] = Form.useForm()
    const [formDetail] = Form.useForm()
    const [details, setDetails] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [formModal, setFormModal] = useState(false);
    const [modalTitle, setModalTitle]= useState(null);
    const [status, setStatus] = useState(null)

    const fetchGenerals = (generalCode, form) => {
        setLoaded(false)
        if (generalCode === 'new') {
            form.resetFields();
        } else {
            setIsEdit(true)
            SentralModel.list('General', {filter: [['general_code', generalCode]]}).then(res => {
                form.setFieldsValue({
                    general_code: res.data[0].general_code,
                    description: res.data[0].description
                })
                setDetails(res.data[0].details)
            })
        }
        setLoaded(true)
    }

    const fetchDetails = () =>{
        setLoaded(false)
        SentralModel.list('GeneralDetail', {filter:[["general_code", generalCode]]}).then(res=>{
            setDetails(res.data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchGenerals(generalCode, form)
    }, [generalCode, form]);
    
    const saveGeneral = (v) => {
        setSaving(true)
        
        let data = {
            general_code: generalCode === 'new' ? v.general_code : generalCode,
            description: v.description
        }

        SentralModel.action('General', 'saveGeneral', data, generalCode === 'new' ? v.general_code : generalCode).then((res) => {
            message.success('General saved', 2);

            if (generalCode === 'new') {
                setIsEdit(true)
                setGenCode(v.general_code)
            }
            // setTimeout(() => {
            //     history.push('/setting/general');
            // }, 1000);
        }).finally(() => {
            setSaving(false)
        });
    }

    const dataSource = [
        ...details.map( el => {
            return {
                detail_code: el.detail_code,
                general_code: el.general_code,
                description: el.description,
                order_key: el.order_key,
                is_active: el.is_active
            }
        })
    ];

    const columns = [{
            title: 'No',
            dataIndex: 'no',
            key: 'name',
        },
        {
            title: 'Detail Code',
            dataIndex: 'detail_code',
            key: 'detail_code',
            width: '10%'
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            width: '70%'
        },
        {
            title: 'Order Key',
            dataIndex: 'order_key',
            key: 'order_key',
            align:'center',
            width: '10%'
        },
        {
            title: 'Status',
            dataIndex: 'is_active',
            key: 'is_active',
            width: '10%',
            render: (text) => (
                <div className="text-center">
                    <Tag color={text === '1' ? "green" : "red"}>{(text === '1') ? 'Active' : 'Inactive'}</Tag>
                </div>
            )
        },
        {
            title: 'Action',
            width:120,
            render:(row) =>(
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => editDetails(row)}/>
                        <DeleteButton onConfirm={() => deleteGenerals(row.general_code)}/>
                    </Space>
                </div>
            )
        },
    ];

    const addDetail=()=>{
        setModalTitle('Add General Detail')
        formDetail.resetFields()
        formDetail.setFieldsValue({
            is_active: 1
        })
        setStatus(1)
        setFormModal(true)
    }

    const editDetails = (v) =>{
        setModalTitle('Edit General Detail')
        formDetail.setFieldsValue({
            detail_code: v.detail_code,
            general_code: v.general_code,
            description: v.description,
            order_key: v.order_key,
            is_active: parseInt(v.is_active)
        })
        setStatus(v.is_active)
        setFormModal(true)
    }

    const deleteGenerals = (detail_code) =>{
        message.loading('Deleting Detail')
        SentralModel.deleteById('GeneralDetail', detail_code).then((res)=>{
            message.destroy()
            message.success('Detail deleted', 2)
            let tmp = details
            let index = tmp.findIndex(el => el.detail_code === detail_code)
            tmp.splice(index, 1);
            setDetails([])
            setDetails(tmp)
        })
    }

    const saveDetail = (v) => {
        setSaving(true)
        let payload={
            detail_code: v.detail_code,
            general_code: genCode,
            description: v.description,
            order_key: v.order_key,
            is_active: status
        }
        SentralModel.action('General', 'saveDetail', payload, v.detail_code).then((res)=>{
            setFormModal(false)
            fetchDetails()
            message.success('Detail saved', 2);
        }).finally(() => {
            setSaving(false)
        })
    }

    const onChangeGeneralCode = e => {
        const {value} = e.target;
        if (isNaN(value)) {
            form.setFieldsValue({general_code: value.toUpperCase()});
        }
    }

    const onChangeDetailCode = e => {
        const {value} = e.target;
        if (isNaN(value)) {
            formDetail.setFieldsValue({detail_code: value.toUpperCase()});
        }
    }

    const changeStatus = e => {
        setStatus(e)
    }
    
    return (
        <Card className="content-container">
            <PageTitle title="General" breadcrumbs={[['General', '/setting/general'], ['Detail']]}></PageTitle>
            {
                loaded ?
                <div>
                    <Card title={
                        <div className="full-width">
                            <Text strong style={{float:'left'}}>General Detail</Text>
                        </div> }>
                        <Form size="middle" form={form} layout="vertical" onFinish={saveGeneral}>
                            <Row justify="space-around">
                                <Col span={11}>
                                    <Form.Item label="General Code" name="general_code" rules={[{required:true}]}>
                                        <Input placeholder="General Code" onChange={onChangeGeneralCode} maxLength={5}/>
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item label="Description" name="description" rules={[{required:true}]}>
                                        <Input placeholder="Description" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify="space-around">
                                <Col span={11} />
                                <Col span={11}>
                                    <SubmitButton right loading={saving}/>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                
                    <br />
                    
                    {
                        isEdit ?
                        <Card title={
                            <div className="full-width">
                                <Text strong style={{float:'left'}}>Detail List</Text>
                                <AddButton right onAdd={addDetail} title="New Detail"/>
                            </div> }>

                            <DataTable size='small' bordered dataSource={dataSource} columns={columns}/>
                        </Card>
                        : null
                    }
                </div>
                : <Spinner />
            }

            {
                (formModal) ?
                <FormModal title={modalTitle} form={formDetail} submitForm={(v)=>saveDetail(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                    <Form.Item label="Detail Code" name="detail_code" rules={[{required:true}]}>
                        <Input placeholder="Detail Code" onChange={onChangeDetailCode} maxLength={5} readOnly={modalTitle==="Edit General Detail"}/>
                    </Form.Item>
                    <Form.Item label="Description" name="description" rules={[{required:true}]}>
                        <Input placeholder="Description" />
                    </Form.Item>
                    <Form.Item label="Order Key" name="order_key" rules={[{required:true}]}>
                        <Input placeholder="Order Key" />
                    </Form.Item>
                    <Form.Item label="Status" name="is_active">
                        <Switch checkedChildren="Active" unCheckedChildren="Inactive" defaultChecked={status} onChange={changeStatus} />
                    </Form.Item>
                </FormModal>
                : null
            }
        </Card>
    );
}

export default Detail;
