import React, {useEffect, useState} from 'react'
import {Row, Typography, Divider, Empty, Form, Input, message} from 'antd'
import ProfileItem from './ProfileItem';
import ActionButton from './ActionButton';
import {AddButton, FormModal, Spinner, Uploader} from '../../../components';
import {SentralModel} from '../../../models/SentralModel'
import { ImageViewer } from '../../../components/ImageViewer';

const {Title} = Typography
const Certification = (props) => {
    const [empCertifications, setEmpCertifications] = useState(props.data);
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false);
    const [defaultValues, setDefaultValues] = useState({});
    const [saving, setSaving] = useState(false);
    const [modalTitle, setModalTitle]= useState(null);
    const [attachmentFile, setAttachmentFile] = useState(null)
    const [uploading, setUploading] = useState(false)

    const fetchEmpCertifications = () =>{
        setLoaded(false)
        SentralModel.list('RecruitmentApplicantTrainingCertification', {filter: [['rec_applicant_id', props.applicant_id]]}).then(res=>{
            setEmpCertifications(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    useEffect(() => {
        setLoaded(true)
    }, []);

    const addCertification=()=>{
        setModalTitle('Add Certification')
        setDefaultValues({
            rec_applicant_training_certification_id: 0
        })
        setFormModal(true)
    }

    const editCertification = (v) =>{
        setModalTitle('Edit Certification')
        setDefaultValues({
            rec_applicant_training_certification_id: v.rec_applicant_training_certification_id,
            description: v.description,
            start_year: v.start_year,
            certificate: v.certificate
        })
        setAttachmentFile(v.certificate)
        setFormModal(true)
    }

    const saveCertification = (v) => {
        setSaving(true)
        let payload={
            rec_applicant_training_certification_id: v.rec_applicant_training_certification_id,
            rec_applicant_id: props.applicant_id,
            description: v.description,
            start_year: v.start_year,
            certificate: attachmentFile
        }
        SentralModel.save('RecruitmentApplicantTrainingCertification', payload, v.rec_applicant_training_certification_id).then((res)=>{
            setFormModal(false)
            fetchEmpCertifications()
            message.success('Certification saved', 2);
        }).finally(() => {
            setSaving(false)
        })
    }

    const deleteCertification = (id) =>{
        message.loading('deleting certification')
        SentralModel.deleteById('RecruitmentApplicantTrainingCertification', id).then((res)=>{
            message.destroy()
            message.success('Certification deleted', 2)
            let tmp = empCertifications
            let index = tmp.findIndex(el => el.rec_applicant_training_certification_id===id)
            tmp.splice(index, 1);
            setEmpCertifications([])
            setEmpCertifications(tmp)
        })
    }

    const fileUploaded = (v) => {
        setAttachmentFile(v.message)
    }

    return (
        <div>
            <div className="text-right">
                <AddButton onAdd={addCertification}>Add data</AddButton>
            </div>
            {
                loaded ?
                    (empCertifications.length > 0) ?
                        empCertifications.map((el, key) => (
                            <div key={key}>
                                <Divider orientation="left"><Title level={4}>{key+1}.</Title></Divider>
                                <ActionButton editable deleteable onEdit={() => editCertification(el)} onDelete={() => deleteCertification(el.rec_applicant_training_certification_id)}/>
                                <Row>
                                    <ProfileItem title="Description">{el.description}</ProfileItem>
                                    <ProfileItem title="Year">{el.start_year}</ProfileItem>
                                    <ProfileItem image span={24} title="Certificate">
                                        <div style={{width:200, maxWidth:'100%', maxHeight:150, border:'1px solid #ededed'}}>
                                            <ImageViewer previewable={el.certificate ? true : false} src={el.certificate === null ? process.env.PUBLIC_URL + '/images/noimage.png' : process.env.REACT_APP_API_FILE + "/" + el.certificate} alt="certificate"/>
                                        </div>
                                    </ProfileItem>
                                </Row>
                            </div>
                        )) 
                    : <Empty/>
                : <Spinner />
            }
            {
                (formModal) ?
                <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => saveCertification(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving} loading={uploading}>
                    <Form.Item name="rec_applicant_training_certification_id" hidden>
                        <Input placeholder="input placeholder" />
                    </Form.Item>
                    <Form.Item label="Description" name="description" rules={[{required:true}]}>
                        <Input placeholder="Description" />
                    </Form.Item>
                    <Form.Item label="Year" name="start_year" rules={[{required:true}]}>
                        <Input placeholder="Year" />
                    </Form.Item>
                    <Form.Item label="Certificate" name="certificate" rules={[{required:false}]}>
                        <Uploader dragger folder="certificate" onUploaded={(v)=> fileUploaded(v)} isUploading={(v)=> setUploading(v)}/>
                    </Form.Item>
                </FormModal> :
                null
            }
        </div>
    );
}

export default Certification;
