import React, {useState, useEffect} from 'react';
import {Card, Row, Col, Tabs, Typography, Descriptions} from 'antd'
import {CalendarOutlined} from '@ant-design/icons'
import {PageTitle, NewButton, Spinner} from '../../../../components'
import authData from '../../../../helpers/authData'
import {SentralModel} from '../../../../models/SentralModel'
import {toIdr} from '../../../../utils/currencyHandler'
import EntertainmentRequest from './EntertainmentRequest';
import RealizationRequest from './RealizationRequest';

const {Text} = Typography
const {TabPane} = Tabs;

const Index = () => {
    const [entertainments, setEntertainments] = useState([])
    const [entertainmentInfo, setEntertainmentInfo] = useState(null)
    const [instruction, setInstruction] = useState('')
    const [available, setAvailable] = useState(false)
    const [loaded, setLoaded] = useState(false)

    const fetchEntertainment = () => {
        setLoaded(false)
        let payload={
            filter: [["employee_code", authData.employee_code]],
            orderBy:'created_at',
            order:'desc',
        }
        SentralModel.list('SubmissionEntertainment', payload).then(res => {
            setEntertainments(res.data)
            setLoaded(true)
        })
    }

    const fetchInstruction = () => {
        SentralModel.list('Instruction',{filter:[['code','SBEN']]}, null).then((res) => {
            setInstruction(res.data.length ? res.data[0].content : '')
        })
    }

    const fetchEntertainmentInfo = () => {
        SentralModel.action('Submission', 'getMonthlyEntertainment', null, null).then((res) => {
            setEntertainmentInfo(res.data[0])
        })
    }

    const checkApprovalSchema = () => {
        SentralModel.action('Submission','checkSchema',{category: 'ENTERTAINMENT', division_id: authData.division_id}).then((res) => {
            setAvailable(res.message)
        })
    }

    useEffect(() => {
        fetchEntertainment()
        fetchEntertainmentInfo()
        fetchInstruction()
        checkApprovalSchema()
    }, []);

    return (
        <div>
            <Card>
                <PageTitle title="Entertainment" breadcrumbs={[['Entertainment']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>Request Entertainment</Text>
                        <NewButton disabled={!available} right to="/entertainment/create" title={(available) ? "Request Entertainment" : 'Approval not available'}/>
                    </div>
                }>
                    {
                        (loaded) ? 
                            <Row justify="space-between">
                                <Col span={4}>
                                    <Descriptions size="middle" column={1} bordered layout="vertical">
                                        <Descriptions.Item label={<Text strong>Entertainment This Month</Text>}>
                                            {
                                                (entertainmentInfo) ? entertainmentInfo.total_submission : '0'
                                            }
                                        </Descriptions.Item>
                                        <Descriptions.Item label={<Text strong>Total Entertained</Text>}>
                                            {
                                                (entertainmentInfo) ? toIdr(entertainmentInfo.total_amount) : 'Rp 0'
                                            }
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                                <Col span={19} className="instruction-box">
                                    <div dangerouslySetInnerHTML={{ __html: instruction }}></div>
                                </Col>
                            </Row>
                        : <Spinner/>
                    }
                </Card>
                <br/>
                {
                    (loaded) ? 
                    <Tabs defaultActiveKey="request" type="card">
                        <TabPane tab={<span><CalendarOutlined style={{color:'#4ca3ff'}}/> Entertainment Request</span>} key="request">
                            <EntertainmentRequest data={entertainments.filter(el => el.status_realization === null || el.status_realization === 'REJECTED')}/>
                        </TabPane>
                        <TabPane tab={<span><CalendarOutlined style={{color:'green'}}/> Realization Request</span>} key="realization">
                            <RealizationRequest data={entertainments.filter(el => el.status_realization !== null)}/>
                        </TabPane>
                    </Tabs> : null
                }

            </Card>
        </div>
    );
}

export default Index;
