import React, {useEffect, useState} from 'react';
import {Card, Typography, message, Space, Input, Form} from 'antd'
import {AddButton, EditButton, DeleteButton, PageTitle, Spinner, FormModal, DataTable} from '../../../components'
import {SentralModel} from '../../../models/SentralModel'

const {Text} = Typography
const Index = () => {
    // Variable

    const [applications, setApplications] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [modalTitle, setModalTitle]= useState(null)
    const [formModal, setFormModal] = useState(false)
    const [saving, setSaving] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [status, setStatus] = useState(false)

    // TARIK DATA
    const fetchApplications = () => {
        SentralModel.list('Application', {orderBy: 'application_name', order: 'asc'}).then(res=>{
            setLoaded(false)
            setApplications(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    // OPEN FIRST PAGE
    useEffect(() => {
        fetchApplications()
    }, []);
    
    
    const dataSource = [
        ...applications.map( el => {
            return {
                application_name: el.application_name,
                application_logo: el.application_logo,
                application_url:  el.application_url
            }
        })
    ];

    const columns = [{
            title: 'No',
            dataIndex: 'no',
            key: 'No',
        },
        {
            title: 'Application Name',
            dataIndex: 'application_name',
            key: 'application_name',
            width: '30%'
        },
        {
            title: 'Application URL',
            dataIndex: 'application_url',
            key: 'application_url',
            width: '30%'
        },
        {
            title: 'Application Logo',
            dataIndex: 'application_logo',
            key: 'application_logo',
            width: '40%'
        },
        {
            title: 'Action',
            width:120,
            render:(row) => (
                <div className="text-center">
                    <Space>
                        <EditButton link to={"/setting/general/detail/" + row.general_code}/>
                        <DeleteButton onConfirm={() => deleteApplications(row.general_code)}/>
                    </Space>
                </div>
            )
        },
    ];

    const addApplication=()=>{
        setModalTitle('Add Application')
        setDefaultValues({
            position_id: 0,
            position_name: '',
            level: '',
            is_active: true
        })
        setStatus(true)
        setFormModal(true)
    }

    const deleteApplications = (general_code) =>{
        message.loading('deleting General')
        SentralModel.deleteById('General', general_code).then((res)=>{
            message.destroy()
            message.success('General deleted', 2)
            let tmp = applications
            let index = tmp.findIndex(el => el.general_code === general_code)
            tmp.splice(index, 1);
            setApplications([])
            setApplications(tmp)
        })
    }

    const savePosition = (v) => {
        setSaving(true)
        let payload={
            position_id: v.id,
            position_name: v.position_name,
            level: v.level,
            is_active: status
        }
        SentralModel.save('Position', payload, v.position_id).then((res)=>{
            setFormModal(false)
            fetchApplications()
            message.success('Position saved',2);
        }).finally(() => {
            setSaving(false)
        })
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Applications" breadcrumbs={[['Application']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>Application List</Text>
                        <AddButton right onAdd={addApplication} title="New Application"/>
                    </div> }>

                    {
                        (loaded) ?
                        <DataTable size='small' bordered dataSource={dataSource} columns={columns}/>
                        : <Spinner />
                    }
                </Card>
                {
                    (formModal) ?
                    <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v)=>savePosition(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                        <Form.Item name="application_id" hidden>
                            <Input placeholder="Application ID" />
                        </Form.Item>
                        <Form.Item label="Applcation Name" name="application_name" required rules={[{required:true}]}>
                            <Input placeholder="Application Name" />
                        </Form.Item>
                        <Form.Item label="Application URL" name="application_url" rules={[{required:true}]}>
                            <Input placeholder="URL" />
                        </Form.Item>
                        <Form.Item label="Application Logo" name="application_logo" rules={[{required:true}]}>
                            <Input placeholder="LOGO" />
                        </Form.Item>
                    </FormModal> :
                    null
                }
            </Card>
        </div>
    );
}

export default Index;
