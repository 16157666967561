import React, {useEffect, useState} from 'react';
import {Card, Typography, Form, Input, message, Space} from 'antd'
import {AddButton, EditButton, DeleteButton, PageTitle, Spinner, FormModal, DataTable} from '../../../components'
import {SentralModel} from '../../../models/SentralModel'

const {Text} = Typography
const Index = () => {
    const [provinces, setProvinces] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [saving, setSaving] = useState(false)
    const [modalTitle, setModalTitle]= useState(null)
    const fetchProvinces = () =>{
        SentralModel.list('Province', {orderBy:"province_name", order:"asc"}).then(res=>{
            setProvinces(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchProvinces()
    }, []);
    
    const dataSource = [
        ...provinces.map( el => {
            return {
                id: el.province_id,
                province_name: el.province_name
            }
        })
    ];

    const columns = [{
            title: 'No',
            dataIndex: 'no',
            key: 'name',
        },
        {
          title: 'Province Name',
          dataIndex: 'province_name',
          key: 'province_name'
        },
        {
            title: 'Action',
            width:120,
            render:(row) =>(
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => editProvinces(row)}/>
                        <DeleteButton onConfirm={() => deleteProvinces(row.id)}/>
                    </Space>
                </div>
            )
        },
    ];

    const addProvince=()=>{
        setModalTitle('Add Province')
        setDefaultValues({
            province_id: 0,
            province_name: ''
        })
        setFormModal(true)
    }

    const editProvinces = (v) =>{
        setModalTitle('Edit Provinces')
        setDefaultValues({
            province_id: v.id,
            province_name: v.province_name
        })
        setFormModal(true)
    }

    const deleteProvinces = (id) =>{
        message.loading('deleting province')
        SentralModel.deleteById('Province', id).then((res)=>{
            message.destroy()
            message.success('Provinces deleted', 2)
            let tmp = provinces
            let index = tmp.findIndex(el => el.province_id===id)
            tmp.splice(index, 1);
            setProvinces([])
            setProvinces(tmp)
        })
    }

    const saveProvince = (v) => {
        setSaving(true)
        let payload={
            province_id: v.id,
            province_name: v.province_name
        }
        SentralModel.save('Province', payload, v.province_id).then((res)=>{
            setFormModal(false)
            fetchProvinces()
            message.success('Province saved',2);
        }).finally(() => {
            setSaving(false)
        })
    }


    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Provinces" breadcrumbs={[['Province']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>Province List</Text>
                        <AddButton right onAdd={addProvince} title="New Province"/>
                    </div> }>

                    {
                        (loaded) ?
                        <DataTable size='small' bordered dataSource={dataSource} columns={columns}/>
                        :
                        <Spinner/>
                    }
                </Card>
                {
                    (formModal) ?
                    <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v)=>saveProvince(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                        <Form.Item name="province_id" hidden>
                            <Input placeholder="input placeholder" />
                        </Form.Item>
                        <Form.Item label="Province Name" name="province_name"  required rules={[{required:true}]}>
                            <Input placeholder="Province Name" />
                        </Form.Item>
                    </FormModal> :
                    null
                }
            </Card>
        </div>
    );
}

export default Index;
