import { useState, useEffect } from 'react'
import { Button, Card, Col, Divider, Form, Input, Row, Select, message } from 'antd'
import { useParams } from 'react-router-dom'
import { DataTable, PageTitle } from '../../../../components'
import { HistoryOutlined } from '@ant-design/icons'
import { SentralModel } from '../../../../models/SentralModel'
import moment from 'moment'

const { Option } = Select

const TicketDetailAdmin = () => {
    const { ticketId } = useParams()
    const [form] = Form.useForm()
    const [listTechnician, setListTechnician] = useState([])
    const [technicians, setTechnicians] = useState([])
    const [handling, setHandling] = useState([])
    const [status, setStatus] = useState('open')

    const fetchTicketDetail = () => {
        SentralModel.action("Task", "ticketDetail", 0, ticketId).then((res) => {
            const v = res.data
            let arr = []
            let technician = []
            if (v.multiple_task_to.length > 0) {
                v.multiple_task_to?.forEach((el) => {
                    arr.push(el?.task_to?.employee_code)
                    technician.push({ name: el?.task_to?.name, employee_code: el?.task_to?.employee_code })
                })
            }

            if (v.handling_ticket.length > 0) {
                setHandling(v.handling_ticket)
            }

            form.setFieldsValue({
                id: v.id,
                task_id: v.task_id,
                type_job: v.type_job,
                unique_code: v.unique_code,
                quantity: v.quantity,
                company_name: v.company_name,
                serial_number: v.serial_number,
                company_address: v.company_address,
                item_name: v.item_name,
                company_phone: v.company_phone,
                sku: v.sku,
                ticket_status: v.status === "Finished" ? "close" : "open",
                sla_level: v.sla_level,
                due_date: moment(v.due_date).format("MM/DD/YYYY"),
                pic_name: v.pic_name,
                description: v.description,
                technicians: arr
            })
        })
    }

    const fetchTechnician = () => {
        SentralModel.action("Task", "getTechnician", 0, 0).then((res) => {
            let arr = []
            res.data.forEach((el) => {
                arr.push({ label: el.name, value: el.employee_code })
            })
            setListTechnician(arr)
        })
    }

    useEffect(() => {
        fetchTicketDetail()
        fetchTechnician()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const submitTicket = (v) => {
        let payload = {
            ...v,
            technicians: technicians
        }

        if (status === "close") {
            SentralModel.action("Task", "closeTicket", 0, v.id).then((res) => {
                message.success("Ticket Closed")
                fetchTicketDetail()
            }).catch((err) => {
                message.warning("Failed Close Ticket")
            })
        } else {
            SentralModel.action("Task", "assignTicket", payload, 0).then((res) => {
                message.success("Success Assign Ticket")
                fetchTicketDetail()
            }).catch((err) => {
                message.warning("Failed Assign Ticket")
            })
        }
    }

    const handleChangeTechnician = (v) => {
        setTechnicians(v)
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
            width: '5%',
            align: 'center'
        },
        {
            title: 'No Handling',
            key: 'no_handling',
            dataIndex: 'all_handling_ticket_id',
        },
        {
            title: 'Technician',
            key: 'technician',
            render: (row) => (
                row?.technician?.name
            ),
        },
        {
            title: 'Date',
            key: 'date',
            render: (row) => (
                moment(row.created_at).format("dddd, DD MMMM YYYY")
            )
        },
        {
            title: 'Type',
            key: 'type',
            dataIndex: 'type'
        },
        {
            title: 'Distance',
            key: 'distance',
            dataIndex: 'distance'
        },
        {
            title: 'Description',
            key: 'description',
            dataIndex: 'handling_description',
        },
    ]

    return (
        <Card className="content-container">
            <PageTitle title={`Ticket Detail #${ticketId}`} breadcrumbs={[['Ticket Detail']]}></PageTitle>

            <Form form={form} layout='vertical' onFinish={(v) => submitTicket(v)}>
                <Form.Item name="id" hidden>
                    <Input />
                </Form.Item>
                <Form.Item name="task_id" hidden>
                    <Input />
                </Form.Item>
                <Row justify='space-between'>
                    <Col span={11}>
                        <Form.Item label="Type Job" name="type_job">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="Ticket Number" name="unique_code">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify='space-between'>
                    <Col span={11}>
                        <Form.Item label="Quantity" name="quantity">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="Company Name" name="company_name">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify='space-between'>
                    <Col span={11}>
                        <Row justify='space-between'>
                            <Col span={19}>
                                <Form.Item label="Serial Number" name="serial_number">
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Button type="primary" style={{ borderRadius: 10, marginTop: 25, height: 40 }}><HistoryOutlined /></Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="Address" name="company_address">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify='space-between'>
                    <Col span={11}>
                        <Row justify='space-between'>
                            <Col span={11}>
                                <Form.Item label="Item Name" name="item_name">
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item label="SKU / Product No" name="sku">
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="Phone" name="company_phone">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify='space-between'>
                    <Col span={11}>
                        <Row justify='space-between'>
                            <Col span={11}>
                                <Form.Item label="SLA Level" name="sla_level">
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item label="Due Date" name="due_date">
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="PIC" name="pic_name">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify='space-between'>
                    <Col span={11}>
                        <Form.Item label="Technician" name="technicians">
                            <Select
                                mode="multiple" allowClear placeholder="Select Technician"
                                onChange={(v) => handleChangeTechnician(v)}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={listTechnician} />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="Ticket Status" name="ticket_status">
                            <Select defaultValue={status} onChange={(v) => setStatus(v)}>
                                <Option value="open">Open</Option>
                                <Option value="close">Close</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={11}>
                        <Form.Item label="Description" name="description">
                            <Input.TextArea rows={10} disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={11}>
                        <Form.Item label="Laporan Kerusakan">
                            <Input.TextArea rows={7} />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item>
                    <Button type='primary' htmlType='submit'>
                        Save
                    </Button>
                </Form.Item>
            </Form>

            <Divider />
            <div>
                <DataTable size dataSource={handling} columns={columns} bordered pagination={false} search={false} />
            </div>
        </Card>
    )
}

export default TicketDetailAdmin