import React, { useState, useEffect } from 'react';
import { Card, Select, DatePicker, Form, Button, Drawer, Space } from 'antd';
import { FilterOutlined, FileExcelOutlined } from '@ant-design/icons'
import { PageTitle, DataTable, Spinner } from '../../../components';
import { toFullDate } from '../../../utils/dateHandler'
import { SentralModel } from '../../../models/SentralModel';
import moment from 'moment'

const { RangePicker } = DatePicker
const { Option } = Select

const Overtime = () => {
    const [loaded, setLoaded] = useState(true)
    const [showFilter, setShowFilter] = useState(false)
    const [categories, setCategories] = useState([])
    const [divisions, setDivisions] = useState([])
    const [overtimes, setOvertimes] = useState([])
    const [selectedDivision, setSelectedDivision] = useState(null)
    const [selectedDateRange, setSelectedDateRange] = useState([])
    const [selectedCategory, setSelectedCategory] = useState([])

    const fetchReport = (ft) => {
        setLoaded(false)
        SentralModel.action('Report', 'overtime', ft, 0).then((res) => {
            setOvertimes(res.data)
            setLoaded(true)
        })
    }

    const fetchDivision = () => {
        SentralModel.list('Division', { orderBy: 'division_name', order: 'ASC' }, 0).then((res) => {
            setDivisions(res.data);
        })
    }

    const fetchOvertimeType = () => {
        SentralModel.list('OvertimeCategory', null, 0).then((res) => {
            setCategories(res.data);
        })
    }

    useEffect(() => {
        fetchDivision()
        fetchOvertimeType()
    }, []);

    const filterData = (v) => {
        let ft = {
            division_id: v.division_id,
            category: v.category,
            date_range: (v.date_range) ? [moment(v.date_range[0]).format('YYYY-MM-DD'), moment(v.date_range[1]).format('YYYY-MM-DD')] : []
        }
        fetchReport(ft)
        setShowFilter(false)
    }

    const overtimeColumn = [{
        title: 'No',
        dataIndex: 'no',
        align: 'center',
        key: 'name',
    },
    {
        title: 'Employee Name',
        dataIndex: 'name',
        key: 'name',
    }, {
        title: 'Division',
        dataIndex: 'division_name',
        key: 'division_name',
    }, {
        title: 'Overtime Category',
        dataIndex: 'overtime_category',
        key: 'overtime_category',
    }, {
        title: 'Overtime Type',
        dataIndex: 'overtime_type',
        key: 'overtime_type',
    }, {
        title: 'Necessity',
        dataIndex: 'description',
        key: 'description'
    }, {
        title: 'Overtime Date',
        dataIndex: 'from_date',
        key: 'from_date',
        render: (text) => (
            toFullDate(text)
        )
    }, {
        title: 'Time',
        render: (row) => (
            row.from_time + ' - ' + row.to_time
        )
    }, {
        title: 'Total Hours',
        dataIndex: 'total_hours',
        key: 'total_hours',
        render: (text) => (
            <div className="text-center">
                {text}
            </div>
        )
    }
    ];

    const changeRangeDate = (v) => {
        if (v) {
            setSelectedDateRange([moment(v[0]).format('YYYY-MM-DD'), moment(v[1]).format('YYYY-MM-DD')])
        } else {
            setSelectedDateRange([])
        }
    }

    const exportReport = () => {
        window.open(process.env.REACT_APP_API_URL + '/report/overtime?division_id=' + selectedDivision + '&category=' + selectedCategory.toString() + '&from=' + selectedDateRange[0] + '&to=' + selectedDateRange[1])
    }
    return (
        <Card className="content-container">
            <PageTitle title="Overtime Report" breadcrumbs={[['Overtime Report']]}></PageTitle>

            <div className="text-right mb-2">
                <Space>
                    <Button type="primary" onClick={() => exportReport()} shape="circle" icon={<FileExcelOutlined />}></Button>
                    <Button shape="circle" icon={<FilterOutlined />} onClick={() => setShowFilter(true)}></Button>
                </Space>
            </div>
            {
                (loaded) ?
                    <DataTable size="small" columns={overtimeColumn} dataSource={overtimes} bordered />
                    : <Spinner />
            }

            <Drawer
                title="Filter Report"
                width={400}
                placement="right"
                closable={false}
                onClose={() => setShowFilter(false)}
                visible={showFilter}
            >
                <Form layout="vertical" onFinish={filterData}>
                    <Form.Item name="division_id" label="Division">
                        <Select
                            style={{ width: '100%' }}
                            onChange={
                                (v) => {
                                    setSelectedDivision(v)
                                }
                            }
                            placeholder="Select Division" allowClear>
                            {
                                divisions.map((el, key) => (
                                    <Option key={key} value={el.division_id}>{el.division_name}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name="category" label="Overtime Category">
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Overtime Category"
                            mode="multiple"
                            onChange={(v) => {
                                setSelectedCategory(v)
                            }} allowClear>
                            {
                                categories.map((el, key) => (
                                    <Option key={key} value={el.overtime_category_id}>{el.description}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name="date_range" label="Date Range">
                        <RangePicker onChange={(v) => changeRangeDate(v)} />
                    </Form.Item>
                    <Form.Item >
                        <div className="text-right">
                            <Button type="primary" htmlType="submit" size="middle" icon={<FilterOutlined />}> Show</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Drawer>
        </Card>
    );
}

export default Overtime;
