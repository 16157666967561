import React, { useState, useEffect } from 'react';
import { Card, Select, DatePicker, Form, Button, Drawer, Space } from 'antd';
import { FilterOutlined, FileExcelOutlined } from '@ant-design/icons'
import { PageTitle, DataTable, Spinner } from '../../../components';
import { formatDateTime } from '../../../utils/dateFormat'
import { toIdr } from '../../../utils/currencyHandler'
import { SentralModel } from '../../../models/SentralModel';
import moment from 'moment'

const { RangePicker } = DatePicker
const { Option } = Select

const PurchaseRequest = () => {
    const [loaded, setLoaded] = useState(true)
    const [showFilter, setShowFilter] = useState(false)
    const [divisions, setDivisions] = useState([])
    const [purchaseRequestData, setPurchaseRequestData] = useState([])
    const [selectedDivision, setSelectedDivision] = useState(null)
    const [selectedDateRange, setSelectedDateRange] = useState([])

    const fetchDivision = () => {
        SentralModel.list('Division', { orderBy: 'division_name', order: 'ASC' }, 0).then((res) => {
            setDivisions(res.data);
        })
    }

    useEffect(() => {
        fetchDivision()
    }, []);

    const filterData = (v) => {
        let ft = {
            division_id: v.division_id ? v.division_id : null,
            date_range: (v.date_range) ? [moment(v.date_range[0]).format('YYYY-MM-DD'), moment(v.date_range[1]).format('YYYY-MM-DD')] : []
        }
        fetchReport(ft)
        setShowFilter(false)
    }

    const fetchReport = (ft) => {
        setLoaded(false)
        SentralModel.action('Report', 'purchaseRequest', ft, 0).then((res) => {
            setPurchaseRequestData(res.data)
            setLoaded(true)
        })
    }

    const purchaseRequestColumn = [{
        title: 'No',
        dataIndex: 'no',
        align: 'center',
        className: 'text-top',
        fixed: 'left',
        key: 'name',
    },
    {
        title: 'Division',
        dataIndex: 'division',
        width: '200px',
        fixed: 'left',
        className: 'text-top',
    }, {
        title: 'COA',
        dataIndex: 'coa',
        width: '250px',
        fixed: 'left',
        className: 'text-top',
    }, {
        title: 'Request Date',
        dataIndex: 'request_date',
        width: '150px',
        fixed: 'left',
        className: 'text-top',
    }, {
        title: 'Budget Plan',
        dataIndex: 'budget_plan',
        width: '150px',
        className: 'text-top',
    }, {
        title: 'Total Amount',
        dataIndex: 'total_amount',
        width: '150px',
        className: 'text-top',
    }, {
        title: 'Item',
        dataIndex: 'item_name',
        width: '200px',
        className: 'text-top',
    }, {
        title: 'Price',
        dataIndex: 'price',
        width: '150px',
        className: 'text-top',
    }, {
        title: 'QTY',
        dataIndex: 'quantity',
        width: '100px',
        align: 'center',
        className: 'text-top',
    }, {
        title: 'Total',
        dataIndex: 'total',
        width: '150px',
        className: 'text-top',
    }
    ];

    const generateRows = () => {
        let rows = [];
        purchaseRequestData.forEach((el, key) => {
            el.items.forEach((el2, index) => {
                let x = {};
                if (index === 0) {
                    x = {
                        division: el.division.division_name,
                        coa: el.coa.coa_name,
                        budget_plan: el.budget_planning.description,
                        request_date: formatDateTime(el.created_at),
                        item_name: el2.item_name,
                        price: toIdr(el2.price),
                        quantity: el2.quantity,
                        total_amount: toIdr(el.total_amount),
                        total: toIdr(el2.total),
                        source_of_price: el2.source_of_price,
                        remarks: el2.remarks,
                        first_flag: 1
                    }
                } else {
                    x = {
                        item_name: el2.item_name,
                        price: toIdr(el2.price),
                        quantity: el2.quantity,
                        total: toIdr(el2.total),
                        source_of_price: el2.source_of_price,
                        remarks: el2.remarks,
                        first_flag: 0
                    }
                }

                rows.push(x)
            })
        })
        return rows;
    }

    const changeDateRange = (v) => {
        if (v) {
            setSelectedDateRange([moment(v[0]).format('YYYY-MM-DD'), moment(v[1]).format('YYYY-MM-DD')])
        } else {
            setSelectedDateRange([])
        }
    }

    const exportReport = () => {
        window.open(process.env.REACT_APP_API_URL + '/report/purchaserequest?division_id=' + selectedDivision + '&from=' + selectedDateRange[0] + '&to=' + selectedDateRange[1])
    }

    return (
        <Card className="content-container">
            <PageTitle title="Purchase Request Report" breadcrumbs={[['Purchase Request Report']]}></PageTitle>

            <div className="text-right mb-2">
                <Space>
                    <Button type="primary" onClick={() => exportReport()} shape="circle" icon={<FileExcelOutlined />}></Button>
                    <Button shape="circle" icon={<FilterOutlined />} onClick={() => setShowFilter(true)}></Button>
                </Space>
            </div>
            {
                (loaded) ?
                    <DataTable scroll={{ x: 1500 }} pagination={false} size="small" columns={purchaseRequestColumn} dataSource={generateRows()} bordered />
                    : <Spinner />
            }

            <Drawer
                title="Filter Report"
                width={400}
                placement="right"
                closable={false}
                onClose={() => setShowFilter(false)}
                visible={showFilter}>
                <Form layout="vertical" onFinish={filterData}>
                    <Form.Item name="division_id" label="Division">
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Select Division"
                            onChange={(v) => {
                                setSelectedDivision(v)
                            }}
                            allowClear>
                            {
                                divisions.map((el, key) => (
                                    <Option key={key} value={el.division_id}>{el.division_name}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name="date_range" label="Date Range">
                        <RangePicker onChange={(v) => changeDateRange(v)} />
                    </Form.Item>
                    <Form.Item >
                        <div className="text-right">
                            <Button htmlType="submit" type="primary" size="middle" icon={<FilterOutlined />}> Show</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Drawer>
        </Card>
    );
}

export default PurchaseRequest;
