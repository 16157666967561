import React from 'react';
import {Tabs, Space} from 'antd'
import {FieldTimeOutlined,CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons'
import {DataTable, ShowButton} from '../../../../components'
import {toIdr} from '../../../../utils/currencyHandler'

const {TabPane} = Tabs;

const RealizationRequest = (props) => {

    const entData = [
        ...props.data.map(el => {
            return { 
                id: el.sub_entertainment_id,
                title: el.title,
                description: el.description,
                total: el.total_amount,
                status: el.status,
                status_realization: el.status_realization,
                approver: el.approver,
                created_at: el.created_at,
            }
        })
    ]

    const entColumn = [{
        title: 'No',
        dataIndex: 'no',
        align:'center',
        key: 'name',
    },
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title'
    },{
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },{
        title: 'Total',
        dataIndex: 'total',
        render: (text) => (
            toIdr(text)
        )
    },{
        title: 'Action',
        align:'center',
        render : (row) => (
            <Space>
                <ShowButton link to={'/entertainment-realization/' + row.id} />
            </Space>
        )
    }];

    return (
        <div>
            <Tabs defaultActiveKey="waiting" type="card">
                <TabPane tab={<span><FieldTimeOutlined style={{color:'#4ca3ff'}}/> Waiting Approval</span>} key="waiting">
                    <DataTable size="small" columns={entColumn} dataSource={entData.filter(el => el.status_realization === "WAITING")} bordered/>
                </TabPane>
                <TabPane tab={<span><CheckCircleOutlined style={{color:'green'}}/> Approved</span>} key="approved">
                    <DataTable size="small" columns={entColumn} dataSource={entData.filter(el => el.status_realization === "APPROVED")} bordered/>
                </TabPane>
                <TabPane tab={<span><CloseCircleOutlined style={{color:'red'}}/> Rejected</span>} key="rejected">
                    <DataTable size="small" columns={entColumn} dataSource={entData.filter(el => el.status_realization === "REJECTED")} bordered/>
                </TabPane>
            </Tabs>
        </div>
    );
}

export default RealizationRequest;
