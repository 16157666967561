import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form, Input, Typography, DatePicker, Tabs, Select, Button, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons'
import { SubmitButton, AddButton } from '../../../../components'
import { toIdr, extractNumber } from '../../../../utils/currencyHandler';
import { SentralModel } from '../../../../models/SentralModel'
import moment from 'moment'

const { TextArea } = Input
const { Text } = Typography
const { Option } = Select
const { TabPane } = Tabs

function useForceUpdate() {
    // eslint-disable-next-line
    const [value, setValue] = useState(0);
    return () => setValue(value => ++value);
}

const RequestForm = (props) => {
    const forceUpdate = useForceUpdate()
    const [entForm] = Form.useForm()
    const [giftCount, setGiftCount] = useState(1)
    const [giftItem, setGiftItem] = useState([{ date: null, gift: '', place: '', address: '', amount: '' }])
    const [totalItemPrice, setTotalItemPrice] = useState(null)
    const [relationCount, setRelationCount] = useState(1)
    // const [attachmentFile, setAttachmentFile] = useState(null)
    const [relationItem, setRelationItem] = useState([{ name: '', company: '', position: '', business_type: '' }])
    // const [uploading, setUploading] = useState(false)
    const [types, setTypes] = useState([])

    const fetchTypes = () => {
        let payload = {
            filter: [["general_code", "ENT"]],
            orderBy: "order_key",
            order: "asc"
        }
        SentralModel.list('GeneralDetail', payload).then((res) => {
            setTypes(res.data)
        })
    }

    useEffect(() => {
        fetchTypes()
    }, []);

    // const fileUploaded = (v) => {
    //     setAttachmentFile(v.message)
    // }

    const addGiftItem = () => {
        let x = giftItem
        let key = giftItem.length
        x.push({ date: null, gift: '', place: '', address: '', amount: '' })
        entForm.setFieldsValue({
            ['gift_date_' + key]: null,
            ['gift_name_' + key]: null,
            ['gift_place_' + key]: null,
            ['gift_address_' + key]: null,
            ['gift_price_' + key]: null
        })
        setGiftItem(x)
        setGiftCount(giftCount + 1)
    }

    const addRelationItem = () => {
        let x = relationItem
        let key = relationItem.length
        x.push({ name: '', company: '', position: '', business_type: '' })
        entForm.setFieldsValue({
            ['relation_name_' + key]: null,
            ['relation_position_' + key]: null,
            ['relation_organization_' + key]: null,
            ['relation_business_type_' + key]: null,
        })
        setRelationItem(x)
        setRelationCount(relationCount + 1)
    }

    const changePrice = (v, key) => {
        let x = giftItem
        if (v.target.value) {
            let newPrice = extractNumber(v.target.value)
            x[key].amount = newPrice
            entForm.setFieldsValue({ ['gift_price_' + key]: toIdr(newPrice) })
            setGiftItem(x)
            changeTotalPrice()
        }
    }

    const changeTotalPrice = () => {
        let total = 0;
        giftItem.forEach(el => {
            total += el.amount
        })
        setTotalItemPrice(total)
    }

    const submitEntertainment = (v) => {
        // if (!attachmentFile) {
        //     message.error('Attachment is required', 2)
        // } else {
            if (relationItem[0].name === '') {
                message.error('Relation is required', 2)
            } else {
                let ent = {
                    // attachment: attachmentFile,
                    title: v.title,
                    type: v.type,
                    total_amount: totalItemPrice,
                    description: v.description,
                    gifts: giftItem,
                    relations: relationItem
                }
                props.onSubmit(ent)
            }
        // }
    }

    const removeGiftItem = (key) => {
        let item = giftItem
        item.splice(key, 1)
        setGiftItem(item)
        forceUpdate()
    }
    const removeRelationItem = (key) => {
        let item = relationItem
        item.splice(key, 1)
        setRelationItem(item)
        forceUpdate()
    }

    return (
        <Form size="middle" layout="vertical" form={entForm} onFinish={submitEntertainment}>
            <Row justify="center">
                <Col span={22}>
                    <Row>
                        <Col span={12} style={{ paddingRight: 10 }}>
                            <Form.Item name="title" label="Title" rules={[{ required: true, message: "Title is required" }]}>
                                <Input type="text" placeholder="Title" />
                            </Form.Item>
                            <Form.Item name="description" label="Description" rules={[{ required: true, message: "Description is required" }]}>
                                <TextArea placeholder="Description" autoSize={{ minRows: 2 }} />
                            </Form.Item>
                        </Col>
                        <Col span={12} style={{ paddingLeft: 10 }}>
                            <Form.Item name="type" label="Type" rules={[{ required: true, message: "Type is required" }]}>
                                <Select loading={types.length === 0} placeholder="Select type">
                                    {
                                        types.map((ty, key) => (
                                            <Option key={key} value={ty.detail_code}>{ty.description}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                            {/* <Form.Item label="Attachment">
                                <Uploader folder="submission-entertainment" onUploaded={(v) => fileUploaded(v)} isUploading={(v) => setUploading(v)} view={true} />
                            </Form.Item> */}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Card>
                                <Tabs defaultActiveKey="gift" type="card">
                                    <TabPane tab="Item" key="gift">
                                        <Card title={
                                            <div className="full-width">
                                                <Text strong style={{ float: 'left' }}>Entertainment Item</Text>
                                                <AddButton right onAdd={() => addGiftItem()} title="Add Item" />
                                            </div>}>
                                            <table className="table-item" style={{ width: '100%' }}>
                                                <thead>
                                                    <tr>
                                                        <th>Item</th>
                                                        <th>Date</th>
                                                        <th>Place</th>
                                                        <th>Address</th>
                                                        <th>Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        giftItem.map((el, key) => (
                                                            <tr key={'rmb_item' + key}>
                                                                <td>
                                                                    <Form.Item name={`gift_name_${key}`} rules={[{ required: true, message: "Item is required" }]}>
                                                                        <Input placeholder="Item" onChange={(v) => {
                                                                            let x = giftItem
                                                                            x[key].gift = v.target.value
                                                                            setGiftItem(x)
                                                                        }} />
                                                                    </Form.Item>
                                                                </td>
                                                                <td>
                                                                    <Form.Item name={`gift_date_${key}`} rules={[{ required: true, message: "Date is required" }]}>
                                                                        <DatePicker style={{ width: '100%' }} onChange={(e, d) => {
                                                                            let x = giftItem
                                                                            x[key].date = moment(d).format('YYYY-MM-DD')
                                                                            setGiftItem(x)
                                                                        }} />
                                                                    </Form.Item>
                                                                </td>
                                                                <td>
                                                                    <Form.Item name={`gift_place_${key}`} rules={[{ required: true, message: "Place is required" }]}>
                                                                        <Input placeholder="Place" onChange={(v) => {
                                                                            let x = giftItem
                                                                            x[key].place = v.target.value
                                                                            setGiftItem(x)
                                                                        }} />
                                                                    </Form.Item>
                                                                </td>
                                                                <td>
                                                                    <Form.Item name={`gift_address_${key}`} rules={[{ required: true, message: "Address is required" }]}>
                                                                        <Input placeholder="Address" onChange={(v) => {
                                                                            let x = giftItem
                                                                            x[key].address = v.target.value
                                                                            setGiftItem(x)
                                                                        }} />
                                                                    </Form.Item>
                                                                </td>
                                                                <td>
                                                                    <Form.Item name={`gift_price_${key}`} rules={[{ required: true, message: "Price is required" }]}>
                                                                        <Input placeholder="Price" onChange={(v) => changePrice(v, key)} />
                                                                    </Form.Item>
                                                                </td>
                                                                <td className="text-center" style={{ width: '60px' }}>
                                                                    {
                                                                        ((key + 1) === giftItem.length && giftItem.length > 1) ?
                                                                            <Button type="text" danger onClick={() => removeGiftItem(key)} icon={<DeleteOutlined />} />
                                                                            : null
                                                                    }
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                    <tr style={{ borderTop: '1px solid #ededed' }}>
                                                        <td className="text-right" colSpan={4}>Total : </td>
                                                        <td >{toIdr(totalItemPrice)}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Card>
                                    </TabPane>
                                    <TabPane tab="Relation" key="relation">
                                        <Card title={
                                            <div className="full-width">
                                                <Text strong style={{ float: 'left' }}>Entertainment Relation</Text>
                                                <AddButton right onAdd={() => addRelationItem()} title="Add Relation Item" />
                                            </div>}>
                                            <table className="table-item" style={{ width: '100%' }}>
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Position</th>
                                                        <th>Organization</th>
                                                        <th>Business Type</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        relationItem.map((el, key) => (
                                                            <tr key={'rmb_item' + key}>
                                                                <td>
                                                                    <Form.Item name={`relation_name_${key}`} rules={[{ required: true, message: "Name is required" }]}>
                                                                        <Input placeholder="Name" onChange={(v) => {
                                                                            let x = relationItem
                                                                            x[key].name = v.target.value
                                                                            setRelationItem(x)
                                                                        }} />
                                                                    </Form.Item>
                                                                </td>
                                                                <td>
                                                                    <Form.Item name={`relation_position_${key}`} rules={[{ required: true, message: "Position is required" }]}>
                                                                        <Input placeholder="Position" onChange={(v) => {
                                                                            let x = relationItem
                                                                            x[key].position = v.target.value
                                                                            setRelationItem(x)
                                                                        }} />
                                                                    </Form.Item>
                                                                </td>
                                                                <td>
                                                                    <Form.Item name={`relation_organization_${key}`} rules={[{ required: true, message: "Organization is required" }]}>
                                                                        <Input placeholder="Organization" onChange={(v) => {
                                                                            let x = relationItem
                                                                            x[key].company = v.target.value
                                                                            setRelationItem(x)
                                                                        }} />
                                                                    </Form.Item>
                                                                </td>
                                                                <td>
                                                                    <Form.Item name={`relation_business_type_${key}`} rules={[{ required: true, message: "Business type is required" }]}>
                                                                        <Input placeholder="Business Type" onChange={(v) => {
                                                                            let x = relationItem
                                                                            x[key].business_type = v.target.value
                                                                            setRelationItem(x)
                                                                        }} />
                                                                    </Form.Item>
                                                                </td>
                                                                <td className="text-center" style={{ width: '60px' }}>
                                                                    {
                                                                        ((key + 1) === relationItem.length && relationItem.length > 1) ?
                                                                            <Button type="text" danger onClick={() => removeRelationItem(key)} icon={<DeleteOutlined />} />
                                                                            : null
                                                                    }
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </Card>
                                    </TabPane>
                                </Tabs>
                            </Card>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col span={24} className="text-right">
                            <SubmitButton loading={props.saving} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
}

export default RequestForm;
