import { Button, Progress, Space, Tag } from 'antd'
import { FileExcelOutlined } from '@ant-design/icons'
import { DataTable } from '../../../../components'
import moment from 'moment'

const ActionPlan = ({ data }) => {
    const generateRows = () => {
        let rows = [];
        data.forEach((el, key) => {
            el.action_plan.forEach((ap, index) => {
                let x = {};
                if (index === 0) {
                    x = {
                        division: el.division,
                        project_name: ap.project_name,
                        start_date: ap.start_date,
                        end_date: ap.end_date,
                        realization_date: ap.realization_date,
                        progress: ap.progress,
                    }
                } else {
                    x = {
                        project_name: ap.project_name,
                        start_date: ap.start_date,
                        end_date: ap.end_date,
                        realization_date: ap.realization_date,
                        progress: ap.progress,
                    }
                }

                rows.push(x)
            })
        })
        return rows;
    }

    const actionPlanColumn = [
        {
            title: 'Divison Name',
            dataIndex: 'division',
            key: 'division',
            className: 'text-top',
            fixed: 'left',
            align: 'center'
        },
        {
            title: 'Action Plan',
            dataIndex: 'project_name',
            key: 'project_name',
            align: 'center'
        },
        {
            title: 'Start Date',
            key: 'start_date',
            dataIndex: 'start_date',
            render: (row) => (
                moment(row).format('MMMM YYYY')
            ),
            align: 'center'
        },
        {
            title: 'Due Date',
            key: 'end_date',
            dataIndex: 'end_date',
            render: (row) => (
                moment(row).format('MMMM YYYY')
            ),
            align: 'center'
        },
        {
            title: 'Realization Date',
            key: 'realization_date',
            render: (row) => (
                row.realization_date !== null ?
                    parseInt(moment(row.realization_date).month()) > parseInt(moment(row.end_date).month()) ?
                        <Tag color='#f50'>{moment(row.realization_date).format('MMMM YYYY')}</Tag> :
                        <Tag color='#2db7f5'>{moment(row.realization_date).format('MMMM YYYY')}</Tag>
                    : '-'
            ),
            align: 'center'
        },
        {
            title: 'Progress',
            dataIndex: 'progress',
            key: 'progress',
            render: (row) => (
                <Progress percent={row} />
            ),
            align: 'center'
        },
    ];

    const exportReport = () => {
        window.open(`${process.env.REACT_APP_API_URL}/report/actionPlan`)
    }

    return (
        <div>
            <div className="text-right mb-2">
                <Space>
                    <Button type="primary" onClick={() => exportReport()} icon={<FileExcelOutlined />}>Export</Button>
                    {/* <Button shape="circle" icon={<FilterOutlined />} onClick={() => setShowFilter(true)}></Button> */}
                </Space>
            </div>
            <DataTable size="small" columns={actionPlanColumn} dataSource={generateRows()} bordered />
        </div>
    )
}

export default ActionPlan