import React, { useState, useEffect } from 'react'
import { Button, Card, DatePicker, Drawer, Form, Select, Space } from 'antd'
import { CustomButton, DataTable, PageTitle, Spinner } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import { FilterOutlined, FileExcelOutlined } from '@ant-design/icons'
import moment from 'moment'
import { ZoomInOutlined } from '@ant-design/icons'

const { Option } = Select

const Index = () => {
    const year = moment().year();
    const [loaded, setLoaded] = useState(false)
    const [balances, setBalances] = useState([])
    const [showFilter, setShowFilter] = useState(false)
    const [divisions, setDivisions] = useState([])
    const [filter, setFilter] = useState({})
    const [massLeave, setMassLeave] = useState(null)
    const [newYear, setNewYear] = useState(year)

    const fetchBalances = (ft) => {
        setLoaded(false)

        return SentralModel.action('Report', 'leaveAnnual', ft, 0).then(res => {
            setLoaded(true)
            setBalances(res.data.annualLeave)
            setMassLeave(res.data.massLeave)
        })
    }

    const fetchDivision = () => {
        SentralModel.list('Division', { orderBy: 'division_name', order: 'ASC' }, 0).then((res) => {
            setDivisions(res.data);
        })
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'name',
        },
        {
            title: 'Employee Code',
            dataIndex: 'employee_code',
            key: 'employee_code',
        },
        {
            title: 'Employee Name',
            dataIndex: 'employee_name',
            key: 'employee_code',
        },
        {
            title: 'Division',
            dataIndex: 'division_name',
            key: 'division',
        },
        {
            title: 'Quota',
            dataIndex: 'quota',
            key: 'quota',
            align: 'center'
        },
        {
            title: 'Used',
            dataIndex: 'used',
            key: 'used',
            align: 'center'
        },
        {
            title: 'Mass Leave',
            key: 'mass_leave',
            render: () => (
                massLeave
            ),
            align: 'center'
        },
        {
            title: 'Balance',
            key: 'balance',
            align: 'center',
            render: (text) => (
                text.balance - massLeave
            )
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <CustomButton icon={<ZoomInOutlined />} link to={`/report/annual-report/detail/${row.employee_code}/${newYear}`} />
                    </Space>
                </div>
            )
        },
    ];

    const filterData = (v) => {
        let ft = {
            division_id: v.division_id ?? '',
            year: moment(v.year).format('YYYY')
        }

        setNewYear(moment(v.year).format('YYYY'))
        fetchBalances(ft)
        setFilter(ft)
        setShowFilter(false)
    }

    useEffect(() => {
        fetchBalances()
        fetchDivision()

    }, []);

    const exportReport = () => {
        window.open(process.env.REACT_APP_API_URL + `/report/annualLeave?division_id=${filter.division_id}&year=${filter.year === undefined ? year : filter.year}`)
    }

    return (
        <Card className="content-container">
            <PageTitle title="Annual Leave Report" breadcrumbs={[['Annual Leave Report']]}></PageTitle>
            <div className="text-right mb-2">
                <Space>
                    <Button type="primary" onClick={() => exportReport()} shape="circle" icon={<FileExcelOutlined />}></Button>
                    <Button shape="circle" icon={<FilterOutlined />} onClick={() => setShowFilter(true)}></Button>
                </Space>
            </div>

            {
                loaded ?
                    <DataTable size='small' bordered dataSource={balances} columns={columns} />
                    : <Spinner />
            }

            <Drawer
                title="Filter Report"
                width={400}
                placement="right"
                closable={false}
                onClose={() => setShowFilter(false)}
                visible={showFilter}
            >
                <Form layout="vertical" onFinish={filterData}>
                    <Form.Item name="division_id" label="Division">
                        <Select style={{ width: '100%' }} placeholder="Select Division" allowClear>
                            {
                                divisions.map((el, key) => (
                                    <Option key={key} value={el.division_id}>{el.division_name}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name="year" label="Year">
                        <DatePicker style={{ width: '100%' }} picker="year" />
                    </Form.Item>
                    <Form.Item >
                        <div className="text-right">
                            <Button type="primary" htmlType="submit" size="middle" icon={<FilterOutlined />}> Show</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Drawer>
        </Card>
    )
}

export default Index