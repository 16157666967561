import React, {useEffect, useState} from 'react';
import {Card, Typography, Form, Input, message, Space} from 'antd'
import {EditButton, PageTitle, Spinner, FormModal, DataTable} from '../../../components'
import {SentralModel} from '../../../models/SentralModel'

const {Text} = Typography
const Index = () => {
    const [employees, setEmployees] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [saving, setSaving] = useState(false)
    const [modalTitle, setModalTitle]= useState(null)

    const fetchEmployees = () =>{
        SentralModel.list('Employee', {orderBy: 'name', order: 'asc'}).then(res=>{
            setEmployees(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchEmployees()
    }, []);

    const dataSource = [
        ...employees.map( el => {
            return {
                employee_code: el.employee_code,
                name: el.name,
                extension_no: el.extension_no
            }
        })
    ]

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'name',
        },
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
            title: 'Extension',
            dataIndex: 'extension_no',
            key: 'extension_no',
            render: (text) =>(
                <div className="text-center">
                    <span>
                        {text ?? '-'}
                    </span>
                </div>
            )
        },
        {
            title: 'Action',
            width:120,
            render:(row) =>(
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => editExtension(row)}/>
                    </Space>
                </div>
            )
        },
    ];

    const editExtension = (v) =>{
        setModalTitle('Edit Extension')
        setDefaultValues({
            employee_code: v.employee_code,
            extension_no: v.extension_no,
        })
        setFormModal(true)
    }

    const saveExtension = (v) => {
        setSaving(true)
        let payload={
            employee_code: v.employee_code,
            extension_no: v.extension_no,
        }
        SentralModel.save('Employee', payload, v.employee_code).then((res)=>{
            setFormModal(false)
            fetchEmployees();
            message.success('Extension saved',2);
        }).finally(() => {
            setSaving(false)
        })
    }


    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Employee Extension" breadcrumbs={[['Extension']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>Extension List</Text>
                    </div> }>

                    {
                        (loaded) ?
                        <DataTable size='small' bordered dataSource={dataSource} columns={columns}/>
                        :
                        <Spinner/>
                    }
                </Card>
                
                {
                    (formModal) ?
                    <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v)=>saveExtension(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                        <Form.Item name="employee_code" hidden>
                            <Input placeholder="Employee Code" />
                        </Form.Item>
                        <Form.Item label="Extension" name="extension_no"  required rules={[{required:true}]}>
                            <Input placeholder="Extension" />
                        </Form.Item>
                    </FormModal>
                    : null
                }
            </Card>
        </div>
    );
}

export default Index;
