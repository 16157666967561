import React, {useState, useEffect} from 'react';
import {Card, Row, Col, Tabs, Typography, Descriptions, Space} from 'antd'
import {FieldTimeOutlined,CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons'
import { PageTitle, NewButton, DataTable, Spinner, ShowButton} from '../../../../components'
import authData from '../../../../helpers/authData'
import {SentralModel} from '../../../../models/SentralModel'
import {toIdr} from '../../../../utils/currencyHandler'
// import moment from 'moment'

const {Text} = Typography
const {TabPane} = Tabs;

const Index = () => {
    const [reimbursements, setReimbursements] = useState(null)
    const [reimbursementInfo, setReimbursementInfo] = useState(null)
    const [instruction, setInstruction] = useState('')
    const [available, setAvailable] = useState(false)

    const fetchReimbursement = () => {
        let payload={
            filter: [["employee_code", authData.employee_code]]
        }
        SentralModel.list('SubmissionReimbursement', payload).then(res=>{
            setReimbursements(res.data)
        })
    }

    const fetchInstruction = () => {
        SentralModel.list('Instruction',{filter:[['code','SBRI']]}, null).then((res)=>{
            setInstruction(res.data.length ? res.data[0].content : '')
        })
    }

    const fetchReimbursementInfo = () => {
        SentralModel.action('Submission','getMonthlyReimbursement', null, null).then((res)=>{
            setReimbursementInfo(res.data[0])
        })
    }

    const checkApprovalSchema = () => {
        SentralModel.action('Submission','checkSchema',{category: 'REIMBURSEMENT', division_id: authData.division_id}).then((res)=>{
            setAvailable(res.message)
        })
    }

    useEffect(() => {
        fetchReimbursement()
        fetchReimbursementInfo()
        fetchInstruction()
        checkApprovalSchema()
    }, []);
    
    const reimbursementData = (reimbursements) ? [
        ...reimbursements.map(el => {
            return {
                id: el.sub_reimbursement_id,
                description: el.title,
                total_amount: toIdr(el.total_amount),
                status: el.status,
                approver: el.approver,
                created_at: el.created_at
            }
        })
    ] : [];

    const rmbColumn = [{
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'name',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description'
        },{
            title: 'Total Amount',
            dataIndex: 'total_amount',
            key: 'total_amount',
        },{
            title: 'Action',
            align:'center',
            render : (row) => (
                <Space>
                    <ShowButton link to={'/reimbursement/' + row.id} />
                    {/* <ShowButton onShow={() => showRequestLog(row)}/> */}
                </Space>
            )
        }
    ];

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Reimbursement" breadcrumbs={[['Reimbursement']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>Request Reimbursement</Text>
                        <NewButton disabled={!available} right to="/reimbursement/create" title={(available) ? "Request Reimbursement" : 'Approval not available'}/>
                    </div>
                }>
                    <Row justify="space-between">
                        <Col span={4}>
                            <Descriptions size="middle" column={1} bordered layout="vertical">
                                <Descriptions.Item label={<Text strong>Reimbursement This Month</Text>}>
                                    {
                                        (reimbursementInfo) ? reimbursementInfo.total_submission: '0'
                                    }
                                </Descriptions.Item>
                                <Descriptions.Item label={<Text strong>Total Reimbursed</Text>}>
                                    {
                                        (reimbursementInfo) ? toIdr(reimbursementInfo.total_amount) : 'Rp. 0'
                                    }
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col span={19} className="instruction-box">
                            <div dangerouslySetInnerHTML={{ __html: instruction }}></div>
                        </Col>
                    </Row>
                </Card>
                <br/>
                {
                    (reimbursements) ? 
                        <Tabs defaultActiveKey="waiting" type="card">
                            <TabPane tab={<span><FieldTimeOutlined style={{color:'#4ca3ff'}}/> Waiting Approval</span>} key="waiting">
                                <DataTable size="small" columns={rmbColumn} dataSource={reimbursementData.filter(el => el.status==='WAITING')} bordered/>
                            </TabPane>
                            <TabPane tab={<span><CheckCircleOutlined style={{color:'green'}}/> Approved</span>} key="approved">
                                <DataTable size="small" columns={rmbColumn} dataSource={reimbursementData.filter(el => el.status==='APPROVED')} bordered/>
                            </TabPane>
                            <TabPane tab={<span><CloseCircleOutlined style={{color:'red'}}/> Rejected</span>} key="rejected">
                                <DataTable size="small" columns={rmbColumn} dataSource={reimbursementData.filter(el => el.status==='REJECTED')} bordered/>
                            </TabPane>
                        </Tabs>
                    : <Card><Spinner/></Card>
                }

                {/* {
                    (showLogs) ?
                    <Modal
                        title="Request Status"
                        visible={showLogs}
                        footer={null}
                        onCancel={() => setShowLogs(false)}>
                        <Timeline>
                            {
                                logsData.map((el, key) => (
                                    <Timeline.Item key={key} color={el.color}>
                                        {
                                            (el.status==="success" || el.status==="danger") ?
                                            <div>
                                                <span style={{fontWeight:'bold'}}>{moment(el.date).format('D MMMM YYYY, hh:mm A')}</span><br/>
                                                <span>{el.description}</span><br/>
                                                <span>{el.remarks}</span>
                                            </div> : 
                                            <div>
                                                <span className="text-muted" style={{fontWeight:'bold'}}>{el.description}</span>
                                            </div>
                                        }
                                    </Timeline.Item>
                                ))
                            }
                        </Timeline>
                    </Modal>
                    : null
                } */}
            </Card>
        </div>
    );
}

export default Index;
