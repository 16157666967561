import { useEffect, useState } from 'react'
import { Card, Form, Input, Select, Space, message } from 'antd'
import { DataTable, EditButton, FormModal, PageTitle } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'

const SetApproval = () => {
    const [loaded, setLoaded] = useState(false)
    const [incidentApproval, setIncidentApproval] = useState([])
    const [employees, setEmployees] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [form] = Form.useForm()

    const fetchIncidentApproval = () => {
        setLoaded(false)
        SentralModel.action("ItInfra", "getIncidentApproval", null, null).then((res) => {
            setIncidentApproval(res.data)
            setLoaded(true)
        })
    }

    const fetchEmployee = () => {
        SentralModel.list('Employee', { filter: [['employee_status', ['ESPR', 'ESPB', 'ESKN']]], orderBy: "name", order: "asc" }).then((res) => {
            let arr = []
            res.data.forEach((el) => {
                arr.push({ value: el.employee_code, label: el.name })
            })
            setEmployees(arr)
        })
    }

    useEffect(() => {
        fetchIncidentApproval()
        fetchEmployee()
    }, [])

    const onShowModal = (v) => {
        setShowModal(true)
        form.setFieldsValue({ ...v })
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'name',
            width: '5%'
        },
        {
            title: 'Key',
            dataIndex: 'value',
            key: 'value'
        },
        {
            title: 'Employee',
            key: 'key',
            render: (row) => (
                row?.employee?.name ?? "-"
            )
        },
        {
            title: 'Action',
            render: (row) => (
                <Space>
                    <EditButton onEdit={() => onShowModal(row)} />
                </Space>
            ),
            align: 'center',
            width: '10%'
        }
    ]

    const onFinishApproval = (v) => {
        SentralModel.action("ItInfra", "updateIncidentApproval", { employee_code: v.employee_code }, v.report_incident_approval_id).then((res) => {
            message.success("Success Update Data")
            fetchIncidentApproval()
            setShowModal(false)
        }).catch((err) => {
            message.success("Success Update Data")
        })
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Incident Approval" breadcrumbs={[['Incident Report', '/it-infra/incident-report'], ['Approval']]}></PageTitle>
                <Card title="Approval">
                    {
                        loaded &&
                        <DataTable size="small" columns={columns} dataSource={incidentApproval} bordered />
                    }
                </Card>
            </Card>

            {
                showModal ?
                    <FormModal submitForm={onFinishApproval} showModal={showModal} onCloseModal={() => setShowModal(false)} title="Edit Approval" form={form}>
                        <Form.Item name="report_incident_approval_id" hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item name="value" hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item name="employee_code" label="Employee">
                            <Select
                                showSearch
                                placeholder="Select Employee"
                                options={employees}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            />
                        </Form.Item>
                    </FormModal>
                    : null
            }
        </div>
    )
}

export default SetApproval