import React from 'react';
import {Card, Tabs} from 'antd'
import {FieldTimeOutlined,CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons'
import {DataTable, ShowButton} from '../../../components'
import {toIdr} from '../../../utils/currencyHandler'
import {formatDateTime} from '../../../utils/dateFormat'

const {TabPane} = Tabs;

const Index = (props) => {
    const entColumn = [{
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'name',
        },
        {
            title: 'Employee Name',
            dataIndex: 'name',
            key: 'name'
        },{
            title: 'Title',
            dataIndex: 'title',
            key: 'title'
        },{
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
        },{
            title: 'Request Date',
            dataIndex: 'created_at',
            render: (text) => (
                formatDateTime(text)
            )
        },{
            title:'Action',
            width:'10%',
            render:(row) => (
                <div className="text-center">
                    <ShowButton link to={'/submission-list/entertainment/'+row.id} />
                </div>
            )
        }
    ];
    
    const entData = [
        ...props.data.map((el) => {
            return{
                id:el.request_id,
                name: el.name,
                title:el.title,
                total: toIdr(el.total_amount),
                request_date: formatDateTime(el.created_at),
                status: el.status,
                created_at: el.created_at
            }
        })
    ]

    return (
        <Card>
            <Tabs defaultActiveKey="waiting">
                <TabPane tab={<span><FieldTimeOutlined style={{color:'#4ca3ff'}}/> Waiting Approval</span>} key="waiting">
                    <DataTable size="small" columns={entColumn} dataSource={entData.filter(el=> el.status==="WAITING")} bordered/>
                </TabPane>
                <TabPane tab={<span><CheckCircleOutlined style={{color:'green'}}/> Approved</span>} key="approved">
                    <DataTable size="small" columns={entColumn} dataSource={entData.filter(el=> el.status==="APPROVED")} bordered/>
                </TabPane>
                <TabPane tab={<span><CloseCircleOutlined style={{color:'red'}}/> Rejected</span>} key="rejected">
                    <DataTable size="small" columns={entColumn} dataSource={entData.filter(el=> el.status==="REJECTED")} bordered/>
                </TabPane>
            </Tabs>
        </Card>
    );
}

export default Index;
