import React, { useEffect, useState, useRef } from 'react'
import moment from 'moment'
import { Row, Col, Typography, Button, Form, Select, Input, message, TimePicker, Card, Space } from 'antd'
import { RightCircleOutlined, LeftCircleOutlined } from '@ant-design/icons'
import { Spinner, FormModal, DeleteButton, DataTable, EditButton } from '../../../components';
import { SentralModel } from '../../../models/SentralModel'
import authData from '../../../helpers/authData'
import Webcam from "react-webcam";
import { distance } from '../../../helpers/distance';

const { Title, Text } = Typography;
const { Option } = Select;
const { RangePicker } = TimePicker;

const Absence = () => {
    const [form] = Form.useForm()
    const [loaded, setLoaded] = useState(false);
    const [formModal, setFormModal] = useState(false);
    const [formModalActivity, setFormModalActivity] = useState(false)
    const [titleModalActivity, setTitleModalActivity] = useState('')
    const [defaultValues, setDefaultValues] = useState(null);
    const [editDefaultValues, setEditDefaultValues] = useState(null);
    const [modalTitle, setModalTitle] = useState(null);
    const [saving, setSaving] = useState(false)
    const [dateTime, setDateTime] = useState(moment().format('DD MMMM YYYY, HH:mm:ss'));
    const [divisions, setDivision] = useState({});
    const [attendances, setAttendance] = useState([]);
    const [workLocation, setWorkLocation] = useState([]);
    const [measurements, setMeasurements] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [company, setCompany] = useState(null);
    const [companyName, setCompanyName] = useState('')
    const [measurement, setMeasurement] = useState(null)
    const [location, setLocation] = useState(false)
    const [locationAddress, setLocationAddress] = useState(null)
    const [urlImage, setUrlImage] = useState(null);
    const [photo, setPhoto] = useState(null);
    const [workingLocation, setWorkingLocation] = useState([])
    const [modalCompany, setModalCompany] = useState(false)
    const [titleModalCompany, setTitleModalCompany] = useState('')
    const [isSubmit, setIsSubmit] = useState(false)
    const [listWorkingLocation, setListWorkingLocation] = useState([])
    const [showModalWorkingLocation, setShowModalWorkingLocation] = useState(false)
    const [modalWorkingLocationTitle, setModalWorkingLocationTitle] = useState('')
    const [absenStatus, setAbsesnStatus] = useState('')
    const [isTrueRadius, setIsTrueRadius] = useState(false)
    const itDivision = [13, 24, 25, 33, 34, 36];

    const webcamRef = useRef(null);

    const fetchDivision = () => {
        setLoaded(false)
        SentralModel.list('Division', { filter: [["division_id", authData.division_id]] }).then(res => {
            setLoaded(true);
            setDivision(res.data[0]);
        })
    }

    const fetchCompany = () => {
        SentralModel.action("Company", "getCompany", null, 0).then((res) => {
            setCompanies(res.data)
        })
    }

    const fetchAttendance = () => {
        setLoaded(false)
        SentralModel.action('Attendance', 'getTodayAttendance', { attendance_id: authData.attendance_id }, 0).then(res => {
            setLoaded(true);
            setAttendance(res.message);
        })
    }

    const fetchMeasurements = () => {
        setLoaded(false)
        SentralModel.list('Measurement').then(res => {
            setLoaded(true)
            const data = res.data.filter((el) => el.measurement_id === 1 || el.measurement_id === 5 || el.measurement_id === 6)
            setMeasurements(data)
        })
    }

    const fetchEmployeeArea = (v) => {
        SentralModel.action("Area", "getEmployeeAttendanceArea", null, null).then((res) => {
            const employeeArea = res.data.employee_area.length
            const divisionArea = res.data.division_area.length
            let radius = 0;

            if (employeeArea > 0) {
                res.data.employee_area.forEach((el) => {
                    const dis = distance(el.area.latitude, el.area.longitude, v.lat, v.long)
                    if (dis <= el.area.radius) {
                        radius++
                    }
                })
            }
            if (divisionArea > 0) {
                res.data.division_area.forEach((el) => {
                    const dis = distance(el.area.latitude, el.area.longitude, v.lat, v.long)
                    if (dis <= el.area.radius) {
                        radius++
                    }
                })
            }

            if (radius !== 0) {
                setIsTrueRadius(true)
            } else {
                setIsTrueRadius(false)
            }
        })
    }

    const fetchListWorkLocation = () => {
        SentralModel.action("WorkingLocation", "getWorkingLocation", { employee_code: authData.employee_code }, 0).then((res) => {
            const v = res.data
            let data = []

            v.map((el, key) => {
                return data.push({ ...el, index: key })
            })

            setListWorkingLocation(data)
        })
    }

    useEffect(() => {
        fetchDivision();
        fetchAttendance();
        fetchMeasurements();
        fetchCompany();
        fetchListWorkLocation();

        const getUserLocation = (pos) => {
            let crd = pos.coords
            if (!locationAddress) {
                let payload = {
                    lat: crd.latitude,
                    long: crd.longitude,
                }

                fetchEmployeeArea(payload);
                SentralModel.action('Area', 'locationAddress', payload, 0).then((res) => {
                    setLocationAddress(res.data)
                })
            }
            localStorage.setItem('userLocation', JSON.stringify({ lat: crd.latitude, long: crd.longitude }))
        }

        if (navigator.geolocation) {
            if (navigator.permissions) {
                navigator.permissions
                    .query({ name: "geolocation" })
                    .then(function (result) {
                        if (result.state === "granted") {
                            navigator.geolocation.getCurrentPosition(getUserLocation);
                            setLocation(true)
                            // console.log(navigator.geolocation)
                        } else if (result.state === "prompt") {
                            navigator.geolocation.getCurrentPosition(getUserLocation);
                            setLocation(false)
                        }
                    });
            } else {
                navigator.geolocation.getCurrentPosition(getUserLocation);
                setLocation(false)
            }
        } else {
            alert("Sorry Not available!");
        }

        const interval = setInterval(() => {
            setDateTime(moment().format('DD MMMM YYYY, HH:mm:ss'));
        }, 1000);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationAddress]); // eslint-disable-next-line

    const addAbsence = (v) => {
        setModalTitle(v === 'In' ? 'Clock In' : 'Clock Out')
        setAbsesnStatus(v)
        setDefaultValues({
            work_location: 'WFH',
            information: v,
            remarks: null
        })
        setFormModal(true)

        if (itDivision.includes(authData.division_id) || authData.employee_code === "SMI20200803205") {
            setWorkLocation("WFO")
        } else {
            setWorkLocation('WFH');
        }
    }

    const absence = (v) => {
        setSaving(true)

        let attendance = {
            attendance_id: authData.attendance_id,
            source: 'Web',
            attendance_date: moment().format('YYYY-MM-DD'),
            attendance_time: moment().format('HH:mm:ss'),
            information: workLocation,
            remarks: v.remarks,
            photo: photo,
            working_location: absenStatus === 'Out' ? [] : workingLocation,
            division_id: authData.division_id,
        }

        setSaving(false)

        let userLocation = localStorage.getItem('userLocation')
        if (userLocation) {
            attendance.latitude = JSON.parse(userLocation).lat
            attendance.longitude = JSON.parse(userLocation).long
            attendance.location = locationAddress
        }
        if (!photo) {
            setSaving(false)
            message.error('Please capture your photo', 2);
        } else {
            if (workLocation === 'WFH') {
                if (location) {
                    SentralModel.action('Attendance', 'saveAttendanceAbsence', attendance, 0).then((res) => {
                        setFormModal(false)
                        fetchAttendance()
                        fetchListWorkLocation()
                        setSaving(false)
                        message.success('Absence has been saved', 2);
                        setPhoto(null)
                        setUrlImage(null)
                    }).catch((err) => {
                        message.warning('Failed Absence')
                        setSaving(false)
                    })
                } else {
                    setSaving(false)
                    message.error('Please activate your location', 2);
                }
            } else {
                if (location) {
                    SentralModel.action('Area', 'takeAbsenceWeb', attendance, 0).then((res) => {
                        if (res.message.toLowerCase().includes("failed")) {
                            return message.warning("Gagal Absen, Daily Activity (Office) diluar radius kantor")
                        } else {
                            setFormModal(false)
                            fetchAttendance()
                            fetchListWorkLocation()
                            setSaving(false)
                            message.success('Absence has been saved', 2);
                            setPhoto(null)
                            setUrlImage(null)
                        }
                    }).catch((err) => {
                        message.warning('Failed Absence')
                        setSaving(false)
                    })
                } else {
                    setSaving(false)
                    message.error('Please activate your location', 2);
                }
            }
        }
    }

    const changeLocation = e => {
        setWorkLocation(e)
    }

    const videoConstraints = {
        width: 500,
        height: 300,
        facingMode: "user"
    };

    const capturePhoto = React.useCallback(async () => {
        const imageSrc = webcamRef.current.getScreenshot();
        setUrlImage(imageSrc);
        const file = DataURIToBlob(imageSrc);

        SentralModel.upload('attendance', file, 'image.jpg').then(res => {
            setPhoto(res.message);
        })
    }, [webcamRef]);

    function DataURIToBlob(dataURI) {
        const splitDataURI = dataURI.split(',')
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)

        return new Blob([ia], { type: mimeString })
    }

    const onShowModalActivity = () => {
        setFormModalActivity(true)
        setTitleModalActivity('Working Location')
    }

    const addDailyActivity = (v) => {
        let data = workingLocation
        const m = measurements.filter((el) => el.measurement_id === v.measurement_id)
        if (workingLocation.length === 0 && (measurement === 5 && !isTrueRadius)) {
            return message.warning("Gagal menambahkan working location, lokasi anda diluar radius.")
        }
        const val = Math.floor(1000 + Math.random() * 9000);
        data.push({
            ...v,
            measurement: m[0].title,
            measurement_id: v.measurement_id,
            employee_code: authData.employee_code,
            description: v.description,
            company_id: company,
            company_name: companyName,
            do_date: moment(Date.now()).format('YY-MM-DD'),
            time_start: moment(v.time[0]).format('HH:mm'),
            time_end: moment(v.time[1]).format('HH:mm'),
            key: val
        })

        setWorkingLocation(data)

        if (workingLocation[workingLocation.length - 1].time_end >= '17:00') {
            setIsSubmit(true)
        } else {
            setIsSubmit(false)
        }

        form.resetFields()
    }

    const deleteWorkingLocation = (v) => {
        const idx = workingLocation.findIndex((el) => el.key === v)
        workingLocation.splice(idx, 1);
    }

    const changeCompany = e => {
        setCompany(e)
        const comp = companies.filter((el) => el.company_id === e)
        setCompanyName(comp[0].company_name)
    }

    const changeMeasurement = e => {
        setMeasurement(e)
        if (e === 5) {
            setCompanyName("PT Sentral Mitra Informatika")
        }
    }

    const onShowModalCompany = () => {
        setModalCompany(true)
        setTitleModalCompany('Add Company')
    }

    const addCompany = (v) => {
        let payload = {
            company_name: v.company_name,
            address: v.address,
            email: v.email,
            phone_no: v.phone_no,
            phone_no_2: v.phone_no_2
        }

        SentralModel.action("Company", "saveCompany", payload, 0).then((res) => {
            message.success("Success Add Company")
            form.resetFields()
            setModalCompany(false)
            fetchCompany()
        }).catch((err) => {
            message.error("Failed Add Company")
        })
    }

    const columns = [
        {
            title: "Company Name",
            key: "company_name",
            render: (row) => (
                row?.company?.company_name
            ),
            align: "center"
        },
        {
            title: "Time",
            key: "time",
            render: (row) => (
                `${row.time_start} - ${row.time_end}`
            )
        },
        {
            title: "Action",
            key: "action",
            render: (row) => (
                row.index === 0 ?
                    <Space>
                        <EditButton disabled onEdit={() => onShowModalWorkingLocation(row)} />
                        <DeleteButton disabled />
                    </Space>
                    :
                    <Space>
                        <EditButton onEdit={() => onShowModalWorkingLocation(row)} />
                        <DeleteButton onConfirm={() => onDelete(row.working_location_id)} />
                    </Space>
            ),
            align: "center",
        }
    ];

    const onShowModalWorkingLocation = (v) => {
        setShowModalWorkingLocation(true)
        setModalWorkingLocationTitle("Edit Working Location")
        setEditDefaultValues({
            id: v.working_location_id,
            measurement_id: v.measurement_id,
            company_id: v.company_id,
            description: v.description,
            time: [moment(v.time_start, 'HH:mm'), moment(v.time_end, 'HH:mm')]
        })
    }

    const editWorkingLocation = (v) => {
        let payload = {
            company_id: v.company_id,
            measurement_id: v.measurement_id,
            description: v.description,
            time_start: moment(v.time[0]).format('HH:mm'),
            time_end: moment(v.time[1]).format('HH:mm'),
        }

        SentralModel.action("WorkingLocation", "updateWorkingLocation", payload, v.id).then((res) => {
            setShowModalWorkingLocation(false)
            message.success("Success Update Woking Location")
            fetchListWorkLocation()
        }).catch((err) => {
            message.warning("Failed Update Woking Location")
        })
    }

    const onDelete = (v) => {
        SentralModel.action("WorkingLocation", "deleteWorkingLocation", null, v).then((res) => {
            message.success("Success Delete Woking Location")
            fetchListWorkLocation()
        }).catch((err) => {
            message.warning("Failed Delete Woking Location")
        })
    }

    return (
        <div>
            {
                loaded ?
                    <Row>
                        <Col span={24}>
                            <Row justify='center' >
                                <Col xs={24} sm={24} md={20} style={{ borderRadius: 15, border: '1px solid #808080' }}>
                                    <div className="full-width" style={{ borderBottom: '1px solid #ededed', padding: 15 }}>
                                        <Row>
                                            <Col span={12}>
                                                <Title level={4}>
                                                    Office Hours
                                                </Title>
                                            </Col>
                                            <Col span={12}>
                                                <span style={{ float: 'right', fontSize: 20 }}>
                                                    {dateTime}
                                                </span>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div style={{ fontSize: 40, textAlign: 'center', padding: 30 }}>
                                        {divisions ? moment(divisions.daily_in, 'HH:mm:ss').format('HH:mm') + ' - ' + moment(divisions.daily_out, 'HH:mm:ss').format('HH:mm') : ''}
                                    </div>
                                    <div style={{ fontSize: 16, textAlign: 'center', padding: 10 }}>
                                        {locationAddress ? locationAddress : null}
                                    </div>
                                    {
                                        itDivision.includes(authData.division_id) || authData.employee_code === "SMI20200803205" ?
                                            <div style={{ margin: '0 20px 10px 20px' }}>
                                                <Card title="Working Location">
                                                    <DataTable columns={columns} dataSource={listWorkingLocation} pagination={false} search={false} size="small" />
                                                </Card>
                                            </div>
                                            : null
                                    }
                                    <div className="full-width" style={{ borderTop: '1px solid #ededed', padding: 20 }}>
                                        <Row justify="space-around">
                                            <Col span={7} className="text-center">
                                                <Button block type="primary" size="large" icon={<RightCircleOutlined />} onClick={() => addAbsence('In')} disabled={attendances[0] !== '-'}>Check In</Button>
                                                <span style={{ fontSize: 20 }}>
                                                    {attendances ? attendances[0] : '-'}
                                                </span>
                                            </Col>
                                            <Col span={7} className="text-center">
                                                <Button block type="danger" size="large" icon={<LeftCircleOutlined />} onClick={() => addAbsence('Out')}>Check Out</Button>
                                                <span style={{ fontSize: 20 }}>
                                                    {attendances ? attendances[1] : '-'}
                                                </span>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row >
                    : <Spinner />
            }

            {
                (formModal) ?
                    <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => (absence(v))} width={700} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}
                        disabled={
                            modalTitle === 'Clock Out' ? false :
                                itDivision.includes(authData.division_id) || authData.employee_code === "SMI20200803205" ?
                                    isSubmit ? false : true
                                    : false
                        }>
                        <Form.Item name="information" hidden>
                            <Input placeholder="Information" />
                        </Form.Item>
                        {
                            itDivision.includes(authData.division_id) || authData.employee_code === "SMI20200803205" ?
                                <div style={{ fontSize: 16, textAlign: 'center', padding: 10, marginBottom: 10 }}>
                                    {locationAddress ? locationAddress : null}
                                </div>
                                :
                                <Form.Item label="Work Location" name="work_location" rules={[{ required: true }]}>
                                    <Select placeholder="Select category" style={{ width: "100%" }} onChange={changeLocation}>
                                        <Option value="WFH">Work From Home</Option>
                                        <Option value="WFO">Work From Office</Option>
                                    </Select>
                                </Form.Item>
                        }
                        {
                            modalTitle === 'Clock Out' ?
                                null :
                                itDivision.includes(authData.division_id) || authData.employee_code === "SMI20200803205" ?
                                    <>
                                        <Row justify='end' className='mb-3'>
                                            <Button type='primary' onClick={onShowModalActivity}>Add Working Location</Button>
                                        </Row>
                                        <Card className="mb-3" title="Working Location">
                                            {
                                                workingLocation.length === 0 ?
                                                    <Text>No Working Location</Text> :
                                                    <table className='styled-table'>
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    Measurement
                                                                </th>
                                                                <th>
                                                                    Working Location
                                                                </th>
                                                                <th>
                                                                    Time
                                                                </th>
                                                                <th>
                                                                    Action
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                workingLocation.map((el, key) => (
                                                                    <tr key={key}>
                                                                        <td>
                                                                            {el.measurement}
                                                                        </td>
                                                                        <td>
                                                                            {el.company_name ?? '-'}
                                                                        </td>
                                                                        <th>
                                                                            {`${el.time_start} - ${el.time_end}`}
                                                                        </th>
                                                                        <td>
                                                                            <DeleteButton onConfirm={() => deleteWorkingLocation(el.key)} />
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                            }
                                        </Card>
                                    </> : null
                        }
                        <span>Photo</span>
                        {
                            !urlImage ?
                                <div className='mb-3'>
                                    <Webcam
                                        ref={webcamRef}
                                        audio={false}
                                        height={300}
                                        screenshotFormat="image/jpeg"
                                        width={'100%'}
                                        videoConstraints={videoConstraints}
                                    />
                                </div>
                                :
                                <div style={{ marginTop: 15, marginBottom: 30 }}>
                                    <img src={urlImage} alt="Screenshot" />
                                </div>
                        }

                        <Row justify="space-around">
                            <Col span={10} className="text-center">
                                {
                                    modalTitle === 'Clock Out' ?
                                        <Button block type="primary" onClick={capturePhoto}>Capture</Button>
                                        :
                                        itDivision.includes(authData.division_id) || authData.employee_code === "SMI20200803205" ?
                                            workingLocation.length === 0 || isSubmit === false ?
                                                <Button block type="primary" onClick={capturePhoto} disabled>Capture</Button> :
                                                <Button block type="primary" onClick={capturePhoto}>Capture</Button> :
                                            <Button block type="primary" onClick={capturePhoto}>Capture</Button>

                                }
                            </Col>
                            <Col span={10} className="text-center">
                                <Button block type='danger' onClick={() => setUrlImage(null)}>Reset</Button>
                            </Col>
                        </Row>
                        <br />
                        <Form.Item label="Remarks" name="remarks">
                            <Input.TextArea placeholder="Remarks" />
                        </Form.Item>
                    </FormModal> :
                    null
            }

            {
                formModalActivity ?
                    <FormModal form={form} title={titleModalActivity} submitForm={(v) => addDailyActivity(v)} width={500} showModal={formModalActivity} onCloseModal={() => setFormModalActivity(false)} isAdd={true}>
                        <Form.Item label="Job Category" name="measurement_id" required rules={[{ required: true }]}>
                            <Select placeholder="Select category" style={{ width: "100%" }} onChange={changeMeasurement}>
                                {
                                    measurements.map((el, key) => (
                                        <Option key={key} value={el.measurement_id}>{el.title}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        {
                            measurement === 1 || measurement === 6 ?
                                <Row justify='space-between'>
                                    <Col span={17}>
                                        <Form.Item label="Company" name="company_id" required rules={[{ required: true }]}>
                                            <Select showSearch allowClear placeholder="Select company" style={{ width: "100%" }} onChange={changeCompany} optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                {
                                                    companies.map((el, key) => (
                                                        <Option key={key} value={el.company_id}>{el.company_name}</Option>
                                                    ))
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <div style={{ marginTop: 30 }}>
                                            <Button type='primary' onClick={onShowModalCompany}>Add Company</Button>
                                        </div>
                                    </Col>
                                </Row>
                                : null
                        }
                        <Form.Item label="Description" name="description">
                            <Input.TextArea placeholder='Description' rows={4} />
                        </Form.Item>
                        <Form.Item label="Time" name="time" required rules={[{ required: true }]}>
                            <RangePicker format="HH:mm" style={{ width: '100%' }} />
                        </Form.Item>
                    </FormModal> : null
            }

            {
                onShowModalCompany ?
                    <FormModal form={form} title={titleModalCompany} submitForm={(v) => addCompany(v)} width={600} showModal={modalCompany} onCloseModal={() => setModalCompany(false)} >
                        <Form.Item label="Company Name" name="company_name" required rules={[{ required: true }]}>
                            <Input placeholder='Company Name' />
                        </Form.Item>
                        <Form.Item label="Company Email" name="email" required rules={[{ required: true }]}>
                            <Input type='email' placeholder='Company Email' />
                        </Form.Item>
                        <Row justify='space-between'>
                            <Col span={11}>
                                <Form.Item label="Phone 1" name="phone_no">
                                    <Input placeholder='Phone 1' />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item label="Phone 2" name="phone_no_2">
                                    <Input placeholder='Phone 2' />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label="Address" name="address" required rules={[{ required: true }]}>
                            <Input placeholder='Address' />
                        </Form.Item>
                    </FormModal>
                    : null
            }

            {
                showModalWorkingLocation ?
                    <FormModal title={modalWorkingLocationTitle} defaultValues={editDefaultValues} submitForm={(v) => editWorkingLocation(v)} width={600} showModal={showModalWorkingLocation} onCloseModal={() => setShowModalWorkingLocation(false)}>
                        <Form.Item name="id" hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Job Category" name="measurement_id" required rules={[{ required: true }]}>
                            <Select placeholder="Select category" style={{ width: "100%" }} onChange={changeMeasurement}>
                                {
                                    measurements.map((el, key) => (
                                        <Option key={key} value={el.measurement_id}>{el.title}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Row justify='space-between'>
                            <Col span={17}>
                                <Form.Item label="Company" name="company_id" required rules={[{ required: true }]}>
                                    <Select showSearch allowClear placeholder="Select company" style={{ width: "100%" }} onChange={changeCompany} optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {
                                            companies.map((el, key) => (
                                                <Option key={key} value={el.company_id}>{el.company_name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <div style={{ marginTop: 30 }}>
                                    <Button type='primary' onClick={onShowModalCompany}>Add Company</Button>
                                </div>
                            </Col>
                        </Row>
                        <Form.Item label="Description" name="description" >
                            <Input.TextArea placeholder='Description' rows={4} />
                        </Form.Item>
                        <Form.Item label="Time" name="time" required rules={[{ required: true }]}>
                            <RangePicker format="HH:mm" style={{ width: '100%' }} />
                        </Form.Item>
                    </FormModal>
                    : null
            }
        </div >
    );
}

export default Absence;

