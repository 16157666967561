import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { SentralModel } from "../../../models/SentralModel";
import { message } from "antd";

export const fetchEmployee = createAsyncThunk(
    'karajoTicket/fetchEmployee',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await SentralModel.list('Employee', { filter: [['is_active', '=', '1'], ['employee_status', ['ESPR', 'ESPB', 'ESKN']]], orderBy: 'name', order: 'asc' })
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchEmployeePresales = createAsyncThunk(
    'karajoTicket/fetchEmployeePresales',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await SentralModel.list('Employee', { filter: [['is_active', '=', '1'], ['employee_status', ['ESPR', 'ESPB', 'ESKN']], ['division_id', '=', '13']], orderBy: 'name', order: 'asc' })
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchDivision = createAsyncThunk(
    'karajoTicket/fetcDivision',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await SentralModel.action("Division", "getDivision", null, null)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchCompanies = createAsyncThunk(
    'karajoTicket/fetchCompanies',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await SentralModel.action("Company", "getCompany", null, 0)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchListTicket = createAsyncThunk(
    'karajoTicket/fetchListTicket',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await SentralModel.action("DailyActivity", "karajoTicketList", null, null)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchTicketAssign = createAsyncThunk(
    'karajoTicket/fetchTicketAssign',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await SentralModel.action("DailyActivity", "karajoTicketAssign", null, null)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const takeTicketPresales = createAsyncThunk(
    'karajoTicket/takeTicket',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await SentralModel.action("DailyActivity", "takeTicket", payload, null)
            message.success("Success Take Ticket")
            return response.data
        } catch (error) {
            message.warning("Failed Take Ticket")
            return rejectWithValue(error)
        }
    }
)

export const changeTicketPresalesEmployee = createAsyncThunk(
    'karajoTicket/changeTicketPresales',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await SentralModel.action("DailyActivity", "changeEmployeeTicketPresales", payload, null)
            message.success("Success Change employee")
            return response.data
        } catch (error) {
            message.warning("Failed Change employee")
            return rejectWithValue(error)
        }
    }
)

export const updateTicketPresales = createAsyncThunk(
    'karajoTicket/updateTicketPresales',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await SentralModel.action("DailyActivity", "updateTicketPresales", payload, null)
            message.success("Success Update Ticket")
            return response.data
        } catch (error) {
            message.warning("Failed Update Ticket")
            return rejectWithValue(error)
        }
    }
)

export const updateTicketSoftware = createAsyncThunk(
    'karajoTicket/updateTicketSoftware',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await SentralModel.action("DailyActivity", "updateTicket", payload, null)
            message.success("Success Update Ticket")
            return response.data
        } catch (error) {
            message.warning("Failed Update Ticket")
            return rejectWithValue(error)
        }
    }
)

export const updateTicketGeneral = createAsyncThunk(
    'karajoTicket/updateTicketGeneral',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await SentralModel.action("DailyActivity", "updateTicket", payload, null)
            message.success("Success Update Ticket")
            return response.data
        } catch (error) {
            message.warning("Failed Update Ticket")
            return rejectWithValue(error)
        }
    }
)

export const saveSubmitGeneralTicket = createAsyncThunk(
    'karajoTicket/saveSubmitGeneralTicket',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await SentralModel.action("DailyActivity", "saveTicketGeneral", payload, null)
            message.success("Success Create Ticket Presales")
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const saveSubmitPresalesTicket = createAsyncThunk(
    'karajoTicket/saveSubmitPresalesTicket',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await SentralModel.action("DailyActivity", "saveTicketPresales", payload, null)
            message.success("Success Create Ticket Presales")
            return response.data
        } catch (error) {
            message.warning("Failed Create Ticket Presales")
            return rejectWithValue(error)
        }
    }
)

export const saveSubmitSoftwareTicket = createAsyncThunk(
    'karajoTicket/saveSubmitSoftwareTicket',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await SentralModel.action("DailyActivity", "saveTicketSoftware", payload, null)
            message.success("Success Create Ticket")
            return response.data
        } catch (error) {
            message.success("Failed Create Ticket")
            return rejectWithValue(error)
        }
    }
)

const karajoTicketSlice = createSlice({
    name: 'karajoTicket',
    initialState: {
        divisionId: 0,
        listTicket: [],
        assignTicket: [],
        employees: [],
        employeesPresales: [],
        division: [],
        companies: [],
        info: {},
        loadedList: false,
        loadedAssign: false,
        disabled: false,
        categoryRequest: false,
        uploading: false,
        showModalAddTicket: false,
        showModalInfoTicket: false,
        showModalEditTicket: false,
        status: false,
        isTicket: "",
        taskTo: "",
        companyName: "",
        company: null,
        attachmentFile: null,
    },
    reducers: {
        changeEmployee: (state, action) => {
            state.taskTo = action.payload
        },
        changeDivision: (state, action) => {
            state.divisionId = action.payload
        },
        changeCategory: (state, action) => {
            if (action.payload === "Support Project") {
                state.categoryRequest = true
            } else {
                state.categoryRequest = false
            }
        },
        changeCompany: (state, action) => {
            state.company = action.payload
            const comp = current(state.companies).filter((el) => el.company_id === action.payload)
            state.companyName = comp[0].company_name
        },
        fileUploaded: (state, action) => {
            const v = action.payload
            state.attachmentFile = v.message
            state.uploading = true
        },
        changeUploading: (state, action) => {
            state.uploading = action.payload
        },
        addTicket: (state, action) => {
            state.showModalAddTicket = true
            state.attachmentFile = null
        },
        changeModalTicket: (state, action) => {
            state.showModalAddTicket = action.payload
        },
        showInfoTicket: (state, action) => {
            state.showModalInfoTicket = true
            state.isTicket = action.payload.data_from
            state.info = action.payload
        },
        changeShowInfoTicket: (state, action) => {
            state.showModalInfoTicket = action.payload
            state.isTicket = ""
        },
        showEditTicket: (state, action) => {
            state.showModalEditTicket = true
            state.isTicket = action.payload.data_from
            state.info = action.payload
        },
        changeShowEditTicket: (state, action) => {
            state.showModalEditTicket = action.payload
            state.isTicket = ""
        },
        changeStatus: (state, action) => {
            state.status = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchEmployee.fulfilled, (state, action) => {
            state.employees = action.payload
        })
        builder.addCase(fetchEmployeePresales.fulfilled, (state, action) => {
            let arr = []
            action.payload.forEach((el) => {
                arr.push({ value: el.employee_code, label: el.name })
            })

            state.employeesPresales = arr
        })
        builder.addCase(fetchDivision.fulfilled, (state, action) => {
            let arr = []
            action.payload.forEach((el) => {
                arr.push({ value: el.division_id, label: el.division_name })
            })

            state.division = arr
        })
        builder.addCase(fetchCompanies.fulfilled, (state, action) => {
            state.companies = action.payload
        })
        builder.addCase(fetchListTicket.pending, (state, action) => {
            state.loadedList = false
        })
        builder.addCase(fetchListTicket.fulfilled, (state, action) => {
            state.listTicket = action.payload
            state.loadedList = true
        })
        builder.addCase(fetchListTicket.rejected, (state, action) => {
            state.listTicket = []
            state.loadedList = true
        })
        builder.addCase(fetchTicketAssign.pending, (state, action) => {
            state.loadedAssign = false
        })
        builder.addCase(fetchTicketAssign.fulfilled, (state, action) => {
            state.assignTicket = action.payload
            state.loadedAssign = true
        })
        builder.addCase(fetchTicketAssign.rejected, (state, action) => {
            state.assignTicket = []
            state.loadedAssign = true
        })
        builder.addCase(changeTicketPresalesEmployee.fulfilled, (state, action) => {
            state.showModalEditTicket = false
        })
        builder.addCase(saveSubmitGeneralTicket.fulfilled, (state, action) => {
            state.showModalAddTicket = false
        })
        builder.addCase(saveSubmitPresalesTicket.fulfilled, (state, action) => {
            state.showModalAddTicket = false
        })
        builder.addCase(saveSubmitSoftwareTicket.fulfilled, (state, action) => {
            state.showModalAddTicket = false
        })
        builder.addCase(updateTicketPresales.fulfilled, (state, action) => {
            state.showModalEditTicket = false
        })
        builder.addCase(updateTicketSoftware.fulfilled, (state, action) => {
            state.showModalEditTicket = false
        })
        builder.addCase(updateTicketGeneral.fulfilled, (state, action) => {
            state.showModalEditTicket = false
        })
    }
})

export const {
    changeEmployee,
    changeDivision,
    changeCategory,
    changeCompany,
    fileUploaded,
    changeUploading,
    addTicket,
    changeModalTicket,
    showInfoTicket,
    changeShowInfoTicket,
    showEditTicket,
    changeShowEditTicket,
    changeStatus
} = karajoTicketSlice.actions
export default karajoTicketSlice.reducer