export const getUnique = (arr) => {
    let mapObj = new Map()

    arr.forEach(v => {
        let prevValue = mapObj.get(v.name)
        if (!prevValue || prevValue.type === "new") {
            mapObj.set(v.name, v)
        }
    })

    return [...mapObj.values()]
}