import React, {useEffect, useState} from 'react'
import {Row, Col, Typography, Divider, Empty, Form, Input, Select, message} from 'antd'
import ProfileItem from './ProfileItem';
import ActionButton from './ActionButton';
import {AddButton, FormModal, Spinner} from '../../../components';
import {SentralModel} from '../../../models/SentralModel'

const {Title} = Typography
const {Option} = Select;
const Language = (props) => {
    const [empLanguages, setEmpLanguages] = useState(props.data);
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false);
    const [defaultValues, setDefaultValues] = useState({});
    const [saving, setSaving] = useState(false);
    const [modalTitle, setModalTitle]= useState(null);
    const [readings, setReadings] = useState([]);
    const [writings, setWritings] = useState([]);
    const [listenings, setListenings] = useState([]);
    const [speakings, setSpeakings] = useState([]);
    const [selectedReading, setSelectedReading] = useState([]);
    const [selectedWriting, setSelectedWriting] = useState([]);
    const [selectedListening, setSelectedListening] = useState([]);
    const [selectedSpeaking, setSelectedSpeaking] = useState([]);

    const fetchEmpLanguages = () =>{
        setLoaded(false)
        SentralModel.list('RecruitmentApplicantLanguageSkill', {filter: [['rec_applicant_id', props.applicant_id]]}).then(res=>{
            setEmpLanguages(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    const fetchReadings = () =>{
        SentralModel.list('GeneralDetail', {filter: [['general_code', 'LS']], orderBy:'order_key', order:'asc'}).then(res=>{
            setReadings(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    const fetchWritings = () =>{
        SentralModel.list('GeneralDetail', {filter: [['general_code', 'LS']], orderBy:'order_key', order:'asc'}).then(res=>{
            setWritings(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    const fetchListenings = () =>{
        SentralModel.list('GeneralDetail', {filter: [['general_code', 'LS']], orderBy:'order_key', order:'asc'}).then(res=>{
            setListenings(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    const fetchSpeakings = () =>{
        SentralModel.list('GeneralDetail', {filter: [['general_code', 'LS']], orderBy:'order_key', order:'asc'}).then(res=>{
            setSpeakings(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchReadings()
        fetchWritings()
        fetchListenings()
        fetchSpeakings()
    }, []);

    const addLanguage=()=>{
        setModalTitle('Add Language')
        setDefaultValues({
            rec_applicant_language_skill_id: 0
        })
        setFormModal(true)
    }

    const editLanguage = (v) =>{
        setModalTitle('Edit Language')
        setDefaultValues({
            rec_applicant_language_skill_id: v.rec_applicant_language_skill_id,
            language: v.language,
            reading: v.reading.detail_code,
            writing: v.writing.detail_code,
            listening: v.listening.detail_code,
            speaking: v.speaking.detail_code
        })
        setSelectedReading(v.reading.detail_code)
        setSelectedWriting(v.writing.detail_code)
        setSelectedListening(v.listening.detail_code)
        setSelectedSpeaking(v.speaking.detail_code)
        setFormModal(true)
    }

    const saveLanguage = (v) => {
        setSaving(true)
        let payload={
            rec_applicant_language_skill_id: v.rec_applicant_language_skill_id,
            rec_applicant_id: props.applicant_id,
            language: v.language,
            reading: selectedReading,
            writing: selectedWriting,
            listening: selectedListening,
            speaking: selectedSpeaking
        }
        SentralModel.save('RecruitmentApplicantLanguageSkill', payload, v.rec_applicant_language_skill_id).then((res)=>{
            setFormModal(false)
            fetchEmpLanguages()
            message.success('Language saved', 2);
        }).finally(() => {
            setSaving(false)
        })
    }

    const deleteLanguage = (id) =>{
        message.loading('deleting language')
        SentralModel.deleteById('RecruitmentApplicantLanguageSkill', id).then((res)=>{
            message.destroy()
            message.success('Language deleted', 2)
            let tmp = empLanguages
            let index = tmp.findIndex(el => el.rec_applicant_language_skill_id===id)
            tmp.splice(index, 1);
            setEmpLanguages([])
            setEmpLanguages(tmp)
        })
    }

    const changeReading = (val) => {
        setSelectedReading(val)
    }

    const changeWriting = (val) => {
        setSelectedWriting(val)
    }

    const changeListening = (val) => {
        setSelectedListening(val)
    }

    const changeSpeaking = (val) => {
        setSelectedSpeaking(val)
    }

    return (
        <div>
            <div className="text-right">
                <AddButton onAdd={addLanguage}>Add data</AddButton>
            </div>
            {
                loaded ?
                    (empLanguages.length>0) ?
                        empLanguages.map((el, key) => (
                            <div key={key}>
                                <Divider orientation="left"><Title level={4}>{key+1}.</Title></Divider>
                                <ActionButton editable deleteable onEdit={() => editLanguage(el)} onDelete={() => deleteLanguage(el.rec_applicant_language_skill_id)}/>
                                <Row>
                                    <ProfileItem span={5} title="Language">{el.language}</ProfileItem>
                                    <ProfileItem span={5} title="Reading">{el.reading ? el.reading.description : null}</ProfileItem>
                                    <ProfileItem span={5} title="Speaking">{el.speaking ? el.speaking.description : null}</ProfileItem>
                                    <ProfileItem span={5} title="Writing">{el.writing ? el.writing.description : null}</ProfileItem>
                                    <ProfileItem span={4} title="Listening">{el.listening ? el.listening.description : null}</ProfileItem>
                                </Row>
                            </div>
                        ))
                    : <Empty/>
                : <Spinner />
            }
            {
                (formModal) ?
                <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => saveLanguage(v)} width={800} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                    <Row>
                        <Col span={24} style={{paddingLeft: 5, paddingRight: 5}}>
                            <Form.Item name="rec_applicant_language_skill_id" hidden>
                                <Input placeholder="input placeholder" />
                            </Form.Item>
                            <Form.Item label="Language" name="language" rules={[{required:true}]}>
                                <Input placeholder="Language" />
                            </Form.Item>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col span={12} style={{paddingLeft: 5, paddingRight: 5}}>
                            <Form.Item label="Reading" name="reading" rules={[{required:true}]}>
                                <Select showSearch style={{ width: "100%" }} placeholder="Select Reading" optionFilterProp="children" onChange={changeReading} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {
                                        readings.map((el, key) => (
                                            <Option key={key} value={el.detail_code}>{el.description}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12} style={{paddingLeft: 5, paddingRight: 5}}>
                            <Form.Item label="Writing" name="writing" rules={[{required:true}]}>
                                <Select showSearch style={{ width: "100%" }} placeholder="Select Writing" optionFilterProp="children" onChange={changeWriting} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {
                                        writings.map((el, key) => (
                                            <Option key={key} value={el.detail_code}>{el.description}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col span={12} style={{paddingLeft: 5, paddingRight: 5}}>
                            <Form.Item label="Listening" name="listening" rules={[{required:true}]}>
                                <Select showSearch style={{ width: "100%" }} placeholder="Select Listening" optionFilterProp="children" onChange={changeListening} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {
                                        listenings.map((el, key) => (
                                            <Option key={key} value={el.detail_code}>{el.description}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12} style={{paddingLeft: 5, paddingRight: 5}}>
                            <Form.Item label="Speaking" name="speaking" rules={[{required:true}]}>
                                <Select showSearch style={{ width: "100%" }} placeholder="Select Speaking" optionFilterProp="children" onChange={changeSpeaking} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {
                                        speakings.map((el, key) => (
                                            <Option key={key} value={el.detail_code}>{el.description}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </FormModal> :
                null
            }
        </div>
    );
}

export default Language;
