import React, {useState} from 'react';
import {Card, message} from 'antd';
import {PageTitle} from '../../../components'
import RequestForm from './RequestForm'
import {SentralModel} from '../../../models/SentralModel'
import {useHistory} from 'react-router-dom'

const Create = () => {
    const history = useHistory()
    const [saving, setSaving] = useState(false)

    const submitReport = (v)=>{
        setSaving(true)
        SentralModel.action('Submission', 'saveMonthlyReport', v, 0).then(() => {
            message.destroy()
            message.success('Monthly report submitted', 1)
            setSaving(false)
            setTimeout(() => {
                history.push('/monthly-report');
            }, 1000);
        }).catch((err) => {
            setSaving(false)
        })
    }
    
    return (
        <Card className="content-container">
            <PageTitle title="Monthly Report Submission" breadcrumbs={[['Monthly Report', '/monthly-report'], ['Create']]}></PageTitle>
            <RequestForm onSubmit={(v) => submitReport(v)} saving={saving}/>
        </Card>
    );
}

export default Create;
