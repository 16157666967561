import React, {useEffect, useState} from 'react';
import {Card, Form, Row, Col, Input, DatePicker, Select, Typography, Space, Switch, Tag, Button, Popconfirm, message} from 'antd';
import {PageTitle, SubmitButton, DataTable, Spinner, ViewButton, AddButton, EditButton, DeleteButton, FormModal} from '../../../components'
import {SentralModel} from '../../../models/SentralModel'
import {useHistory, useParams} from 'react-router-dom'
import {SendOutlined} from '@ant-design/icons'
import moment from 'moment'

const {RangePicker} = DatePicker
const {Option} = Select;
const {Text} = Typography

const Detail = () => {
    const history = useHistory()
    const {vacancyId} = useParams()
    const [applicants, setApplicants] = useState([]);
    const [applicantInvitations, setApplicantInvitations] = useState([]);
    const [loaded, setLoaded] = useState(false)
    const [saving, setSaving] = useState(false)
    const [online, setOnline] = useState(false)
    const [applicant, setApplicant] = useState(false)
    const [applicantData, setApplicantData] = useState(null)
    const [defaultValues, setDefaultValues] = useState({})
    const [formModal, setFormModal] = useState(false)
    const [modalTitle, setModalTitle]= useState(null)

    const [form] = Form.useForm()
    const [divisions, setDivisions] = useState([]);
    const [positions, setPositions] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [educationLevels, setEducationLevels] = useState([]);
    const [selectedDivision, setSelectedDivision] = useState(null);
    const [selectedPosition, setSelectedPosition] = useState(null);
    const [selectedProvince, setSelectedProvince] = useState(null);
    const [selectedEducation, setSelectedEducation] = useState(null);
    const [isEdit, setIsEdit] = useState(null);

    const fetchVacancies = (form, vacancyId) => {
        setLoaded(false)
        if (vacancyId === '0') {
            form.resetFields();
            setIsEdit(false);
        } else {
            setIsEdit(true)
            SentralModel.action('Recruitment', 'getVacancyById', {rec_vacancy_id: vacancyId}).then(res => {
                form.setFieldsValue({
                    rec_vacancy_id: res.data.rec_vacancy_id,
                    division_id: res.data.division_id,
                    position_id: res.data.position_id,
                    subject: res.data.subject,
                    date: [moment(res.data.from_date, 'YYYY-MM-DD'), moment(res.data.to_date, 'YYYY-MM-DD')],
                    min_work_experience: res.data.min_work_experience,
                    education_level: res.data.education_level,
                    min_education: res.data.min_education,
                    age_limit: res.data.age_limit,
                    quota: res.data.quota,
                    work_location: res.data.work_location,
                    requirement: res.data.requirement,
                    job_description: res.data.job_description,
                    status: res.data.status
                })
                setApplicants(res.data.applicants)
                setApplicantInvitations(res.data.applicant_invitations)
                setSelectedDivision(res.data.division_id)
                setSelectedPosition(res.data.position_id)
                setSelectedProvince(res.data.work_location)
                setSelectedEducation(res.data.education_level)
            })
        }
        setLoaded(true)
    }

    const fetchDivisions = () => {
        SentralModel.list('Division', {orderBy:"division_name", order:"asc"}).then(res=> {
            setDivisions(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    const fetchPositions = () => {
        SentralModel.list('Position', {orderBy:"position_name", order:"asc"}).then(res=> {
            setPositions(res.data)
        }).finally(()=>{
            // setLoaded(true)
        })
    }

    const fetchProvinces = () => {
        SentralModel.list('Province', {orderBy:"province_name", order:"asc"}).then(res=> {
            setProvinces(res.data)
        }).finally(()=>{
            // setLoaded(true)
        })
    }

    const fetchEducationLevels = () => {
        SentralModel.list('GeneralDetail', {filter: [['general_code', 'ET'], ['detail_code', '!=', 'ETBS'], ['detail_code', '!=', 'ETSD'], ['detail_code', '!=', 'ETSLTP']], orderBy:"order_key", order:"asc"}).then(res=> {
            setEducationLevels(res.data)
        }).finally(()=>{
            // setLoaded(true)
        })
    }

    useEffect(() => {
        fetchVacancies(form, vacancyId)
        fetchDivisions()
        fetchPositions()
        fetchProvinces()
        fetchEducationLevels()
    }, [form, vacancyId]);
    
    const saveVacancy = (v) => {
        setSaving(true)

        let data = {
            rec_vacancy_id: vacancyId,
            division_id: selectedDivision,
            position_id: selectedPosition,
            subject: v.subject,
            from_date: moment(v.date[0], 'DD MMMM YYYY').format('YYYY-MM-DD'),
            to_date: moment(v.date[1], 'DD MMMM YYYY').format('YYYY-MM-DD'),
            min_work_experience: v.min_work_experience,
            education_level: selectedEducation,
            min_education: v.min_education,
            age_limit: v.age_limit,
            quota: v.quota,
            work_location: selectedProvince,
            requirement: v.requirement,
            job_description: v.job_description,
            status: 'Approved'
        }

        SentralModel.save('RecruitmentVacancy', data, vacancyId).then((res) => {
            message.success('Vacancy saved', 2);
            setTimeout(() => {
                history.push('/management/vacancy');
            }, 1000);
        })
    }

    const dataSource = [
        ...applicants.map( el => {
            return {
                id: el.rec_applicant_id,
                name: el.name,
                mobile_phone_no: el.mobile_phone_no,
                email: el.email
            }
        })
    ];

    const columns = [{
            title: 'No',
            dataIndex: 'no',
            key: 'name',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '40%'
        },
        {
            title: 'Mobile Phone',
            dataIndex: 'mobile_phone_no',
            key: 'mobile_phone_no',
            width: '30%'
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: '30%'
        },
        {
            title: 'Action',
            width:120,
            render:(row) =>(
                <div className="text-center">
                    <Space>
                        <ViewButton link to={"/management/vacancy/applicant/" + vacancyId + "/" + row.id}/>
                    </Space>
                </div>
            )
        },
    ];

    const handleApplicant = (e) =>{
        applicants.forEach((el) => {
            if(el.rec_applicant_id === e) {
                setApplicantData({name: el.name, email: el.email})
            }
        })
    }

    const handleSendEmail = (id) =>{
        SentralModel.action('Recruitment', 'sendEmailInvitation', {rec_applicant_invitation_id: id}, 0).then(res => {
            message.success('Email has been sent', 2);
        });
    }
    
    const addInvitation=()=>{
        setModalTitle('Add Invitation')
        setDefaultValues({
            rec_applicant_invitation_id: 0,
            rec_vacancy_id: vacancyId,
            type: 0,
            place: '',
            name: '',
            email: '',
        })
        setFormModal(true)
    }

    const editInvitation = (v) =>{
        setModalTitle('Edit Invitation')
        setDefaultValues({
            rec_applicant_invitation_id: v.id,
            rec_applicant_id: v.rec_applicant_id,
            rec_vacancy_id: vacancyId,
            type: v.type,
            place: v.place,
            schedule: moment(v.schedule, 'YYYY-MM-DD HH:mm'),
            name: v.name,
            email: v.email,
        })
        setFormModal(true)
        setOnline(v.type)

        if(v.rec_applicant_id){
            setApplicant(true)
        }
    }

    const deleteInvitation = (id) =>{
        message.loading('deleting invitation')
        SentralModel.deleteById('RecruitmentApplicantInvitation', id).then((res)=>{
            message.destroy()
            message.success('Invitation deleted', 2)
            let tmp = applicantInvitations
            let index = tmp.findIndex(el => el.rec_applicant_invitation_id===id)
            tmp.splice(index, 1);
            setApplicantInvitations([])
            setApplicantInvitations(tmp)
        }) // eslint-disable-next-line 
    }

    const saveInvitation = (v) => {
        setSaving(true)
        let payload={
            rec_applicant_id: v.name ? 0 : v.rec_applicant_id,
            rec_vacancy_id: vacancyId,
            type: v.type,
            place: v.place,
            schedule: moment(v.schedule, 'YYYY-MM-DD HH:mm').format("YYYY-MM-DD HH:mm"),
            name: v.name ? v.name : applicantData.name,
            email: v.email ? v.email : applicantData.email,
        }
        SentralModel.save('RecruitmentApplicantInvitation', payload, v.rec_applicant_invitation_id).then((res)=>{
            setFormModal(false)
            window.location.reload()
        }).finally(() => {
            setSaving(false)
        })
    }

    const dataSource2 = [
        ...applicantInvitations.map( el => {
            return {
                id: el.rec_applicant_invitation_id,
                rec_applicant_id: el.rec_applicant_id,
                name: el.name,
                email: el.email,
                type: el.type,
                place: el.place,
                schedule: moment(el.schedule, 'YYYY-MM-DD HH:mm').format("YYYY-MM-DD HH:mm"),
                status: el.status,
            }
        })
    ];

    const columns2 = [{
            title: 'No',
            dataIndex: 'no',
            key: 'name',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Type',
            width:120,
            render:(row) =>(
                <div className="text-center">
                    <Tag color={parseInt(row.type) === 1 ? 'green' : 'volcano'}>
                        {parseInt(row.type) === 1 ? 'Online' : 'Offline'}
                    </Tag>
                </div>
            )
        },
        {
            title: 'Place / Link',
            key: 'place',
            width: 120,
            render:(row) =>(
                <Typography.Text ellipsis={true} style={{width: 200}}>
                    {parseInt(row.type) === 1 ? 
                        <a href={row.place}>{row.place}</a>
                    : row.place}
                </Typography.Text>
            )
        },
        {
            title: 'Schedule',
            dataIndex: 'schedule',
            key: 'schedule',
        },

        {
            title: 'Action',
            width:120,
            render:(row) =>(
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => editInvitation(row)}/>
                        <DeleteButton onConfirm={() => deleteInvitation(row.id)}/>
                        { parseInt(row.status) === 0 ?
                            <Popconfirm title="Send email ?" okText="Yes" cancelText="No" onConfirm={(e) => handleSendEmail(row.id)}>
                                <Button type="link" icon={<SendOutlined />}/>
                            </Popconfirm>
                        : null }
                    </Space>
                </div>
            )
        },
    ];

    const changeDivision = e => {
        setSelectedDivision(e)
    }

    const changePosition = e => {
        setSelectedPosition(e)
    }

    const changeProvince = e => {
        setSelectedProvince(e)
    }

    const changeEducation = e => {
        setSelectedEducation(e)
    }

    const onChangeExp = e => {
        const {value} = e.target;
        if (isNaN(value)) {
            form.setFieldsValue({min_work_experience: value.replace(/[^0-9]+/g,"")})
        }
    }

    const onChangeAge = e => {
        const {value} = e.target;
        if (isNaN(value)) {
            form.setFieldsValue({age_limit: value.replace(/[^0-9]+/g,"")})
        }
    }

    const onChangeQuota = e => {
        const {value} = e.target;
        if (isNaN(value)) {
            form.setFieldsValue({quota: value.replace(/[^0-9]+/g,"")})
        }
    }

    // const selectBefore = (
    //     <Select placeholder="Select" defaultValue={selectedEducation} onChange={changeEducation}>
    //         {
    //             educationLevels.map((el, key) => (
    //                 <Option key={key} value={el.detail_code}>{el.description}</Option>
    //             ))
    //         }
    //     </Select>
    // );
    
    return (
        <Card className="content-container">
            <PageTitle title="Vacancy" breadcrumbs={[['Vacancy', '/management/vacancy'], ['Detail']]}></PageTitle>
            {
                loaded ?
                <div>
                    <Card title={
                        <div className="full-width">
                            <Text strong style={{float:'left'}}>Vacancy Detail</Text>
                        </div> }>
                    <Form size="middle" form={form} layout="vertical" onFinish={saveVacancy}>
                        <Form.Item name="rec_vacancy_id" hidden>
                            <Input placeholder="Vacancy ID" />
                        </Form.Item>
                        <Row justify="space-around">
                            <Col span={11}>
                                <Form.Item label="Division" name="division_id" rules={[{required:true}]}>
                                    <Select showSearch style={{ width: "100%" }} placeholder="Select Division" optionFilterProp="children" onChange={changeDivision} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {
                                            divisions.map((el, key) => (
                                                <Option key={key} value={el.division_id}>{el.division_name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item label="Position" name="position_id" rules={[{required:true}]}>
                                    <Select showSearch style={{ width: "100%" }} placeholder="Select Position" optionFilterProp="children" onChange={changePosition} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {
                                            positions.map((el, key) => (
                                                <Option key={key} value={el.position_id}>{el.position_name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="space-around">
                            <Col span={11}>
                                <Form.Item label="Subject" name="subject" rules={[{required:true}]}>
                                    <Input placeholder="Subject" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item label="Work Experience" name="min_work_experience" rules={[{required:true}]}>
                                    <Input addonBefore="Min" addonAfter="Year(s)" onChange={onChangeExp} placeholder="Work Experience" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="space-around">
                            <Col span={11}>
                                <Form.Item label="Work Location" name="work_location" rules={[{required:true}]}>
                                    <Select showSearch style={{ width: "100%" }} placeholder="Select Location" optionFilterProp="children" onChange={changeProvince} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {
                                            provinces.map((el, key) => (
                                                <Option key={key} value={el.province_id}>{el.province_name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item label="Age Limit" name="age_limit" rules={[{required:true}]}>
                                    <Input onChange={onChangeAge} placeholder="Age Limit" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="space-around">
                            <Col span={11}>
                                <Form.Item label="Recruitment Date" name="date" rules={[{required:true}]}>
                                    <RangePicker style={{width:'100%'}}/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item label="Quota" name="quota" rules={[{required:true}]}>
                                    <Input onChange={onChangeQuota} placeholder="Quota" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="space-around">
                            <Col span={11}>
                                <Form.Item label="Education Level" name="education_level" rules={[{required:true}]}>
                                <Select placeholder="Select" defaultValue={selectedEducation} onChange={changeEducation}>
                                        {
                                            educationLevels.map((el, key) => (
                                                <Option key={key} value={el.detail_code}>{el.description}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item label="Major" name="min_education" rules={[{required:true}]}>
                                    <Input placeholder="Education" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="space-around">
                            <Col span={11}>
                                <Form.Item label="Requirement" name="requirement" rules={[{required:true}]}>
                                    <Input.TextArea rows={4} placeholder="Requirement" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item label="Job Description" name="job_description" rules={[{required:true}]}>
                                    <Input.TextArea rows={4} placeholder="Job Description" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="space-around">
                            <Col span={11} />
                            <Col span={11}>
                                <SubmitButton right loading={saving}/>
                            </Col>
                        </Row>
                    </Form>
                    </Card>

                    <br />
                    
                    {
                        isEdit ?
                        <Card title={
                            <div className="full-width">
                                <Text strong style={{float:'left'}}>Applicant List</Text>
                                {/* <NewButton right to="/management/applicant/detail/0" title="New Applicant"/> */}
                            </div> }>

                            <DataTable size='small' bordered dataSource={dataSource} columns={columns}/>
                        </Card>
                        : null
                    }

                    <br />
                    
                    {
                        isEdit ?
                        <Card title={
                            <div className="full-width">
                                <Text strong style={{float:'left'}}>Invitation List</Text>
                                <AddButton right onAdd={addInvitation} title="Add Invitation"/>
                            </div> }>

                            <DataTable size='small' bordered dataSource={dataSource2} columns={columns2}/>
                        </Card>
                        : null
                    }
                </div>
                : <Spinner />
            }

            {
                (formModal) ?
                <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v)=>saveInvitation(v)} width={500} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                    <Form.Item name="rec_applicant_invitation_id" hidden>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Type" name="type" rules={[{required:true}]}>
                        <Switch checkedChildren="Online" unCheckedChildren="Offline" onChange={(v) => setOnline(v)} defaultChecked={online} />
                    </Form.Item>
                    { online ?
                        <Form.Item label="Link" name="place" required rules={[{required:true}]}>
                            <Input placeholder="Link" />
                        </Form.Item>
                    : 
                        <Form.Item label="Place" name="place" required rules={[{required:true}]}>
                            <Input placeholder="Place" />
                        </Form.Item>
                    }

                    <Form.Item label="Schedule" name="schedule" required rules={[{required:true}]}>
                        <DatePicker showTime format="YYYY-MM-DD HH:mm" style={{ width: "100%"}}/>
                    </Form.Item>
                    <Form.Item label="Status">
                        <Switch checkedChildren="Applicant" unCheckedChildren="Non Applicant" onChange={(v) => setApplicant(v)} defaultChecked={applicant} />
                    </Form.Item>

                    { applicant ?
                        <Form.Item name="rec_applicant_id" label="Applicant" rules={[{required:true}]}>
                            <Select onChange={handleApplicant}>
                                {
                                    applicants.map((el, key)=>(
                                        <Option key={key} value={el.rec_applicant_id}>{el.name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    : 
                        <>
                            <Form.Item label="Name" name="name" required rules={[{required:true}]}>
                                <Input placeholder="Name" />
                            </Form.Item>
                            <Form.Item label="Email" name="email" required rules={[{required:true}]}>
                                <Input placeholder="Email" />
                            </Form.Item>
                        </>
                    }
                </FormModal> :
                null
            }
        </Card>
    );
}

export default Detail;
