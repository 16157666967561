import { useState, useEffect } from 'react'
import { Card, Col, Row, Typography, Statistic } from 'antd'
import { PageTitle, Spinner } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import { FileDoneOutlined } from '@ant-design/icons'
import randomColor from 'randomcolor'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
);

const options = {
    responsive: true,
};

const { Text } = Typography

const DashboardCRF = () => {
    const [loadedTotal, setLoadedTotal] = useState(false)
    const [loadedCrfDivision, setLoadedCrfDivision] = useState(false)
    const [totalCrf, setTotalCrf] = useState([])
    const [crfDivision, setCrfDivision] = useState([])

    const fetchTotalCrf = () => {
        SentralModel.action('ChangeRequestForm', 'listCrf', null, null).then((res) => {
            setTotalCrf(res.data)
            setLoadedTotal(true)
        }).catch(() => {
        })
    }

    const fetchCrfDivision = () => {
        SentralModel.action('ChangeRequestForm', 'crfDivision', null, null).then((res) => {
            const v = res.data
            let data = []

            v.map((el, key) => {
                return data.push({
                    label: el.division_name,
                    data: el.list,
                    backgroundColor: randomColor(),
                    borderWidth: 1
                })
            })

            setCrfDivision(data)
            setLoadedCrfDivision(true)
        }).catch(() => {
        })
    }

    useEffect(() => {
        fetchTotalCrf()
        fetchCrfDivision()

    }, []);

    const dataChart = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: crfDivision
    }

    return (
        <Card className="content-container">
            <PageTitle title="Change Request Form Dashboard" breadcrumbs={[['Change Request Form', '/crf'], ['Dashboard']]}></PageTitle>
            <div style={{ marginBottom: 20 }}>
                <Row justify='space-between mb-5'>
                    <Col span={7}>
                        <Card className="custom-box-shadow" style={{ borderRadius: 10, backgroundColor: "#17A2B8" }}>
                            <div className='mb-3'>
                                <Text style={{ fontSize: 20, color: 'white' }}>Waiting</Text>
                            </div>
                            <Statistic
                                value={loadedTotal ? totalCrf.waiting?.length ?? 0 : <Spinner />}
                                valueStyle={{ color: 'white', fontSize: 20 }}
                                prefix={<FileDoneOutlined />}
                            />
                        </Card>
                    </Col>
                    <Col span={7}>
                        <Card className="custom-box-shadow" style={{ borderRadius: 10, backgroundColor: "#23D3C6" }}>
                            <div className='mb-3'>
                                <Text style={{ fontSize: 20, color: 'white' }}>Open</Text>
                            </div>
                            <Statistic
                                value={loadedTotal ? totalCrf.open?.length ?? 0 : <Spinner />}
                                valueStyle={{ color: 'white', fontSize: 20 }}
                                prefix={<FileDoneOutlined />}
                            />
                        </Card>
                    </Col>
                    <Col span={7}>
                        <Card className="custom-box-shadow" style={{ borderRadius: 10, backgroundColor: "#FFB23E" }}>
                            <div className='mb-3'>
                                <Text style={{ fontSize: 20, color: 'white' }}>On Progress</Text>
                            </div>
                            <Statistic
                                value={loadedTotal ? totalCrf.onprogress?.length ?? 0 : <Spinner />}
                                valueStyle={{ color: 'white', fontSize: 20 }}
                                prefix={<FileDoneOutlined />}
                            />
                        </Card>
                    </Col>
                </Row>
                <Row justify='space-between'>
                    <Col span={7}>
                        <Card className="custom-box-shadow" style={{ borderRadius: 10, backgroundColor: "#28A745" }}>
                            <div className='mb-3'>
                                <Text style={{ fontSize: 20, color: 'white' }}>Finished</Text>
                            </div>
                            <Statistic
                                value={loadedTotal ? totalCrf.finished?.length ?? 0 : <Spinner />}
                                valueStyle={{ color: 'white', fontSize: 20 }}
                                prefix={<FileDoneOutlined />}
                            />
                        </Card>
                    </Col>
                    <Col span={7}>
                        <Card className="custom-box-shadow" style={{ borderRadius: 10, backgroundColor: "#DC3545" }}>
                            <div className='mb-3'>
                                <Text style={{ fontSize: 20, color: 'white' }}>Closed</Text>
                            </div>
                            <Statistic
                                value={loadedTotal ? totalCrf.closed?.length ?? 0 : <Spinner />}
                                valueStyle={{ color: 'white', fontSize: 20 }}
                                prefix={<FileDoneOutlined />}
                            />
                        </Card>
                    </Col>
                    <Col span={7}>
                        <Card className="custom-box-shadow" style={{ borderRadius: 10, backgroundColor: "#DC3545" }}>
                            <div className='mb-3'>
                                <Text style={{ fontSize: 20, color: 'white' }}>Reject</Text>
                            </div>
                            <Statistic
                                value={loadedTotal ? totalCrf.reject?.length ?? 0 : <Spinner />}
                                valueStyle={{ color: 'white', fontSize: 20 }}
                                prefix={<FileDoneOutlined />}
                            />
                        </Card>
                    </Col>
                </Row>
            </div>

            <Card title="Total CRF Division" style={{ height: '800px', borderRadius: 10 }}>
                {
                    loadedCrfDivision ?
                        <Row>
                            <Col span={24}>
                                <Bar
                                    data={dataChart}
                                    options={options}
                                />
                            </Col>
                        </Row>
                        : <Spinner />
                }
            </Card>
        </Card>
    )
}

export default DashboardCRF