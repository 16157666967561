import React, {useEffect, useState} from 'react'
import {Row, Col, Typography, Divider, Empty, Form, Input, Select, DatePicker, message} from 'antd'
import ProfileItem from './ProfileItem';
import ActionButton from './ActionButton';
import {AddButton, FormModal, Spinner} from '../../../components';
import {SentralModel} from '../../../models/SentralModel'
import moment from 'moment'

const {Title} = Typography
const {Option} = Select;
const Family = (props) => {
    const [empFamilies, setEmpFamilies] = useState(props.data);
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false);
    const [defaultValues, setDefaultValues] = useState({});
    const [saving, setSaving] = useState(false);
    const [modalTitle, setModalTitle]= useState(null);
    const [relation, setRelation] = useState([]);
    const [gender, setGender] = useState([]);
    const [education, setEducation] = useState([]);
    const [selectedRelation, setSelectedRelation] = useState([]);
    const [selectedGender, setSelectedGender] = useState([]);
    const [selectedEducation, setSelectedEducation] = useState([]);

    const fetchEmpFamilies = () => {
        setLoaded(false)
        SentralModel.list('RecruitmentApplicantFamily', {filter: [['rec_applicant_id', props.applicant_id]]}).then(res=>{
            setEmpFamilies(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    const fetchRelation = () => {
        SentralModel.list('GeneralDetail', {filter: [['general_code', 'FR']], orderBy:'order_key', order:'asc'}).then(res=>{
            setRelation(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    const fetchGender = () => {
        SentralModel.list('GeneralDetail', {filter: [['general_code', 'G']], orderBy:'order_key', order:'asc'}).then(res=>{
            setGender(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    const fetchEducation = () => {
        SentralModel.list('GeneralDetail', {filter: [['general_code', 'ET']], orderBy:'order_key', order:'asc'}).then(res=>{
            setEducation(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchRelation()
        fetchGender()
        fetchEducation()
    }, []);

    const addFamily = () => {
        setModalTitle('Add Family')
        setDefaultValues({
            rec_applicant_family_id: 0
        })
        setFormModal(true)
    }

    const editFamily = (v) =>{
        setModalTitle('Edit Family')
        setDefaultValues({
            rec_applicant_family_id: v.rec_applicant_family_id,
            name: v.name,
            family_relation: v.family_relation.detail_code,
            gender: v.gender.detail_code,
            place_of_birth: v.place_of_birth,
            date_of_birth: moment(v.date_of_birth, 'YYYY-MM-DD'),
            education: v.education === null ? null : v.education.detail_code,
            job: v.job
        })
        setSelectedRelation(v.family_relation.detail_code)
        setSelectedGender(v.gender.detail_code)
        setSelectedEducation(v.education === null ? null : v.education.detail_code)
        setFormModal(true)
    }

    const saveFamily = (v) => {
        setSaving(true)
        let payload={
            rec_applicant_family_id: v.rec_applicant_family_id,
            rec_applicant_id: props.applicant_id,
            name: v.name,
            family_relation: selectedRelation,
            gender: selectedGender,
            place_of_birth: v.place_of_birth,
            date_of_birth: moment(v.date_of_birth, 'DD MMMM YYYY').format('YYYY-MM-DD'),
            education: selectedEducation,
            job: v.job
        }
        SentralModel.save('RecruitmentApplicantFamily', payload, v.rec_applicant_family_id).then((res)=>{
            setFormModal(false)
            fetchEmpFamilies()
            message.success('Family saved', 2);
        }).finally(() => {
            setSaving(false)
        })
    }

    const deleteFamily = (id) => {
        message.loading('deleting family')
        SentralModel.deleteById('RecruitmentApplicantFamily', id).then((res)=>{
            message.destroy()
            message.success('Family deleted', 2)
            let tmp = empFamilies
            let index = tmp.findIndex(el => el.rec_applicant_family_id===id)
            tmp.splice(index, 1);
            setEmpFamilies([])
            setEmpFamilies(tmp)
        })
    }

    const changeRelation = e => {
        setSelectedRelation(e)
    }

    const changeGender = e => {
        setSelectedGender(e)
    }

    const changeEducation = e => {
        setSelectedEducation(e)
    }

    return (
        <div>
            <div className="text-right">
                <AddButton onAdd={addFamily}>Add data</AddButton>
            </div>
            {
                loaded ?
                    (empFamilies.length > 0) ?
                        empFamilies.map((el, key) => (
                            <div key={key}>
                                <Divider orientation="left"><Title level={4}>{el.family_relation === null ? null : el.family_relation.description}</Title></Divider>
                                <ActionButton editable deleteable onEdit={() => editFamily(el)} onDelete={() => deleteFamily(el.rec_applicant_family_id)}/>
                                <Row>
                                    <ProfileItem title="Name">{el.name}</ProfileItem>
                                    <ProfileItem title="Gender">{el.gender === null ? null : el.gender.description}</ProfileItem>
                                    <ProfileItem title="Place Of Birth">{el.place_of_birth}</ProfileItem>
                                    <ProfileItem title="Date Of Birth">{moment(el.date_of_birth).format('DD MMMM YYYY')}</ProfileItem>
                                    <ProfileItem title="Education">{el.education === null ? '-' : el.education ? el.education.description : null}</ProfileItem>
                                    <ProfileItem title="Job">{el.job === null ? '-' : el.job}</ProfileItem>
                                </Row>
                            </div>
                        ))
                    : <Empty />
                : <Spinner />
            }
            {
                (formModal) ?
                <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => saveFamily(v)} width={800} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                    <Row>
                        <Col span={24} style={{paddingLeft: 5, paddingRight: 5}}>
                            <Form.Item name="rec_applicant_family_id" hidden>
                                <Input placeholder="input placeholder" />
                            </Form.Item>
                            <Form.Item label="Name" name="name" rules={[{required:true}]}>
                                <Input placeholder="Name" />
                            </Form.Item>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col span={12} style={{paddingLeft: 5, paddingRight: 5}}>
                            <Form.Item label="Family Relation" name="family_relation" rules={[{required:true}]}>
                                <Select showSearch style={{ width: "100%" }} placeholder="Select Family Relation" optionFilterProp="children" onChange={changeRelation} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {
                                        relation.map((el, key) => (
                                            <Option key={key} value={el.detail_code}>{el.description}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12} style={{paddingLeft: 5, paddingRight: 5}}>
                            <Form.Item label="Gender" name="gender" rules={[{required:true}]}>
                                <Select showSearch style={{ width: "100%" }} placeholder="Select Gender" optionFilterProp="children" onChange={changeGender} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {
                                        gender.map((el, key) => (
                                            <Option key={key} value={el.detail_code}>{el.description}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col span={12} style={{paddingLeft: 5, paddingRight: 5}}>
                            <Form.Item label="Place Of Birth" name="place_of_birth" rules={[{required:true}]}>
                                <Input placeholder="Place Of Birth" />
                            </Form.Item>
                        </Col>
                        <Col span={12} style={{paddingLeft: 5, paddingRight: 5}}>
                            <Form.Item label="Date Of Birth" name="date_of_birth" rules={[{required:true}]}>
                                <DatePicker placeholder="Date Of Birth" format="DD MMMM YYYY" style={{width:'100%'}}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col span={12} style={{paddingLeft: 5, paddingRight: 5}}>
                            <Form.Item label="Last Education" name="education" rules={[{required:false}]}>
                                <Select showSearch style={{ width: "100%" }} placeholder="Select Last Education" optionFilterProp="children" onChange={changeEducation} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {
                                        education.map((el, key) => (
                                            <Option key={key} value={el.detail_code}>{el.description}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        
                        <Col span={12} style={{paddingLeft: 5, paddingRight: 5}}>
                            <Form.Item label="Job" name="job" rules={[{required:false}]}>
                                <Input placeholder="Job" />
                            </Form.Item>
                        </Col>
                    </Row>
                </FormModal> :
                null
            }
        </div>
    );
}

export default Family;
