import { useState, useEffect } from 'react'
import { Button, Card, Col, Row, Space, Tag, message } from 'antd'
import { PageTitle } from '../../../components'
import { useParams } from 'react-router-dom'
import { SentralModel } from '../../../models/SentralModel'
import { CheckCircleTwoTone } from '@ant-design/icons'
import moment from 'moment'
import currentUser from '../../../helpers/authData'

const Index = () => {
    const { id } = useParams()
    const [loaded, setLoaded] = useState(true)
    const [defaultValue, setDefaultValue] = useState({})
    const [incidentCeklis, setIncidentCeklis] = useState([])
    const [incidentApproval, setIncidentApproval] = useState([])

    const fetchIncidentReport = (id) => {
        setLoaded(false)
        SentralModel.action("ItInfra", "getIncidentReportById", null, id).then((res) => {
            setDefaultValue(res.data.incident_report)
            setIncidentCeklis(res.data.incident_ceklis)
            setLoaded(true)
        })
    }

    const fetchIncidentApproval = () => {
        SentralModel.action("ItInfra", "getIncidentApproval", null, null).then((res) => {
            setIncidentApproval(res.data)
        })
    }

    useEffect(() => {
        fetchIncidentReport(id)
        fetchIncidentApproval()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const approvePic = () => {
        SentralModel.action("ItInfra", "incidentApprovePic", null, id).then((res) => {
            message.success("Approved")
            fetchIncidentReport(id)
        }).catch((err) => {
            message.warning("Failed Approved")
        })
    }

    const approvePm = () => {
        SentralModel.action("ItInfra", "incidentApprovePm", null, id).then((res) => {
            message.success("Approved")
            fetchIncidentReport(id)
        }).catch((err) => {
            message.warning("Failed Approved")
        })
    }

    const generateReport = () => {
        window.open(`${process.env.REACT_APP_API_URL}/report/incident-report/${id}`)
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Detail Incident Report" breadcrumbs={[['Incident Report', '/it-infra/incident-report'], ['Detail']]}></PageTitle>
                <Card title={
                    <Row justify='space-between'>
                        <Col span={10}>
                            Detail
                        </Col>
                        <Col>
                            <Space>
                                {
                                    defaultValue.pic_status === "WAITING" &&
                                        incidentApproval.find((el) => el.value === "PIC_IT" && el.employee_code === currentUser.employee_code) ?
                                        <Button type='primary' onClick={approvePic}>
                                            Approve PIC
                                        </Button> : null
                                }
                                {
                                    defaultValue.pic_status === "APPROVE" && defaultValue.pm_status === "WAITING" && incidentApproval.filter((el) => el.value === "MR_IT" && el.employee_code === currentUser.employee_code)[0] ?
                                        <Button type='primary' onClick={approvePm}>
                                            Approve PM
                                        </Button> : null
                                }
                                {
                                    defaultValue.pic_status === "APPROVE" && defaultValue.pm_status === "APPROVE" ?
                                        <Button type="primary" onClick={generateReport}>
                                            Export Report
                                        </Button> : null
                                }
                            </Space>
                        </Col>
                    </Row>
                }>
                    <Row className="mb-4 mt-1">
                        <Col span={24}>
                            {
                                loaded &&
                                <table className="table-collapse table-default" style={{ width: '100%' }}>
                                    <tbody>
                                        <tr>
                                            <th>Insiden Kode</th><td>{defaultValue?.incident_code ?? "-"}</td>
                                        </tr>
                                        <tr>
                                            <th>Insiden</th><td>{defaultValue?.incident_name ?? "-"}</td>
                                        </tr>
                                        <tr>
                                            <th>Tipe Insiden</th><td>{defaultValue?.incident_type?.replaceAll("_", " ") ?? "-"}</td>
                                        </tr>
                                        <tr>
                                            <th>Deskripsi</th><td>{defaultValue?.incident_description ?? "-"}</td>
                                        </tr>
                                        <tr>
                                            <th>Tanggal Insiden</th><td>{moment(defaultValue?.incident_date).format("DD MMMM YYYY")}</td>
                                        </tr>
                                        <tr>
                                            <th>Waktu Insiden</th><td>{defaultValue?.incident_time ?? "-"}</td>
                                        </tr>
                                        <tr>
                                            <th>Penyebab Masalah</th><td>{defaultValue?.cause_problem ?? "-"}</td>
                                        </tr>
                                        <tr>
                                            <th>Verifikasi Keefektifan dan Keefesienan Tindakan Koreksi</th>
                                            <td style={{ margin: 0, padding: 0 }}>
                                                <table className="table-collapse table-default" style={{ width: '100%' }}>
                                                    <tr>
                                                        <td>
                                                            {defaultValue?.corrective_verification_description ?? "-"}
                                                        </td>
                                                        <td>
                                                            <Tag color={defaultValue?.corrective_verification_status === "CLOSE" ? "red" : "green"}>
                                                                {defaultValue?.corrective_verification_status}
                                                            </Tag>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Verifikasi Keefektifan dan Keefisienan Tindakan Perbaikan</th>
                                            <td style={{ margin: 0, padding: 0 }}>
                                                <table className="table-collapse table-default" style={{ width: '100%' }}>
                                                    <tr>
                                                        <td>
                                                            {defaultValue?.perb_verification_description ?? "-"}
                                                        </td>
                                                        <td>
                                                            <Tag color={defaultValue?.perb_verification_status === "CLOSE" ? "red" : "green"}>
                                                                {defaultValue?.perb_verification_status}
                                                            </Tag>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Kesimpulan</th>
                                            <td style={{ margin: 0, padding: 0 }}>
                                                <table className="table-collapse table-default" style={{ width: '100%' }}>
                                                    <tr>
                                                        <td>
                                                            {defaultValue?.incident_conclusion_description ?? "-"}
                                                        </td>
                                                        <td>
                                                            <Tag color={defaultValue?.incident_conclusion_status === "CLOSE" ? "red" : "green"}>
                                                                {defaultValue?.incident_conclusion_status}
                                                            </Tag>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>PIC Status</th>
                                            <td>
                                                <Tag color={defaultValue?.pic_status === "WAITING" ? "blue" : "green"}>
                                                    {defaultValue?.pic_status}
                                                </Tag>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>PM Status</th>
                                            <td>
                                                <Tag color={defaultValue?.pm_status === "WAITING" ? "blue" : "green"}>
                                                    {defaultValue?.pm_status}
                                                </Tag>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <table className="table-collapse table-default" style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'center' }}>Alat / Lingkungan</th>
                                    <th style={{ textAlign: 'center' }}>Personil</th>
                                    <th style={{ textAlign: 'center' }}>Metode Kerja</th>
                                    <th style={{ textAlign: 'center' }}>Dokumen</th>
                                    <th style={{ textAlign: 'center' }}>Biaya</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ textAlign: 'center' }}>
                                        {incidentCeklis.includes("tools_value") ? <CheckCircleTwoTone /> : "-"}
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        {incidentCeklis.includes("personnel_value") ? <CheckCircleTwoTone /> : "-"}
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        {incidentCeklis.includes("working_method_value") ? <CheckCircleTwoTone /> : "-"}
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        {incidentCeklis.includes("document_value") ? <CheckCircleTwoTone /> : "-"}
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        {incidentCeklis.includes("cost_value") ? <CheckCircleTwoTone /> : "-"}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Row>
                </Card>
            </Card >
        </div >
    )
}

export default Index