import React, { useState, useEffect } from 'react';
import { Button, Card, Col, DatePicker, Form, Input, message, Row, Select, Tabs } from 'antd'
import { FormModal, Spinner } from '../../../components'
import PersonalData from './PersonalData'
import Education from './Education'
import Document from './Document'
import WorkExperience from './WorkExperience'
import Language from './Language'
import Skill from './Skill'
import Certification from './Certification'
import Family from './Family'
import Spouse from './Spouse'
import { SentralModel } from '../../../models/SentralModel'
import moment from 'moment'
// import { useParams } from 'react-router-dom';
// import authData from '../../../helpers/authData'
// import {useParams} from 'react-router-dom'

const { TabPane } = Tabs
const { Option } = Select

function useForceUpdate() {
    /* eslint-disable */
    const [value, setValue] = useState(0);
    /* eslint-enable */
    return () => setValue(value => ++value);
}

const Index = (props) => {
    const forceUpdate = useForceUpdate()

    // const {applicantId} = props.applicantId
    const [applicantId] = useState(props.applicantId);
    const [applicant, setApplicant] = useState()
    const [generals, setGenerals] = useState(null)

    const [companies, setCompanies] = useState([]);
    const [employeeStatus, setEmployeeStatus] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [formModal, setFormModal] = useState(false)
    const [saving, setSaving] = useState(false)

    const fetchApplicant = (applicantId) => {
        setTimeout(() => {
            SentralModel.get('RecruitmentApplicant', [], applicantId).then((res) => {
                setApplicant(res.data)
            })
        }, 1000);
    }

    const fetchGeneral = () => {
        SentralModel.list('GeneralDetail', { orderBy: 'order_key', order: 'asc' }).then((res) => {
            setGenerals(res.data)
        })
    }

    const updateApplicant = (v) => {
        setApplicant(v)
        forceUpdate()
    }

    const fetchCompanies = () => {
        SentralModel.list('Company', {filter:[["company_code", "!=", null]], orderBy:"company_code", order:"desc"}).then(res=> {
            setCompanies(res.data)
        })
    }

    const fetchEmployeeStatus = () => {
        SentralModel.list('GeneralDetail', {filter:[['general_code', 'ES'], ['detail_code', '!=', 'ESRS'], ['detail_code', '!=', 'ESFR']], orderBy:"order_key", order:"asc"}).then(res=> {
            setEmployeeStatus(res.data)
        })
    }

    useEffect(() => {
        fetchApplicant(applicantId)
        fetchGeneral()
        fetchCompanies()
        fetchEmployeeStatus()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicantId]);

    const renderItem = (v) => {
        if (v) return v;
        return '-';
    }

    const changeProfileTab = (v) => {
        sessionStorage.setItem('selectedProfileTab', v)
    }

    const changeCompany = e => {
        setSelectedCompany(e)
    }

    const changeStatus = e => {
        setSelectedStatus(e)
    }

    const addEmployee = () => {
        setFormModal(true)
    }

    const saveEmployee = (v) => {
        setSaving(true)
        let data = {
            rec_applicant_id: applicantId,
            company_id: selectedCompany,
            join_date: moment(v.join_date, 'DD MMMM YYYY').format('YYYY-MM-DD'),
            work_email: v.work_email,
            employee_status: selectedStatus,
            password: 'password'
        }
        SentralModel.action('HumanResource', 'exportEmployee', data, 0).then((res) => {
            setFormModal(false)
            message.success('Employee exported', 2);
        }).finally(() => {
            setSaving(false)
        })
    }

    return (
        <div>
            <div className='mb-3 text-right' >
                <Button type='primary' onClick={() => addEmployee()}>Generate Employee Data</Button>
            </div>

            <Card>
                {/* <PageTitle title="Applicant" breadcrumbs={applicantId ? [['Vacancy', '/management/vacancy'], ['Detail', '/management/vacancy/detail/' + applicantId], ['Applicant']] : [['Recruitment']]}></PageTitle> */}
                {
                    (applicant) ?
                        <Tabs defaultActiveKey="personalData" tabPosition="left" onChange={changeProfileTab} tabBarStyle={{ marginLeft: 0, paddingLeft: 0 }}>
                            <TabPane tab="Personal Data" key="personalData">
                                <PersonalData generals={generals} data={applicant} renderItem={(v) => renderItem(v)} updateApplicant={(v) => updateApplicant(v)} />
                            </TabPane>
                            <TabPane tab="Education" key="education">
                                <Education applicant_id={applicant.rec_applicant_id} data={applicant.educations} renderItem={(v) => renderItem(v)} />
                            </TabPane>
                            <TabPane tab="Family" key="family">
                                <Family applicant_id={applicant.rec_applicant_id} data={applicant.families} renderItem={(v) => renderItem(v)} />
                            </TabPane>
                            {
                                applicant.marital_status === 'MSM' ?
                                    <TabPane tab="Spouse" key="spouse">
                                        <Spouse applicant_id={applicant.rec_applicant_id} spouse={applicant.spouses} children={applicant.childrens} renderItem={(v) => renderItem(v)} />
                                    </TabPane>
                                    : null
                            }
                            <TabPane tab="Work Experience" key="workExperience">
                                <WorkExperience applicant_id={applicant.rec_applicant_id} data={applicant.work_experiences} renderItem={(v) => renderItem(v)} />
                            </TabPane>
                            <TabPane tab="Language" key="language">
                                <Language applicant_id={applicant.rec_applicant_id} data={applicant.language_skills} renderItem={(v) => renderItem(v)} />
                            </TabPane>
                            <TabPane tab="Skill" key="skill">
                                <Skill applicant_id={applicant.rec_applicant_id} data={applicant.skills} renderItem={(v) => renderItem(v)} />
                            </TabPane>
                            <TabPane tab="Training and Certification" key="certification">
                                <Certification applicant_id={applicant.rec_applicant_id} data={applicant.certifications} renderItem={(v) => renderItem(v)} />
                            </TabPane>
                            <TabPane tab="Document" key="document">
                                <Document applicant_id={applicant.rec_applicant_id} data={applicant.document} renderItem={(v) => renderItem(v)} updateApplicant={(v) => updateApplicant(v)} />
                            </TabPane>
                        </Tabs>
                    : <Spinner />
                }
            </Card>

            {
                (formModal) ?
                <FormModal title="Export Employee" submitForm={(v)=>saveEmployee(v)} width={800} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                    <Row>
                        <Col span={12} style={{paddingLeft: 5, paddingRight: 5}}>
                            <Form.Item label="Company" name="company_id" rules={[{required:true}]}>
                                <Select showSearch style={{ width: "100%" }} placeholder="Select Company" optionFilterProp="children" onChange={changeCompany} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {
                                        companies.map((el, key) => (
                                            <Option key={key} value={el.company_id}>{el.company_name}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12} style={{paddingLeft: 5, paddingRight: 5}}>
                            <Form.Item label="Join Date" name="join_date" rules={[{required:true}]}>
                                <DatePicker placeholder="Join Date" format="DD MMMM YYYY" style={{width:'100%'}}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} style={{paddingLeft: 5, paddingRight: 5}}>
                            <Form.Item label="Work Email" name="work_email" rules={[{required:true}]}>
                                <Input placeholder="Work Email" />
                            </Form.Item>
                        </Col>
                        <Col span={12} style={{paddingLeft: 5, paddingRight: 5}}>
                            <Form.Item label="Employee Status" name="employee_status" rules={[{required:true}]}>
                                <Select showSearch style={{ width: "100%" }} placeholder="Select Status" optionFilterProp="children" onChange={changeStatus} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {
                                        employeeStatus.map((el, key) => (
                                            <Option key={key} value={el.detail_code}>{el.description}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </FormModal>
                : null
            }
        </div>
    );
}

export default Index;
