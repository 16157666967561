import { atom } from "recoil";

export const tabState = atom({
    key: "defaultTab",
    default: (sessionStorage.getItem('selectedDashboardTab')) ? sessionStorage.getItem('selectedDashboardTab') : 'absence'
})

export const payrollPeriodState = atom({
    key: "payrollPeriodId",
    default: 0
})
