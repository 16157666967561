import React, {useEffect, useState} from 'react';
import {Row, message} from 'antd'
import ProfileItem from './ProfileItem'
// import {SentralModel} from '../../models/SentralModel'
import {ImageViewer} from '../../../components/ImageViewer'
import {Spinner, BasicModal, Uploader, EditButton} from '../../../components';
import {SentralModel} from '../../../models/SentralModel'

const Document = (props) => {
    const [empDocuments, setEmpDocuments] = useState(props.data);
    const [loaded, setLoaded] = useState(false)
    const [showEditDocument, setShowEditDocument] = useState(false)
    const [documentField, setDocumentField] = useState(null);
    const [documentFolder, setDocumentFolder] = useState(null);
    const [modalTitle, setModalTitle] = useState(null);
    const [uploadingDocument, setUploadingDocument] = useState(false)

    // const fetchempDocuments = () =>{
    //     setLoaded(false)
    //     SentralModel.list('EmployeeDocument', {filter: [['employee_code', props.emp_code]]}).then(res=>{
    //         setempDocuments(res.data)
    //     }).finally(()=>{
    //         setLoaded(true)
    //     })
    // }

    useEffect(() => {
        // fetchempDocuments()
        setLoaded(true)
    }, []);

    const upload = (title, field, folder) => {
        setShowEditDocument(true);
        setModalTitle(title);
        setDocumentField(field);
        setDocumentFolder(folder)
    }

    const documentUploaded = (v, field) =>{
        if(uploadingDocument){
            setUploadingDocument(false)
        }
        SentralModel.action('Profile', 'changeDocument', {employee_code:props.data.employee_code, field: documentField, value: v.message},0).then(()=>{
            message.success('Document Updated', 2)
            let document = props.data
            if (field === 'identity_card')
                document.identity_card = v.message
            else if (field === 'family_card')
                document.family_card = v.message
            else if (field === 'cv')
                document.cv = v.message
            else if (field === 'education_certificate')
                document.education_certificate = v.message
            else if (field === 'transcripts')
                document.transcripts = v.message
            else if (field === 'npwp')
                document.npwp = v.message
            else if (field === 'skck')
                document.skck = v.message
            setEmpDocuments(document)
            setShowEditDocument(false)
        })
    }

    return (
        <div>
            {
                loaded ?
                    <Row>
                        <ProfileItem image title="CV">
                            {
                                empDocuments.cv !== null ?
                                    empDocuments.cv.includes('.pdf') ?
                                    <>
                                        <a rel="noopener noreferrer" target='_blank' href={process.env.REACT_APP_API_FILE + '/' + empDocuments.cv}>
                                            Show Attachment
                                        </a>
                                        <EditButton onEdit={() => upload('CV', 'cv', 'applicant')}/>
                                    </> :
                                    <ImageViewer previewable={empDocuments.cv ? true : false} onEdit={() => upload('CV', 'cv', 'applicant')} style={{width:'100%'}} src={process.env.REACT_APP_API_FILE + "/" + empDocuments.cv}/> :
                                <ImageViewer previewable={empDocuments.cv ? true : false} onEdit={() => upload('CV', 'cv', 'applicant')} style={{width:'100%'}} src={process.env.PUBLIC_URL + '/images/noimage.png'}/>
                            }
                        </ProfileItem>
                        <ProfileItem image title="Education Certificate">
                            {
                                empDocuments.education_certificate !== null ?
                                    empDocuments.education_certificate.includes('.pdf') ?
                                    <>
                                        <a rel="noopener noreferrer" target='_blank' href={process.env.REACT_APP_API_FILE + '/' + empDocuments.education_certificate}>
                                            Show Attachment
                                        </a>
                                        <EditButton onEdit={() => upload('Education Certificate', 'education_certificate', 'applicant')}/>
                                    </> :
                                    <ImageViewer previewable={empDocuments.education_certificate ? true : false} onEdit={() => upload('Education Certificate', 'education_certificate', 'applicant')} style={{width:'100%'}} src={process.env.REACT_APP_API_FILE + "/" + empDocuments.education_certificate}/> :
                                <ImageViewer previewable={empDocuments.education_certificate ? true : false} onEdit={() => upload('Education Certificate', 'education_certificate', 'applicant')} style={{width:'100%'}} src={process.env.PUBLIC_URL + '/images/noimage.png'}/>
                            }
                        </ProfileItem>
                        <ProfileItem image title="Transcripts of Record">
                            {
                                empDocuments.transcripts !== null ?
                                    empDocuments.transcripts.includes('.pdf') ?
                                    <>
                                        <a rel="noopener noreferrer" target='_blank' href={process.env.REACT_APP_API_FILE + '/' + empDocuments.transcripts}>
                                            Show Attachment
                                        </a>
                                        <EditButton onEdit={() => upload('Transcripts of Record', 'transcripts', 'applicant')}/>
                                    </> :
                                    <ImageViewer previewable={empDocuments.transcripts ? true : false} onEdit={() => upload('Transcripts of Record', 'transcripts', 'applicant')} style={{width:'100%'}} src={process.env.REACT_APP_API_FILE + "/" + empDocuments.transcripts}/> :
                                <ImageViewer previewable={empDocuments.transcripts ? true : false} onEdit={() => upload('Transcripts of Record', 'transcripts', 'applicant')} style={{width:'100%'}} src={process.env.PUBLIC_URL + '/images/noimage.png'}/>
                            }
                        </ProfileItem>
                        <ProfileItem image title="Identity Card">
                            {
                                empDocuments.identity_card !== null ?
                                    empDocuments.identity_card.includes('.pdf') ?
                                    <>
                                        <a rel="noopener noreferrer" target='_blank' href={process.env.REACT_APP_API_FILE + '/' + empDocuments.identity_card}>
                                            Show Attachment
                                        </a>
                                        <EditButton onEdit={() => upload('Identity Card', 'identity_card', 'identity-card')}/>
                                    </> :
                                    <ImageViewer previewable={empDocuments.identity_card ? true : false} onEdit={() => upload('Identity Card', 'identity_card', 'identity-card')} style={{width:'100%'}} src={process.env.REACT_APP_API_FILE + "/" + empDocuments.identity_card}/> :
                                <ImageViewer previewable={empDocuments.identity_card ? true : false} onEdit={() => upload('Identity Card', 'identity_card', 'identity-card')} style={{width:'100%'}} src={process.env.PUBLIC_URL + '/images/noimage.png'}/>
                            }
                        </ProfileItem>
                        <ProfileItem image title="Family Card">
                            {
                                empDocuments.family_card !== null ?
                                    empDocuments.family_card.includes('.pdf') ?
                                    <>
                                        <a rel="noopener noreferrer" target='_blank' href={process.env.REACT_APP_API_FILE + '/' + empDocuments.family_card}>
                                            Show Attachment
                                        </a>
                                        <EditButton onEdit={() => upload('Family Card', 'family_card', 'family-card')}/>
                                    </> :
                                    <ImageViewer previewable={empDocuments.family_card ? true : false} onEdit={() => upload('Family Card', 'family_card', 'family-card')} style={{width:'100%'}} src={process.env.REACT_APP_API_FILE + "/" + empDocuments.family_card}/> :
                                <ImageViewer previewable={empDocuments.family_card ? true : false} onEdit={() => upload('Family Card', 'family_card', 'family-card')} style={{width:'100%'}} src={process.env.PUBLIC_URL + '/images/noimage.png'}/>
                            }
                        </ProfileItem>
                        <ProfileItem image title="Tax ID Number">
                            {
                                empDocuments.npwp !== null ?
                                    empDocuments.npwp.includes('.pdf') ?
                                    <>
                                        <a rel="noopener noreferrer" target='_blank' href={process.env.REACT_APP_API_FILE + '/' + empDocuments.npwp}>
                                            Show Attachment
                                        </a>
                                        <EditButton onEdit={() => upload('Tax ID Number', 'npwp', 'npwp')}/>
                                    </> :
                                    <ImageViewer previewable={empDocuments.npwp ? true : false} onEdit={() => upload('Tax ID Number', 'npwp', 'npwp')} style={{width:'100%'}} src={process.env.REACT_APP_API_FILE + "/" + empDocuments.npwp}/> :
                                <ImageViewer previewable={empDocuments.npwp ? true : false} onEdit={() => upload('Tax ID Number', 'npwp', 'npwp')} style={{width:'100%'}} src={process.env.PUBLIC_URL + '/images/noimage.png'}/>
                            }
                        </ProfileItem>
                        <ProfileItem image title="Statement of Police Record">
                            {
                                empDocuments.skck !== null ?
                                    empDocuments.skck.includes('.pdf') ?
                                    <>
                                        <a rel="noopener noreferrer" target='_blank' href={process.env.REACT_APP_API_FILE + '/' + empDocuments.skck}>
                                            Show Attachment
                                        </a>
                                        <EditButton onEdit={() => upload('Statement of Police Record', 'skck', 'applicant')}/>
                                    </> :
                                    <ImageViewer previewable={empDocuments.skck ? true : false} onEdit={() => upload('Statement of Police Record', 'skck', 'applicant')} style={{width:'100%'}} src={process.env.REACT_APP_API_FILE + "/" + empDocuments.skck}/> :
                                <ImageViewer previewable={empDocuments.skck ? true : false} onEdit={() => upload('Statement of Police Record', 'skck', 'applicant')} style={{width:'100%'}} src={process.env.PUBLIC_URL + '/images/noimage.png'}/>
                            }
                        </ProfileItem>
                    </Row>
                : <Spinner />
            }

            <BasicModal title={modalTitle} showModal={showEditDocument} onCloseModal={()=>setShowEditDocument(false)}>
                {
                    (showEditDocument) ? 
                        <Uploader dragger folder={documentFolder} onUploaded={(v)=> documentUploaded(v, documentField)} isUploading={(v)=> setUploadingDocument(v)}/>
                    : null
                }
            </BasicModal>
        </div>
    );
}

export default Document;
