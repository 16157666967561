import React, {useEffect, useState} from 'react';
import {Card, Typography, Form, Input, message, Collapse, Space, Select} from 'antd'
import {AddButton, PageTitle, Spinner, FormModal, EditButton, DeleteButton,} from '../../../components'
import {SentralModel} from '../../../models/SentralModel'

const {Text} = Typography
const {Panel} = Collapse
const {Option} = Select
const Index = () => {
    const [kpis, setKpis] = useState([])
    const [divisions, setDivisions] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false)
    const [formCategoryModal, setFormCategoryModal] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [defaultCategoryValues, setDefaultCategoryValues] = useState({})
    const [saving, setSaving] = useState(false)
    const [modalTitle, setModalTitle]= useState(null)
    const fetchKpis = () =>{
        SentralModel.list('KpiItemCategory', {orderBy:"order", order:"asc"}).then(res=>{
            setKpis(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }
    const fetchDivisions = () =>{
        SentralModel.list('Division').then(res=>{
            setDivisions(res.data)
        })
    }

    useEffect(() => {
        fetchKpis()
        fetchDivisions()
    }, []);
    

    const addKpi=()=>{
        setModalTitle('Add Kpi Item')
        setDefaultValues({
            kpi_item_id: 0,
            description: '',
            category_id: '',
            division_id:null
        })
        setFormModal(true)
    }

    const addCategory=()=>{
        setModalTitle('Add Category')
        setDefaultCategoryValues({
            kpi_item_category_id: 0,
            name: '',
            order:kpis.length+1
        })
        setFormCategoryModal(true)
    }
    const editCategory = (v) =>{
        setModalTitle('Edit Category')
        setDefaultCategoryValues({
            kpi_item_category_id: v.kpi_item_category_id,
            name: v.name,
            order:v.order
        })
        setFormCategoryModal(true)
    }

    const editKpi = (v) =>{
        setModalTitle('Edit Kpi Item')
        setDefaultValues({
            kpi_item_id: v.kpi_item_id,
            description: v.description,
            category_id: v.kpi_item_category_id,
            division_id:v.division_id
        })
        setFormModal(true)
    }

    const deleteKpi = (id) =>{
        message.loading('deleting kpi')
        SentralModel.deleteById('KpiItem', id).then((res)=>{
            message.destroy()
            message.success('Kpi deleted', 2)
            fetchKpis()
        })
    }

    const saveCategory = (v) => {
        setSaving(true)
        let payload={
            kpi_item_category_id: v.kpi_item_category_id,
            name: v.name,
            order:v.order
        }
        SentralModel.save('KpiItemCategory', payload, v.kpi_item_category_id).then((res)=>{
            setFormCategoryModal(false)
            fetchKpis()
            message.success('Category saved',2);
        }).finally(() => {
            setSaving(false)
        })
    }

    const saveKpi = (v) => {
        setSaving(true)
        let payload={
            kpi_item_id: v.kpi_item_id,
            description: v.description,
            kpi_item_category_id: v.category_id,
            division_id: v.division_id,
        }
        SentralModel.save('KpiItem', payload, v.kpi_item_id).then(()=>{
            setFormModal(false)
            fetchKpis()
            message.success('KPI item saved',2);
        }).finally(() => {
            setSaving(false)
        })
    }


    return (
        <div>
            <Card className="content-container">
                <PageTitle title="KPI Item" breadcrumbs={[['KPI']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>Item KPI</Text>
                        <Space style={{float:'right'}}>
                            <AddButton onAdd={addCategory} title="New Category"/>
                            <AddButton onAdd={addKpi} title="New KPI Item"/>
                        </Space>
                    </div> }>
                    
                    {
                        (loaded) ?
                        <Collapse defaultActiveKey={[]}>
                            {
                                kpis.map((el, i) =>(
                                    <Panel header={el.name} extra={<EditButton onEdit={() => editCategory(el)}/>} key={i}>
                                        <ol>
                                            {
                                                el.items.map((item, i) => (
                                                    <li key={i}>
                                                        {item.description} - {item.division === null ? 'All Division' : item.division.division_name} <br/>
                                                        <Space>
                                                            <EditButton onEdit={() => editKpi(item)}/>
                                                            <DeleteButton onConfirm={() => deleteKpi(item.kpi_item_id)}/>
                                                        </Space>
                                                    </li>
                                                ))
                                            }
                                        </ol>
                                    </Panel>
                                ))
                            }
                        </Collapse>
                        :
                        <Spinner/>
                    }
                </Card>
                {
                    (formModal) ?
                    <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v)=>saveKpi(v)} width={600} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                        <Form.Item name="kpi_item_id" hidden>
                            <Input placeholder="input placeholder" />
                        </Form.Item>
                        <Form.Item label="Kpi Name" name="description" required rules={[{required:true}]}>
                            <Input placeholder="Kpi Name" />
                        </Form.Item>
                        <Form.Item label="Category" name="category_id" required>
                            <Select>
                                {
                                    kpis.map((el, i) => (
                                        <Option key={i} value={el.kpi_item_category_id}>{el.name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="Division" name="division_id">
                            <Select>
                                <Option value={null}>All Division</Option>
                                {
                                    divisions.map((el, i) => (
                                        <Option key={i} value={el.division_id}>{el.division_name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </FormModal> :
                    null
                }
                {
                    (formCategoryModal) ?
                    <FormModal defaultValues={defaultCategoryValues} title={modalTitle} submitForm={(v)=>saveCategory(v)} width={600} showModal={formCategoryModal} onCloseModal={() => setFormCategoryModal(false)} saving={saving}>
                        <Form.Item name="kpi_item_category_id" hidden>
                            <Input placeholder="input placeholder" />
                        </Form.Item>
                        <Form.Item label="Name" name="name" required rules={[{required:true}]}>
                            <Input placeholder="Name" />
                        </Form.Item>
                        <Form.Item hidden name="order">
                            <Input />
                        </Form.Item>
                        
                    </FormModal> :
                    null
                }
            </Card>
        </div>
    );
}

export default Index;
