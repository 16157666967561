import React, {useState, useEffect} from 'react';
import {Card, Typography, Row, Empty} from 'antd'
// import {Spinner} from '../../../components'
import {SentralModel} from '../../../models/SentralModel'
import { Spinner } from '../../../components';

const {Text} = Typography

const Index = (props) => {
    const [applicantId] = useState(props.applicantId);
    const [question, setQuestion] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const fetchQuestion = (applicantId) => {
        SentralModel.list('RecruitmentApplicantAnswer', {filter: [['rec_applicant_id', applicantId]], orderBy:'question_id', order: 'asc'}).then((res) => {
            setQuestion(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchQuestion(applicantId)
    }, [applicantId]);

    return (
        <div>
            <Card>
                {
                    (loaded) ?
                        (question.length !== 0) ?
                            question.map((row, i) => (
                                <div>
                                    <Row>
                                        <Text style={{display:'block'}}>{i+1}. {row.question.question}</Text>
                                        <Text strong style={{display:'block'}}>&nbsp; {row.answer}</Text>
                                    </Row>
                                    <br />
                                </div>
                            ))
                        : <Empty />
                    : <Spinner />
                }
            </Card>
        </div>
    );
}

export default Index;
