import React, { useEffect, useState } from 'react';
import { Card, Form, Row, Col, Input, Space, Typography, DatePicker, message } from 'antd';
import { PageTitle, SubmitButton, AddButton, EditButton, DeleteButton, DataTable, Spinner, FormModal } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import {/*useHistory,*/ useParams } from 'react-router-dom'
import moment from 'moment';

const { Text } = Typography

const Detail = () => {
    // const history = useHistory()
    const { voteId } = useParams()
    const [loaded, setLoaded] = useState(false)
    const [saving, setSaving] = useState(false)
    const [form] = Form.useForm()
    const [formDetail] = Form.useForm()
    const [questions, setQuestions] = useState([]);
    const [formModal, setFormModal] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);

    const fetchVote = (voteId, form) => {
        setLoaded(false)
        SentralModel.list('Vote', { filter: [['vote_id', voteId]] }).then(res => {
            form.setFieldsValue({
                title: res.data[0].title,
                due_date: moment(res.data[0].due_date, 'YYYY-MM-DD'),
                url: res.data[0].url
            })
            setQuestions(res.data[0].questions)
        })
        setLoaded(true)
    }

    const fetchQuestions = () => {
        setLoaded(false)
        SentralModel.list('VoteQuestion', { filter: [["vote_id", voteId]] }).then(res => {
            setQuestions(res.data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchVote(voteId, form)
    }, [voteId, form]);

    const saveGeneral = (v) => {
        setSaving(true)
        let data = {
            title: v.title,
            due_date: moment(v.due_date, 'DD MMMM YYYY').format('YYYY-MM-DD')
        }

        SentralModel.save('Vote', data, voteId).then((res) => {
            message.success('Vote saved', 2);
        }).finally(() => {
            setSaving(false)
        })
    }

    const dataSource = [
        ...questions.map(el => {
            return {
                id: el.vote_question_id,
                question: el.question,
            }
        })
    ];

    const columns = [{
        title: 'No',
        dataIndex: 'no',
        key: 'no',
        width: '5%'
    },
    {
        title: 'Question',
        dataIndex: 'question',
        key: 'question',
    },
    {
        title: 'Action',
        width: 120,
        render: (row) => (
            <div className="text-center">
                <Space>
                    <EditButton onEdit={() => editQuestion(row)} />
                    <DeleteButton onConfirm={() => deleteQuestion(row.id)} />
                </Space>
            </div>
        )
    },
    ];

    const addQuestion = () => {
        setModalTitle('Add Question')
        formDetail.resetFields()
        formDetail.setFieldsValue({
            vote_question_id: 0,
            question: null
        })
        setFormModal(true)
    }

    const editQuestion = (v) => {
        setModalTitle('Edit Question')
        formDetail.setFieldsValue({
            vote_question_id: v.id,
            question: v.question
        })
        setFormModal(true)
    }

    const saveQuestion = (v) => {
        setSaving(true)
        let payload = {
            vote_question_id: v.vote_question_id,
            vote_id: voteId,
            question: v.question
        }
        SentralModel.save('VoteQuestion', payload, v.vote_question_id).then((res) => {
            setFormModal(false)
            fetchQuestions();
            message.success('Question saved', 2);
        }).finally(() => {
            setSaving(false)
        })
    }

    const deleteQuestion = (id) => {
        message.loading('Deleting question')
        SentralModel.deleteById('VoteQuestion', id).then((res) => {
            message.destroy()
            message.success('Question deleted', 2)
            let tmp = questions
            let index = tmp.findIndex(el => el.vote_question_id === id)
            tmp.splice(index, 1);
            setQuestions([])
            setQuestions(tmp)
        })
    }

    return (
        <Card className="content-container">
            <PageTitle title="Vote Question" breadcrumbs={[['Vote', '/management/vote'], ['Question']]}></PageTitle>
            {
                loaded ?
                    <div>
                        <Card title={
                            <div className="full-width">
                                <Text strong style={{ float: 'left' }}>Vote</Text>
                            </div>}>
                            <Form size="middle" form={form} layout="vertical" onFinish={saveGeneral}>
                                <Row justify="space-around">
                                    <Col span={11}>
                                        <Form.Item label="Title" name="title" rules={[{ required: true }]}>
                                            <Input placeholder="Title" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                        <Form.Item label="Due Date" name="due_date" rules={[{ required: true }]}>
                                            <DatePicker format="DD MMMM YYYY" style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row justify="space-around">
                                    <Col span={11}>
                                        <Form.Item label="URL" name="url" rules={[{ required: true }]}>
                                            <Input placeholder="URL" disabled />
                                        </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                    </Col>
                                </Row>
                                <Row justify="space-around">
                                    <Col span={11} />
                                    <Col span={11}>
                                        <SubmitButton right loading={saving} />
                                    </Col>
                                </Row>
                            </Form>
                        </Card>

                        <br />

                        <Card title={
                            <div className="full-width">
                                <Text strong style={{ float: 'left' }}>Question List</Text>
                                <AddButton right onAdd={addQuestion} title="New Question" />
                            </div>}>

                            <DataTable size='small' bordered dataSource={dataSource} columns={columns} />
                        </Card>

                    </div>
                    : <Spinner />
            }

            {
                (formModal) ?
                    <FormModal title={modalTitle} form={formDetail} submitForm={(v) => saveQuestion(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                        <Form.Item name="vote_question_id" hidden>
                            <Input placeholder="ID" />
                        </Form.Item>
                        <Form.Item label="Question" name="question" rules={[{ required: true }]}>
                            <Input.TextArea rows={2} placeholder="Question" />
                        </Form.Item>
                    </FormModal>
                    : null
            }
        </Card>
    );
}

export default Detail;
