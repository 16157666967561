import React, { useEffect, useState } from 'react'
import { Row, Col, Typography, Divider, Empty, Form, Input, DatePicker, message } from 'antd'
import ProfileItem from './ProfileItem';
import ActionButton from './ActionButton';
import { AddButton, FormModal, Spinner } from '../../components';
import { SentralModel } from '../../models/SentralModel'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import authData from '../../helpers/authData'

const { Title } = Typography
const { RangePicker } = DatePicker;
const { TextArea } = Input;

const WorkExperience = (props) => {
    const { employeeCode } = useParams()
    const [empWorkExperiences, setEmpWorkExperiences] = useState(props.data);
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false);
    const [defaultValues, setDefaultValues] = useState({});
    const [saving, setSaving] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);

    const fetchEmpWorkExperiences = () => {
        setLoaded(false)
        SentralModel.list('EmployeeWorkExperience', { filter: [['employee_code', props.emp_code]] }).then(res => {
            setEmpWorkExperiences(res.data)
        }).finally(() => {
            setLoaded(true)
        })
    }

    useEffect(() => {
        setLoaded(true)
    }, []);

    const addWorkExp = () => {
        setModalTitle('Add Work Experience')
        setDefaultValues({
            emp_work_experience_id: 0
        })
        setFormModal(true)
    }

    const editWorkExp = (v) => {
        setModalTitle('Edit Work Experience')
        setDefaultValues({
            emp_work_experience_id: v.emp_work_experience_id,
            company_name: v.company_name,
            phone_no: v.phone_no,
            address: v.address,
            business_type: v.business_type,
            period: [moment(v.start_period), moment(v.end_period)],
            position: v.position,
            salary: v.salary,
            reason_for_resign: v.reason_for_resign,
            task_description: v.task_description
        })
        setFormModal(true)
    }

    const saveWorkExp = (v) => {
        setSaving(true)
        let payload = {
            emp_work_experience_id: v.emp_work_experience_id,
            employee_code: props.emp_code,
            company_name: v.company_name,
            phone_no: v.phone_no,
            address: v.address,
            business_type: v.business_type,
            start_period: moment(v.period[0], 'DD MMMM YYYY').format('YYYY-MM-DD'),
            end_period: moment(v.period[1], 'DD MMMM YYYY').format('YYYY-MM-DD'),
            position: v.position,
            salary: v.salary,
            reason_for_resign: v.reason_for_resign,
            task_description: v.task_description
        }
        SentralModel.save('EmployeeWorkExperience', payload, v.emp_work_experience_id).then((res) => {
            setFormModal(false)
            fetchEmpWorkExperiences()
            message.success('Work experience saved', 2);
        }).finally(() => {
            setSaving(false)
        })
    }

    const deleteWorkExp = (id) => {
        message.loading('deleting work experience')
        SentralModel.deleteById('EmployeeWorkExperience', id).then((res) => {
            message.destroy()
            message.success('Work experience deleted', 2)
            let tmp = empWorkExperiences
            let index = tmp.findIndex(el => el.emp_work_experience_id === id)
            tmp.splice(index, 1);
            setEmpWorkExperiences([])
            setEmpWorkExperiences(tmp)
        })
    }

    return (
        <div>
            <div className="text-right">
                {
                    authData.employee_code === employeeCode || employeeCode === undefined || authData.employee_code === 'SMI20220323229' || authData.employee_code === 'SMI20180322139' ?
                        <AddButton onAdd={addWorkExp}>Add data</AddButton>
                        : null
                }
            </div>
            {
                loaded ?
                    (empWorkExperiences.length > 0) ?
                        empWorkExperiences.map((el, key) => (
                            <div key={key}>
                                <Divider orientation="left"><Title level={4}>{key + 1}.</Title></Divider>
                                {
                                    authData.employee_code === employeeCode || employeeCode === undefined || authData.employee_code === 'SMI20220323229' || authData.employee_code === 'SMI20180322139' ?
                                        <ActionButton editable deleteable onEdit={() => editWorkExp(el)} onDelete={() => deleteWorkExp(el.emp_work_experience_id)} /> : null
                                }
                                <Row>
                                    <ProfileItem title="Company Name">{el.company_name}</ProfileItem>
                                    <ProfileItem title="Company Phone">{el.phone_no}</ProfileItem>
                                    <ProfileItem title="Business Type">{el.business_type}</ProfileItem>
                                    <ProfileItem title="Company Address">{el.address}</ProfileItem>
                                    <ProfileItem title="Working Period">{moment(el.start_period).format('DD MMMM YYYY') + ' - ' + moment(el.end_period).format('DD MMMM YYYY')}</ProfileItem>
                                    <ProfileItem title="Position">{el.position}</ProfileItem>
                                    <ProfileItem title="Salary">{el.salary}</ProfileItem>
                                    <ProfileItem title="Reason for Resignation">{el.reason_for_resign}</ProfileItem>
                                    <ProfileItem span={24} title="Job Description">{el.task_description}</ProfileItem>
                                </Row>
                            </div>
                        ))
                        : <Empty />
                    : <Spinner />
            }
            {
                (formModal) ?
                    <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => saveWorkExp(v)} width={800} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                        <Row>
                            <Col span={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                <Form.Item name="emp_work_experience_id" hidden>
                                    <Input placeholder="input placeholder" />
                                </Form.Item>
                                <Form.Item label="Company Name" name="company_name" rules={[{ required: true }]}>
                                    <Input placeholder="Company Name" />
                                </Form.Item>
                            </Col>
                            <Col span={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                <Form.Item label="Company Phone" name="phone_no" rules={[{ required: true }]}>
                                    <Input placeholder="Company Phone" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                <Form.Item label="Business Type" name="business_type" rules={[{ required: true }]}>
                                    <Input placeholder="Business Type" />
                                </Form.Item>
                            </Col>
                            <Col span={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                <Form.Item label="Company Address" name="address" rules={[{ required: true }]}>
                                    <TextArea placeholder="Company Address" rows={2} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                <Form.Item label="Working Period" name="period" rules={[{ required: true }]}>
                                    <RangePicker format="DD MMMM YYYY" style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                            <Col span={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                <Form.Item label="Position" name="position" rules={[{ required: true }]}>
                                    <Input placeholder="Position" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                <Form.Item label="Salary" name="salary" rules={[{ required: true }]}>
                                    <Input placeholder="Salary" />
                                </Form.Item>
                            </Col>
                            <Col span={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                <Form.Item label="Reason for Resignation" name="reason_for_resign" rules={[{ required: true }]}>
                                    <TextArea placeholder="Reason for Resignation" rows={2} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={24} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                <Form.Item label="Job Description" name="task_description" rules={[{ required: true }]}>
                                    <TextArea placeholder="Job Description" rows={4} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </FormModal> :
                    null
            }
        </div>
    );
}

export default WorkExperience;
