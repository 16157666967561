import React, { useEffect, useState } from 'react';
import { Card, Form, Row, Col, Input, DatePicker, Typography, message } from 'antd';
import { PageTitle, SubmitButton, Spinner } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'
// import { Editor } from '@tinymce/tinymce-react';
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css'
import {
    align,
    font,
    fontColor,
    fontSize,
    formatBlock,
    hiliteColor,
    horizontalRule,
    lineHeight,
    list,
    paragraphStyle,
    table,
    template,
    textStyle,
    image,
    link,
} from "suneditor/src/plugins"

const { Text } = Typography

const Detail = () => {
    const history = useHistory()
    const { versionId } = useParams()
    const [loaded, setLoaded] = useState(false)
    const [saving, setSaving] = useState(false)
    const [form] = Form.useForm()
    const [content, setContent] = useState('');

    const fetchVersion = (versionId, form) => {
        setLoaded(false)
        if (versionId === '0') {
            form.resetFields();
        } else {
            SentralModel.get('VersionPatch', {}, versionId).then(res => {
                form.setFieldsValue({
                    version_patch_id: res.data.version_patch_id,
                    version: res.data.version,
                    description: res.data.description,
                    release_date: moment(res.data.release_date, 'YYYY-MM-DD'),
                })
                setContent(res.data.description);
            })
        }
        setLoaded(true)
    }
    useEffect(() => {
        fetchVersion(versionId, form)
    }, [versionId, form]);

    const saveVersion = (v) => {
        setSaving(true)

        let data = {
            version_patch_id: versionId,
            version: v.version,
            description: content,
            release_date: moment(v.release_date, 'DD MMMM YYYY').format('YYYY-MM-DD')
        }

        SentralModel.save('VersionPatch', data, versionId).then((res) => {
            message.success('Version saved', 2);
            setTimeout(() => {
                history.push('/setting/version-patch');
            }, 1000);
            setSaving(false)
        })
    }

    const handleEditorChange = (content) => {
        setContent(content);
    }

    return (
        <Card className="content-container">
            <PageTitle title="Version Patch" breadcrumbs={[['Version Patch', '/setting/version-patch'], ['Detail']]}></PageTitle>
            {
                loaded ?
                    <Form size="middle" form={form} layout="vertical" onFinish={saveVersion}>
                        <Form.Item name="version_patch_id" hidden>
                            <Input placeholder="Version Patch ID" />
                        </Form.Item>
                        <Form.Item label="Version" name="version" required rules={[{ required: true }]}>
                            <Input placeholder="Version" />
                        </Form.Item>
                        <Row style={{ paddingBottom: 10 }}>
                            <Text style={{ color: '#ed5b56', paddingRight: 5 }}>*</Text>
                            <Text>Description</Text>
                        </Row>
                        <SunEditor setOptions={{
                            showPathLabel: false,
                            minHeight: "100vh",
                            maxHeight: "100vh",
                            placeholder: "Enter your text here!!!",
                            plugins: [
                                align,
                                font,
                                fontColor,
                                fontSize,
                                formatBlock,
                                hiliteColor,
                                horizontalRule,
                                lineHeight,
                                list,
                                paragraphStyle,
                                table,
                                template,
                                textStyle,
                                image,
                                link
                            ],
                            buttonList: [
                                ["undo", "redo"],
                                ["font", "fontSize", "formatBlock"],
                                ["paragraphStyle"],
                                [
                                    "bold",
                                    "underline",
                                    "italic",
                                    "strike",
                                    "subscript",
                                    "superscript"
                                ],
                                ["fontColor", "hiliteColor"],
                                ["removeFormat"],
                                "/", // Line break
                                ["outdent", "indent"],
                                ["align", "horizontalRule", "list", "lineHeight"],
                                ["table", "link", "image"]
                            ],
                            formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
                            font: [
                                "Arial",
                                "Calibri",
                                "Comic Sans",
                                "Courier",
                                "Garamond",
                                "Georgia",
                                "Impact",
                                "Lucida Console",
                                "Palatino Linotype",
                                "Segoe UI",
                                "Tahoma",
                                "Times New Roman",
                                "Trebuchet MS"
                            ]
                        }} height={500} setContents={content} onChange={(value) => handleEditorChange(value)} />

                        {/* <Editor
                        apiKey={process.env.REACT_APP_TINYMCE_KEY}
                        value={content}
                        init={{
                            height: 500,
                            menubar: true,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar:
                                'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                        }}
                        onEditorChange={(value) => handleEditorChange(value)}
                    /> */}
                        <Form.Item label="Release Date" name="release_date" required rules={[{ required: true }]} style={{ paddingTop: 20 }}>
                            <DatePicker format="DD MMMM YYYY" style={{ width: '100%' }} />
                        </Form.Item>
                        <Row justify="space-around">
                            <Col span={24} className="text-right">
                                <SubmitButton loading={saving} />
                            </Col>
                        </Row>
                    </Form>
                    : <Spinner />
            }
        </Card>
    );
}

export default Detail;
