import { useState, useEffect } from 'react';
import { Row, Col, Form, Input, Select, DatePicker, message } from 'antd';
import moment from 'moment'
import { SubmitButton, Uploader } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import authData from '../../../helpers/authData'
import { ImageViewer } from '../../../components/ImageViewer';

const { RangePicker } = DatePicker
const { Option } = Select

const RequestForm = (props) => {
    const { leaveData = null } = props
    const [leaveForm] = Form.useForm()
    const [leaveTypes, setLeaveTypes] = useState([])
    const [informations, setInformations] = useState([])
    const [responsibles, setResponsibles] = useState([])
    const [limit, setLimit] = useState(-1)
    const [isLimitDay, setIsLimitDay] = useState(false)
    const [minDays, setMinDays] = useState(0)
    const [type, setType] = useState(null)
    const [showAttachment, setShowAttachment] = useState(false)
    const [attachmentFile, setAttachmentFile] = useState(null)
    const [uploading, setUploading] = useState(false)
    const [selectedLeaveType, setSelectedLeaveType] = useState(null)
    const pathname = window.location.pathname

    const fetchDivision = () => {
        SentralModel.get('Division', {}, authData.division_id).then(res => {
            setIsLimitDay(res.data?.is_limit_days === '1' ? true : false)
        })
    }

    const fetchLeaveTypes = () => {
        SentralModel.action('Submission', 'getLeaveTypes', {}, 0).then((res) => {
            setLeaveTypes(res.data)
        })
    }

    const fetchInformations = () => {
        let payload = {
            filter: [["general_code", "LI"]],
        }
        SentralModel.list('GeneralDetail', payload).then((res) => {
            setInformations(res.data)
        })
    }

    const fetchResponsibles = () => {
        SentralModel.action('Submission', 'getResponsible', { employee_code: authData.employee_code }).then((res) => {
            setResponsibles(res.data)
        })
    }

    const setDefaultFormValue = (leaveData, leaveForm) => {
        leaveForm.setFieldsValue({
            leave_date: [moment(leaveData.from_date), moment(leaveData.to_date)],
            information: leaveData.information.detail_code,
            leave_type: leaveData.leave_id,
            necessity: leaveData.reason,
            responsible_employee: leaveData.responsible.employee_code
        })
    }

    useEffect(() => {
        fetchDivision()
        fetchLeaveTypes()
        fetchInformations()
        fetchResponsibles()
        if (leaveData) {
            setDefaultFormValue(leaveData, leaveForm)
        }

        if (leaveData?.leave.leave_id === 5) {
            setShowAttachment(true)
        }
    }, [leaveData, leaveForm]);

    const submitLeave = async (v) =>  {
        if (v.leave_type === 5 && leaveData && !attachmentFile) {
            message.error('Attachment is required', 2)
        } else {
            let totalDay
            if (v.leave_type === 7) {
                totalDay = limit
            } else {
                let totalImportantDate = 0;
                await SentralModel.action('ImportantDate', 'countImportantDate', { from_date: moment(v.leave_date[0]).format('YYYY-MM-DD'), to_date: moment(v.leave_date[1]).format('YYYY-MM-DD') }).then((res) => {
                    totalImportantDate = res.data;
                })
                totalDay = getTotalDate(v.leave_date[1], v.leave_date[0]) - totalImportantDate;
            }
            
            let data = {
                employee_code: authData.employee_code,
                leave_id: v.leave_type,
                reason: v.necessity,
                information: v.information,
                responsible: v.responsible_employee,
                from_date: moment(v.leave_date[0]).format('YYYY-MM-DD'),
                to_date: moment(v.leave_date[1]).format('YYYY-MM-DD'),
                total_days: totalDay,
                status: 'WAITING',
                limit: limit,
                attachment: attachmentFile,
                cancellation_reason: v.cancellation_reason || null
            }
            props.onSubmit(data)
        }
    }

    const getTotalDate = (endDate, startDate) => {
        let totalDiff = moment(endDate).diff(moment(startDate), 'days') + 1;
        let newTotal = 0;
        let date = moment(startDate);
        while (totalDiff > 0) {
            // add "total days" only if it's a weekday.
            if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
                newTotal += 1;
            }
            date = date.add(1, 'days');
            totalDiff -= 1;
        }
        return newTotal;
    }

    // const getTotalCalendarDate = (endDate, startDate) => {
    //     let totalDiff = moment(endDate).diff(moment(startDate), 'days') + 1;
    //     let newTotal = 0;
    //     let date = moment(startDate);
    //     while (totalDiff > 0) {
    //         date = date.add(1, 'days');
    //         // add "total days" only if it's a weekday.
    //         newTotal += 1;
    //         totalDiff -= 1;
    //     }
    //     return newTotal;
    // }

    const calculateDateFromDays = (startDate, days) => {
        var datestrings = startDate.split("-"),
            date = new Date(+datestrings[2], datestrings[1] - 1, +datestrings[0]);
        date.setDate(date.getDate() + days);
        return [date.getFullYear(), ("0" + (date.getMonth() + 1)).slice(-2), ("0" + date.getDate()).slice(-2)].join("-");
    }

    const changeLeaveType = (v, e) => {
        if (isLimitDay) {
            setMinDays(e['data-min_days'] == null ? 0 : e['data-min_days'])
        }

        const defaultDate = calculateDateFromDays(moment(Date.now()).format("DD-MM-YYYY"), e['data-balance'])
        leaveForm.setFieldsValue({
            leave_date: v === 7 ? [moment(Date.now()), moment(defaultDate)] : [null, null]
        })

        if (e['data-type'] !== 'Permission' && e['data-type'] !== 'Sick' && e['data-type'] !== 'Business') {
            setLimit(e['data-balance']);
        } else {
            setLimit(-1)
        }

        if (e['data-type'] === 'Sick') {
            setShowAttachment(true)
        } else {
            setShowAttachment(false)
            setAttachmentFile(null)
        }

        setType(e['data-type']);
        setSelectedLeaveType(v)
    }

    const disabledDate = (current) => {
        let customDate = moment(new Date()).add(minDays, 'd').format('YYYY-MM-DD');
        if (type === 'Annual') {
            let customDate2 = moment(new Date()).add(2, 'M').format('YYYY-MM-DD');
            return current && (current < moment(customDate, "YYYY-MM-DD") || current > moment(customDate2, "YYYY-MM-DD"));
        } else {
            return current && current < moment(customDate, "YYYY-MM-DD");
        }
    };

    const onChangeDateLeave = (v) => {
        let endDate = calculateDateFromDays(moment(v[0]).format("DD-MM-YYYY"), limit)
        leaveForm.setFieldsValue({
            leave_date: [moment(v[0]), moment(endDate)]
        })
    }

    const fileUploaded = (v) => {
        setAttachmentFile(v.message)
    }

    return (
        <Form form={leaveForm} labelCol={{ span: 9 }} wrapperCol={{ span: 15 }} onFinish={submitLeave}>
            <Row justify="center">
                <Col span={10}>
                    <Form.Item name="leave_type" label="Leave Type" rules={[{ required: true }]}>
                        <Select loading={leaveTypes.length === 0} onChange={(v, e) => changeLeaveType(v, e)} disabled={leaveData}>
                            {
                                leaveTypes.map((lt, key) => (
                                    <Option key={key} data-balance={lt.balance} data-type={lt.type} data-min_days={lt.min_days} value={lt.leave_id}>
                                        {lt.description}
                                        {
                                            (lt.leave_id !== 4 && lt.leave_id !== 5 && lt.leave_id !== 8) ? ` (${lt.balance})` : null
                                        }
                                    </Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name="information" label="Information" rules={[{ required: true }]}>
                        <Select loading={informations.length === 0} disabled={leaveData}>
                            {
                                informations.map((inf, key) => (
                                    <Option key={key} value={inf.detail_code}>{inf.description}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name="responsible_employee" label="Responsible Employee" rules={[{ required: true }]}>
                        <Select disabled={leaveData}>
                            {
                                responsibles.map((re, key) => (
                                    <Option key={key} value={re.employee_code}>{re.name}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    {
                        pathname.includes("create-cancellation") ? null :
                            (leaveData) && leaveData.leave.leave_id === 5 ?
                                showAttachment ?
                                    <Form.Item label="Attachment">
                                        <Uploader folder="submission-leave" onUploaded={(v) => fileUploaded(v)} isUploading={(v) => setUploading(v)} view={true} />
                                    </Form.Item>
                                    : null
                                : null
                    }
                </Col>
                <Col span={10}>
                    {
                        selectedLeaveType === 7 ?
                            <Form.Item name="leave_date" label="Leave Date" rules={[{ required: true }]}>
                                <RangePicker
                                    disabled={[false, true]}
                                    onChange={(v) => onChangeDateLeave(v)}
                                    allowEmpty={true}
                                />
                            </Form.Item>
                            :
                            <Form.Item name="leave_date" label="Leave Date" rules={[{ required: true }]}>
                                <RangePicker style={{ width: '100%' }} disabled={leaveData} disabledDate={disabledDate} />
                            </Form.Item>
                    }
                    <Form.Item name="necessity" label="Necessity" rules={[{ required: true }]}>
                        <Input disabled={leaveData} />
                    </Form.Item>
                    {
                        pathname.includes("create-realization") ? null :
                            (leaveData) ?
                                <Form.Item name="cancellation_reason" label="Cancellation Remarks" rules={[{ required: true }]}>
                                    <Input />
                                </Form.Item>
                                : null
                    }
                    {
                        attachmentFile !== null ?
                            <Form.Item label="Uploaded File">
                                <div style={{ maxWidth: '100%', border: attachmentFile !== null ? attachmentFile.includes('.pdf') ? '0px' : '1px solid #ededed' : '0px' }}>
                                    {
                                        attachmentFile.includes('.pdf') ?
                                            <a rel="noopener noreferrer" target='_blank' href={process.env.REACT_APP_API_FILE + '/' + attachmentFile}>
                                                Show Attachment
                                            </a> :
                                            <ImageViewer previewable={attachmentFile ? true : false} src={process.env.REACT_APP_API_FILE + "/" + attachmentFile} alt="attachment" />
                                    }
                                </div>
                            </Form.Item>
                            : null
                    }
                </Col>
                <Col span={20} className="text-right">
                    <SubmitButton loading={props.saving || uploading} />
                </Col>
            </Row>
        </Form>
    );
}

export default RequestForm;
