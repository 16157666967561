import React, {useEffect, useState} from 'react';
import {Card, message} from 'antd';
import {CheckCircleTwoTone, CloseCircleTwoTone, ExclamationCircleOutlined} from '@ant-design/icons'
import {SentralModel} from '../models/SentralModel'
import {Spinner} from '../components'
import {getQuery} from '../helpers/url'


const Approval = () => {
    const [statusRequest, setStatusRequest] = useState(null)
    const [loading, setLoading] = useState(true)

    const submitRequest = () =>{
        setLoading(true)

        var category = getQuery('category')
        var request_id = getQuery('request_id')
        var level = getQuery('level')
        var employee_code = getQuery('employee_code')
        var status = getQuery('status')
        SentralModel.approval(category, request_id, level, employee_code, status).then((res)=>{
            var sts;
            if(res.message === 'Request invalid') sts = 'invalid';
            if(res.message === 'Request rejected') sts = 'rejected';
            if(res.message === 'Request approved') sts = 'approved';

            if(sts === 'invalid') window.location.href='/'
            setStatusRequest(sts)
            setLoading(false)
        }).catch((err) => {
            message.destroy()
            message.error(err.message, 2)
        })
    }

    useEffect(() => {
        const request = window.setInterval(() => {
            submitRequest();
        }, 1000);
        return () => { // Return callback to run on unmount.
            window.clearInterval(request);
        }
    }, []);
    
    return (
        !loading ?
            <Card style={{minHeight:'100vh'}} className="text-center">
                {
                    (statusRequest==="approved") ?
                    <div>
                        <CheckCircleTwoTone style={{marginTop:'10%', fontSize:'150px'}} twoToneColor="#52c41a" /><br/><br/>
                        Submission Approved
                    </div> : null
                }
                {
                    (statusRequest==="rejected") ?
                    <div>
                        <CloseCircleTwoTone style={{marginTop:'10%', fontSize:'150px'}} twoToneColor="#ff4d4e" /><br/><br/>
                        Submission Rejected
                    </div> : null
                }
                {
                    (statusRequest==="invalid") ?
                    <div>
                        <ExclamationCircleOutlined style={{marginTop:'10%', fontSize:'150px', color:'#808080'}} /><br/><br/>
                        You are already submitted
                    </div> : null
                }
            </Card>
        : <Spinner/>
    );
}

export default Approval;
