import './index.css';
import { createRoot } from 'react-dom/client';
import App from './App';
// import * as serviceWorker from './serviceWorker';
// import { ContextProvider } from "./assets/languages/localizationContext";
// import languages from './assets/localize'

// const localizedText = (tId) => {
//   return languages[tId]
// };

const root = createRoot(document.getElementById("root"));
root.render(<App />);
// serviceWorker.unregister();

