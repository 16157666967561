import { Button, Descriptions, Modal } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { changeShowInfoTicket } from '../../../../../../../redux/slices/Dashboard/karajoTicketSlice'
import moment from 'moment'
import { DataTable } from '../../../../../../../components'

const InfoModalGeneral = () => {
    const dispatch = useDispatch()
    const showModalInfoTicket = useSelector((state) => state.karajoTicket.showModalInfoTicket)
    const info = useSelector((state) => state.karajoTicket.info)

    const columns = [
        {
            title: 'Job Description',
            dataIndex: 'job_description',
            key: 'description'
        },
        {
            title: 'Remarks',
            dataIndex: 'remarks',
            key: 'remarks'
        },
        {
            title: 'Employee',
            render: (row) => (
                row?.employee?.name ?? "-"
            )
        }
    ]

    return (
        <Modal visible={showModalInfoTicket} onCancel={() => dispatch(changeShowInfoTicket(false))} title="Ticket Info" width={950}
            footer={
                <Button onClick={() => dispatch(changeShowInfoTicket(false))}>
                    Close
                </Button>
            }>
            <div style={{ marginBottom: 10 }}>
                <Descriptions title="General Ticket Info" bordered>
                    <Descriptions.Item span={2} label="Employee">{info?.multiple_task_to[0]?.task_to?.name ?? "-"}</Descriptions.Item>
                    <Descriptions.Item label="Due Date">{moment(info?.due_date).format("DD MMMM YYYY")}</Descriptions.Item>
                    <Descriptions.Item span={3} label="Description">{info?.description ?? "-"}</Descriptions.Item>
                </Descriptions>
            </div>

            <DataTable bordered size="small" dataSource={info?.progress ?? []} columns={columns} search={false} pagination={false} />


        </Modal>
    )
}

export default InfoModalGeneral