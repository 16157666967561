import { useState, useEffect } from 'react';
import { Row, Col, Card, Tabs, Steps, Divider, List } from 'antd';
import ProfileBox from './ProfileBox';
import CalendarSchedule from './CalendarSchedule';
import Absence from './tabContents/Absence';
import Attendance from './tabContents/Attendance';
import authData from '../../helpers/authData';
import { CompanyRegulationModal } from '../../components';
import { SentralModel } from '../../models/SentralModel';
import Milestone from './tabContents/Milestone';
import currentUser from '../../helpers/authData';
import DailyActivity from './tabContents/DailyActivity';
import JobDesc from './tabContents/JobDesc';
import ScoreCard from './tabContents/ScoreCard';
import RewardPunishment from './tabContents/RewardPunishment';
import { useRecoilState } from 'recoil';
import { tabState } from '../../recoil-state/dashboard.state';

const { TabPane } = Tabs;
const { Step } = Steps;

const defaultTab = (sessionStorage.getItem('selectedDashboardTab')) ? sessionStorage.getItem('selectedDashboardTab') : 'absence'

const Dashboard = () => {
    const [activeKey, setActiveKey] = useRecoilState(tabState);
    const [showModal, setShowModal] = useState(false)
    const [current, setCurrent] = useState(0)
    const [chapters, setChapters] = useState([])
    const [articles, setArticles] = useState([])
    const itDivision = [24, 25]

    const fetchChapters = () => {
        SentralModel.action('Documentation', 'getChapter', {}, 0).then(res => {
            setChapters(res.message)
        }).finally(() => {
        })
    }

    const changeCurrent = (current) => {
        setCurrent(current)
    }

    const fetchArticle = (chapter_id) => {
        var payload = {
            chapter_id: chapter_id
        }
        SentralModel.action('Documentation', 'getArticle', payload, chapter_id).then(res => {
            setArticles(res.message.articles)
        }).finally(() => {
        })
    }

    useEffect(() => {
        fetchChapters()
        fetchArticle(1)
        // syncEmployeeAttendanceMachine()
        authData.is_read_regulation === '0' ? setShowModal(false) : setShowModal(false)
    }, []);

    const prevButton = () => {
        if (current >= 0) setCurrent(current - 1)
        fetchArticle(current + 1)
    }

    const nextButton = () => {
        if (current <= 12) setCurrent(current + 1)
        fetchArticle(current + 1)
    }

    const changeTab = (selected) => {
        setActiveKey(selected)
        sessionStorage.setItem('selectedDashboardTab', selected)
    }

    return (
        <div>
            <Row>
                <Col span={6}>
                    <ProfileBox />
                </Col>
                <Col span={18} style={{ paddingLeft: 10 }}>
                    <Card style={{ marginBottom: 20, minHeight: '130vh', maxHeight: '200vh' }} className="box-shadow-2">
                        <Tabs defaultActiveKey={defaultTab} activeKey={activeKey} onChange={changeTab}>
                            <TabPane tab="Absence" key="absence">
                                <Absence />
                            </TabPane>
                            <TabPane tab="Calendar" key="calendar">
                                <CalendarSchedule />
                            </TabPane>
                            <TabPane tab="Log Attendance" key="attendance">
                                <Attendance />
                            </TabPane>
                            {
                                itDivision.includes(authData.division_id) ?
                                    null :
                                    <TabPane tab="Jobs Description" key="jobdesc">
                                        <JobDesc />
                                    </TabPane>
                            }
                            {
                                currentUser.division_id === 13 || currentUser.division_id === 22 || currentUser.division_id === 24 || currentUser.division_id === 25 || currentUser.division_id === 33 || currentUser.division_id === 34 || currentUser.division_id === 36 || currentUser.employee_code === "SMI20200803205" || currentUser.employee_code === "SMI20220323229" ?
                                    <TabPane tab="Daily Activity" key="daily_activity">
                                        <DailyActivity />
                                    </TabPane>
                                    : null
                            }
                            <TabPane tab="Score Card" key="score_card">
                                <ScoreCard />
                            </TabPane>
                            {
                                (currentUser.division_id === 24 || currentUser.division_id === 25) && (currentUser.position_id === 16 || currentUser.position_id === 55) ?
                                    <TabPane tab="Project Management" key="milestone">
                                        <Milestone />
                                    </TabPane> :
                                    null
                            }
                            <TabPane tab="My Reward & Punishment" key="reward_punishment">
                                <RewardPunishment />
                            </TabPane>
                        </Tabs>
                    </Card>
                </Col>
            </Row>

            {
                showModal ?
                    <CompanyRegulationModal title={'Company Regulation'} width={1300} showModal={showModal} onCloseModal={() => setShowModal(false)} prevCurrent={prevButton} nextCurrent={nextButton}>
                        <Row>
                            <Col span={6}>
                                <Steps direction="vertical" size="small" current={current} onChange={changeCurrent}>
                                    {chapters.map((item, index) => (
                                        <Step title={item.title} onClick={() => fetchArticle(item.chapter_id)} />
                                    ))}
                                </Steps>
                            </Col>
                            <Col span={18} style={{ paddingLeft: 10 }}>
                                <Card style={{ marginBottom: 20, minHeight: 500, maxHeight: 600, overflow: 'auto' }}>
                                    <Tabs defaultActiveKey={1}>
                                        {articles.map((item, index) => (
                                            <TabPane tab={`Article ` + item.article_no} key={index}>
                                                <Divider orientation="left">{item.title}</Divider>
                                                <List
                                                    bordered
                                                    dataSource={item.contents}
                                                    renderItem={item => (
                                                        <List.Item>
                                                            {item.order_no}. {item.content}
                                                        </List.Item>
                                                    )}
                                                />

                                            </TabPane>
                                        ))}
                                    </Tabs>
                                </Card>
                            </Col>
                        </Row>
                    </CompanyRegulationModal>
                    : null
            }
        </div >
    );
}

export default Dashboard;
