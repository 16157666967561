import { useState, useEffect, useRef } from 'react';
import { Button, Card, Col, Row, Typography, Carousel, Space, Drawer, Form, Select, DatePicker } from 'antd';
import { FilterOutlined, RightOutlined, LeftOutlined } from '@ant-design/icons';
import { SentralModel } from '../../../models/SentralModel';
// import { Pie } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import { useHistory } from 'react-router-dom';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from 'moment';
import TableGantt from '../table';

const { Title, Text, } = Typography
const { Option } = Select

Chart.register(ChartDataLabels);
Chart.defaults.set('plugins.datalabels', {
    color: 'white',
    font: {
        size: 20
    }
});

const DashboardActionPlanHome = () => {
    const history = useHistory()
    const ref = useRef()
    // const [data, setData] = useState([])
    const [allData, setAllData] = useState([])
    const [dataFilter, setDataFiter] = useState([])
    const [dataActionPlanSoftware, setDataActionPlanSoftware] = useState([])
    const [dataActionPlanBizdev, setDataActionPlanBizdev] = useState([])
    const [dataActionPlanIt, setDataActionPlanIt] = useState([])
    const [dataActionPlantSki, setDataActionPlanSki] = useState([])
    const [division, setDivision] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [isFilter, setIsFilter] = useState(false)
    const [year, setYear] = useState(moment(Date.now()))

    const fetchActionPlan = (v) => {
        let payload = {
            employee_code: null,
            division_id: null,
            year: moment(v).format('YYYY')
        }

        SentralModel.dashboardData('ActionPlan', 'getActionPlan', payload).then((res) => {
            const v = res.data
            let allData = []
            let software = []
            let bizdev = []
            let ski = []
            let it = []

            v.map((el) => {
                let range = []
                let done = []

                for (let i = parseInt(moment(el.start_date).format('M')); i <= parseInt(moment(el.end_date).format('M')); i++) {
                    range.push(i)
                }

                if (el.progress !== 0) {
                    for (let i = 0; i < Math.ceil((el.progress / (100 / range.length))); i++) {
                        done.push(range[i])
                    }
                }

                Object.assign(el, { range_date: range, date_realization: parseInt(moment(el.realization_date).format('M')), progress_average: (100 / range.length), done: done })

                if (el.division_id === 24) {
                    software.push(el)
                }
                if (el.division_id === 13) {
                    bizdev.push(el)
                }
                if (el.division_id === 33) {
                    ski.push(el)
                }
                if (el.division_id === 34) {
                    it.push(el)
                }

                return allData.push(el)
            })

            setAllData(v)
            setDataActionPlanSoftware(software)
            setDataActionPlanBizdev(bizdev)
            setDataActionPlanIt(it)
            setDataActionPlanSki(ski)
            setLoaded(true)
        })
    }

    const fetchActionPlanData = () => {
        SentralModel.dashboardData('ActionPlan', 'actionPlanDivisionProgress', 0).then((res) => {
            // setData(res.data)
            setLoaded(true)
        })
    }

    const fetchDivision = () => {
        SentralModel.dashboardData('Division', 'getDivision', null, null).then((res) => {
            const v = res.data
            let data = []
            v.map((el) => {
                if (el.division_id === 13 || el.division_id === 24 || el.division_id === 33 || el.division_id === 34) {
                    return data.push(el)
                }

                return null;
            })

            setDivision(data)
        })
    }

    useEffect(() => {
        fetchActionPlan(year)
        fetchActionPlanData()
        fetchDivision()

    }, [year])

    const finishFilter = (v) => {
        const filter = allData.filter(e => e.division_id === v.division_id)
        setIsFilter(true)
        setDataFiter(filter)
        setDrawerOpen(false)
    }

    const onChangeYear = (v) => {
        fetchActionPlan(v)
        setYear(v)
    }

    return (
        <div>
            <Card style={{ margin: '15px 10px 0 10px' }} className="content-container">
                {
                    loaded ?
                        <>
                            <div style={{ marginBottom: 15 }}>
                                <Row justify='space-between'>
                                    <Col>
                                        <Card>
                                            <Row style={{ marginBottom: 10 }}>
                                                <Space>
                                                    <Button className='button-dept' style={{ width: 300, fontSize: 11, fontWeight: 'bold', backgroundColor: '#4cd137', border: '#4cd137' }} type='primary' onClick={() => ref.current.goTo(0)}>DEPT. IT & BUSINESS DEVELOPMENT</Button>
                                                    <Button style={{ width: 320, fontSize: 11, fontWeight: 'bold' }} type='primary' onClick={() => ref.current.goTo(1)}>IT OPERATION & SUPPORT</Button>
                                                    <Button style={{ width: 300, fontSize: 11, fontWeight: 'bold' }} type='primary' onClick={() => ref.current.goTo(2)}>IT BUSINESS DEVELOPMENT ENTERPRISE</Button>
                                                </Space>
                                            </Row>
                                            <Row style={{ marginBottom: 10 }}>
                                                <Space>
                                                    <Button style={{ width: 300, fontSize: 11, fontWeight: 'bold' }} type='primary' onClick={() => ref.current.goTo(3)}>IT SOFTWARE DEVELOPMENT</Button>
                                                    <Button style={{ width: 320, fontSize: 11, fontWeight: 'bold' }} type='primary' onClick={() => ref.current.goTo(4)}>IT BUSINESS DEVELOPMENT COMMERCE & PARTNER</Button>
                                                    <Button style={{ width: 300, fontSize: 11, fontWeight: 'bold' }} type='primary' onClick={() => ref.current.goTo(5)}>ACTION PLAN PROGRESS</Button>
                                                </Space>
                                            </Row>
                                        </Card>
                                    </Col>
                                    <Col>
                                        <Space>
                                            <Card>
                                                <Row style={{ marginBottom: 10 }}>
                                                    <Button style={{ width: 160, fontSize: 11, fontWeight: 'bold' }} type='primary' onClick={() => history.push('/action-plan-home')}>ACTION PLAN</Button>
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    <Button disabled style={{ width: 160, fontSize: 11, fontWeight: 'bold' }} type='primary' onClick={() => null}>DAILY ACTIVITY</Button>
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    <Button style={{ width: 160, fontSize: 11, fontWeight: 'bold' }} type='primary' onClick={() => history.push('/working-location-dashboard')}>WORKING LOCATION</Button>
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    <Button style={{ width: 160, fontSize: 11, fontWeight: 'bold' }} type='primary' onClick={() => history.push('/digitalisasi-home')}>DIGITALISASI DOKUMEN</Button>
                                                </Row>
                                                <Row>
                                                    <Button style={{ width: 160, fontSize: 11, fontWeight: 'bold' }} type='primary' onClick={() => history.push('/dashboard-project')}>PROJECT BOOST</Button>
                                                </Row>
                                            </Card>
                                            <Card title="Legend">
                                                <Row style={{ marginBottom: 7 }}>
                                                    <Space>
                                                        <Space>
                                                            <div className='color-table-row' style={{ width: 20, height: 20 }}></div>
                                                            <Text>Timeline</Text>
                                                        </Space>
                                                        <Space>
                                                            <div className='color-table-progress' style={{ width: 20, height: 20 }}></div>
                                                            <Text>On Progress</Text>
                                                        </Space>
                                                    </Space>
                                                </Row>
                                                <Row>
                                                    <Space>
                                                        <Space>
                                                            <div className='color-table-done' style={{ width: 20, height: 20 }}></div>
                                                            <Text>Done</Text>
                                                        </Space>
                                                        <Space>
                                                            <div className='color-table-overdue' style={{ width: 20, height: 20 }}></div>
                                                            <Text>Overdue</Text>
                                                        </Space>
                                                    </Space>
                                                </Row>
                                            </Card>
                                        </Space>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <Carousel autoplay autoplaySpeed={5000} draggable ref={ref}>
                                    <div style={{ padding: '10px 15px' }}>
                                        <Card title={
                                            <>
                                                <Row style={{ marginBottom: 10 }} justify="space-between">
                                                    <Col>
                                                        <Title level={5}>DEPT. IT & BUSINESS DEVELOPMENT</Title>
                                                    </Col>
                                                    <Col>
                                                        <Space>
                                                            <Button onClick={() => setDrawerOpen(true)} type="primary" shape="round">Filter <FilterOutlined /></Button>
                                                            <Button onClick={() => setIsFilter(false)} type='primary' danger shape='round'>Reset Filter</Button>
                                                        </Space>
                                                    </Col>
                                                </Row>
                                                <Row justify='end'>
                                                    <DatePicker onChange={onChangeYear} value={year} picker="year" />
                                                </Row>
                                            </>
                                        }>
                                            {
                                                !isFilter ?
                                                    <TableGantt data={allData} />
                                                    :
                                                    <TableGantt data={dataFilter} />
                                            }
                                        </Card>
                                    </div>
                                    <div key="all-data" style={{ padding: '10px 15px' }}>
                                        <Card title={
                                            <Row justify='space-between'>
                                                <Col>
                                                    <Title level={5}>IT OPERATION & SUPPORT</Title>
                                                </Col>
                                                <Col>
                                                    <DatePicker onChange={onChangeYear} value={year} picker="year" />
                                                </Col>
                                            </Row>
                                        }>
                                            <TableGantt data={dataActionPlanIt} />
                                        </Card>
                                    </div>
                                    <div key="it-bizdev" style={{ padding: '10px 15px' }}>
                                        <Card title={
                                            <Row justify='space-between'>
                                                <Col>
                                                    <Title level={5}>IT BUSINESS DEVELOPMENT ENTERPRISE</Title>
                                                </Col>
                                                <Col>
                                                    <DatePicker onChange={onChangeYear} value={year} picker="year" />
                                                </Col>
                                            </Row>
                                        }>
                                            <TableGantt data={dataActionPlanBizdev} />
                                        </Card>
                                    </div>
                                    <div key="it-software" style={{ padding: '10px 15px' }}>
                                        <Card title={
                                            <Row justify='space-between'>
                                                <Col>
                                                    <Title level={5}>IT SOFTWARE DEVELOPMENT</Title>
                                                </Col>
                                                <Col>
                                                    <DatePicker onChange={onChangeYear} value={year} picker="year" />
                                                </Col>
                                            </Row>
                                        }>
                                            <TableGantt data={dataActionPlanSoftware} />
                                        </Card>
                                    </div>
                                    <div key="it-ski" style={{ padding: '10px 15px' }}>
                                        <Card title={
                                            <Row justify='space-between'>
                                                <Col>
                                                    <Text>IT BUSINESS DEVELOPMENT COMMERCE & PARTNER</Text>
                                                </Col>
                                                <Col>
                                                    <DatePicker onChange={onChangeYear} value={year} picker="year" />
                                                </Col>
                                            </Row>
                                        }>
                                            <TableGantt data={dataActionPlantSki} />
                                        </Card>
                                    </div>
                                    {/* <div style={{ padding: '10px 15px' }}>
                                        <Card title={
                                            <Row justify='space-between'>
                                                <Col>
                                                    <Title level={5}>ACTION PLAN PROGRESS</Title>
                                                </Col>
                                                <Col>
                                                    <DatePicker onChange={onChangeYear} value={year} picker="year" />
                                                </Col>
                                            </Row>
                                        }>
                                            <Row justify="space-between">
                                                {
                                                    data.map((el, key) => (
                                                        <Col key={key} span={5}>
                                                            <Card style={{ borderRadius: 10 }}>
                                                                <div className='mb-4'>
                                                                    <Title style={{ fontSize: 16 }}>{el.division}</Title>
                                                                </div>
                                                                <div style={{ marginBottom: 10 }}>
                                                                    <Descriptions layout="vertical" bordered>
                                                                        <Descriptions.Item label="Total Action Plan">
                                                                            <div className='text-center'>
                                                                                <Statistic value={el.total_action_plan} />
                                                                            </div>
                                                                        </Descriptions.Item>
                                                                        <Descriptions.Item label="Progress">
                                                                            <Progress type="circle" width={80} percent={el.action_plan_progress.toFixed(1)} />
                                                                        </Descriptions.Item>
                                                                    </Descriptions>
                                                                </div>
                                                                <Card>
                                                                    <Row justify='center'>
                                                                        <Col span={24}>
                                                                            {
                                                                                el.realization !== 0 && el.overdue !== 0 ?
                                                                                    <Pie width={"80%"} data={{
                                                                                        labels: ['Overdue', 'Realization'],
                                                                                        datasets: [
                                                                                            {
                                                                                                data: [el.overdue, el.realization],
                                                                                                backgroundColor: [
                                                                                                    '#f50',
                                                                                                    '#87d068',
                                                                                                ],
                                                                                                borderColor: [
                                                                                                    '#f50',
                                                                                                    '#87d068',
                                                                                                ],
                                                                                                borderWidth: 1,
                                                                                            },
                                                                                        ],
                                                                                    }} />
                                                                                    :
                                                                                    <Text>No Realization Data</Text>
                                                                            }
                                                                        </Col>
                                                                    </Row>
                                                                </Card>
                                                            </Card>
                                                        </Col>
                                                    ))
                                                }
                                                <Col span={15}>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </div> */}
                                </Carousel>
                            </div>
                            <div className='button-overflow-bottom'>
                                <Space>
                                    <Button type='primary' size='large' shape="circle" onClick={() => ref.current.prev()}><LeftOutlined /></Button>
                                    <Button type='primary' size='large' shape="circle" onClick={() => ref.current.next()}><RightOutlined /></Button>
                                </Space>
                            </div>
                        </>
                        : null
                }

                <Drawer
                    title="Filter Data"
                    width={400}
                    placement="right"
                    closable={true}
                    onClose={() => setDrawerOpen(false)}
                    visible={drawerOpen}
                    key="right"
                >
                    <Form layout='vertical' onFinish={(v) => finishFilter(v)}>
                        <Form.Item label="Select Division" name="division_id">
                            <Select>
                                {
                                    division.map((el, key) => (
                                        <Option key={key} value={el.division_id}>
                                            {el.division_name}
                                        </Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Button type='primary' htmlType='submit'>Submit</Button>
                    </Form>
                </Drawer>
            </Card>
        </div >
    )
}

export default DashboardActionPlanHome