import React, {useState, useEffect} from 'react';
import {Card, Row, Col, Tabs} from 'antd'
import {PageTitle, PrintPdfButton, Spinner} from '../../../../components'
import {useParams} from 'react-router-dom';
import {SentralModel} from '../../../../models/SentralModel';

import Work from './Work'
import Inventory from './Inventory'
import File from './File'
import Handover from './Handover'
import Witness from './Witness'

const {TabPane} = Tabs;

const BAST = () => {
    const {resignId} = useParams();
    const [loaded, setLoaded] = useState(false);
    const [activeTab, setActiveTab] = useState('work');
    const [works, setWorks] = useState([]);
    const [inventories, setInventories] = useState([]);
    const [files, setFiles] = useState([]);
    const [handovers, setHandovers] = useState([]);
    const [witnesses, setWitnesses] = useState([]);

    const fetchWorks = (resignId) => {
        SentralModel.list('SubmissionResignWork', {filter: [['sub_resign_id', resignId]], orderBy: "sub_resign_id", order: "asc"}).then(res => {
            setLoaded(false)
            setWorks(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    const fetchInventories = (resignId) => {
        SentralModel.list('SubmissionResignInventory', {filter: [['sub_resign_id', resignId]]}).then(res => {
            setLoaded(false)
            setInventories(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    const fetchFiles = (resignId) => {
        SentralModel.list('SubmissionResignFile', {filter: [['sub_resign_id', resignId]]}).then(res => {
            setLoaded(false)
            setFiles(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    const fetchHandovers = (resignId) => {
        SentralModel.list('SubmissionResignHandover', {filter: [['sub_resign_id', resignId]]}).then(res => {
            setLoaded(false)
            setHandovers(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    const fetchWitnesses = (resignId) => {
        SentralModel.list('SubmissionResignWitness', {filter: [['sub_resign_id', resignId]]}).then(res => {
            setLoaded(false)
            setWitnesses(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchWorks(resignId);
        fetchInventories(resignId);
        fetchFiles(resignId);
        fetchHandovers(resignId);
        fetchWitnesses(resignId);
    }, [resignId]);

    const changeTab = e => {
        setActiveTab(e)
    }

    const printData = () => {
        window.open(process.env.REACT_APP_API_URL + '/print/bast/' + resignId)
    }

    return (
        <Card className="content-container">
            <div>
                <PageTitle title="BAST" breadcrumbs={[['Resign', '/resign'], ['BAST']]}></PageTitle>
            </div>

            <Row className="mb-3">
                <Col span={24} >
                    <PrintPdfButton right onPrint={printData} />
                </Col>
            </Row>

            <Card>
                {
                    (loaded) ?
                    <>
                        <Tabs defaultActiveKey={activeTab} type="card" onChange={changeTab}>
                            <TabPane tab={<span>Work</span>} key="work">
                                <Work data={works} onFetch={() => fetchWorks(resignId)}/>
                            </TabPane>
                            <TabPane tab={<span>Inventory</span>} key="inventory">
                                <Inventory data={inventories} onFetch={() => fetchInventories(resignId)}/>
                            </TabPane>
                            <TabPane tab={<span>File</span>} key="file">
                                <File data={files} onFetch={() => fetchFiles(resignId)}/>
                            </TabPane>
                            <TabPane tab={<span>Handover To</span>} key="handover">
                                <Handover data={handovers} onFetch={() => fetchHandovers(resignId)}/>
                            </TabPane>
                            <TabPane tab={<span>Witnessed By</span>} key="witness">
                                <Witness data={witnesses} onFetch={() => fetchWitnesses(resignId)}/>
                            </TabPane>
                        </Tabs>
                    </>
                    : <Spinner />
                }
            </Card>
        </Card>
    );
}

export default BAST;
