import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { SentralModel } from "../../../models/SentralModel";
import { message } from "antd";
import moment from "moment";
import { getDatesInRange } from "../../../helpers/dateRange";
import authData from '../../../helpers/authData';
import dayjs from "dayjs";

export const fetchMyTask = createAsyncThunk(
    'myTask/fetchMyTask',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await SentralModel.action("DailyActivity", "dailyTasks", null, null);
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }

    }
)

export const fetchTaskAssign = createAsyncThunk(
    'myTask/fetchTaskAssign',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await SentralModel.action("DailyActivity", "taskAssigns", null, null);
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchEmployee = createAsyncThunk(
    'myTask/fetchEmployee',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await SentralModel.list('Employee', { filter: [['is_active', '=', '1'], ['employee_status', ['ESPR', 'ESPB', 'ESKN']]], orderBy: 'name', order: 'asc' })
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchListProgressTask = createAsyncThunk(
    'myTask/fetchListProgressTask',
    async (arg, { rejectWithValue }) => {
        try {
            const response = await SentralModel.action("Task", "listProgressTask", null, arg)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const saveCreateTask = createAsyncThunk(
    'myTask/saveCreateTask',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await SentralModel.action("DailyActivity", "addDailyTask", payload, null)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const deleteTaskProgress = createAsyncThunk(
    'myTak/deleteTaskProgress',
    async (payload, { rejectWithValue }) => {
        try {
            await SentralModel.action("Task", "deleteProgress", null, payload.all_progress_task_id)
            message.success("Success Delete Progress")
            return payload
        } catch (error) {
            message.warning("Failed Remove Progress")
            return rejectWithValue(error)
        }
    }
)

export const updateTaskProgress = createAsyncThunk(
    'myTask/updateTaskProgress',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await SentralModel.action("Task", "updateTaskProgress", payload, 0)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const updateMilestoneProgress = createAsyncThunk(
    'myTask/updateMilestoneProgress',
    async (payload, { rejectWithValue }) => {
        try {
            await SentralModel.action("Task", "updateMilestoneTask", payload, 0)
            message.success("Success Update Progress", 2)
            return
        } catch (error) {
            message.warning("Failed Update", 2)
            return rejectWithValue(error)
        }
    }
)

export const saveFinishTask = createAsyncThunk(
    'myTask/saveFinishTask',
    async (payload, { rejectWithValue }) => {
        try {
            if (payload.confirm_finished === "confirm_with_attachment" && payload.attachmentConfirm === null) {
                message.warning("Please Complete your Requirement")
                return null
            } else {
                const response = await SentralModel.action("Task", "updateTaskProgress", payload, 0)
                return response.data
            }
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

const myTaskSlice = createSlice({
    name: 'myTask',
    initialState: {
        loaded: false,
        loadedAssign: false,
        uploading: false,
        saving: false,
        isMilestoneTask: false,
        showModalAddTask: false,
        editProgressTaskModal: false,
        showModalAddProgressTask: false,
        modalAddTaskProgress: false,
        modalConfirm: false,
        modalExtend: false,
        modalLogTimeProgress: false,
        isOwner: false,
        datas: [],
        dataAssigns: [],
        employees: [],
        taskProgressData: [],
        multipleAssign: [],
        diffDate: [],
        timeProgressData: [],
        defaultValues: {},
        ticketInfo: {},
        saveDataTask: {},
        extendDataSend: {},
        editProgressTaskModalTitle: "",
        titleAddTask: "",
        defaultStat: "",
        remarksFinished: "",
        confirmFinished: "confirm",
        showAttachmentFinished: null,
        attachmentInfo: null,
        attachmentFile: null,
        attachmentConfirm: null,
        myProgressPercentage: 0,
        percentageTask: 0,
        progressValue: 0,
    },
    reducers: {
        addTask: (state, action) => {
            state.showModalAddTask = true
            state.titleAddTask = "Add Task"
        },
        changeAddTaskModal: (state, action) => {
            state.showModalAddTask = action.payload
        },
        changeConfirmFinished: (state, action) => {
            state.confirmFinished = action.payload
        },
        editProgressTask: (state, action) => {
            const v = action.payload.data
            const stat = action.payload.stat
            const owner = action.payload.owner ? true : false

            state.isOwner = owner

            if (v.data_from === "TICKET PRESALES") {
                state.ticketInfo = v
                state.isTicketPresales = true
            } else {
                state.isTicketPresales = false
            }

            if (v.data_from === "MILESTONE TASK") {
                state.isMilestoneTask = true
            } else {
                state.isMilestoneTask = false
            }

            state.defaultValues = {
                all_app_task_id: v.id,
                emp_task_id: v.task_id,
                employee_code: v.created_by,
                category: state.category,
                category_task_code: v.category_task_code,
                milestone_id: v.milestone_id,
                milestone_task_id: v.milestone_task_id,
                data_from: v.data_from,
                task_to: v.assign_to,
                description: v.description,
                remarks_presales: v.progress[0]?.job_description,
                start_date: moment(v.start_date),
                deadline: moment(v.due_date),
                note: v.reply,
                finished_date: v.finished_date === null ? null : moment(v.finished_date),
                status: v.status === 'Finished' ? true : false,
                confirm_finished: v.confirm_finished,
                attachment_clause: v.attachment_clause,
                remark_finished: v.remark_finished
            }

            state.showAttachmentFinished = v.finished_attachment
            state.confirmFinishedValue = v.confirm_finished
            state.attachmentInfo = v.attachment

            let arr = []

            if (v.progress.length === 0) {
                state.taskProgressData = []
            } else {
                v.progress?.forEach((el) => {
                    arr.push(el)
                })
            }

            state.taskProgressData = arr
            state.defaultStat = stat
            state.editProgressTaskModal = true
            state.editProgressTaskModalTitle = "Edit Progress Task"

            let progress = 0
            let myProgress = 0
            let arrAssign = []
            v.progress.forEach((el) => {
                if (el.employee_code === authData.employee_code) {
                    myProgress += el.progress
                }

                progress += el.progress
            })

            v.multiple_task_to.forEach((el) => {
                arrAssign.push(el)
            });

            let progressAverage = progress / parseInt(v.multiple_task_to.length)
            // let progressAverage = progress
            state.myProgressPercentage = myProgress
            state.percentageTask = Math.round(progressAverage)
            state.multipleAssign = arrAssign
            const date1 = new Date(v.start_date);
            const date2 = (Date.parse(moment(new Date()).format('YYYY-MM-DD')) > Date.parse(moment(v.due_date).format('YYYY-MM-DD'))) ? new Date() : new Date(v.due_date);
            const dates = getDatesInRange(date1, date2)
            state.diffDate = dates
        },
        addProgress: (state, action) => {
            const v = action.payload
            let arr = []
            let percentage = 0
            let myPercentage = 0
            let key = current(state.taskProgressData).length

            arr.push({
                key: key,
                all_progress_task_id: 0,
                job_description: v.job_description,
                remarks: v.remarks,
                progress: state.progressValue,
                time_start: dayjs(v.time[0]).format("HH:mm"),
                time_end: dayjs(v.time[1]).format("HH:mm"),
                progress_date: moment(Date.now()).format("YYYY-MM-DD"),
                employee_code: authData.employee_code
            })

            if (state.taskProgressData.length > 0) {
                const newData = state.taskProgressData.concat(arr)
                newData.forEach((el) => {
                    if (el.employee_code === authData.employee_code) {
                        myPercentage += el.progress
                    }
                    percentage += el.progress
                })

                state.percentageTask = Math.round(percentage / state.multipleAssign.length)
                state.myProgressPercentage = myPercentage
                state.taskProgressData = newData
            } else {
                arr.forEach((el) => {
                    percentage += el.progress
                    myPercentage += el.progress
                })

                state.percentageTask = Math.round(percentage / state.multipleAssign.length)
                state.myProgressPercentage = myPercentage
                state.taskProgressData = arr
            }

            state.modalAddTaskProgress = false
        },
        closeEditTask: (state, action) => {
            state.editProgressTaskModal = false
        },
        removeProgressTask: (state, action) => {
            const v = action.payload

            let percentage = 0
            let myPercentage = 0
            const dp = current(state.taskProgressData).filter((el) => el.key !== v.key)

            dp.forEach((el) => {
                if (el.employee_code === authData.employee_code) {
                    myPercentage += el.progress
                }

                percentage += el.progress
            })

            state.percentageTask = Math.round(percentage)
            state.myProgressPercentage = myPercentage
            state.taskProgressData = dp
        },
        onClickAddProgress: (state, action) => {
            state.modalAddTaskProgress = true
            state.progressValue = 0
        },
        onClickShowModalAddProgress: (state, action) => {
            state.modalAddTaskProgress = action.payload
        },
        showModalConfirm: (state, action) => {
            const v = action.payload
            state.modalConfirm = true
            state.saveDataTask = {
                ...v
            }
        },
        changeModalConfirm: (state, action) => {
            state.modalConfirm = action.payload
        },
        fileUploaded: (state, action) => {
            state.uploading = true
            const v = action.payload
            state.attachmentFile = v.message
        },
        fileUploadFinish: (state, action) => {
            state.uploading = true
            const v = action.payload
            state.attachmentConfirm = v.message
            state.uploading = false
        },
        changeUploading: (state, action) => {
            state.uploading = action.payload
        },
        changeProgressValue: (state, action) => {
            state.progressValue = action.payload
        },
        changeRemark: (state, action) => {
            state.remarksFinished = action.payload
        },
        changeSaving: (state, action) => {
            state.saving = action.payload
        },
        showModalExtend: (state, action) => {
            state.modalExtend = action.payload.status
            state.extendDataSend = action.payload.data
        },
        showLogTimeProgress: (state, action) => {
            state.modalLogTimeProgress = action.payload.status
            if (action.payload.data !== null) {
                state.timeProgressData.push(action.payload.data)
            } else {
                state.timeProgressData = []
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchMyTask.pending, (state) => {
            state.loaded = false
        })
        builder.addCase(fetchMyTask.fulfilled, (state, action) => {
            state.datas = action.payload
            state.loaded = true
        })
        builder.addCase(fetchMyTask.rejected, (state, action) => {
            state.datas = []
            state.loaded = true
        })
        builder.addCase(fetchTaskAssign.pending, (state) => {
            state.loadedAssign = false
        })
        builder.addCase(fetchTaskAssign.fulfilled, (state, action) => {
            state.dataAssigns = action.payload
            state.loadedAssign = true
        })
        builder.addCase(fetchTaskAssign.rejected, (state, action) => {
            state.dataAssigns = []
            state.loadedAssign = true
        })
        builder.addCase(fetchEmployee.fulfilled, (state, action) => {
            let arr = []
            action.payload.forEach((el) => {
                arr.push({ value: el.employee_code, label: el.name })
            })
            state.employees = arr
        })
        builder.addCase(fetchListProgressTask.fulfilled, (state, action) => {
            state.taskProgressData = action.payload
        })

        // Save Create Task
        builder.addCase(saveCreateTask.pending, (state, action) => {
            state.saving = true
        })
        builder.addCase(saveCreateTask.fulfilled, (state, action) => {
            state.saving = false
            state.showModalAddTask = false
            message.success("Success Create Task")
        })
        builder.addCase(saveCreateTask.rejected, (state, action) => {
            state.saving = false
            message.warning("Failed Create Task")
        })
        // End Save Create Task

        builder.addCase(deleteTaskProgress.fulfilled, (state, action) => {
            const v = action.payload

            let percentage = 0
            let myPercentage = 0
            const dp = current(state.taskProgressData).filter((el) => el.all_progress_task_id !== v.all_progress_task_id)

            dp.forEach((el) => {
                if (el.employee_code === authData.employee_code) {
                    myPercentage += el.progress
                }

                percentage += el.progress
            })

            state.percentageTask = Math.round(percentage)
            state.myProgressPercentage = myPercentage
            state.taskProgressData = dp
        })

        // Update Task Progress
        builder.addCase(updateTaskProgress.pending, (state, action) => {
            state.saving = true
        })
        builder.addCase(updateTaskProgress.fulfilled, (state, action) => {
            state.saving = false
            state.attachmentFile = null
            message.success("Success Update Progress", 2)
        })
        builder.addCase(updateTaskProgress.rejected, (state, action) => {
            state.saving = false
            message.warning("Failed Update", 2)
        })
        // End Update Task Progress

        builder.addCase(updateMilestoneProgress.fulfilled, (state, action) => {
            state.attachmentFile = null
        })

        // FinishTask
        builder.addCase(saveFinishTask.pending, (state, action) => {
            state.saving = true
        })
        builder.addCase(saveFinishTask.fulfilled, (state, action) => {
            state.saving = false
            state.modalConfirm = false
            state.editProgressTaskModal = false
            state.attachmentFile = null
            message.success("Success Update Progress", 2)
        })
        builder.addCase(saveFinishTask.rejected, (state, action) => {
            state.saving = false
            message.warning("Failed Update", 2)
        })
        // End Finish Task
    }
})

export const {
    addTask,
    changeAddTaskModal,
    changeConfirmFinished,
    editProgressTask,
    addProgress,
    closeEditTask,
    removeProgressTask,
    onClickAddProgress,
    onClickShowModalAddProgress,
    showModalConfirm,
    changeModalConfirm,
    fileUploaded,
    fileUploadFinish,
    changeUploading,
    changeProgressValue,
    changeRemark,
    changeSaving,
    showModalExtend,
    showLogTimeProgress
} = myTaskSlice.actions
export default myTaskSlice.reducer