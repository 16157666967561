import React from 'react';
import {Space} from 'antd'
import {EditButton, DeleteButton} from '../../components'
const ActionButton = (props) => {
    const {editable=false, deleteable =false} = props
    return (
        <div className="full-width">
            <Space>
                {
                    editable ? 
                    <EditButton onEdit={props.onEdit}/> : null
                }
                {
                    deleteable ?
                    <DeleteButton onConfirm={props.onDelete}/> : null
                }
            </Space>
        </div>
    );
}

export default ActionButton;
