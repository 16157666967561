import { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Tabs, Space, Form, DatePicker, Select, Drawer } from 'antd';
import { ClockCircleOutlined, CheckOutlined, FileTextOutlined, FilterOutlined, FileExcelOutlined } from '@ant-design/icons';
import { Spinner } from '../../../components';
import { SentralModel } from '../../../models/SentralModel';
import authData from '../../../helpers/authData';
import { DataTable } from '../../../components';
import { toFullDate } from '../../../utils/dateHandler';
import MyDailyActivity from './Tabs/MyDailyActivity';
import MyTask from './Tabs/MyTask';
import AssignTask from './Tabs/AssignedTask';
import TicketBoost from './Tabs/TicketBoost';
import KarajoTicket from './Tabs/KarajoTicket';
import { changeDailyActivityTab } from '../../../redux/slices/Dashboard/dashboardSlice';
import { fetchDailyActivities } from '../../../redux/slices/Dashboard/myDailyActivitySlice';
// import ExtendRequest from './Tabs/ExtendRequest';
// import MyWorkingLocation from './Tabs/MyWorkingLocation';

const { TabPane } = Tabs;
const { Option } = Select;
const { RangePicker } = DatePicker;

const DailyActivity = () => {
    const dispatch = useDispatch();
    const dataAssigns = useSelector((state) => state.myTask.dataAssigns)
    const [loadedSummary, setLoadedSummary] = useState(true);
    const [employees, setEmployees] = useState([]);
    const [showFilter, setShowFilter] = useState(false);
    const [selectedDivision, setSelectedDivision] = useState(null);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [selectedDateRange, setSelectedDateRange] = useState([]);
    const [summaryData, setSummaryData] = useState([]);
    const [isHead, setIsHead] = useState(null);
    const [periods, setPeriods] = useState([]);
    const [datePeriods, setDatePeriods] = useState([]);
    const [loadedPeriod, setLoadedPeriod] = useState(false);

    const fetchPeriods = (year) => {
        SentralModel.list('PayrollPeriod', { filter: [['year', year]] }, { orderBy: 'from_date', order: 'asc' }).then(res => {
            setPeriods(res.data)
        })
    }

    const fetchEmployees = () => {
        SentralModel.list('Employee', { filter: [['is_active', '=', '1'], ['employee_status', ['ESPR', 'ESPB', 'ESKN']]], orderBy: 'name', order: 'asc' })
            .then(res => {
                setEmployees(res.data)
            })
    }

    const fetchMyData = () => {
        SentralModel.get('Employee', {}, authData.employee_code)
            .then((res) => {
                setSelectedDivision(res.data.division_id)
                setIsHead(res.data.is_division_head === '0' ? false : true)
            })
    }

    useEffect(() => {
        fetchMyData();
        fetchEmployees();
        // fetchListWorkLocation();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchReport = (ft) => {
        setLoadedSummary(false)
        SentralModel.action('Task', 'summary', ft, 0).then((res) => {
            setSummaryData(res.data)
            setLoadedSummary(true)
        })
    }

    const changePeriod = (v) => {
        SentralModel.action('PayrollPeriod', 'getListDate', { period_id: v }, 0).then(res => {
            setDatePeriods(res.data)
            setLoadedPeriod(true)
        })
    }

    const onChangeYear = (v) => {
        let year = moment(v).format('YYYY');
        setPeriods([])
        fetchPeriods(year)
    }

    const exportReport = () => {
        window.open(process.env.REACT_APP_API_URL + '/report/task?division_id=' + selectedDivision + '&employee_code=' + selectedEmployee + '&from=' + selectedDateRange[0] + '&to=' + selectedDateRange[1])
    }

    const filterData = (v) => {
        let ft = {
            division_id: selectedDivision,
            employee_code: v.employee_code,
            date_range: (v.date_range) ? [moment(v.date_range[0]).format('YYYY-MM-DD'), moment(v.date_range[1]).format('YYYY-MM-DD')] : []
        }

        fetchReport(ft)
        setShowFilter(false)
    }

    const disabledDate = (current) => {
        let currentMonth = moment().format('MM');
        let addMonth = 1;

        if (currentMonth === '12') {
            addMonth = 2;
        }

        let customDate = moment(new Date()).add(addMonth, 'y').format('YYYY');
        return current && current > moment(customDate, "YYYY");
    }

    const summaryColumn = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
        },
        {
            title: 'Task Origin',
            dataIndex: 'data_from',
            key: 'data_from'
        },
        {
            title: 'Task From',
            dataIndex: 'from_name',
            key: 'from_name',
        }, {
            title: 'Task To',
            dataIndex: 'to_name',
            key: 'to_name',
        }, {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        }, {
            title: 'Deadline',
            key: 'deadline',
            render: (row) => (
                toFullDate(row?.due_date)
            )
        }, {
            title: 'Finished Date',
            render: (row) => (
                row?.finished_date === null ? '-' : moment(row?.finished_date).format('DD MMMM YYYY')
            ),
            key: 'finished_date',
        }, {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        }
    ];

    const onClickDate = (v) => {
        dispatch(fetchDailyActivities({ start_date: v, end_date: v }))
    }

    return (
        <div>
            <Row justify="space-between" className="mb-3">
                <Col>
                    <Space>
                        <DatePicker picker="year" onChange={onChangeYear} disabledDate={disabledDate} placeholder="Select Year" />
                        <Select placeholder="Select Period" style={{ width: 200 }} onChange={changePeriod}>
                            {
                                periods.map((el, key) => (
                                    <Option key={key} value={el.payroll_period_id}>{el.period}</Option>
                                ))
                            }
                        </Select>
                    </Space>
                </Col>
            </Row>
            <>
                <Row justify='center' className='mb-3'>
                    <Col span={24}>
                        {
                            loadedPeriod ?
                                <div className='scrollButton'>
                                    <Space>
                                        {
                                            datePeriods.map((el, key) => (
                                                parseInt(new Date(el.date).getDay()) === 0 || parseInt(new Date(el.date).getDay()) === 6 ?
                                                    <Button key={key} type='primary' danger>
                                                        {moment(el.date).format("DD MMM")}
                                                    </Button> :
                                                    <Button key={key} onClick={() => onClickDate(el.date)}>
                                                        {moment(el.date).format("DD MMM")}
                                                    </Button>
                                            ))
                                        }
                                    </Space>
                                </div>
                                : null
                        }
                    </Col>
                </Row>

                <Tabs defaultActiveKey="my_daily" type="card" onChange={(v) => dispatch(changeDailyActivityTab(v))}>
                    <TabPane tab="My Daily Activity" key="my_daily">
                        <MyDailyActivity />
                    </TabPane>
                    {/* <TabPane tab="My Working Location" key="my_working_location">
                        <MyWorkingLocation />
                    </TabPane> */}
                    <TabPane tab="My Task" key="my_task">
                        <MyTask />
                    </TabPane>
                    <TabPane tab="Assign Task" key="assign_task">
                        <Tabs defaultActiveKey='status' type='card'>
                            <TabPane tab={
                                <span><ClockCircleOutlined style={{ color: '#4ca3ff' }} />
                                    In Progress
                                </span>
                            } key="progress-task">
                                <AssignTask datas={dataAssigns?.progress_task} />
                            </TabPane>

                            <TabPane tab={<span><CheckOutlined style={{ color: 'green' }} /> Finished</span>} key="finished-task">
                                <AssignTask datas={dataAssigns?.finish_task} />
                            </TabPane>
                        </Tabs>
                    </TabPane>

                    {
                        authData.division_id === 22 ? null :
                            <TabPane tab="Karajo Ticket" key="karajo_ticket">
                                <KarajoTicket />
                            </TabPane>
                    }

                    {
                        // authData.employee_code === 'KRTJ20201123064' || authData.employee_code === 'SMI20220103227' || 
                        authData.employee_code === "ADM001" ?
                            <TabPane tab="Ticket Boost" key="ticket_boost">
                                <TicketBoost />
                            </TabPane> :
                            null
                    }

                    {
                        isHead ?
                            <TabPane tab={<span><FileTextOutlined style={{ color: 'green' }} /> Summary</span>} key="summary">
                                <div className="text-right mb-2">
                                    <Space>
                                        <Button type="primary" onClick={() => exportReport()} shape="circle" icon={<FileExcelOutlined />}></Button>
                                        <Button shape="circle" icon={<FilterOutlined />} onClick={() => setShowFilter(true)}></Button>
                                    </Space>
                                </div>
                                {
                                    loadedSummary ?
                                        <DataTable size="small" columns={summaryColumn} dataSource={summaryData} bordered />
                                        : <Spinner />
                                }
                            </TabPane>
                            : null
                    }
                </Tabs>
            </>

            <Drawer
                title="Filter Report"
                width={400}
                placement="right"
                closable={false}
                onClose={() => setShowFilter(false)}
                visible={showFilter}>
                <Form layout="vertical" onFinish={filterData}>
                    <Form.Item name="employee_code" label="Employee">
                        <Select showSearch allowClear style={{ width: '100%' }} onChange={(v) => { setSelectedEmployee(v) }} placeholder="Select Employee" optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            <Select.Option value={null}>All Employee</Select.Option>
                            {
                                employees.filter(el => selectedDivision === 2 || selectedDivision === 8 ? el.is_active === 1 : el.division_id === selectedDivision).map((el, key) => (
                                    <Select.Option
                                        key={key}
                                        value={el.employee_code}
                                    >{el.name}</Select.Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name="date_range" label="Date Range" rules={[{ required: true, message: 'Date range is required' }]}>
                        <RangePicker onChange={(v) => setSelectedDateRange([moment(v[0]).format('YYYY-MM-DD'), moment(v[1]).format('YYYY-MM-DD')])} />
                    </Form.Item>

                    <Form.Item >
                        <div className="text-right">
                            <Button htmlType="submit" type="primary" size="middle" icon={<FilterOutlined />}> Show</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Drawer>
        </div>
    );
}

export default DailyActivity;