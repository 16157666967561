import { useEffect, useState } from 'react'
import { Card, Descriptions } from 'antd'
import { PageTitle, Spinner } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import { useParams } from 'react-router-dom'

const Detail = () => {
    const { referralId } = useParams()
    const [referral, setReferral] = useState({})
    const [cwoman, setCwoman] = useState(null)
    const [loaded, setLoaded] = useState(false)

    const fetchReferrals = (referralId) => {
        setLoaded(false)
        SentralModel.action('Referral', 'getReferralbyID', null, referralId).then(res => {
            setReferral(res.data.referral)
            setCwoman(res.data.cwoman)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchReferrals(referralId)

    }, [referralId])

    return (
        <Card className="content-container">
            <PageTitle title="Referral Detail" breadcrumbs={[['Referral', '/referral'], ['Detail']]}></PageTitle>
            <Card title="Referral Info">
                <div className="mb-3">
                    {
                        loaded ?
                            <Descriptions bordered>
                                <Descriptions.Item span={2} label="Company Name">{referral?.company_name}</Descriptions.Item>
                                <Descriptions.Item label="PIC Name">{referral?.PIC_name}</Descriptions.Item>
                                <Descriptions.Item span={2} label="PIC Phone">{referral?.PIC_phone}</Descriptions.Item>
                                <Descriptions.Item label="PIC Email">{referral?.PIC_email}</Descriptions.Item>
                                <Descriptions.Item span={3} label="Remarks">{referral?.remarks}</Descriptions.Item>
                                <Descriptions.Item span={3} label="Created By">{referral?.employee?.name}</Descriptions.Item>
                            </Descriptions> :
                            <Spinner />
                    }
                </div>

                <Card title="CWOMAN Values">
                    {
                        cwoman ?
                            <table className="styled-table">
                                <thead>
                                    <tr>
                                        <th style={{ fontSize: 14 }}>Champion</th>
                                        <th style={{ fontSize: 14 }}>When</th>
                                        <th style={{ fontSize: 14 }}>Obstacle</th>
                                        <th style={{ fontSize: 14 }}>Money</th>
                                        <th style={{ fontSize: 14 }}>Authority</th>
                                        <th style={{ fontSize: 14 }}>Need</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        {
                                            cwoman.champion_value === 'Hijau' ?
                                                <td style={{ backgroundColor: 'green', height: 50, border: '1px solid #eaeaea' }}></td> :
                                                cwoman.champion_value === 'Kuning' ?
                                                    <td style={{ backgroundColor: 'yellow', height: 50, border: '1px solid #eaeaea' }}></td> :
                                                    <td style={{ backgroundColor: 'red', height: 50, border: '1px solid #eaeaea' }}></td>
                                        }
                                        {
                                            cwoman.when_value === 'Hijau' ?
                                                <td style={{ backgroundColor: 'green', height: 50, border: '1px solid #eaeaea' }}></td> :
                                                cwoman.when_value === 'Kuning' ?
                                                    <td style={{ backgroundColor: 'yellow', height: 50, border: '1px solid #eaeaea' }}></td> :
                                                    <td style={{ backgroundColor: 'red', height: 50, border: '1px solid #eaeaea' }}></td>
                                        }
                                        {
                                            cwoman.obstacle_value === 'Hijau' ?
                                                <td style={{ backgroundColor: 'green', height: 50, border: '1px solid #eaeaea' }}></td> :
                                                cwoman.obstacle_value === 'Kuning' ?
                                                    <td style={{ backgroundColor: 'yellow', height: 50, border: '1px solid #eaeaea' }}></td> :
                                                    <td style={{ backgroundColor: 'red', height: 50, border: '1px solid #eaeaea' }}></td>
                                        }
                                        {
                                            cwoman.money_value === 'Hijau' ?
                                                <td style={{ backgroundColor: 'green', height: 50, border: '1px solid #eaeaea' }}></td> :
                                                cwoman.money_value === 'Kuning' ?
                                                    <td style={{ backgroundColor: 'yellow', height: 50, border: '1px solid #eaeaea' }}></td> :
                                                    <td style={{ backgroundColor: 'red', height: 50, border: '1px solid #eaeaea' }}></td>
                                        }
                                        {
                                            cwoman.authority_value === 'Hijau' ?
                                                <td style={{ backgroundColor: 'green', height: 50, border: '1px solid #eaeaea' }}></td> :
                                                cwoman.authority_value === 'Kuning' ?
                                                    <td style={{ backgroundColor: 'yellow', height: 50, border: '1px solid #eaeaea' }}></td> :
                                                    <td style={{ backgroundColor: 'red', height: 50, border: '1px solid #eaeaea' }}></td>
                                        }
                                        {
                                            cwoman.need_value === 'Hijau' ?
                                                <td style={{ backgroundColor: 'green', height: 50, border: '1px solid #eaeaea' }}></td> :
                                                cwoman.need_value === 'Kuning' ?
                                                    <td style={{ backgroundColor: 'yellow', height: 50, border: '1px solid #eaeaea' }}></td> :
                                                    <td style={{ backgroundColor: 'red', height: 50, border: '1px solid #eaeaea' }}></td>
                                        }
                                    </tr>
                                    <tr>
                                        <td style={{ border: '1px solid #eaeaea' }}>
                                            {cwoman.remark_champion ?? "-"}
                                        </td>
                                        <td style={{ border: '1px solid #eaeaea' }}>
                                            {cwoman.remark_when ?? "-"}
                                        </td>
                                        <td style={{ border: '1px solid #eaeaea' }}>
                                            {cwoman.remark_obstacle ?? "-"}
                                        </td>
                                        <td style={{ border: '1px solid #eaeaea' }}>
                                            {cwoman.remark_money ?? "-"}
                                        </td>
                                        <td style={{ border: '1px solid #eaeaea' }}>
                                            {cwoman.remark_authority ?? "-"}
                                        </td>
                                        <td style={{ border: '1px solid #eaeaea' }}>
                                            {cwoman.remark_need ?? "-"}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            :
                            <p>
                                CWOMAN Not Updated
                            </p>
                    }
                </Card>
            </Card>

        </Card>
    )
}

export default Detail