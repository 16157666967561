import React, {useEffect, useState} from 'react';
import {Card, Typography, Form, Input, message, Space, Row, Col} from 'antd'
import {AddButton, EditButton, DeleteButton, PageTitle, Spinner, FormModal, DataTable} from '../../../components'
import {SentralModel} from '../../../models/SentralModel'

const {Text} = Typography
const Index = () => {
    const [form] = Form.useForm()
    const [companies, setCompanies] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false)
    const [saving, setSaving] = useState(false)
    const [modalTitle, setModalTitle]= useState(null)
    const fetchCompanies = () =>{
        SentralModel.list('Company', {filter:[["is_main", "!=", "1"]]}).then(res=>{
            setLoaded(false)
            setCompanies(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchCompanies()
    }, []);
    
    const dataSource = [
        ...companies.map( el => {
            return {
                id: el.company_id,
                company_name: el.company_name,
                email: el.email,
                phone_no: el.phone_no,
                fax_no: el.fax_no,
                address: el.address,
                website: el.website,
                business_type: el.business_type,
                npwp_no: el.npwp_no
            }
        })
    ];

    const columns = [{
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'name',
        },
        {
          title: 'Company Name',
          dataIndex: 'company_name',
          key: 'company_name',
          width: '20%'
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
          width: '20%'
        },
        {
          title: 'Phone Number',
          dataIndex: 'phone_no',
          key: 'phone_no',
          width: '15%'
        },
        {
          title: 'Address',
          dataIndex: 'address',
          key: 'address',
          width: '45%'
        },
        {
            title: 'Action',
            width:120,
            render:(row) =>(
                <div className="text-center">
                    <Space>
                    <EditButton onEdit={() => editCompanies(row)}/>
                    <DeleteButton onConfirm={() => deleteCompanies(row.id)}/>
                    </Space>
                </div>
            )
        },
    ];

    const addCompany=()=>{
        setModalTitle('Add Company')
        form.resetFields()
        form.setFieldsValue({company_id: null})
        setFormModal(true)
    }

    const editCompanies = (v) =>{
        setModalTitle('Edit Companies')
        form.setFieldsValue({
            id: v.id,
            company_id: v.id,
            company_name: v.company_name,
            email: v.email,
            phone_no: v.phone_no,
            fax_no: v.fax_no,
            address: v.address,
            website: v.website,
            business_type: v.business_type,
            npwp_no: v.npwp_no
        })
        setFormModal(true)
    }

    const deleteCompanies = (id) =>{
        message.loading('deleting company')
        SentralModel.deleteById('Company', id).then((res)=>{
            message.destroy()
            message.success('Companies deleted', 2)
            let tmp = companies
            let index = tmp.findIndex(el => el.company_id===id)
            tmp.splice(index, 1);
            setCompanies([])
            setCompanies(tmp)
        })
    }

    const saveCompany = (v) => {
        setSaving(true)
        let payload={
            company_id: v.company_id,
            company_name: v.company_name,
            email: v.email,
            phone_no: v.phone_no,
            fax_no: v.fax_no,
            address: v.address,
            website: v.website,
            business_type: v.business_type,
            npwp_no: v.npwp_no,
            is_main: '0'
        }
        SentralModel.save('Company', payload, (modalTitle==='Edit Companies') ? form.getFieldValue('id') : 0).then((res)=>{
            setFormModal(false)
            fetchCompanies()
            message.success('Company saved', 2);
        }).finally(() => {
            setSaving(false)
        })
    }

    const onChangeNpwp = e => {
        const {value} = e.target;
        if (isNaN(value)) {
            form.setFieldsValue({npwp_no: value.replace(/[^0-9-.]+/g,"")})
        }
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Companies" breadcrumbs={[['Company']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>Company List</Text>
                        <AddButton right onAdd={addCompany} title="New Company"/>
                    </div> }>

                    {
                        (loaded) ?
                        <DataTable size='small' bordered dataSource={dataSource} columns={columns}/>
                        :
                        <Spinner/>
                    }
                </Card>
                {
                    (formModal) ?
                    <FormModal title={modalTitle} form={form} submitForm={(v)=>saveCompany(v)} width={800} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                        <Row>
                            <Col span={12} style={{paddingLeft: 5, paddingRight: 5}}>
                                <Form.Item name="company_id" label="Company ID" rules={[{required:true}]}>
                                    <Input placeholder="Company ID" />
                                </Form.Item>
                            </Col>
                            <Col span={12} style={{paddingLeft: 5, paddingRight: 5}}>
                                <Form.Item label="Company Name" name="company_name" rules={[{required:true}]}>
                                    <Input placeholder="Company Name" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12} style={{paddingLeft: 5, paddingRight: 5}}>
                                <Form.Item label="Email" name="email" rules={[{required:true}]}>
                                    <Input placeholder="Email" />
                                </Form.Item>
                            </Col>
                            <Col span={12} style={{paddingLeft: 5, paddingRight: 5}}>
                                <Form.Item label="Phone Number" name="phone_no" rules={[{required:true}]}>
                                    <Input placeholder="Phone Number" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12} style={{paddingLeft: 5, paddingRight: 5}}>
                                <Form.Item label="Fax Number" name="fax_no" rules={[{required:false}]}>
                                    <Input placeholder="Fax Number" />
                                </Form.Item>
                            </Col>
                            <Col span={12} style={{paddingLeft: 5, paddingRight: 5}}>
                                <Form.Item label="Business Type" name="business_type" rules={[{required:false}]}>
                                    <Input placeholder="Business Type" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12} style={{paddingLeft: 5, paddingRight: 5}}>
                                <Form.Item label="NPWP Number" name="npwp_no" rules={[{required:false}]}>
                                    <Input placeholder="NPWP Number" onChange={onChangeNpwp} maxLength={20}/>
                                </Form.Item>
                            </Col>
                            <Col span={12} style={{paddingLeft: 5, paddingRight: 5}}>
                                <Form.Item label="Website" name="website" rules={[{required:false}]}>
                                    <Input placeholder="Website" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12} style={{paddingLeft: 5, paddingRight: 5}}>
                                <Form.Item label="Address" name="address" rules={[{required:true}]}>
                                    <Input.TextArea placeholder="Address" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </FormModal> :
                    null
                }
            </Card>
        </div>
    );
}

export default Index;
