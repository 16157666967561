import { useEffect, useState } from 'react'
import { Card, Form, Input, Space, DatePicker, Typography, Progress, message, Tabs, Tag, Button, Row, Col } from 'antd'
import { PageTitle, FormModal, DataTable, EditButton, DeleteButton, AddButton, Spinner, ShowButton, Uploader } from '../../components'
import { SentralModel } from '../../models/SentralModel'
import { useHistory } from 'react-router-dom'
// import { Chart } from "react-google-charts";
import moment from 'moment'
import currentUser from '../../helpers/authData'

const { RangePicker } = DatePicker
const { Text } = Typography
const { TabPane } = Tabs

const ActionPlan = () => {
    const history = useHistory()
    const [loaded, setLoaded] = useState(false)
    const [saving, setSaving] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [actionPlanData, setActionPlanData] = useState([])
    const [attachmentFile, setAttachmentFile] = useState(null)
    const [uploading, setUploading] = useState(false)
    const [defaultValues, setDefauleValues] = useState({})
    const [selectedDate, setSelectedDate] = useState([null, null]);
    const [year, setYear] = useState(moment(Date.now()))
    const salesDivision = [22, 36]

    const fileUploaded = (v) => {
        setAttachmentFile(v.message)
    }

    const fetchActionPlan = (v) => {
        let payload = {
            employee_code: currentUser.employee_code,
            division_id: currentUser.division_id,
            year: moment(v).format('YYYY')
        }

        SentralModel.action('ActionPlan', 'getActionPlan', payload).then((res) => {
            const v = res.data
            setActionPlanData(res.data)
            setLoaded(true)

            let data = [
                [
                    { type: "string", id: "Name" },
                    { type: "string", id: "Name" },
                    { type: "date", id: "Start" },
                    { type: "date", id: "End" },
                ],
            ]

            v.map((el, key) => {
                return data.push([el.project_name, el.project_name, moment(el.start_date), moment(el.end_date)])
            })
        })
    }

    useEffect(() => {
        fetchActionPlan(year)

    }, [year])

    const add = () => {
        setShowModal(true)
        setDefauleValues({
            action_plan_id: 0,
            project_name: '',
            short_description: '',
        })
        setModalTitle('Add Action Plan')
    }

    const edit = (v) => {
        setShowModal(true)
        setDefauleValues({
            action_plan_id: v.action_plan_id,
            date: [moment(v.start_date), moment(v.end_date)],
            project_name: v.project_name,
            short_description: v.short_description,
        })
        setModalTitle('Edit Action Plan')
    }

    const columns = [
        {
            title: "No",
            dataIndex: "no",
            key: "no",
            width: "5%",
            align: "center",
        },
        {
            title: "Project Name",
            dataIndex: "project_name",
            key: "project_name",
            align: "center",
        },
        {
            title: "Description",
            dataIndex: "short_description",
            key: "short_description",
            align: "center",
        },
        {
            title: "Division",
            key: "division_name",
            render: (row) => row?.division.division_name ?? "-",
            align: "center",
        },
        {
            title: "Start Date",
            dataIndex: "start_date",
            key: "start_date",
            align: "center",
            render: (row) => row ? moment(row).format("DD MMMM YYYY") : '-',
        },
        {
            title: "End Date",
            dataIndex: "end_date",
            key: "end_date",
            align: "center",
            render: (row) => row ? moment(row).format("DD MMMM YYYY") : '-',
        },
        {
            title: "Realization Date",
            dataIndex: "realization_date",
            key: "realization_date",
            align: "center",
            render: (row) => row === null ? "-" : moment(row).format("DD MMMM YYYY"),
        },
        {
            title: "Progress",
            dataIndex: "progress",
            key: "progress",
            align: "center",
            render: (row) => (
                <Progress percent={Math.round(row)} />
            ),
        },
        {
            title: 'Created By',
            key: 'created_by',
            render: (row) => (
                <Tag>{row?.created_by?.name ?? '-'}</Tag>
            ),
            align: 'center'
        },
    ];

    if (salesDivision.includes(currentUser.division_id)) {
        columns.push(
            {
                title: 'Attachment',
                render: (row) => (
                    row.attachment === null ? '-' :
                        <Button onClick={() => window.open(`${process.env.REACT_APP_API_FILE}/${row.attachment}`)}>Preview</Button>
                ),
                key: 'attachment',
                align: 'center',

            },
            {
                title: "Action",
                width: 120,
                render: (row) => (
                    <div className="text-center">
                        <Space>
                            <ShowButton onShow={() => history.push(`/action-plan/${row.action_plan_id}`)} />
                            {
                                salesDivision.includes(currentUser.division_id) ?
                                    row.created_by?.employee_code === currentUser.employee_code ?
                                        <>
                                            <EditButton onEdit={() => edit(row)} />
                                            <DeleteButton onConfirm={() => onDeleted(row.action_plan_id)} />
                                        </>
                                        :
                                        <>
                                            <EditButton disabled={true} onEdit={() => edit(row)} />
                                            <DeleteButton disabled={true} onConfirm={() => onDeleted(row.action_plan_id)} />
                                        </>
                                    :
                                    <>
                                        <EditButton onEdit={() => edit(row)} />
                                        <DeleteButton onConfirm={() => onDeleted(row.action_plan_id)} />
                                    </>
                            }
                        </Space>
                    </div>
                ),
                align: "center",
            },)
    } else {
        columns.push(
            {
                title: "Action",
                width: 120,
                render: (row) => (
                    <div className="text-center">
                        <Space>
                            <ShowButton onShow={() => history.push(`/action-plan/${row.action_plan_id}`)} />
                            {
                                salesDivision.includes(currentUser.division_id) ?
                                    row.created_by?.employee_code === currentUser.employee_code ?
                                        <>
                                            <EditButton onEdit={() => edit(row)} />
                                            <DeleteButton onConfirm={() => onDeleted(row.action_plan_id)} />
                                        </>
                                        :
                                        <>
                                            <EditButton disabled={true} onEdit={() => edit(row)} />
                                            <DeleteButton disabled={true} onConfirm={() => onDeleted(row.action_plan_id)} />
                                        </>
                                    :
                                    <>
                                        <EditButton onEdit={() => edit(row)} />
                                        <DeleteButton onConfirm={() => onDeleted(row.action_plan_id)} />
                                    </>
                            }
                        </Space>
                    </div>
                ),
                align: "center",
            },
        )
    }

    const onFinish = (v) => {
        setSaving(true)
        let payload = {
            project_name: v.project_name,
            short_description: v.short_description,
            year: moment(v.year).format('YYYY'),
            start_date: moment(selectedDate[0]).format("YYYY-MM-DD, HH:mm:ss"),
            end_date: moment(selectedDate[1]).format("YYYY-MM-DD, HH:mm:ss"),
            division_id: currentUser.division_id,
            realization_date: null,
            attachment: attachmentFile !== null ? attachmentFile : null
        };

        SentralModel.action('ActionPlan', 'saveActionPlan', payload, v.action_plan_id).then((res) => {
            message.success(v.action_plan_id === 0 ? "Success Add Action Plan" : "Success Update Action Plan")
            fetchActionPlan()
            setShowModal(false)
            setSaving(false)
        }).catch((err) => {
            message.warning(v.action_plan_id === 0 ? "Failed Add Acton Plan" : "Failed Update Acton Plan")
            setShowModal(false)
            setSaving(false)
        })
    }

    const onDeleted = (v) => {
        SentralModel.deleteById("ActionPlan", v).then((res) => {
            message.success("Success Deleted Data")
            fetchActionPlan()
        }).catch((err) => {
            message.warning("Failed Delete Data")
        })
    }

    const onChangeYear = (v) => {
        fetchActionPlan(v)
        setYear(v)
    }

    return (
        <Card className="content-container">
            <PageTitle title="Action Plan" breadcrumbs={[['Action Plan']]}></PageTitle>
            <Tabs defaultActiveKey='list-data' type="card">
                <TabPane tab="List Data" key="list-daa">
                    <Card title={
                        <Row justify='space-between'>
                            <Col>
                                <Text strong>Action Plan</Text>
                            </Col>
                            <Col>
                                <Space>
                                    <AddButton right onAdd={add} />
                                    <DatePicker onChange={onChangeYear} value={year} picker="year" />
                                </Space>
                            </Col>
                        </Row>
                    }>
                        {
                            loaded ?
                                <DataTable columns={columns} dataSource={actionPlanData} />
                                :
                                <Spinner />
                        }

                    </Card>
                </TabPane>
                {/* <TabPane tab="Timeline" key="timeline">
                    <Card>
                        {
                            loadedChart ?
                                <Chart chartType="Timeline" data={timelineData} width="100%" height="400px" />
                                : null
                        }
                    </Card>
                </TabPane> */}
            </Tabs>

            {showModal ? (
                <FormModal
                    loading={saving || uploading}
                    defaultValues={defaultValues}
                    title={modalTitle}
                    submitForm={(v) => onFinish(v)}
                    width={700}
                    showModal={showModal}
                    onCloseModal={() => setShowModal(false)}
                >
                    <Form.Item name="action_plan_id" hidden>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Timeline"
                        name="date"
                        required
                        rules={[{ required: true }]}
                    >
                        <RangePicker onChange={(v) => setSelectedDate(v)} />
                    </Form.Item>
                    <Form.Item
                        label="Project Name"
                        name="project_name"
                        required
                        rules={[{ required: true }]}
                    >
                        <Input placeholder="Project Name" />
                    </Form.Item>
                    <Form.Item
                        label="Year"
                        name="year"
                        required
                        rules={[{ required: true }]}
                    >
                        <DatePicker picker="year" style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        label="Short Description"
                        name="short_description"
                        required
                        rules={[{ required: true }]}
                    >
                        <Input.TextArea rows={3} placeholder="Short Description" />
                    </Form.Item>
                    {
                        salesDivision.includes(currentUser.division_id) ?
                            <Form.Item label="Attachment">
                                <Uploader folder="action-plan-attachment" onUploaded={(v) => fileUploaded(v)} isUploading={(v) => setUploading(v)} view={true} />
                            </Form.Item>
                            : null
                    }
                </FormModal>
            ) : null}
        </Card>
    )
}

export default ActionPlan