import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Typography } from 'antd'
import { ImageViewer, PageTitle, Spinner } from '../../../components'
import ApprovalLog from '../ApprovalLog'
import DetailHeader from '../DetailHeader'
import { useParams } from 'react-router-dom';
import { SentralModel } from '../../../models/SentralModel';

const { Text } = Typography

const Detail = () => {
    const { requestId } = useParams()
    const [attendance, setAttendance] = useState(null)

    const fetchAttendance = (id) => {
        SentralModel.get('EmployeeAttendanceRequest', {}, id).then((res) => {
            setAttendance(res.data)
            // console.log(res.data)
        })
    }

    useEffect(() => {
        fetchAttendance(requestId)
    }, [requestId]);

    const statusUpdated = () => {
        setAttendance(null)
        fetchAttendance(requestId)
        window.location.reload();
    }

    return (
        <Card className="content-container">
            <div>
                <PageTitle title="Attendance Request" breadcrumbs={[['Submission List', '/submission-list'], ['Detail']]}></PageTitle>
            </div>
            {
                (attendance) ?
                    <div>
                        <DetailHeader
                            name={attendance.employee.name}
                            date={attendance.created_at}
                            status={attendance.status} />
                        <Card bordered className="mb-4">
                            <Text strong>ATTENDANCE DETAIL</Text>
                            <Row className="mb-4 mt-1">
                                <Col span={12}>
                                    <table className="table-collapse table-default" style={{ width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <th>Name</th><td>{attendance.employee.name}</td>
                                            </tr>
                                            <tr>
                                                <th>Date</th><td>{attendance.attendance_date}</td>
                                            </tr>
                                            <tr>
                                                <th>Time</th><td>{attendance.attendance_time}</td>
                                            </tr>
                                            <tr>
                                                <th>Type</th><td>{attendance.information === "WFO" ? "WFO" : "Manual"}</td>
                                            </tr>
                                            {
                                                attendance.information === "WFO" ?
                                                <>
                                                    <tr>
                                                        <th>Latitude</th><td>{attendance.latitude}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Longitude</th><td>{attendance.longitude}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Location</th><td>{attendance.location}</td>
                                                    </tr>
                                                    {
                                                        attendance.photo != null ?
                                                            <tr>
                                                                <th>Attachment</th>
                                                                <td>
                                                                    <ImageViewer style={{ width: '100%' }} src={process.env.REACT_APP_API_FILE + '/' + attendance.photo} alt="photo" />
                                                                </td>
                                                            </tr>
                                                        : null
                                                    }
                                                    
                                                </> 
                                                : null
                                            }
                                            <tr>
                                                <th>Remarks</th><td>{attendance.remarks}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </Card>
                        <Card bordered>
                            <ApprovalLog category="ATTENDANCE"
                                requestId={requestId}
                                approvers={attendance.approver}
                                onSubmitted={() => statusUpdated()} />
                        </Card>
                    </div> : <Spinner />
            }
        </Card>
    );
}

export default Detail;
