import React, { useState, useEffect } from 'react';
import { Card, Typography, Button, Row, Col, Select, Form, Input, message, Tag, Space } from 'antd'
import { PageTitle, DataTable, Spinner, ShowButton, FormModal, EditButton } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'

const { Text } = Typography
const { Option } = Select

const Index = () => {
    const [form] = Form.useForm()
    const [employees, setEmployees] = useState([])
    const [rewardPunishmentData, setRewardPunishmentData] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [category, setCategory] = useState('')

    const fetchEmployees = () => {
        SentralModel.list('Employee', { orderBy: "name", order: "asc" }).then((res) => {
            let emp = []
            res.data.forEach((el) => {
                let obj = { label: el.name, value: el.employee_code }
                emp.push(obj)
            })
            setEmployees(emp)
        })
    }

    const fetchRewardPunishment = () => {
        SentralModel.action("EmployeeRewardPunishment", "getData", 0, 0).then((res) => {
            setRewardPunishmentData(res.data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchRewardPunishment()
        fetchEmployees()

    }, []);

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'name',
            width: '5%'
        },
        {
            title: 'To',
            render: (row) => (
                row?.to?.name
            ),
            key: 'to_employee',
        },
        {
            title: 'From',
            render: (row) => (
                row?.from?.name
            ),
            key: 'from_employee',
        },
        {
            title: 'Category',
            render: (row) => (
                <Tag color={row.category === "PUNISHMENT" ? "red" : "green"}>{row.category}</Tag>
            ),
            key: 'category',
            align: 'center',
            filters: [
                {
                    text: 'PUNISHMENT',
                    value: 'PUNISHMENT',
                },
                {
                    text: 'REWARD',
                    value: 'REWARD',
                },
            ],
            onFilter: (value, record) => record.category.indexOf(value) === 0,
        },
        {
            title: 'Sub Category',
            dataIndex: 'sub_category',
            key: 'sub_category',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: 'Action',
            render: (row) => (
                <Space>
                    <ShowButton onShow={() => console.log(row)} />
                    <EditButton onEdit={() => onEdit(row)} />
                </Space>
            ),
            align: 'center',
            width: '10%'
        }
    ];

    const onShowModal = () => {
        setIsEdit(false)
        setShowModal(true)
        setModalTitle("Send Reward/Punishment")
        form.setFieldsValue({
            emp_reward_punishment_id: 0,
            category: '',
            sub_category: '',
            description: ''
        })
    }

    const onEdit = (v) => {
        setIsEdit(true)
        setShowModal(true)
        setModalTitle("Edit Send Reward/Punishment")
        form.setFieldsValue({
            emp_reward_punishment_id: v.emp_reward_punishment_id,
            category: v.category,
            sub_category: v.sub_category,
            description: v.description
        })
    }

    const onFinish = (v) => {
        let payload = {
            ...v
        }

        SentralModel.action("EmployeeRewardPunishment", "saveData", payload, v.emp_reward_punishment_id).then((res) => {
            message.success("Success Send Reward/Punishment");
            fetchRewardPunishment()
            setShowModal(false)
        })
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Reward & Punishment" breadcrumbs={[['Reward & Punishment']]}></PageTitle>
                <Card title={
                    <Row justify='space-between'>
                        <Col>
                            <Text>My Reward & Punishment</Text>
                        </Col>
                        <Col>
                            <Button type='primary' onClick={onShowModal}>Send Reward/Punishment</Button>
                        </Col>
                    </Row>
                }>
                    {
                        (loaded) ?
                            <DataTable size="small" columns={columns} dataSource={rewardPunishmentData} bordered />
                            : <Spinner />
                    }
                </Card>
            </Card>

            {
                showModal ?
                    <FormModal form={form} title={modalTitle} showModal={showModal} onCloseModal={() => setShowModal(false)} width={600} submitForm={(v) => onFinish(v)}>
                        <Form.Item name="emp_reward_punishment_id" hidden>
                            <Input />
                        </Form.Item>
                        {
                            !isEdit ?
                                <Form.Item label="Employee" name="employees" required rules={[{ required: true }]}>
                                    <Select
                                        mode="multiple"
                                        placeholder="Select Employee"
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        options={employees}
                                    />
                                </Form.Item>
                                : null
                        }
                        <Form.Item label="Category" name="category" required rules={[{ required: true }]}>
                            <Select placeholder="Select Category" onChange={(v) => setCategory(v)}>
                                <Option value="REWARD">REWARD</Option>
                                <Option value="PUNISHMENT">PUNISHMENT</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label="Sub Category" name="sub_category" required rules={[{ required: true }]}>
                            {
                                category === '' ?
                                    <Select placeholder="Select Sub Category">
                                    </Select> :
                                    category === 'PUNISHMENT' ?
                                        <Select placeholder="Select Sub Category">
                                            <Option value="COMPLAIN">COMPLAIN</Option>
                                        </Select>
                                        :
                                        <Select placeholder="Select Sub Category">
                                            <Option value="COMPLIMENT">COMPLIMENT</Option>
                                        </Select>
                            }
                        </Form.Item>
                        <Form.Item label="Description" name="description" required rules={[{ required: true }]}>
                            <Input.TextArea rows={4} placeholder='Description...' />
                        </Form.Item>
                    </FormModal>
                    : null
            }
        </div>
    );
}

export default Index;
