import React from 'react'
import { FormModal } from '../../../../../../components'
import { useDispatch, useSelector } from 'react-redux'
import { changeAddDailyModal, fetchDailyActivities, saveDailyActivity } from '../../../../../../redux/slices/Dashboard/myDailyActivitySlice'
import { Form, Input } from 'antd'

const ModalAddDaily = () => {
    const dispatch = useDispatch()
    const showModalAddDailyActivity = useSelector((state) => state.myDailyActivity.showModalAddDailyActivity)
    const titleAddDailyActivity = useSelector((state) => state.myDailyActivity.titleAddDailyActivity)

    const onFinish = (v) => {
        let payload = {
            ...v,
            data_from: "DAILY ACTIVITY",
            status: "In Progress"
        }

        dispatch(saveDailyActivity(payload)).then(() => {
            dispatch(fetchDailyActivities({ start_date: null, end_date: null }))
        })
    }

    return (
        <FormModal title={titleAddDailyActivity} showModal={showModalAddDailyActivity} onCloseModal={() => dispatch(changeAddDailyModal(false))} width={700} submitForm={(v) => onFinish(v)}>
            <Form.Item label="Description" name="description" rules={[{ required: true }]}>
                <Input.TextArea rows={3} />
            </Form.Item>
        </FormModal>
    )
}

export default ModalAddDaily