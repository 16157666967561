import { Card, DatePicker, Form, Input, message, Space, Tag, Typography } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AddButton, DataTable, DeleteButton, EditButton, FormModal, ShowButton } from '../../../components';
import { SentralModel } from '../../../models/SentralModel';

const { Text } = Typography

const Milestones = () => {
    const history = useHistory()
    const { actionPlanId } = useParams()
    const [loaded, setLoaded] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [saving, setSaving] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [defaultValues, setDefaultValues] = useState({})
    const [milestone, setMilestone] = useState([])

    const fetchMilestone = () => {
        SentralModel.action("Milestone", `getMilestone`, 0, actionPlanId).then((res) => {
            setMilestone(res.data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchMilestone()

        // eslint-disable-next-line
    }, []);


    const columns = [
        {
            title: "Due Date",
            key: "due_date",
            align: "center",
            render: (row) => (
                <div style={{ border: '1px solid #dfe6e9' }}>
                    <div style={{ height: '30px', backgroundColor: '#0A98B4', width: '100%' }}>
                        <h3 style={{ color: 'white' }}>{moment(row.due_date).format('MMMM')}</h3>
                    </div>
                    <h1 style={{ fontSize: 30, margin: 0, color: 'grey' }}>{moment(row.due_date).format('DD')}</h1>
                    <h4 style={{ fontSize: 16, color: 'grey' }}>{moment(row.due_date).format('dddd')}</h4>
                </div>
            ),
            width: '8%'
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            width: '50%'
        },
        {
            title: "Progress",
            dataIndex: "progress",
            key: "progress",
            align: "center",
            render: (row) => (
                parseInt(row) !== 100 ? <Tag color="#108ee9">In Progress</Tag> : <Tag color="#87d068">Complete</Tag>
            ),
            width: '25%'
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            align: "center",
            render: (row) => (
                row === null ? '-' : row === "On Time" ? <Tag color='#87d068'>{row}</Tag> : <Tag color='#f50'>{row}</Tag>
            )
        },
        {
            title: "Action",
            key: "action",
            align: "center",
            render: (row) => (
                <Space>
                    <EditButton onEdit={() => editData(row)} />
                    <ShowButton onShow={() => history.push(`${actionPlanId}/milestone/${row.milestone_id}`)} />
                    <DeleteButton onConfirm={() => onDelete(row.milestone_id)} />
                </Space>
            ),
            width: '5%'
        }
    ];

    const add = () => {
        setShowModal(true)
        setDefaultValues({
            milestone_id: 0,
            name: '',
            due_date: ''
        })
        setModalTitle('Add Milestone')
    }

    const editData = (v) => {
        setShowModal(true)
        setDefaultValues({
            milestone_id: v.milestone_id,
            name: v.name,
            due_date: moment(v.due_date)
        })
        setModalTitle('Edit Milestone')
    }

    const onDelete = (v) => {
        SentralModel.deleteById("Milestone", v).then((res) => {
            message.success("Success Deleted Data")
            fetchMilestone()
        }).catch((err) => {
            message.warning("Failed Delete Data")
        })
    }

    const onFinish = (v) => {
        setSaving(true)
        let payload = {
            action_plan_id: actionPlanId,
            name: v.name,
            due_date: v.due_date,
        }
        SentralModel.action('Milestone', 'saveMilestone', payload, v.milestone_id).then((res) => {
            message.success('Success')
            setSaving(false)
            fetchMilestone()
            setShowModal(false)
        }).catch((err) => {
            message.warning('Failed')
            setSaving(false)
            setShowModal(false)
        })
    }

    return (
        <Card title={
            <div className="full-width">
                <Text strong style={{ float: 'left' }}>Milestone</Text>
                <AddButton right onAdd={add} />
            </div>
        }>
            {
                loaded ?
                    <DataTable columns={columns} dataSource={milestone} />
                    : null
            }

            {
                showModal ?
                    <FormModal
                        loading={saving}
                        defaultValues={defaultValues}
                        title={modalTitle}
                        submitForm={(v) => onFinish(v)}
                        width={600}
                        showModal={showModal}
                        onCloseModal={() => setShowModal(false)}
                    >
                        <Form.Item name="milestone_id" hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Name"
                            name="name"
                            required
                            rules={[{ required: true }]}
                        >
                            <Input placeholder="Name" />
                        </Form.Item>
                        <Form.Item
                            label="Due Date"
                            name="due_date"
                            required
                            rules={[{ required: true }]}
                        >
                            <DatePicker style={{ width: '100%' }} />
                        </Form.Item>
                    </FormModal>
                    : null
            }
        </Card>
    )
}

export default Milestones