import React, { useState, useEffect } from 'react';
import { Calendar, Tag, Typography } from 'antd';
import { BasicModal } from '../../components';
import { SentralModel } from '../../models/SentralModel';
import moment from 'moment';

const { Title, Text } = Typography

const MeetingSchedule = () => {
    const [showModal, setShowModal] = useState(false)
    const [modalTask, setModalTask] = useState(false)
    const [events, setEvents] = useState([])
    const [tasks, setTasks] = useState([])
    const [value, setValue] = useState(moment())
    const [selectedDateEvents, setSelectedDateEvents] = useState([])
    const [selectedDateTask, setSelectedDateTasks] = useState([])

    const fetchImportantDate = () => {
        SentralModel.action('ImportantDate', 'getImportantDate', {}, 0).then((res) => {
            setEvents(res.data);
        })
    }

    const fetchPendingTask = () => {
        SentralModel.action('DailyActivity', 'pendingTask', {}, 0).then((res) => {
            setTasks(res.data)
        })
    }

    useEffect(() => {
        fetchPendingTask()
        fetchImportantDate()

    }, []);

    const getEvent = (date) => {
        let x = [];
        events.forEach((el, key) => {
            if (el.repeat_yearly) {
                if (el.date.substring(5, 10) === date.substring(5, 10)) {
                    let color = "red";
                    if (el.category === 'OFFICE EVENT') {
                        color = "green"
                    }
                    if (el.category === 'OTHER') {
                        color = "cyan"
                    }
                    x.push(<Tag key={'event_' + Math.random()} onClick={() => showDateDetail(date)} style={{ cursor: 'pointer' }} color={color}>{el.description}</Tag>)
                }
            }
            if (!el.repeat_yearly && el.date === date) {
                let color = "red";
                if (el.category === 'OFFICE EVENT') {
                    color = "green"
                }
                if (el.category === 'OTHER') {
                    color = "cyan"
                }
                x.push(<Tag key={'event_' + Math.random()} onClick={() => showDateDetail(date)} style={{ cursor: 'pointer' }} color={color}>{el.description}</Tag>)
            }
        })
        tasks.forEach((el, key) => {
            if (moment(el.start_date).format("YYYY-MM-DD") === date) {
                x.push(<Tag key={'event_' + Math.random()} onClick={() => showDetailPendingTask(date)} style={{ cursor: 'pointer' }} color="red">TASK : {el.description}</Tag>)
            }
        })
        return x
    }

    function dateCellRender(value) {
        return (
            getEvent(moment(value).format('YYYY-MM-DD'))
        )
    }

    const showDateDetail = (v) => {
        setValue(moment(v))
        let x = []
        events.forEach((el) => {
            if (el.repeat_yearly) {
                if (el.date.substring(5, 10) === v.substring(5, 10)) {
                    x.push(el)
                }
            }
            if (!el.repeat_yearly && el.date === moment(v).format('YYYY-MM-DD')) {
                x.push(el)
            }
        })
        tasks.forEach((el, key) => {
            if (moment(el.start_date).format("YYYY-MM-DD") === moment(v).format('YYYY-MM-DD')) {
                x.push(el)
            }
        })
        setSelectedDateEvents(x)
        setShowModal(true)
    }

    const showDetailPendingTask = (v) => {
        setValue(moment(v))
        let x = []
        tasks.forEach((el, key) => {
            if (moment(el.start_date).format("YYYY-MM-DD") === moment(v).format('YYYY-MM-DD')) {
                x.push(el)
            }
        })
        setSelectedDateTasks(x)
        setModalTask(true)
    }

    const changePanel = (v) => {
        setValue(v)
    }

    return (
        <div style={{ border: '1px solid #ededed', padding: 20, borderRadius: 10 }}>
            <Title level={3}>Office Calendar</Title>
            <Calendar value={value} dateCellRender={dateCellRender} onPanelChange={changePanel} />
            <BasicModal title={<Title style={{ marginBottom: 0 }} level={4}>{moment(value).format('LL')}</Title>} width={500} showModal={showModal} onCloseModal={() => setShowModal(false)}>
                {
                    selectedDateEvents.map((el, key) => (
                        <div key={key}>
                            <Text strong>{el.description}</Text><br />
                            <span className="text-muted">{el.category}</span>
                        </div>
                    ))
                }
                {
                    (selectedDateEvents.length < 1) ?
                        <div className="text-center text-muted">no event</div>
                        : null
                }
            </BasicModal>

            <BasicModal title={<Title style={{ marginBottom: 0 }} level={4}>{moment(value).format('LL')}</Title>} width={500} showModal={modalTask} onCloseModal={() => setModalTask(false)}>
                <ol>
                    {
                        selectedDateTask.map((el, key) => (
                            <li>
                                <div key={key}>
                                    <Text strong>{el.description}</Text> -
                                    <span className="text-muted">TASK</span>
                                </div>
                            </li>
                        ))
                    }
                </ol>
                {
                    (selectedDateTask.length < 1) ?
                        <div className="text-center text-muted">no task</div>
                        : null
                }
            </BasicModal>
        </div>
    );
}

export default MeetingSchedule;
