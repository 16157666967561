import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Input, Select, DatePicker, message } from 'antd';
import { SubmitButton, Uploader } from '../../../components'
import authData from '../../../helpers/authData'
import { SentralModel } from '../../../models/SentralModel'
import { ImageViewer } from '../../../components/ImageViewer';

const RequestForm = (props) => {
    const [form] = Form.useForm()
    const [attachmentFile, setAttachmentFile] = useState(null)
    const [uploading, setUploading] = useState(false)
    const [types, setTypes] = useState([])

    const fetchTypes = () => {
        SentralModel.list('GeneralDetail',{filter: [["general_code", "RT"]]}).then((res) => {
            setTypes(res.data);
        })
    }

    const fileUploaded = (v) => {
        setAttachmentFile(v.message)
    }

    const submitReport = (v) => {
        if (!attachmentFile) {
            message.error('Attachment is required', 2);
        } else {
            let data = {
                employee_code: authData.employee_code,
                title: v.title,
                type: v.type,
                submit_date: v.submit_date,
                description: v.description,
                attachment: attachmentFile,
            }
            props.onSubmit(data);
        }
    }

    useEffect(() => {
        fetchTypes();
    }, []);

    return (
        <Form size="middle" form={form} layout="vertical" onFinish={submitReport}>
            <Row justify='center'>
                <Col span={20}>
                    <Row>
                        <Col span={12} style={{ paddingRight: 10 }}>
                            <Form.Item name="title" label="Title" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12} style={{ paddingLeft: 10 }}>
                            <Form.Item name="type" label="Type" rules={[{required:true}]}>
                                <Select>
                                    {
                                        types.map((el, key) => (
                                            <Select.Option key={key} value={el.detail_code}>{el.description}</Select.Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col span={20}>
                    <Row>
                        <Col span={12} style={{ paddingRight: 10 }}>
                            <Form.Item name="submit_date" label="Submit Email Date" rules={[{ required: true }]}>
                                <DatePicker style={{width:'100%'}} />
                            </Form.Item>
                        </Col>
                        <Row span={12} style={{ paddingLeft: 10 }}>
                            <Form.Item label="Attachment (Screenshot Email)">
                                <Uploader folder="monthly-report" onUploaded={(v) => fileUploaded(v)} isUploading={(v) => setUploading(v)} view={true} />
                            </Form.Item>
                            <div style={{ width: 200, maxWidth: '100%', marginLeft: 20, marginTop: 25, border: attachmentFile !== null ? attachmentFile.includes('.pdf') ? '0px' : '1px solid #ededed' : '0px' }}>
                                {
                                    attachmentFile !== null ?
                                        attachmentFile.includes('.pdf') ?
                                            <a rel="noopener noreferrer" target='_blank' href={process.env.REACT_APP_API_FILE + '/' + attachmentFile}>
                                                Show Attachment
                                            </a> :
                                            <ImageViewer previewable={attachmentFile ? true : false} src={process.env.REACT_APP_API_FILE + "/" + attachmentFile} alt="attachment" /> :
                                        null
                                }
                            </div>
                        </Row>
                    </Row>
                </Col>
                <Col span={20}>
                    <Form.Item label="Description" name="description" rules={[{ required: true }]}>
                        <Input.TextArea rows={3} placeholder="Description" />
                    </Form.Item>
                </Col>
                <Col span={20} className="text-right">
                    <SubmitButton loading={props.saving || uploading} />
                </Col>
            </Row>
        </Form>
    );
}

export default RequestForm;
