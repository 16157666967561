import React, {useEffect, useState} from 'react';
import {Card, Typography, Form, Input, message, Space, Switch, Tag} from 'antd'
import {AddButton, EditButton, DeleteButton, PageTitle, Spinner, FormModal, DataTable} from '../../../../components'
import {SentralModel} from '../../../../models/SentralModel'

const {Text} = Typography
const Index = () => {
    const [assetCategories, setAssetCategories] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [saving, setSaving] = useState(false)
    const [modalTitle, setModalTitle]= useState(null)
    const [status, setStatus] = useState(false)
    
    const fetchAssetCategories = () =>{
        SentralModel.list('AssetCategory').then(res=>{
            setAssetCategories(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchAssetCategories()
    }, []);
    
    const dataSource = [
        ...assetCategories.map( el => {
            return {
                id: el.asset_category_id,
                code: el.code,
                name: el.name,
                start_number: el.start_number,
                next_number: el.next_number,
                is_active: el.is_active
            }
        })
    ];

    const columns = [{
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'no',
        },
        {
          title: 'Code',
          dataIndex: 'code',
          key: 'code',
        },
        {
          title: 'Category Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Start Number',
          dataIndex: 'start_number',
          key: 'start_number',
        },
        {
            title: 'Next Number',
            dataIndex: 'next_number',
            key: 'next_number',
        },
        {
          title: 'Status',
          dataIndex: 'is_active',
          align:'center',
          key: 'is_active',
          width: '20%',
          render: (text) => (
            <Tag color={text === 1 ? "green" : "red"}>{(text === 1) ? 'Active' : 'Inactive'}</Tag>
          )
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => editCategory(row)}/>
                        <DeleteButton onConfirm={() => deleteCategory(row.id)}/>
                    </Space>
                </div>
            )
        },
    ];

    const addCategory=()=>{
        setModalTitle('Add Category')
        setDefaultValues({
            asset_category_id: 0,
            code: '',
            name: '',
            start_number: '',
            next_number: '',
            is_active: true
        })
        setStatus(true)
        setFormModal(true)
    }

    const editCategory = (v) =>{
        setModalTitle('Edit Category')
        setDefaultValues({
            asset_category_id: v.id,
            code: v.code,
            name: v.name,
            start_number: v.start_number,
            next_number: v.next_number,
            is_active: parseInt(v.is_active)
        })
        setStatus(v.is_active)
        setFormModal(true)
    }

    const deleteCategory = (id) =>{
        message.loading('deleting category')
        SentralModel.deleteById('AssetCategory', id).then((res)=>{
            message.destroy()
            message.success('Category deleted', 2)
            let tmp = assetCategories
            let index = tmp.findIndex(el => el.asset_category_id===id)
            tmp.splice(index, 1);
            setAssetCategories([])
            setAssetCategories(tmp)
        })
    }

    const saveCategory = (v) => {
        setSaving(true)
        let payload={
            asset_category_id: v.id,
            code: v.code,
            name: v.name,
            start_number: v.start_number,
            next_number: v.next_number,
            is_active: status ? 1 : 0
        }
        SentralModel.save('AssetCategory', payload, v.asset_category_id).then((res)=>{
            setFormModal(false)
            fetchAssetCategories()
            message.success('Category saved',2);
        }).finally(() => {
            setSaving(false)
        })
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Categories" breadcrumbs={[['Asset'],['Category']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>Category List</Text>
                        <AddButton right onAdd={addCategory} title="New Category"/>
                    </div> }>

                    {
                        (loaded) ?
                        <DataTable size='small' bordered dataSource={dataSource} columns={columns}/>
                        :
                        <Spinner/>
                    }
                </Card>
                {
                    (formModal) ?
                    <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v)=>saveCategory(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                        <Form.Item name="asset_category_id" hidden>
                            <Input/>
                        </Form.Item>
                        <Form.Item label="Code" name="code" required rules={[{required:true}]}>
                            <Input placeholder="Code" />
                        </Form.Item>
                        <Form.Item label="Start Number" name="start_number" required rules={[{required:true}]}>
                            <Input placeholder="Start Number" />
                        </Form.Item>
                        <Form.Item label="Next Number" name="next_number" required rules={[{required:true}]}>
                            <Input placeholder="Next Number" />
                        </Form.Item>
                        <Form.Item label="Category Name" name="name" required rules={[{required:true}]}>
                            <Input placeholder="Category Name" />
                        </Form.Item>
                        <Form.Item label="Status" name="is_active" rules={[{required:true}]}>
                            <Switch checkedChildren="Active" unCheckedChildren="Inactive" onChange={(v) => setStatus(v)} defaultChecked={defaultValues.is_active} />
                        </Form.Item>
                    </FormModal> :
                    null
                }
            </Card>
        </div>
    );
}

export default Index;
