import React, {useState, useEffect} from 'react'
import {Row, Card, Input} from 'antd'
import CompanyItem from './CompanyItem';
import {PageTitle, Spinner, EditButton, ImageViewer, BasicModal, Uploader} from '../../../components'
import {SentralModel} from '../../../models/SentralModel'

const CompanyInformation = () => {
    const [companies, setCompanies] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [showEditNpwp, setShowEditNpwp] = useState(false)
    const [valueNpwp, setValueNpwp] = useState(null)
    const [showEditCompanyLogo, setShowEditCompanyLogo] = useState(false)
    const [uploadingCompanyLogo, setUploadingCompanyLogo] = useState(false)

    const fetchCompanies = () =>{
        SentralModel.list('Company', {filter:[["is_main", "1"]]}).then(res=>{
            setCompanies(res.data[0])
        }).finally(()=>{
            setLoaded(true)
        })
    }

    const updateCompany = (value) => {
        setCompanies(value)
    }

    useEffect(() => {
        fetchCompanies()
    }, []);

    const updateCompanyInformation = (value, field) =>{
        let payload= {};
        payload[field]=value
        if (companies == null) {
            payload['is_main'] = '1'
        }
        SentralModel.save('Company', payload, companies == null ? 0 : companies.company_id).then((res)=>{
            let company = companies == null ? {} : companies
            company[field] = value
            updateCompany(company)
            fetchCompanies()
        })
    }

    const editNpwp = (v) =>{
        setShowEditNpwp(true)
        setValueNpwp(companies.npwp_no)
    }

    const onChangeNpwp = e => {
        const {value} = e.target;
        if (isNaN(value)) {
            setValueNpwp(value.replace(/[^0-9-.]+/g,""))
        } else {
            setValueNpwp(value)
        }
    }

    const submitNpwp = (v) => {
        setShowEditNpwp(false)
        let company = companies
        company.npwp_no = v.target.value
        updateCompanyInformation(v.target.value, 'npwp_no')
    }


    const companyLogoUploaded = (v) =>{
        if(uploadingCompanyLogo){
            setUploadingCompanyLogo(false)
        }
        let payload= {
            logo: v.message
        }

        SentralModel.save('Company', payload, companies == null ? 0 : companies.company_id).then((res)=>{
            fetchCompanies()
        })
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Company Information" breadcrumbs={[['Company Information']]}></PageTitle>
                {
                    (loaded) ?
                    <div>
                        <Row>
                            <CompanyItem onEdited={(value) => updateCompanyInformation(value, 'company_name')} title="Name">{companies == null ? '' : companies.company_name}</CompanyItem>
                            <CompanyItem onEdited={(value) => updateCompanyInformation(value, 'email')} title="Email">{companies == null ? '' : companies.email}</CompanyItem>
                            <CompanyItem onEdited={(value) => updateCompanyInformation(value, 'phone_no')} title="Phone Number">{companies == null ? '' : companies.phone_no}</CompanyItem>
                            <CompanyItem onEdited={(value) => updateCompanyInformation(value, 'fax_no')} title="Fax Number">{companies == null ? '' : companies.fax_no}</CompanyItem>
                            <CompanyItem onEdited={(value) => updateCompanyInformation(value, 'website')} title="Website">{companies == null ? '' : companies.website}</CompanyItem>
                            <CompanyItem onEdited={(value) => updateCompanyInformation(value, 'business_type')} title="Business Type">{companies == null ? '' : companies.business_type}</CompanyItem>
                            <CompanyItem title="NPWP Number">
                            {
                                (!showEditNpwp && (companies == null ? '' : companies.npwp_no)) ? (companies == null ? '' : companies.npwp_no) : null
                            }
                            {
                                (showEditNpwp) ? 
                                <Input value={valueNpwp} autoFocus onChange={onChangeNpwp} onBlur={submitNpwp} onPressEnter={submitNpwp} maxLength={20}/>
                                : <EditButton onEdit={() => editNpwp()}/>
                            }
                            </CompanyItem>
                            <CompanyItem onEdited={(value) => updateCompanyInformation(value, 'address')} title="Address">{companies == null ? '' : companies.address}</CompanyItem>
                        </Row>
                        <Row>
                            <CompanyItem title="Logo">
                                <div style={{width:300, border:'1px solid #ededed'}}>
                                    <ImageViewer onEdit={() => setShowEditCompanyLogo(true)} style={{width:'50%'}} previewable src={companies == null ? '' : process.env.REACT_APP_API_FILE +'/'+ companies.logo}/>
                                </div>
                            </CompanyItem>
                        </Row>

                        <BasicModal title="Change Company Logo" showModal={showEditCompanyLogo} onCloseModal={()=>setShowEditCompanyLogo(false)}>
                            {
                                (showEditCompanyLogo) ? 
                                    <Uploader dragger folder="logo" onUploaded={(v)=> companyLogoUploaded(v)} isUploading={(v)=> setUploadingCompanyLogo(v)}/>
                                : null
                            }
                        </BasicModal>
                    </div>
                    : <Spinner/>
                }
            </Card>
        </div>
    );
}

export default CompanyInformation;
