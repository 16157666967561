import React, {useState} from 'react';
import {Card, Form, Input, Typography, Space, message, InputNumber, Progress} from 'antd'
import {EditButton, DeleteButton, FormModal, AddButton, DataTable} from '../../../../components'
import {SentralModel} from '../../../../models/SentralModel'
import moment from 'moment'
import authData from '../../../../helpers/authData'

const {Text} = Typography
const Index = (props) => {
    const [taskForm] = Form.useForm()
    const [modalTaskTitle, setModalObjectiveTitle]= useState(null)
    const [formModalTask, setFormModalTask] = useState(false)
    const [saving, setSaving] = useState(false)

    const dataSourceSupport = [
        ...props.data.map( el => {
            return {
                id: el.support_task_id,
                employee_code: el.employee_code,
                employee_name: el.employee.name,
                title: el.title,
                description: el.description,
                progress: el.progress,
                status: el.status
            }
        })
    ];

    const columnSupport = [
        {
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'no',
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Submit Date',
            dataIndex: 'submit_date',
            key: 'submit_date',
            render: (text) => (
                <div className="text-center">
                    {moment(text).format('DD MMMM YYYY')}
                </div>
            )
        },
        {
            title: 'Progress',
            dataIndex: 'progress',
            key: 'progress',
            render: (text) => (
                <div style={{paddingRight: 7}}>
                    <Progress strokeColor={{from: '#108ee9', to: '#87d068'}} percent={text} size="small" status="active" />
                </div>
            )
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
    ];

    if (authData.employee_code === 'SMI20150601108') {
        columnSupport.push(
            {
                title: 'Handling By',
                dataIndex: 'employee_name',
                key: 'employee_name',
            },
            {
                title: 'Action',
                width:120,
                render:(row) =>(
                    <div className="text-center">
                        <Space>
                            <EditButton onEdit={() => editTask(row)} />
                            <DeleteButton onConfirm={() => deleteTask(row.id)} />
                        </Space>
                    </div>
                )
            }
        )
    } else {
        columnSupport.push(
            {
                title: 'Action',
                width:120,
                render:(row) =>(
                    <div className="text-center">
                        <Space>
                            <EditButton onEdit={() => editTask(row)} />
                            <DeleteButton onConfirm={() => deleteTask(row.id)} />
                        </Space>
                    </div>
                )
            }
        )
    }

    const addTask = () => {
        setModalObjectiveTitle('Add Support Task')
        taskForm.setFieldsValue({
            support_task_id: 0,
            employee_code: authData.employee_code,
            title: '',
            description: '',
            progress: 0
        })
        setFormModalTask(true)
    }

    const editTask = (v) => {
        setModalObjectiveTitle('Edit Support Task')
        taskForm.setFieldsValue({
            support_task_id: v.id,
            employee_code: v.employee_code,
            title: v.title,
            description: v.description,
            progress: v.progress,
        })
        setFormModalTask(true)
    }

    const saveTask = (v) => {
        setSaving(true)
        let payload = {
            employee_code: v.employee_code,
            title: v.title,
            description: v.description,
            progress: v.progress,
            status: v.progress === 100 ? 'Finish' : 'On Progress'
        }
        SentralModel.save('SupportTask', payload, v.support_task_id).then((res) => {
            setFormModalTask(false)
            props.onFetch();
            message.success('Support task saved',2);
        }).finally(() => {
            setSaving(false)
        })
    }

    const deleteTask = (id) =>{
        message.loading('Deleting support task')
        SentralModel.deleteById('SupportTask', id).then((res) => {
            message.destroy();
            message.success('Support task deleted', 2);
            props.onFetch();
        })
    }

    return (
        <div>
            <Card title={
                <div className="full-width">
                    <Text strong style={{float:'left'}}>Support Task List</Text>
                    <AddButton right onAdd={addTask} title="New Task"/>
                </div> }>

                <DataTable size="small" columns={columnSupport} dataSource={authData.employee_code !== 'SMI20150601108' ? dataSourceSupport.filter(el => el.employee_code === authData.employee_code) : dataSourceSupport} bordered/>
            </Card>

            {
                (formModalTask) ?
                <FormModal form={taskForm} title={modalTaskTitle} submitForm={(v)=>saveTask(v)} width={400} showModal={formModalTask} onCloseModal={() => setFormModalTask(false)} saving={saving}>
                    <Form.Item name="support_task_id" hidden>
                        <Input placeholder="ID" />
                    </Form.Item>
                    <Form.Item name="employee_code" hidden>
                        <Input placeholder="Employee Code" />
                    </Form.Item>
                    <Form.Item label="Title" name="title" rules={[{required:true}]}>
                        <Input placeholder="Title" />
                    </Form.Item>
                    <Form.Item label="Description" name="description" rules={[{required:true}]}>
                        <Input.TextArea rows={3} placeholder="Description" />
                    </Form.Item>
                    <Form.Item label="Progress" name="progress" rules={[{required:true}]}>
                        <InputNumber min={0} max={100} formatter={value => `${value}%`} parser={value => value.replace('%', '')} style={{width: "100%"}}/>
                    </Form.Item>
                </FormModal>
                : null
            }
        </div>
    );
}

export default Index;
