import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Space } from 'antd';
import { PageTitle, Spinner, CustomButton, PrintPdfButton, DataTable } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import { useParams } from 'react-router-dom'
import { ZoomInOutlined } from '@ant-design/icons'
import moment from 'moment'

const VacancyDetail = () => {
    const { vacancyId } = useParams();
    const [vacancy, setVacancy] = useState(null);
    const [applicant, setApplicant] = useState([])
    const [loaded, setLoaded] = useState(false);

    const fetchReportVacancies = (vacancyId) => {
        setLoaded(false)
        SentralModel.list('RecruitmentVacancy', { filter: [['rec_vacancy_id', vacancyId]] }).then(res => {
            setVacancy(res.data[0])
            setLoaded(true)
        })
    }

    const fetchApplicant = (vacancyId) => {
        // setLoaded(false)
        SentralModel.action('Recruitment', 'getVacancyById', { rec_vacancy_id: vacancyId }, 0).then((res) => {
            setApplicant(res.data.applicants)
            // setLoaded(true)
        })
    }

    useEffect(() => {
        fetchReportVacancies(vacancyId)
        fetchApplicant(vacancyId)
    }, [vacancyId]);

    // const date = `${moment(vacancy.from_date).format("DD MMM YYYY")} -  ${moment(vacancy.to_date).format("DD MMM YYYY")}`;

    // const dataSource = [
    //     ...applicant.map(el => {
    //         return {
    //             rec_applicant_id: el.rec_applicant_id,
    //             name: el.name,
    //             mobile_phone_no: el.mobile_phone_no,
    //             email: el.email
    //         }
    //     })
    // ]

    const columns = [{
        title: 'No',
        dataIndex: 'no',
        key: 'name',
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Phone',
        dataIndex: 'mobile_phone_no',
        key: 'mobile_phone_no'
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email'
    },
    {
        title: 'Action',
        width: 120,
        render: (row) => (
            <div className="text-center">
                <Space>
                    <CustomButton icon={<ZoomInOutlined />} link to={`/report/vacancy/detail/applicant/${row.rec_applicant_id}`} />
                </Space>
            </div>
        )
    },
    ];

    const exportReport = () => {
        window.open(process.env.REACT_APP_API_URL + `/report/vacancy/detail/${vacancyId}`)
    }

    return (
        <Card className="content-container">
            <div>
                <PageTitle title="Vacancies Detail" breadcrumbs={[['Report', '/report/vacancy'], ['Vacancies Detail']]}></PageTitle>
            </div>

            {
                loaded ?
                    <div>
                        <Row className="mb-3">
                            <Col span={24} >
                                <PrintPdfButton right onPrint={exportReport} />
                            </Col>
                        </Row>
                        <Card bordered className="mb-4">
                            <Row className="mb-4 mt-1">
                                <Col span={12}>
                                    <table className="table-collapse table-default" style={{ width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <th>Division</th><td>{vacancy.division.division_name}</td>
                                            </tr>
                                            <tr>
                                                <th>Position</th><td>{vacancy.position.position_name}</td>
                                            </tr>
                                            <tr>
                                                <th>Subject</th><td>{vacancy.subject}</td>
                                            </tr>
                                            <tr>
                                                <th>Date</th><td>{moment(vacancy.from_date).format("DD MMM YYYY")} - {moment(vacancy.to_date).format("DD MMM YYYY")}</td>
                                            </tr>
                                            <tr>
                                                <th>Work Experience</th><td>{vacancy.min_work_experience} Year</td>
                                            </tr>
                                            <tr>
                                                <th>Age Limit</th><td>{vacancy.age_limit} Year</td>
                                            </tr>
                                            <tr>
                                                <th>Education</th><td>{vacancy.min_education}</td>
                                            </tr>
                                            <tr>
                                                <th>Work Location</th><td>{vacancy.location.province_name}</td>
                                            </tr>
                                            <tr>
                                                <th>Quota</th><td>{vacancy.quota}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </Card>
                        <Card className='mb-3'>
                            <Row>
                                <h1>Requirement</h1>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {vacancy.requirement}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Row>
                        </Card>
                        <Card className='mb-3'>
                            <Row>
                                <h1>Job Description</h1>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {vacancy.job_description}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Row>
                        </Card>
                        <Card>
                            <DataTable dataSource={applicant} columns={columns} />
                        </Card>
                    </div> : <Spinner />
            }
        </Card >
    );
}

export default VacancyDetail;
