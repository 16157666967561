import React, {useState, useEffect} from 'react';
import {Card, Row, Col, Typography} from 'antd'
import {PageTitle, PrintPdfButton, Spinner} from '../../../components'
import ApprovalLog from '../ApprovalLog'
import DetailHeader from '../DetailHeader'
import {useParams} from 'react-router-dom';
import {SentralModel} from '../../../models/SentralModel';
import {toFullDate} from '../../../utils/dateHandler'

const {Text} = Typography

const Detail = () => {
    const {reportId} = useParams()
    const [monthlyReport, setMonthlyReport] = useState(null)

    const fetchMonthlyReport = (id) => {
        SentralModel.get('SubmissionMonthlyReport', {}, id).then((res)=>{
            setMonthlyReport(res.data)
        })
    }

    useEffect(() => {
        fetchMonthlyReport(reportId)
    }, [reportId]);

    const printData = () => {
        window.open(process.env.REACT_APP_API_URL + '/print/monthly-report/' + reportId)
    }

    return (
        <Card className="content-container">
            <div>
                <PageTitle title="Monthly Report" breadcrumbs={[['Monthly Report', '/monthly-report'], ['Detail']]}></PageTitle>
            </div>
            {
                (monthlyReport) ? 
                <div>
                    <Row className="mb-3">
                        <Col span={24} >
                            <PrintPdfButton right onPrint={printData} />
                        </Col>
                    </Row>
                    <DetailHeader 
                        name={monthlyReport.employee.name} 
                        date={monthlyReport.created_at}
                        status={monthlyReport.status}/>
                    <Card bordered  className="mb-4">
                        <Text strong>MONTHLY REPORT DETAIL</Text>
                        <Row className="mb-4 mt-1">
                            <Col span={12}>
                                <table className="table-collapse table-default" style={{width:'100%'}}>
                                    <tbody>
                                        <tr>
                                            <th>Employee Name</th><td>{monthlyReport.employee.name}</td>
                                        </tr>
                                        <tr>
                                            <th>Division</th><td>{monthlyReport.employee.division.division_name}</td>
                                        </tr>
                                        <tr>
                                            <th>Position</th><td>{monthlyReport.employee.position.position_name}</td>
                                        </tr>
                                        <tr>
                                            <th>Title</th><td>{monthlyReport.title}</td>
                                        </tr>
                                        <tr>
                                            <th>Type</th><td>{monthlyReport.report_type.description}</td>
                                        </tr>
                                        <tr>
                                            <th>Submit Date</th><td>{toFullDate(monthlyReport.submit_date)}</td>
                                        </tr>
                                        <tr>
                                            <th>Description</th><td>{monthlyReport.description ?? '-'}</td>
                                        </tr>
                                        <tr>
                                            <th>Attachment</th>
                                            <td>
                                                {
                                                    (monthlyReport.attachment) ?
                                                    <a rel="noopener noreferrer" target='_blank' href={process.env.REACT_APP_API_FILE+'/'+monthlyReport.attachment}>
                                                        Show Attachment
                                                    </a> : '-'
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Card>
                    <Card bordered>
                        <ApprovalLog category="MONTHLYREPORT" 
                            approvers={monthlyReport.approver} />
                    </Card>
                </div> : <Spinner/>
            }
            
        </Card>
    );
}

export default Detail;
