import { useState, useEffect } from 'react'
import { Card, Tabs } from 'antd';
import { PageTitle } from '../../components';
import { SentralModel } from '../../models/SentralModel';
import { useParams } from 'react-router-dom';
import Overview from './Tabs/overview';
import Milestones from './Tabs/milestone';

const ActionPlanDetail = () => {
    const { actionPlanId } = useParams()
    const [ap, setAp] = useState([])
    const [actionLog, setActionLog] = useState([])
    const [compMilestone, setCompMilestone] = useState(0)
    const [ipMilestone, setIpMilestone] = useState(0)
    const [loaded, setLoaded] = useState(false)

    const fetchActionPlan = () => {
        SentralModel.action("ActionPlan", `getDetail`, 0, actionPlanId).then((res) => {
            setAp(res.data.action_plan)
            setCompMilestone(res.data.complete_milestone)
            setIpMilestone(res.data.inprogress_milestone)

            setLoaded(true)
        })
    }

    const fetchActionPlanLog = () => {
        SentralModel.action("ActionPlan", "getActionPlanLog", 0, actionPlanId).then((res) => {
            setActionLog(res.data)
        })
    }

    useEffect(() => {
        fetchActionPlan()
        fetchActionPlanLog()

        // eslint-disable-next-line
    }, []);

    return (
        <Card className="content-container">
            <PageTitle title="Action Plan Detail" breadcrumbs={[['Action Plan', '/action-plan'], ["Detail"]]}></PageTitle>
            {
                loaded ?
                    <Tabs defaultActiveKey="overview-tab" type="card">
                        <Tabs.TabPane tab="Overview" key="overview-tab">
                            <Overview data={ap} chart={{ compMilestone, ipMilestone }} log={actionLog} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Milestones" key="milestone-tab">
                            <Milestones />
                        </Tabs.TabPane>
                    </Tabs >
                    : null
            }
        </Card >
    )
}

export default ActionPlanDetail