import React, { useEffect, useState } from 'react';
import { Card, Typography, Tabs, Space, Form, Input, message } from 'antd'
import { PageTitle, Spinner, DataTable, AddButton, EditButton, EditButton2, DeleteButton, DeleteButton2, FormModal } from '../../../components'
import { useParams } from 'react-router-dom'
import { SentralModel } from '../../../models/SentralModel'
import { checkRole } from '../../../helpers/roleData'

const { Text } = Typography
const { TabPane } = Tabs
const Detail = () => {
    const { chapterId } = useParams()
    const [chapters, setChapters] = useState({})
    const [loaded, setLoaded] = useState(false)
    const [formModalArticle, setFormModalArticle] = useState(false)
    const [formModalContent, setFormModalContent] = useState(false);
    const [defaultValueArticle, setDefaultValueArticle] = useState({})
    const [defaultValueContent, setDefaultValueContent] = useState({});
    const [saving, setSaving] = useState(false)
    const [modalTitle, setModalTitle] = useState(null)

    const fetchChapters = (chapterId) => {
        SentralModel.action('Documentation', 'getArticle', { chapter_id: chapterId }, 0).then(res => {
            setChapters(res.message)
        }).finally(() => {
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchChapters(chapterId)
    }, [chapterId]);

    const columns = [
        {
            title: 'No',
            render: (text, record, index) => (
                <div className="text-center">
                    {index + 1}
                </div>
            )
        },
        {
            title: 'Content',
            width: '100%',
            render: (row) => (
                row.content
            )
        }
    ];

    if (checkRole('SUPERADMIN') || checkRole('ADMIN') || checkRole('HR')) {
        columns.push({
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => editContent(row)} />
                        <DeleteButton onConfirm={() => deleteContent(row.doc_regulation_content_id)} />
                    </Space>
                </div>
            )
        })
    }

    const addArticle = () => {
        setModalTitle('Add Article')
        setDefaultValueArticle({
            doc_regulation_article_id: 0,
            doc_regulation_chapter_id: chapterId,
            article_no: '',
            title: ''
        })
        setFormModalArticle(true)
    }

    const editArticle = (v) => {
        setModalTitle('Edit Article')
        setDefaultValueArticle({
            doc_regulation_article_id: v.article_id,
            doc_regulation_chapter_id: chapterId,
            article_no: v.article_no,
            title: v.title
        })
        setFormModalArticle(true)
    }

    const deleteArticle = (id) => {
        message.loading('deleting article')
        SentralModel.deleteById('DocumentRegulationArticle', id).then((res) => {
            message.destroy()
            message.success('Article deleted', 2)
            fetchChapters(chapterId);
        })
    }

    const saveArticle = (v) => {
        setSaving(true)
        let payload = {
            doc_regulation_article_id: v.doc_regulation_article_id,
            doc_regulation_chapter_id: v.doc_regulation_chapter_id,
            article_no: v.article_no,
            title: v.title
        }
        SentralModel.save('DocumentRegulationArticle', payload, v.doc_regulation_article_id).then((res) => {
            setFormModalArticle(false)
            message.success('Article saved', 2);
            setSaving(false)
            fetchChapters(chapterId);
        })
    }

    const addContent = (id) => {
        setModalTitle('Add Content')
        setDefaultValueContent({
            doc_regulation_content_id: 0,
            doc_regulation_article_id: id,
            content: '',
            order_no: ''
        })
        setFormModalContent(true)
    }

    const editContent = (v) => {
        setModalTitle('Edit Content')
        setDefaultValueContent({
            doc_regulation_content_id: v.doc_regulation_content_id,
            doc_regulation_article_id: v.doc_regulation_article_id,
            content: v.content,
            order_no: v.order_no
        })
        setFormModalContent(true)
    }

    const deleteContent = (id) => {
        message.loading('deleting content')
        SentralModel.deleteById('DocumentRegulationContent', id).then((res) => {
            message.destroy()
            message.success('Content deleted', 2)
            fetchChapters(chapterId);
        })
    }

    const saveContent = (v) => {
        setSaving(true)
        let payload = {
            doc_regulation_content_id: v.doc_regulation_content_id,
            doc_regulation_article_id: v.doc_regulation_article_id,
            content: v.content,
            order_no: v.order_no
        }
        SentralModel.save('DocumentRegulationContent', payload, v.doc_regulation_content_id).then((res) => {
            setFormModalContent(false)
            fetchChapters(chapterId);
            message.success('Content saved', 2);
        }).finally(() => {
            setSaving(false)
        })
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Company Regulations" breadcrumbs={[['Company Regulation', '/documentation/regulation'], ['Detail']]}></PageTitle>
                {
                    (loaded) ?
                        <Card title={
                            <div className="full-width">
                                <Text strong style={{ float: 'left' }}>{'Chapter - ' + chapters.chapter_no + ' [' + chapters.title + ']'}</Text>
                                {
                                    checkRole('ADMIN') || checkRole('HR') ?
                                        <AddButton right onAdd={addArticle} title="New Article" />
                                        : null
                                }
                            </div>}>

                            <Tabs defaultActiveKey="1" tabPosition="top">
                                {
                                    chapters.articles.map((article, i) => (
                                        <TabPane tab={'Article ' + article.article_no} key={'tab_' + i}>
                                            {
                                                checkRole('ADMIN') || checkRole('HR') ?
                                                    <div>
                                                        <AddButton style={{ padding: 5 }} onAdd={() => addContent(article.article_id)} title="New Content" /> &nbsp;
                                                        <EditButton2 onEdit={() => editArticle(article)} title="Edit Article" /> &nbsp;
                                                        <DeleteButton2 onConfirm={() => deleteArticle(article.article_id)} title="Delete Article" /> <br /><br />
                                                    </div>
                                                    : null
                                            }
                                            <Text strong style={{ display: 'block', fontSize: '20px' }}>{article.title}</Text>
                                            <DataTable size='small' bordered dataSource={article.contents} columns={columns} pagination={false} search={false} />

                                            {
                                                (formModalContent) ?
                                                    <FormModal defaultValues={defaultValueContent} title={modalTitle} submitForm={(v) => saveContent(v)} width={800} showModal={formModalContent} onCloseModal={() => setFormModalContent(false)} saving={saving}>
                                                        <Form.Item name="doc_regulation_content_id" hidden>
                                                            <Input placeholder="input placeholder" />
                                                        </Form.Item>
                                                        <Form.Item name="doc_regulation_article_id" hidden>
                                                            <Input placeholder="input placeholder" />
                                                        </Form.Item>
                                                        <Form.Item label="Content" name="content" rules={[{ required: true }]}>
                                                            <Input.TextArea rows={7} placeholder="Content" />
                                                        </Form.Item>
                                                        <Form.Item label="Order Number" name="order_no" rules={[{ required: true }]}>
                                                            <Input placeholder="Order Number" />
                                                        </Form.Item>
                                                    </FormModal>
                                                    : null
                                            }
                                        </TabPane>
                                    ))
                                }
                            </Tabs>

                            {
                                (formModalArticle) ?
                                    <FormModal defaultValues={defaultValueArticle} title={modalTitle} submitForm={(v) => saveArticle(v)} width={400} showModal={formModalArticle} onCloseModal={() => setFormModalArticle(false)} saving={saving}>
                                        <Form.Item name="doc_regulation_article_id" hidden>
                                            <Input placeholder="input placeholder" />
                                        </Form.Item>
                                        <Form.Item name="doc_regulation_chapter_id" hidden>
                                            <Input placeholder="input placeholder" />
                                        </Form.Item>
                                        <Form.Item label="Article Number" name="article_no" rules={[{ required: true }]}>
                                            <Input placeholder="Article Number" />
                                        </Form.Item>
                                        <Form.Item label="Title" name="title" rules={[{ required: true }]}>
                                            <Input placeholder="Title" />
                                        </Form.Item>
                                    </FormModal>
                                    : null
                            }
                        </Card>
                        : <Spinner />
                }
            </Card>
        </div>
    );
}

export default Detail;
