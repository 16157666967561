import React, {useEffect, useState} from 'react';
import {Card, Typography, Form, Input, message, Space} from 'antd'
import {AddButton, EditButton, DeleteButton, PageTitle, Spinner, FormModal, DataTable} from '../../../../components'
import {SentralModel} from '../../../../models/SentralModel'
import moment from 'moment'

const {Text} = Typography
const Index = () => {
    const [classificationISO, setClassificationISO] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [saving, setSaving] = useState(false)
    const [modalTitle, setModalTitle]= useState(null)
    const fetchclassificationISO = () =>{
        SentralModel.list('DocumentIsoClassification').then(res=>{
            setClassificationISO(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchclassificationISO()
    }, []);

    const editClassificationISO = (v) =>{
        setModalTitle('Edit Classification')
        setDefaultValues({
            document_iso_classification_id: v.document_iso_classification_id,
            document_iso_classification_name: v.document_iso_classification_name,
            updated_at: moment(v.daily_in, 'HH:mm'),
        })
        setFormModal(true)
    }

    const deleteClassificationISO = (id) =>{
        message.loading('deleting Classification ISO')
        SentralModel.deleteById('DocumentIsoClassification', id).then((res)=>{
            message.destroy()
            message.success('Classification deleted', 2)
            let tmp = classificationISO
            let index = tmp.findIndex(el => el.document_iso_classification_id===id)
            tmp.splice(index, 1);
            setClassificationISO([])
            setClassificationISO(tmp)
        })
    }

    const dataSource = [
        ...classificationISO.map( el => {
            return {
                document_iso_classification_id: el.document_iso_classification_id,
                document_iso_classification_name: el.document_iso_classification_name,
            }
        })
    ]

    const columns = [{
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'name',
        },
        {
          title: 'Classification ISO Name',
          dataIndex: 'document_iso_classification_name',
          key: 'document_iso_classification_name',
        },
        {
            title: 'Action',
            width:120,
            render:(row) =>(
                <div className="text-center">
                    <Space>
                    <EditButton onEdit={() => editClassificationISO(row)}/>
                    <DeleteButton onConfirm={() => deleteClassificationISO(row.document_iso_classification_id)}/>
                    </Space>
                </div>
            )
        },
    ];

    const addClassificationISO=()=>{
        setModalTitle('Add Classification ISO')
        setDefaultValues({
            document_iso_classification_id: 0,
            document_iso_classification_name: '',
        })
        setFormModal(true)
    }
    const saveClassificationISO = (v) => {
        setSaving(true)
        let payload={
            document_iso_classification_id:v.document_iso_classification_id,
            document_iso_classification_name:v.document_iso_classification_name,
            created_at:moment(v.daily_in).format('HH:mm'),
        }
        SentralModel.save('DocumentIsoClassification', payload, v.document_iso_classification_id).then((res)=>{
            setFormModal(false)
            fetchclassificationISO();
            message.success('Classification saved',2);
        }).finally(() => {
            setSaving(false)
        })
    }


    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Classification ISO" breadcrumbs={[['ClassificationISO']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>Classification List</Text>
                        <AddButton right onAdd={addClassificationISO} title="New Classification"/>
                    </div> }>

                    {
                        (loaded) ?
                        <DataTable size='small' bordered dataSource={dataSource} columns={columns}/>
                        :
                        <Spinner/>
                    }
                </Card>
                
                {
                    (formModal) ?
                    <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v)=>saveClassificationISO(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                        <Form.Item name="document_iso_classification_id" hidden>
                            <Input placeholder="ID" />
                        </Form.Item>
                        <Form.Item label="Classification Name" name="document_iso_classification_name"  required rules={[{required:true}]}>
                            <Input placeholder="Classification Name" />
                        </Form.Item>
                    </FormModal>
                    : null
                }
            </Card>
        </div>
    );
}

export default Index;
