import React, {useState} from 'react';
import {Card, message} from 'antd';
import {PageTitle} from '../../../components'
import RealizationForm from './RealizationForm'
import {SentralModel} from '../../../models/SentralModel'
import {useHistory, useParams} from 'react-router-dom'

const CreateRealization = () => {
    const history = useHistory()
    const {tripId} = useParams()
    const [saving, setSaving] = useState()

    const submitBusinessTrip = (v)=>{
        setSaving(true)
        SentralModel.action('Submission', 'saveBusinessTripRealization', v, tripId).then((res)=>{
            message.destroy()
            message.success('Business Trip Realization submitted', 1)
            setSaving(false)
            setTimeout(() => {
                history.push('/business-trip');
            }, 1000);
        }).catch((err) => {
            message.destroy()
            message.error('Oops, something went wrong', 2)
            setSaving(false)
        })
    }
    return (
        <Card className="content-container">
            <PageTitle title="Business Trip Realization" breadcrumbs={[['Business Trip', '/business-trip'], ['Realization']]}></PageTitle>
            <RealizationForm onSubmit={(v) => submitBusinessTrip(v)} saving={saving}/>
        </Card>
    );
}

export default CreateRealization;
