import React, {/*useEffect,*/ useState } from 'react';
import { Row, Col, Card, Form, Input, Typography, Button/*, Select*/ } from 'antd';
import { DeleteOutlined } from '@ant-design/icons'
import { SubmitButton, AddButton, Uploader/*, Spinner*/ } from '../../../../components'
import authData from '../../../../helpers/authData'
import { toIdr, extractNumber } from '../../../../utils/currencyHandler';
// import { CustomModel } from '../../../../models/CustomModel';

const { TextArea } = Input
const { Text } = Typography
// const {Option} = Select;

function useForceUpdate() {
    // eslint-disable-next-line
    const [value, setValue] = useState(0);
    return () => setValue(value => ++value);
}

const RequestForm = (props) => {
    const forceUpdate = useForceUpdate()
    const [rmbForm] = Form.useForm()
    const [itemCount, setItemCount] = useState(1)
    const [attachmentFile, setAttachmentFile] = useState([])
    const [item, setItem] = useState([{ description: null, amount: 0 }])
    // const [ticket, setTicket] = useState([{ticket_no: null, company_name: null}])
    // const [ticketCount, setTicketCount] = useState(1)
    const [uploading, setUploading] = useState(false)
    const [totalAmount, setTotalAmoun] = useState(0)
    const [urlFile, setUrlFile] = useState([])
    // const [closeTickets, setCloseTickets] = useState([]);
    // const [loaded, setLoaded] = useState(true);

    // const fetchTickets = () => {
    //     setLoaded(false)
    //     CustomModel.boost('getTicketClose', authData.name).then(res => {
    //         setLoaded(true)
    //         setCloseTickets(res.data)
    //     })
    // }

    // useEffect(() => {
    //     fetchTickets()
    // }, []);

    const fileUploaded = (v, key) => {
        let attcs = attachmentFile
        attcs[key] = v.message
        setAttachmentFile(attcs)
        setUrlFile(process.env.REACT_APP_API_FILE + "/" + v.message)
        console.log(urlFile)
    }

    const submitReimbursement = (v) => {
        let total = 0;
        item.forEach((el, key) => {
            el.attachment = (attachmentFile[key]) ? attachmentFile[key] : null;
            total += parseInt(el.amount)
        })
        let submittedData = {
            title: v.title,
            employee_code: authData.employee_code,
            total_amount: total,
            items: item,
            // tickets: authData.division_id === 9 ? ticket : []
            tickets: []
        }
        props.onSubmit(submittedData)
    }

    const addItem = () => {
        let x = item
        let key = item.length
        x.push({ description: null, amount: 0 })
        rmbForm.setFieldsValue({
            ['item_name[' + key + ']']: null,
            ['amount[' + key + ']']: null
        })
        setItem(x)
        setItemCount(itemCount + 1)
        forceUpdate()
    }

    // const addTicket = () => {
    //     let x=ticket
    //     let key = ticket.length
    //     x.push({ticket_no: null, company_name: null})
    //     rmbForm.setFieldsValue({
    //         ['ticket_no['+key+']'] : null,
    //         ['company_name['+key+']'] : null
    //     })
    //     setTicket(x)
    //     setTicketCount(ticketCount+1)
    //     forceUpdate()
    // }

    const changeItemName = (v, key) => {
        let x = item
        x[key].description = v.target.value
        setItem(x)
    }

    const changeItemTotal = (v, key) => {
        let x = item
        const newPrice = extractNumber(v.target.value)
        x[key].amount = newPrice
        rmbForm.setFieldsValue({ ['amount[' + key + ']']: toIdr(newPrice) })
        x[key].amount = extractNumber(v.target.value)
        setItem(x)

        let t = 0
        item.forEach((el) => {
            t += el.amount
        })

        setTotalAmoun(t)
    }

    // const changeTicket = (v, key) => {
    //     let x = ticket
    //     x[key].ticket_no = v
    //     x[key].company_name = closeTickets.find(element => element.kode_unik === v).company_name
    //     setTicket(x)
    // }

    const removeItem = (key) => {
        let itm = item
        itm.splice(key, 1)
        setItem(itm)
        let attcs = attachmentFile
        attcs[key] = null
        setAttachmentFile(attcs)
        forceUpdate()
    }

    // const removeTicket = (key) =>{
    //     let tick = ticket
    //     tick.splice(key,1)
    //     setTicket(tick)
    //     forceUpdate()
    // }

    return (
        // loaded ?
        <Form size="middle" form={rmbForm} layout="vertical" onFinish={submitReimbursement}>
            <Row justify="center">
                <Col span={20}>
                    <Row>
                        <Col span={12}>
                            <Form.Item name="title" placeholder="Title" label="Title" rules={[{ required: true, message: 'Title is required' }]}>
                                <TextArea placeholder="Title" autoSize={{ minRows: 2 }} />
                            </Form.Item>
                        </Col>
                    </Row>

                    {/* {
                        authData.division_id === 9 ?
                        <>
                            <Row>
                                <Col span={24}>
                                    <Card title={
                                        <div className="full-width">
                                            <Text strong style={{float:'left'}}>Ticket</Text>
                                            <AddButton right onAdd={() => addTicket()} title="Add Ticket"/>
                                        </div>}>
                                        <table className="table-item" style={{width:'100%'}}>
                                            <thead>
                                                <tr>
                                                    <th style={{width:'90%'}}>Ticket No.</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    ticket.map((el, key) =>(
                                                        <tr key={'rmb_ticket'+key}>
                                                            <td>
                                                                <Form.Item name={'ticket['+key+']'} rules={[{required:authData.division_id === 9 ? true : false, message:'Ticket is required'}]}>
                                                                    <Select showSearch allowClear placeholder="Select Ticket" style={{ width: "100%", marginRight:10 }} onChange={(v) => changeTicket(v, key)} optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                                        {
                                                                            closeTickets.map((el, key) => (
                                                                                <Option key={key} value={el.kode_unik}>{el.kode_unik + ` - ` + el.company_name}</Option>
                                                                            ))
                                                                        }
                                                                    </Select>
                                                                </Form.Item>
                                                            </td>
                                                            <td className="text-center" style={{width:'30px'}}>
                                                                {
                                                                    ((key+1)===ticket.length && ticket.length>1) ? 
                                                                    <Button type="text" danger onClick={() => removeTicket(key)} icon={<DeleteOutlined/>}/>
                                                                    : null
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </Card>
                                </Col>
                            </Row>
                            <br />
                        </>
                        : null
                    } */}

                    <Row>
                        <Col span={24}>
                            <Card title={
                                <div className="full-width">
                                    <Text strong style={{ float: 'left' }}>Submission Item</Text>
                                    <AddButton right onAdd={() => addItem()} title="Add Item" />
                                </div>}>
                                <table className="table-item" style={{ width: '100%' }}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '50%' }}>Item</th>
                                            <th style={{ width: '25%' }}>Amount</th>
                                            <th style={{ width: '25%' }}>Attachment</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            item.map((el, key) => (
                                                <tr key={'rmb_item' + key}>
                                                    <td>
                                                        <Form.Item name={'item_name[' + key + ']'} rules={[{ required: true, message: 'Item name is required' }]}>
                                                            <Input placeholder="Item Name" onChange={(v) => changeItemName(v, key)} />
                                                        </Form.Item>
                                                    </td>
                                                    <td>
                                                        <Form.Item name={'amount[' + key + ']'} rules={[{ required: true, message: 'Amount is required' }]}>
                                                            <Input type="text" placeholder="Amount" onChange={(v) =>
                                                                changeItemTotal(v, key)
                                                            } />
                                                        </Form.Item>
                                                    </td>
                                                    <td>
                                                        <Form.Item>
                                                            <Uploader folder="submission-reimbursement" onUploaded={(v) => fileUploaded(v, key)} isUploading={(v) => setUploading(v)} view={true} />
                                                        </Form.Item>
                                                    </td>
                                                    <td className="text-center" style={{ width: '30px' }}>
                                                        {
                                                            ((key + 1) === item.length && item.length > 1) ?
                                                                <Button type="text" danger onClick={() => removeItem(key)} icon={<DeleteOutlined />} />
                                                                : null
                                                        }
                                                    </td>
                                                </tr>

                                            ))
                                        }
                                        <tr style={{ borderTop: '1px solid #ededed' }}>
                                            <td className="text-right" colSpan={2}>Total Amount : </td>
                                            <td >{toIdr(totalAmount)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Card>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col span={24} className="text-right">
                            <SubmitButton loading={props.saving || uploading} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
        // : <Spinner />
    );
}

export default RequestForm;
