import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Card, Typography, Descriptions, Form, message } from 'antd'
import { useParams } from 'react-router-dom'
import { PageTitle, Spinner, SubmitButton } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import { CustomModel } from '../../../models/CustomModel'
import { toFullDate } from '../../../utils/dateHandler'
import { toIdr } from '../../../utils/currencyHandler'

const { Text } = Typography

function useForceUpdate() {
    // eslint-disable-next-line
    const [value, setValue] = useState(0);
    return () => setValue(value => ++value);
}

const AppraisalForm = () => {
    const history = useHistory()
    const forceUpdate = useForceUpdate()
    const { appraisalId } = useParams()
    const [appraisalForm] = Form.useForm()
    const [appraisal, setAppraisal] = useState(null)
    const [formValue, setFormValue] = useState({})
    const [loaded, setLoaded] = useState(false)
    const [disabledAuto, setDisabledAuto] = useState([])
    const [disabledId, setDisabledId] = useState([])
    const [salesDivision] = useState([21, 22, 28, 29, 30, 36])
    const [dataAppraisal, setDataAppraisal] = useState({})

    const fetchAppraisal = (appraisalId, salesDivision) => {
        setLoaded(false)
        SentralModel.action('PraAppraisal', 'getAppraisalForm', { pra_appraisal_id: appraisalId }).then(async (res) => {
            setAppraisal(res.data)
            setDisabledAuto(res.data.disabled)
            const values = res.data.values
            let value = {};
            res.data.categories.forEach(ctg => {
                ctg.items.forEach(item => {
                    value['kpi_value_' + item.kpi_item_id] = item.value
                })
            })

            Object.assign(value, values)

            if (salesDivision?.some(el => el === res.data.employee.division_id)) {
                if (res.data.employee.slp_code) {
                    await CustomModel.boost('APISAP', 'data_appraisal/' + res.data.employee.employee_code, {}).then(res => {
                        setDataAppraisal(res.data)
                    })
                }
            }

            let payload = {
                kpi_pra_appraisal_id: appraisalId
            }
            SentralModel.action('PraAppraisal', 'getFormAppraisalValue', payload).then((res) => {
                let newValue = value
                Object.assign(newValue, res.data.value)
                setDisabledId(res.data.disabled)
                setFormValue(newValue)
                forceUpdate()
                setLoaded(true)
            }).catch((err) => {
            })
        })
    }

    useEffect(() => {
        fetchAppraisal(appraisalId, salesDivision)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appraisalId, salesDivision]);

    const submitAppraisal = (v) => {
        let payload = {
            ...formValue,
            pra_appraisal_id: appraisalId
        }

        SentralModel.action('PraAppraisal', 'submitAssessmentNew', payload).then((res) => {
            message.success('Penilaian berhasil disimpan', 2)
            fetchAppraisal(appraisalId)
            setTimeout(() => {
                history.push('/management/pra-appraisal');
            }, 1000);
        }).catch((err) => {
            message.warning("Penilaian gagal disimpan")
            // setLoaded(false)
        })
    }


    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Pra Appraisals" breadcrumbs={[['Pra Appraisal', '/management/pra-appraisal'], ['Form Penilaian']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{ float: 'left' }}>Appraisal Form</Text>
                    </div>}>

                    {
                        (loaded) ?
                            <div>
                                <Descriptions className="mb-3" layout="horizontal" column={1} bordered size="small" style={{ width: "500px" }}>
                                    <Descriptions.Item label="Name">{appraisal?.employee.name}</Descriptions.Item>
                                    <Descriptions.Item label="Division">{appraisal?.employee.division.division_name}</Descriptions.Item>
                                    <Descriptions.Item label="Position">{appraisal?.employee.position.position_name || ''}</Descriptions.Item>
                                    <Descriptions.Item label="Join Date">{toFullDate(appraisal?.employee.joinDate)}</Descriptions.Item>
                                </Descriptions>
                                <Form form={appraisalForm} onFinish={submitAppraisal} size="small">
                                    <table className="table-collapse table-default" style={{ width: '100%' }}>
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>Component</th>
                                                <th className="text-center" style={{ verticalAlign: 'top' }}>1<br />Sangat Kurang</th>
                                                <th className="text-center" style={{ verticalAlign: 'top' }}>2<br />Kurang</th>
                                                <th className="text-center" style={{ verticalAlign: 'top' }}>3<br />Cukup</th>
                                                <th className="text-center" style={{ verticalAlign: 'top' }}>4<br />Baik</th>
                                                <th className="text-center" style={{ verticalAlign: 'top' }}>5<br />Sangat Baik</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                appraisal?.categories.map((el, i) => (
                                                    (el.items.length > 0) ?
                                                        <React.Fragment key={'category_' + i}>
                                                            <tr >
                                                                <th colSpan={7}>{el.name}</th>
                                                            </tr>
                                                            {
                                                                el.items.map((item, j) => (
                                                                    <>
                                                                        {/* {
                                                                            el.kpi_item_category_id === 7 && j === 0 && dataAppraisal.yearly_target ?
                                                                                <tr key={'a' + j} style={{ backgroundColor: '#f1c40f' }}>
                                                                                    <td colSpan={7}>Target : <strong>{toIdr(dataAppraisal.yearly_target)}</strong> | Achievement (SMI, SKI, SST) : <strong>{toIdr(dataAppraisal.yearly_achievement)} ({parseFloat((dataAppraisal.yearly_achievement / dataAppraisal.yearly_target) * 100).toFixed(2)})%</strong></td>
                                                                                </tr>
                                                                                : null
                                                                        } */}

                                                                        {
                                                                            el.kpi_item_category_id === 3 && (item.kpi_item_id === 567) && dataAppraisal.cancel_so ?
                                                                                <tr key={'c' + j} style={{ backgroundColor: '#f1c40f' }}>
                                                                                    {/* <td style={{ width: '2%' }} rowSpan={2}></td> */}
                                                                                    <td colSpan={7}>Total SO Cancel : <strong>{dataAppraisal.cancel_so}</strong></td>
                                                                                </tr > : null
                                                                        }

                                                                        {
                                                                            el.kpi_item_category_id === 2 && j === 0 ?
                                                                                <tr key={'b' + j} style={{ backgroundColor: '#f1c40f' }}>
                                                                                    {/* <td style={{ width: '2%' }} rowSpan={3}></td> */}
                                                                                    <td colSpan={7}>Total Leave : <strong>{appraisal.statistic.total_leave}</strong> | Total Sick : <strong>{appraisal.statistic.total_sick}</strong> | Total Alpha : <strong>{appraisal.statistic.total_absence}</strong></td>
                                                                                </tr>
                                                                                : null
                                                                        }

                                                                        {
                                                                            el.kpi_item_category_id === 10 && j === 0 ?
                                                                                <tr key={'c' + j} style={{ backgroundColor: '#f1c40f' }}>
                                                                                    {/* <td style={{ width: '2%' }} rowSpan={3}></td> */}
                                                                                    <td colSpan={7}>Total Training : <strong>{appraisal.statistic.total_training}</strong></td>
                                                                                </tr>
                                                                                : null
                                                                        }

                                                                        {
                                                                            (el.kpi_item_category_id === 3 || el.kpi_item_category_id === 7) && (item.kpi_item_id === 229 || item.kpi_item_id === 256 || item.kpi_item_id === 528 || item.kpi_item_id === 558) && dataAppraisal.yearly_target ?
                                                                                <tr key={'d' + j} style={{ backgroundColor: '#f1c40f' }}>
                                                                                    {/* <td style={{ width: '2%' }} rowSpan={2}></td> */}
                                                                                    <td colSpan={7}>Target Perbulan : <strong>{toIdr(dataAppraisal.monthly_target)}</strong> Achievement Perbulan (SMI, SKI, SST) : <strong>{toIdr(dataAppraisal.monthly_achievement ?? 0)}</strong>   Persentase : <strong>({parseFloat((dataAppraisal.monthly_achievement / dataAppraisal.monthly_target) * 100).toFixed(2)})%</strong></td>
                                                                                </tr>
                                                                                : null
                                                                        }

                                                                        {
                                                                            (el.kpi_item_category_id === 3 || el.kpi_item_category_id === 7) && (item.kpi_item_id === 230 || item.kpi_item_id === 257 || item.kpi_item_id === 529 || item.kpi_item_id === 555) && dataAppraisal.yearly_target ?
                                                                                <tr key={'e' + j} style={{ backgroundColor: '#f1c40f' }}>
                                                                                    <td colSpan={7}>Penjualan Tahun Sebelumnya (SMI, SKI, SST) : <strong>{toIdr(dataAppraisal.previous_year)}</strong> Penjualan Tahun Ini (SMI, SKI, SST) : <strong>{toIdr(dataAppraisal.yearly_achievement)}</strong> Growth : <strong>({parseFloat(((dataAppraisal.yearly_achievement - dataAppraisal.previous_year) / dataAppraisal.yearly_achievement) * 100).toFixed(2)})%</strong></td>
                                                                                </tr>
                                                                                : null
                                                                        }

                                                                        {
                                                                            (el.kpi_item_category_id === 3 || el.kpi_item_category_id === 7) && (item.kpi_item_id === 232 || item.kpi_item_id === 259 || item.kpi_item_id === 553 || item.kpi_item_id === 564) && dataAppraisal.yearly_target ?
                                                                                <tr key={'f' + j} style={{ backgroundColor: '#f1c40f' }}>
                                                                                    {/* <td style={{ width: '2%' }} rowSpan={2}></td> */}
                                                                                    <td colSpan={7}>Penambahan Account Persemester (SMI, SKI, SST) : <strong>{dataAppraisal.monthly_account}</strong></td>
                                                                                </tr >
                                                                                : null
                                                                        }

                                                                        {
                                                                            (el.kpi_item_category_id === 3 || el.kpi_item_category_id === 7) && (item.kpi_item_id === 233 || item.kpi_item_id === 260 || item.kpi_item_id === 552 || item.kpi_item_id === 561) && dataAppraisal.yearly_target ?
                                                                                <tr key={'g' + j} style={{ backgroundColor: '#f1c40f' }}>
                                                                                    {/* <td style={{ width: '2%' }} rowSpan={2}></td> */}
                                                                                    <td colSpan={7}>Total Quotation : <strong>{toIdr(dataAppraisal.quotation)}</strong>, Total Invoicing: <strong>{toIdr(dataAppraisal.invoicing)}</strong>, Persentase Quotation menjadi Invoice (SMI, SKI, SST) : <strong>{dataAppraisal.percentage_invoice}%</strong></td>
                                                                                </tr>
                                                                                : null
                                                                        }

                                                                        {
                                                                            (el.kpi_item_category_id === 3 || el.kpi_item_category_id === 7) && (item.kpi_item_id === 239 || item.kpi_item_id === 266 || item.kpi_item_id === 546) && dataAppraisal.yearly_target ?
                                                                                <tr key={'h' + j} style={{ backgroundColor: '#f1c40f' }}>
                                                                                    <td colSpan={7}>Total Open DO (SMI, SKI, SST) : <strong>{dataAppraisal.open_do}</strong></td>
                                                                                </tr>
                                                                                : null
                                                                        }

                                                                        <tr key={'value' + j}>
                                                                            {
                                                                                item.kpi_item_id === 34 ||
                                                                                    item.kpi_item_id === 35 ||
                                                                                    item.kpi_item_id === 564 ||
                                                                                    item.kpi_item_id === 558 ||
                                                                                    item.kpi_item_id === 561 ||
                                                                                    item.kpi_item_id === 564 ||
                                                                                    item.kpi_item_id === 567 ||
                                                                                    item.kpi_item_id === 579 ||
                                                                                    item.kpi_item_id === 580 ?
                                                                                    <td className="text-center" style={{ verticalAlign: 'top', backgroundColor: "#F1C40F" }}>{j + 1}</td> :
                                                                                    <td className="text-center" style={{ verticalAlign: 'top' }}>{j + 1}</td>
                                                                            }
                                                                            <td>{item.description} - <strong>Bobot {item.rate}%</strong></td>
                                                                            {
                                                                                [1, 2, 3, 4, 5].map((rate) => (
                                                                                    <td className="text-center" key={'option_' + j + '_' + rate} style={{ width: '50px' }}>
                                                                                        {
                                                                                            disabledAuto.includes(item.kpi_item_id) ?
                                                                                                <input
                                                                                                    disabled
                                                                                                    onChange={(e) => {
                                                                                                        if (e.target.checked) {
                                                                                                            let x = formValue
                                                                                                            x['kpi_value_' + item.kpi_item_id] = rate
                                                                                                            setFormValue(x)
                                                                                                        }
                                                                                                    }}
                                                                                                    name={'kpi_value_' + item.kpi_item_id} type="radio" value={rate} defaultChecked={rate === formValue['kpi_value_' + item.kpi_item_id]} required />
                                                                                                :
                                                                                                disabledId.includes(item.kpi_item_id) ?
                                                                                                    <input
                                                                                                        disabled
                                                                                                        onChange={(e) => {
                                                                                                            if (e.target.checked) {
                                                                                                                let x = formValue
                                                                                                                x['kpi_value_' + item.kpi_item_id] = rate
                                                                                                                setFormValue(x)
                                                                                                            }
                                                                                                        }}
                                                                                                        name={'kpi_value_' + item.kpi_item_id} type="radio" value={rate} defaultChecked={rate === formValue['kpi_value_' + item.kpi_item_id]} required /> :
                                                                                                    <input
                                                                                                        onChange={(e) => {
                                                                                                            if (e.target.checked) {
                                                                                                                let x = formValue
                                                                                                                x['kpi_value_' + item.kpi_item_id] = rate
                                                                                                                setFormValue(x)
                                                                                                            }
                                                                                                        }}
                                                                                                        name={'kpi_value_' + item.kpi_item_id} type="radio" value={rate} defaultChecked={rate === formValue['kpi_value_' + item.kpi_item_id]} required />
                                                                                        }
                                                                                    </td>
                                                                                ))
                                                                            }
                                                                        </tr>
                                                                    </>
                                                                ))
                                                            }
                                                        </React.Fragment >
                                                        : null
                                                ))
                                            }
                                        </tbody >
                                    </table >
                                    <div className="full-width text-right mt-3">
                                        <SubmitButton />
                                    </div>
                                </Form >
                            </div >
                            :
                            <Spinner />
                    }
                </Card >
            </Card >
        </div >
    );
}

export default AppraisalForm;
