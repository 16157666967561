import React, { useEffect, useState } from 'react'
import { Row, Typography, Divider, Empty, Form, Input, message, DatePicker } from 'antd'
import ProfileItem from './ProfileItem';
import ActionButton from './ActionButton';
import { AddButton, FormModal, Spinner } from '../../components';
import { SentralModel } from '../../models/SentralModel'
import moment from 'moment'
import { toFullDate } from '../../utils/dateHandler';
import authData from '../../helpers/authData';
import { useParams } from 'react-router-dom';
import { decrypt } from '../../helpers/crypto';

const { Title } = Typography
const Contract = (props) => {
    const { employeeCode } = useParams()
    const [empContracts, setEmpContracts] = useState(props.data);
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false);
    const [defaultValues, setDefaultValues] = useState({});
    const [saving, setSaving] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);

    const role = localStorage.getItem('role') ? JSON.parse(decrypt(localStorage.getItem('role'))) : []

    const fetchEmpContracts = (empCode) => {
        setLoaded(false)
        SentralModel.list('EmployeeContract', { filter: [['employee_code', empCode]], order: 'asc', orderBy: 'contract_start' }).then(res => {
            setEmpContracts(res.data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchEmpContracts(props.emp_code)
    }, [props]);

    const addContract = () => {
        setModalTitle('Add Contract')
        setDefaultValues({
            employee_contract_id: 0
        })
        setFormModal(true)
    }

    const editContract = (v) => {
        setModalTitle('Edit Contract')
        setDefaultValues({
            employee_contract_id: v.employee_contract_id,
            contract_start: moment(v.contract_start, 'YYYY-MM-DD'),
            contract_end: moment(v.contract_end, 'YYYY-MM-DD'),
        })
        setFormModal(true)
    }

    const saveContract = (v) => {
        setSaving(true)
        let payload = {
            employee_code: props.emp_code,
            contract_start: moment(v.contract_start).format('YYYY-MM-DD'),
            contract_end: moment(v.contract_end).format('YYYY-MM-DD'),
        }
        SentralModel.save('EmployeeContract', payload, v.employee_contract_id).then(() => {
            setFormModal(false)
            fetchEmpContracts(props.emp_code)
            message.success('Contract saved', 2);
            setSaving(false)
        })
    }

    const deleteContract = (id) => {
        message.loading('deleting contract')
        SentralModel.deleteById('EmployeeContract', id).then((res) => {
            message.destroy()
            message.success('Contract deleted', 2)
            let tmp = empContracts
            let index = tmp.findIndex(el => el.employee_contract_id === id)
            tmp.splice(index, 1);
            setEmpContracts([])
            setEmpContracts(tmp)
        })
    }
    return (
        <div>
            {
                (props.isHr) ?
                    <div className="text-right">
                        {
                            authData.employee_code === employeeCode || employeeCode === undefined || authData.employee_code === 'SMI20220323229' || role.includes("SUPERADMIN") ?
                                <AddButton onAdd={addContract}>Add data</AddButton>
                                : null
                        }
                    </div>
                    : null
            }
            {
                loaded ?
                    (empContracts.length > 0) ?
                        empContracts.map((el, key) => (
                            <div key={key}>
                                <Divider orientation="left"><Title level={4}>Kontrak {key + 1}.</Title></Divider>
                                {
                                    (props.isHr) ?
                                        <ActionButton editable deleteable onEdit={() => editContract(el)} onDelete={() => deleteContract(el.employee_contract_id)} />
                                        : null
                                }
                                <Row>
                                    <ProfileItem title="Contract Start">{toFullDate(el.contract_start)}</ProfileItem>
                                    <ProfileItem title="Contract End">{toFullDate(el.contract_end)}</ProfileItem>
                                </Row>
                            </div>
                        ))
                        : <Empty />
                    : <Spinner />
            }
            {
                (formModal) ?
                    <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => saveContract(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                        <Form.Item name="employee_contract_id" hidden>
                            <Input placeholder="input placeholder" />
                        </Form.Item>
                        <Form.Item label="Contract Start" name="contract_start" rules={[{ required: true }]}>
                            <DatePicker className="full-width" placeholder="Contract start" />
                        </Form.Item>
                        <Form.Item label="Contract End" name="contract_end" rules={[{ required: true }]}>
                            <DatePicker className="full-width" placeholder="Contract end" />
                        </Form.Item>
                    </FormModal> :
                    null
            }
        </div>
    );
}

export default Contract;
