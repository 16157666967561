import React, {useEffect, useState} from 'react';
import {Card, message} from 'antd';
import {PageTitle} from '../../../components'
import RequestForm from './RequestForm'
import moment from 'moment'
import { SentralModel } from '../../../models/SentralModel';
import authData from '../../../helpers/authData'
import {useHistory} from 'react-router-dom'

const Create = () => {
    const history = useHistory()
    const [saving, setSaving] = useState(false)
    const [validityHour, setValidityHour] = useState({});

    const fetchValidityHour = () => {
        SentralModel.action('Submission', 'getOvertimeHour').then(res=>{
            setValidityHour(res.message);
        })
    }

    useEffect(() => {
        fetchValidityHour()
    }, []);

    const submitOvertime = (v)=>{
        if (moment(v.time[0], 'HH:mm').format('HH:mm:ss') < validityHour.start_time) {
            message.error('You cannot apply for overtime below ' + moment(validityHour.start_time, 'HH:mm:ss').format('HH:mm'), 2);
        } else if (moment(v.time[1], 'HH:mm').format('HH:mm:ss') > validityHour.end_time) {
            message.error('You cannot apply for overtime after ' + moment(validityHour.end_time, 'HH:mm:ss').format('HH:mm'), 2);
        } else {
            message.loading('Saving Data', 0)
            setSaving(true)
            let data={
                employee_code: authData.employee_code,
                from_date: moment(v.date).format('YYYY-MM-DD'),
                to_date: moment(v.date).format('YYYY-MM-DD'),
                description: v.necessity,
                from_time: moment(v.time[0], 'HH:mm').format('HH:mm:ss'),
                to_time: moment(v.time[1], 'HH:mm').format('HH:mm:ss'),
                overtime_type: v.location
            }
            SentralModel.action('Submission','checkOvertime', data, 0).then((res)=>{
                if(res.message==='ok'){
                    SentralModel.saveWithApproval('SubmissionOvertime','OVERTIME', data,0).then(()=>{
                        setSaving(false)
                        message.destroy()
                        message.success('Overtime submitted', 1)
                        setTimeout(() => {
                            history.push('/overtime');
                        }, 1000);
                    }).catch((err) => {
                        setSaving(false)
                        message.destroy()
                        message.error(err.message, 2)
                    })
                }else{
                    setSaving(false)
                    message.destroy()
                    message.error("Can't apply for overtime on the same day", 2)
                }
            })
            
        }
    }

    return (
        <Card className="content-container">
            <PageTitle title="Overtime Submission" breadcrumbs={[['Overtime','/overtime'],['Create']]}></PageTitle>
            <RequestForm onSubmit={(v) => submitOvertime(v)} saving={saving}/>
        </Card>
    );
}

export default Create;
