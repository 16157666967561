import React, { useEffect, useState } from 'react';
import { Card, Typography, Button, Popconfirm, message, Space, Form, Input, DatePicker } from 'antd'
import { PageTitle, Spinner, AddButton, FormModal } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import { SendOutlined } from '@ant-design/icons'
import moment from "moment"

const { Text } = Typography
const { RangePicker } = DatePicker

const Period = () => {
    const [periodForm] = Form.useForm()
    const [formModal, setFormModal] = useState(false)
    const [saving, setSaving] = useState(false)
    const [periods, setPeriods] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [sendingNotification, setSendingNotification] = useState(false)
    const [sendingNotificationProbation, setSendingNotificationProbation] = useState(false)
    const [sendingNotificationContract, setSendingNotificationContract] = useState(false)

    const fetchPeriods = () => {
        SentralModel.list('KpiAppraisalPeriod', { orderBy: 'year', order: 'asc' }).then(res => {
            setPeriods(res.data)
        }).finally(() => {
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchPeriods()
    }, []);

    const addPeriod = () => {
        // setLoaded(false)
        // SentralModel.save('KpiAppraisalPeriod', { year: parseInt(periods[periods.length - 1].year) + 1, is_active: 0 }, 0).then(res => {
        //     message.success('Periode Berhasil Ditambahkan', 2)
        //     fetchPeriods()
        // })

        setFormModal(true)

        periodForm.setFieldsValue({
            kpi_appraisal_period_id: 0,
            year: '',
            from_month: '',
            to_month: '',
            is_active: 0
        })
    }

    const savePeriod = (v) => {
        setSaving(true)

        let payload = {
            year: (moment(v.period_range[0]).format("YYYY")).toString(),
            from_month: parseInt(moment(v.period_range[0]).format("MM")),
            to_month: parseInt(moment(v.period_range[1]).format("MM")),
            is_active: 0
        }

        const temp = [];

        for (const x in periods) {
            temp.push((periods[x].year).toString());
        }

        if (temp.includes(payload.year)) {
            const x = periods.find(({ year }) => year === payload.year).to_month

            if (payload.from_month > x && payload.to_month <= 12) {
                SentralModel.save('KpiAppraisalPeriod', payload, 0).then(() => {
                    message.success('Periode Berhasil Ditambahkan', 2)
                    fetchPeriods()
                    setSaving(false)
                    setFormModal(false)
                })
            } else {
                message.error('Oops, period already exists.');
                setSaving(false)
            }
        } else {
            SentralModel.save('KpiAppraisalPeriod', payload, 0).then(() => {
                message.success('Periode Berhasil Ditambahkan', 2)
                fetchPeriods()
                setSaving(false)
                setFormModal(false)
            })
        }
    }

    const changeActivePeriod = (year, from_month, to_month) => {
        setLoaded(false)
        
        SentralModel.action('Appraisal', 'setActivePeriod', { year: year, from_month: from_month, to_month: to_month, }, 0).then(() => {
            message.success('Periode Aktif Diubah')
            fetchPeriods()
        })
    }

    const sendNotification = () => {
        setSendingNotification(true)
        message.loading('sending notification', 0)
        SentralModel.action('Appraisal', 'sendNotification', {}, 0).then((res) => {
            message.destroy()
            message.success('Notifikasi berhasil dikirimkan', 2)
            setSendingNotification(false)
        }).catch(() => {
            message.destroy()
        })
    }

    const sendNotificationProbation = () => {
        setSendingNotificationProbation(true)
        message.loading('sending notification', 0)
        SentralModel.action('Appraisal', 'sendNotificationProbation', {}, 0).then((res) => {
            message.destroy()
            message.success('Notifikasi berhasil dikirimkan', 2)
            setSendingNotificationProbation(false)
        }).catch(() => {
            message.destroy()
        })
    }

    const sendNotificationContract = () => {
        setSendingNotificationContract(true)
        message.loading('sending notification', 0)
        SentralModel.action('Appraisal', 'sendNotificationContract', {}, 0).then((res) => {
            message.destroy()
            message.success('Notifikasi berhasil dikirimkan', 2)
            setSendingNotificationContract(false)
        }).catch(() => {
            message.destroy()
        })
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="KPI Period" breadcrumbs={[['Appraisal', '/management/appraisal'], ['Periode KPI']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{ float: 'left' }}>Period List</Text>
                        <Space style={{ float: 'right' }}>
                            <Popconfirm
                                title="Kirim Notifikasi pengisian form appraisal?"
                                onConfirm={sendNotificationContract}
                                okText="Ya"
                                cancelText="Tidak"
                            >
                                <Button loading={sendingNotificationContract} type="primary" icon={<SendOutlined />}>
                                    Send Notification Contract
                                </Button>
                            </Popconfirm>
                            <Popconfirm
                                title="Kirim Notifikasi pengisian form appraisal?"
                                onConfirm={sendNotificationProbation}
                                okText="Ya"
                                cancelText="Tidak"
                            >
                                <Button loading={sendingNotificationProbation} type="primary" icon={<SendOutlined />}>
                                    Send Notification Probation
                                </Button>
                            </Popconfirm>
                            <Popconfirm
                                title="Kirim Notifikasi pengisian form appraisal?"
                                onConfirm={sendNotification}
                                okText="Ya"
                                cancelText="Tidak"
                            >
                                <Button loading={sendingNotification} type="primary" icon={<SendOutlined />}>
                                    Send Notification
                                </Button>
                            </Popconfirm>

                            <AddButton onAdd={addPeriod} title="Add Period" />

                            {/* <Popconfirm
                                title="Tambahkan periode Baru?"
                                onConfirm={addPeriod}
                                okText="Ya"
                                cancelText="Tidak"
                            >
                                <Button style={{ float: 'right' }} type="primary" icon={<CalendarOutlined />}>
                                    Add Period
                                </Button>
                            </Popconfirm> */}
                        </Space>
                    </div>}>

                    {
                        (loaded) ?
                            <div>
                                <table className="table-collapse table-default" style={{ width: '100%' }}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '50px' }} className="text-center">No</th>
                                            <th>Year</th>
                                            <th>Period</th>
                                            <th className="text-center" style={{ width: '20%' }}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            periods.map((el, i) => (
                                                <tr key={i}>
                                                    <td className="text-center">{i + 1}</td>
                                                    <td>
                                                        {el.year}
                                                        {
                                                            (el.is_active) ?
                                                                <span className="text-muted"> (Active Period)</span>
                                                                : ''
                                                        }
                                                    </td>
                                                    <td>
                                                        <span className="text-muted">{moment().month(el.from_month - 1).format('MMMM')} - {moment().month(el.to_month - 1).format('MMMM')}</span>
                                                    </td>
                                                    <td className="text-center">
                                                        {
                                                            (!el.is_active) ?
                                                                <Popconfirm
                                                                    title="Ganti Periode Aktif ?"
                                                                    onConfirm={() => changeActivePeriod(el.year, el.from_month, el.to_month)}
                                                                    okText="Ya"
                                                                    cancelText="Tidak"
                                                                >
                                                                    <Button type="link" >
                                                                        Set Active
                                                                    </Button>
                                                                </Popconfirm>
                                                                : '-'
                                                        }
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>

                                </table>
                            </div>
                            :
                            <Spinner />
                    }
                </Card>
            </Card>

            <FormModal form={periodForm} title="Add New Period"
                showModal={formModal}
                onCloseModal={() => setFormModal(false)}
                submitForm={(v) => savePeriod(v)}
                saving={saving}>
                <Form.Item name="kpi_appraisal_period_id" hidden>
                    <Input />
                </Form.Item>
                <Form.Item name="year" label="Year" hidden>
                    <Input />
                </Form.Item>
                <Form.Item name="period_range" label="Period Range" rules={[{ required: true }]}>
                    <RangePicker picker="month" />
                </Form.Item>
            </FormModal>
        </div>
    );
}

export default Period;
