import React from 'react';
import {Tabs, Space} from 'antd'
import {FieldTimeOutlined, CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons'
import {toFullDate} from '../../../utils/dateHandler'
import {DataTable, ShowButton} from '../../../components'

const {TabPane} = Tabs

const RealizationRequest = (props) => {
    // const showLogs = (row) => {
    //     props.showRequestLog(row)
    // }
    const overtimeColumn = [
        {
            title: 'Date',
            dataIndex: 'date_realization',
            key: 'date'
        },{
            title: 'Time',
            align:'center',
            render: (row) => (
                <span>{row.from_time_realization} - {row.to_time_realization}</span>
            )
        },{
            title: 'Overtime Type',
            dataIndex:'type',
            key:'type'
        },{
            title: 'Necessity',
            dataIndex: 'necessity',
            key: 'necessity',
        },{
            title: 'Action',
            align:'center',
            render : (row) => (
                <Space>
                    {/* <ShowButton onShow={() => showLogs(row)}/> */}
                    <ShowButton link to={'/overtime-realization/' + row.id} />
                </Space>
            )
        }
    ];
    const overtimes = [
        ...props.data.map((el) => {
            return {
                id: el.sub_overtime_id,
                date_realization: toFullDate(el.from_date_realization),
                type: el.overtime_type.description,
                from_time_realization: (el.from_time_realization) ? el.from_time_realization.substring(0,5) : '-',
                to_time_realization: (el.to_time_realization) ? el.to_time_realization.substring(0,5) : '',
                necessity: el.description,
                status: el.status,
                status_realization: el.status_realization,
                approver: el.approver_realization,
                created_at: el.created_at
            }
        })
    ];
    return (
        <div>
            <Tabs defaultActiveKey="waiting">
                <TabPane tab={<span><FieldTimeOutlined style={{color:'#4ca3ff'}}/> Waiting Approval</span>} key="waiting">
                    <DataTable size="small" columns={overtimeColumn} dataSource={overtimes.filter(el => el.status_realization==="WAITING")} bordered/>
                </TabPane>
                <TabPane tab={<span><CheckCircleOutlined style={{color:'green'}}/> Approved</span>} key="approved">
                    <DataTable size="small" columns={overtimeColumn} dataSource={overtimes.filter(el => el.status_realization==="APPROVED")} bordered/>
                </TabPane>
                <TabPane tab={<span><CloseCircleOutlined style={{color:'red'}}/> Rejected</span>} key="rejected">
                    <DataTable size="small" columns={overtimeColumn} dataSource={overtimes.filter(el => el.status_realization==="REJECTED")} bordered/>
                </TabPane>
            </Tabs>
        </div>
    );
}

export default RealizationRequest;
