import React, { useEffect, useState } from 'react';
import { Card, Typography, message, Space, Tag } from 'antd'
import { NewButton, EditButton, DeleteButton, PageTitle, Spinner, DataTable } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import moment from 'moment'

const { Text } = Typography
const Index = () => {
    const [vacancies, setVacancies] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const fetchVacancies = () => {
        SentralModel.action('Recruitment', 'getVacancy', {}, 0).then(res => {
            setLoaded(false)
            setVacancies(res.data)
        }).finally(() => {
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchVacancies()
    }, []);

    const dataSource = [
        ...vacancies.map(el => {
            return {
                id: el.rec_vacancy_id,
                division: el.division !== null ? el.division.division_name : '-',
                position: el?.position?.position_name ?? '-',
                subject: el.subject,
                from_date: el.from_date,
                to_date: el.to_date,
                work_location: el.location?.province_name,
                quota: el.quota,
                total_applicant: el.total_applicant,
                status: el.to_date > moment().format('YYYY-MM-DD') ? 'Active' : 'Expired'
            }
        })
    ];

    const columns = [{
        title: 'No',
        dataIndex: 'no',
        key: 'name',
    },
    {
        title: 'Division',
        dataIndex: 'division',
        key: 'division',
        width: '15%',
    },
    {
        title: 'Position',
        dataIndex: 'position',
        key: 'position',
        width: '15%'
    },
    {
        title: 'Subject',
        dataIndex: 'subject',
        key: 'subject',
        width: '20%'
    },
    {
        title: 'From Date',
        dataIndex: 'from_date',
        key: 'from_date',
        width: '10%',
        render: (text) => (
            <div className="text-center">
                {moment(text).format('DD MMMM YYYY')}
            </div>
        )
    },
    {
        title: 'To Date',
        dataIndex: 'to_date',
        key: 'to_date',
        width: '10%',
        render: (text) => (
            <div className="text-center">
                {moment(text).format('DD MMMM YYYY')}
            </div>
        )
    },
    {
        title: 'Work Location',
        dataIndex: 'work_location',
        key: 'work_location',
        width: '15%'
    },
    {
        title: 'Quota',
        dataIndex: 'quota',
        key: 'quota',
        width: '5%',
        render: (text) => (
            <div className="text-right">
                {text}
            </div>
        )
    },
    {
        title: 'Applicant',
        dataIndex: 'total_applicant',
        key: 'total_applicant',
        width: '5%',
        render: (text) => (
            <div className="text-right">
                {text}
            </div>
        )
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: '5%',
        render: (text) => (
            <div className="text-center">
                <Tag color={text === 'Active' ? "green" : "red"}>{text}</Tag>
            </div>
        )
    },
    {
        title: 'Action',
        width: 120,
        render: (row) => (
            <div className="text-center">
                <Space>
                    <EditButton link to={"/management/vacancy/detail/" + row.id} />
                    <DeleteButton onConfirm={() => deleteVacancy(row.id)} />
                </Space>
            </div>
        )
    },
    ];

    const deleteVacancy = (id) => {
        message.loading('deleting Vacancy')
        SentralModel.deleteById('RecruitmentVacancy', id).then((res) => {
            message.destroy()
            message.success('Vacancy deleted', 2)
            let tmp = vacancies
            let index = tmp.findIndex(el => el.rec_vacancy_id === id)
            tmp.splice(index, 1);
            setVacancies([])
            setVacancies(tmp)
        })
    }

    return (
        <div>
            <Card>
                <PageTitle title="Vacancies" breadcrumbs={[['Vacancy']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{ float: 'left' }}>Vacancy List</Text>
                        <NewButton right to="/management/vacancy/detail/0" title="New Vacancy" />
                    </div>}>

                    {
                        (loaded) ?
                            <DataTable size='small' bordered dataSource={dataSource} columns={columns} />
                            : <Spinner />
                    }
                </Card>
            </Card>
        </div>
    );
}

export default Index;
