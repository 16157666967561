import React, {useState, useEffect} from 'react';
import {Card, Row, Col, Tabs, Typography, Descriptions, Space} from 'antd'
import {FieldTimeOutlined,CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons'
import { PageTitle, NewButton, DataTable, Spinner, ShowButton} from '../../../components'
import {SentralModel} from '../../../models/SentralModel'
import authData from '../../../helpers/authData'

const {Text} = Typography
const {TabPane} = Tabs;

const Index = () => {
    const [vouchers, setVouchers] = useState(null);
    const [voucherInfo, setVoucherInfo] = useState(null);
    const [instruction, setInstruction] = useState('');
    const [available, setAvailable] = useState(false);

    const fetchVouchers = () => {
        let payload = {
            filter: [["employee_code", authData.employee_code]]
        }
        SentralModel.list('SubmissionVoucher', payload).then(res => {
            setVouchers(res.data);
        })
    }

    const fetchInstruction = () => {
        SentralModel.list('Instruction', {filter:[['code', 'SBVC']]}, null).then((res) => {
            setInstruction(res.data.length ? res.data[0].content : '');
        })
    }

    const fetchVoucherInfo = () => {
        SentralModel.action('Submission', 'getMonthlyVoucher', null, null).then((res) => {
            setVoucherInfo(res.data[0]);
        })
    }

    const checkApprovalSchema = () => {
        SentralModel.action('Submission', 'checkSchema', { category: 'VOUCHER', division_id: authData.division_id }).then((res) => {
            setAvailable(res.message);
        })
    }

    useEffect(() => {
        fetchVouchers()
        fetchVoucherInfo()
        fetchInstruction()
        checkApprovalSchema()
    }, []);
    
    const voucherData = (vouchers) ? [
        ...vouchers.map(el => {
            return {
                id: el.sub_voucher_id,
                title: el.title,
                type: el.voucher_type.description,
                total: el.total,
                status: el.status,
                approver: el.approver,
                created_at: el.created_at
            }
        })
    ] : [];

    const voucherColumn = [{
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'name',
            width: '10%'
        }, {
            title: 'Title',
            dataIndex: 'title',
            key: 'title'
        }, {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            width: '15%'
        }, {
            title: 'Total Voucher',
            dataIndex: 'total',
            key: 'total',
            width: '15%'
        }, {
            title: 'Action',
            align:'center',
            width: '10%',
            render : (row) => (
                <Space>
                    <ShowButton link to={'/voucher/' + row.id} />
                    {/* <ShowButton onShow={() => showRequestLog(row)}/> */}
                </Space>
            )
        }
    ];

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Voucher" breadcrumbs={[['Voucher']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>Request Voucher</Text>
                        <NewButton disabled={!available} right to="/voucher/create" title={(available) ? "Request Voucher" : 'Approval not available'}/>
                    </div>
                }>
                    <Row justify="space-between">
                        <Col span={4}>
                            <Descriptions size="middle" column={1} bordered layout="vertical">
                                {/* <Descriptions.Item label={<Text strong>Voucher This Month</Text>}>
                                    {
                                        (voucherInfo) ? voucherInfo.total_submission: '0'
                                    }
                                </Descriptions.Item> */}
                                <Descriptions.Item label={<Text strong>Total Voucher This Month</Text>}>
                                    {
                                        (voucherInfo && voucherInfo.total != null) ? voucherInfo.total : 0
                                    }
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col span={19} className="instruction-box">
                            <div dangerouslySetInnerHTML={{ __html: instruction }}></div>
                        </Col>
                    </Row>
                </Card>
                <br/>
                {
                    (vouchers) ?
                        <Tabs defaultActiveKey="waiting" type="card">
                            <TabPane tab={<span><FieldTimeOutlined style={{color:'#4ca3ff'}}/> Waiting Approval</span>} key="waiting">
                                <DataTable size="small" columns={voucherColumn} dataSource={voucherData.filter(el => el.status==='WAITING')} bordered/>
                            </TabPane>
                            <TabPane tab={<span><CheckCircleOutlined style={{color:'green'}}/> Approved</span>} key="approved">
                                <DataTable size="small" columns={voucherColumn} dataSource={voucherData.filter(el => el.status==='APPROVED')} bordered/>
                            </TabPane>
                            <TabPane tab={<span><CloseCircleOutlined style={{color:'red'}}/> Rejected</span>} key="rejected">
                                <DataTable size="small" columns={voucherColumn} dataSource={voucherData.filter(el => el.status==='REJECTED')} bordered/>
                            </TabPane>
                        </Tabs>
                    : <Card><Spinner/></Card>
                }
            </Card>
        </div>
    );
}

export default Index;
