import React, {useEffect, useState} from 'react';
import {Card, Typography, Form, Input, message, Space} from 'antd'
import {AddButton, EditButton, DeleteButton, PageTitle, Spinner, FormModal, DataTable} from '../../../components'
import {SentralModel} from '../../../models/SentralModel'

const {Text} = Typography
const Index = () => {
    const [banks, setBanks] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [saving, setSaving] = useState(false)
    const [modalTitle, setModalTitle]= useState(null)
    const fetchBanks = () =>{
        setLoaded(false)
        SentralModel.list('Bank', {order:'asc',orderBy:'created_at'}).then(res=>{
            setBanks(res.data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchBanks()
    }, []);
    
    const dataSource = [
        ...banks.map( el => {
            return {
                id: el.bank_id,
                bank_name: el.bank_name,
                bank_code: el.bank_code
            }
        })
    ];

    const columns = [{
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'name',
        },
        {
          title: 'Bank Name',
          dataIndex: 'bank_name',
          key: 'bank_name',
          width: '70%'
        },
        {
          title: 'Bank Code',
          dataIndex: 'bank_code',
          key: 'bank_code',
          width: '30%'
        },
        {
            title: 'Action',
            width:120,
            render:(row) =>(
                <div className="text-center">
                    <Space>
                    <EditButton onEdit={() => editBanks(row)}/>
                    <DeleteButton onConfirm={() => deleteBanks(row.id)}/>
                    </Space>
                </div>
            )
        },
    ];

    const addBank=()=>{
        setModalTitle('Add Bank')
        setDefaultValues({
            bank_id: 0,
            bank_name: '',
            bank_code: ''
        })
        setFormModal(true)
    }

    const editBanks = (v) =>{
        setModalTitle('Edit Banks')
        setDefaultValues({
            bank_id: v.id,
            bank_name: v.bank_name,
            bank_code: v.bank_code
        })
        setFormModal(true)
        fetchBanks()
    }

    const deleteBanks = (id) =>{
        message.loading('deleting bank')
        SentralModel.deleteById('Bank', id).then((res)=>{
            message.destroy()
            message.success('Banks deleted', 2)
            let tmp = banks
            let index = tmp.findIndex(el => el.bank_id===id)
            tmp.splice(index, 1);
            setBanks([])
            setBanks(tmp)
        })
    }

    const saveBank = (v) => {
        setSaving(true)
        let payload={
            bank_id: v.id,
            bank_name: v.bank_name,
            bank_code: v.bank_code
        }
        SentralModel.save('Bank', payload, v.bank_id).then((res)=>{
            setFormModal(false)
            fetchBanks()
            message.success('Bank saved',2);
        }).finally(() => {
            setSaving(false)
        })
    }


    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Banks" breadcrumbs={[['Bank']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>Bank List</Text>
                        <AddButton right onAdd={addBank} title="New Bank"/>
                    </div> }>

                    {
                        (loaded) ?
                        <DataTable size='small' bordered dataSource={dataSource} columns={columns}/>
                        :
                        <Spinner/>
                    }
                </Card>
                {
                    (formModal) ?
                    <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v)=>saveBank(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                        <Form.Item name="bank_id" hidden>
                            <Input placeholder="input placeholder" />
                        </Form.Item>
                        <Form.Item label="Bank Name" name="bank_name"  required rules={[{required:true}]}>
                            <Input placeholder="Bank Name" />
                        </Form.Item>
                        <Form.Item label="Bank Code" name="bank_code" rules={[{required:true}]}>
                            <Input placeholder="Bank Code" />
                        </Form.Item>
                    </FormModal> :
                    null
                }
            </Card>
        </div>
    );
}

export default Index;
