import { Row, Col, Button, Form, Input } from 'antd';
import { Uploader } from '../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { changeUploading, fetchListTicket, fetchTicketAssign, fileUploaded, saveSubmitSoftwareTicket } from '../../../../redux/slices/Dashboard/karajoTicketSlice';

const FormatTicketSoftware = (props) => {
    const dispatch = useDispatch()
    const attachmentFile = useSelector((state) => state.karajoTicket.attachmentFile)

    const saveSubmit = (v) => {
        let payload = {
            ...v,
            attachment: attachmentFile
        }

        dispatch(saveSubmitSoftwareTicket(payload)).then(() => {
            dispatch(fetchListTicket())
            dispatch(fetchTicketAssign())
        })
    }

    return (
        <Form layout='vertical' onFinish={(v) => saveSubmit(v)}>
            <Form.Item label="Complain/Problem" name="complain" required rules={[{ required: true }]}>
                <Input placeholder='Complain/Problem' />
            </Form.Item>
            <Form.Item label="Description" name="description" required rules={[{ required: true }]}>
                <Input.TextArea rows={5} placeholder='Description...' />
            </Form.Item>
            <Form.Item label="Attachment">
                <Uploader folder="attachment-ticket-software" onUploaded={(v) => dispatch(fileUploaded(v))} isUploading={(v) => dispatch(changeUploading(v))} view={true} />
            </Form.Item>
            <Row justify='end'>
                <Col>
                    <Form.Item>
                        <Button type='primary' htmlType='submit'>Save</Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}

export default FormatTicketSoftware