import React, {useEffect, useState} from 'react';
import {Card, Row, Col, Tabs, Typography, Descriptions, Tag, Space} from 'antd'
import {FieldTimeOutlined,CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons'
import { PageTitle, NewButton, DataTable, ShowButton, Spinner} from '../../../components'
import authData from '../../../helpers/authData'
import {SentralModel} from '../../../models/SentralModel'
import {toIdr} from '../../../utils/currencyHandler'
import { toFullDate } from '../../../utils/dateHandler';
// import moment from 'moment'

const {Text} = Typography
const {TabPane} = Tabs;

const Index = () => {
    const [purchaseRequests, setPurchaseRequests] = useState([])
    const [purchaseRequestInfo, setPurchaseRequestInfo] = useState(null);
    const [instruction, setInstruction] = useState('')
    const [available, setAvailable] = useState(false)
    const [loaded, setLoaded] = useState(false)

    const fetchPurchaseRequests = () => {
        setLoaded(false)
        let payload={
            division_id: authData.division_id
        }
        SentralModel.action('Submission','getPurchaseRequestList', payload,null).then(res=>{
            setPurchaseRequests(res.data)
            setLoaded(true)
        })
    }

    const fetchInstruction = () => {
        SentralModel.list('Instruction',{filter:[['code','SBPR']]}, null).then((res)=>{
            setInstruction(res.data.length ? res.data[0].content : '')
        })
    }

    const fetchPurchaseRequestInfo = () => {
        SentralModel.action('Submission', 'getMonthlyPurchaseRequest', {division_id: authData.division_id}, null).then((res)=>{
            setPurchaseRequestInfo(res.data[0])
        })
    }

    const checkApprovalSchema = () => {
        SentralModel.action('Submission','checkSchema', {category: 'PR', division_id: authData.division_id}).then((res)=>{
            setAvailable(res.message)
        })
    }

    useEffect(() => {
        fetchPurchaseRequests()
        fetchPurchaseRequestInfo()
        fetchInstruction()
        checkApprovalSchema()
    }, []);
    
    const prData = [
        ...purchaseRequests.map(el =>{
            return {
                id: el.sub_purchase_request_id,
                division: el.division,
                coa: el.coa,
                budget_planning: el.budget_planning,
                due_date: el.due_date,
                is_over_budget: el.is_over_budget,
                total_amount: toIdr(el.total_amount),
                status: el.status,
                approver: el.approver,
                created_at: el.created_at
            }
        })
    ]

    const prColumn = [{
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'name',
        },
        {
            title: 'COA',
            render: (row) => (
                row.coa.coa_name
            )
        },{
            title: 'Budget Planning',
            render: (row) => (
                (row.budget_planning) ? row.budget_planning.description : '-'
            )
        },{
            title: 'Due Date',
            dataIndex: 'due_date',
            key: 'due_date',
            render: (text) => (
                <div className="text-center">
                    {toFullDate(text)}
                </div>
            )
        },{
            title: 'Category',
            dataIndex: 'is_over_budget',
            render : (text) => (
                <div className="text-center">
                    {(text==='0') ? <Tag color="green">ON BUDGET</Tag> : <Tag color="red">OVER BUDGET</Tag>}
                </div>
            )
        },{
            title: 'Total',
            dataIndex: 'total_amount',
            key: 'total_amount',
            render: (text) => (
                <div className="text-right">
                    {text}
                </div>
            )
        },{
            title: 'Action',
            align:'center',
            render : (row) => (
                <Space>
                    <ShowButton link to={'/purchase-request/detail?id=' + row.id} />
                    {/* <ShowButton onShow={() => showRequestLog(row)}/> */}
                </Space>
            )
        }
    ];

    return (
        <Card className="content-container">
            <PageTitle title="Purchase Request" breadcrumbs={[['Purchase Request']]}></PageTitle>
            <Card title={
                <div className="full-width">
                    <Text strong style={{float:'left'}}>Purchase Request Submission</Text>
                    <NewButton disabled={!available} right to="/purchase-request/create" title={(available) ? "Create Purchase Request" : 'Approval not available'}/>
                </div>
            }>
                {
                    (loaded) ?
                    <Row justify="space-between">
                        <Col span={4}>
                            <Descriptions size="middle" column={1} bordered layout="vertical">
                                <Descriptions.Item label={<Text strong>Purchase Request This Month</Text>}>
                                    {
                                        (purchaseRequestInfo) ? purchaseRequestInfo.total_submission : '0'
                                    }
                                </Descriptions.Item>
                                <Descriptions.Item label={<Text strong>Total Purchase Request</Text>}>
                                    {
                                        (purchaseRequestInfo) ? toIdr(purchaseRequestInfo.total_amount) : 'Rp 0'
                                    }
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col span={19} className="instruction-box">
                            <div dangerouslySetInnerHTML={{ __html: instruction }}></div>
                        </Col>
                    </Row>
                    : <Spinner/>
                }
            </Card>
            <br/>
            {
                (loaded) ?
                <Tabs defaultActiveKey="waiting" type="card">
                    <TabPane tab={<span><FieldTimeOutlined style={{color:'#4ca3ff'}}/> Waiting Approval</span>} key="waiting">
                        <DataTable size="small" columns={prColumn} dataSource={prData.filter(el => el.status==="WAITING")} bordered/>
                    </TabPane>
                    <TabPane tab={<span><CheckCircleOutlined style={{color:'green'}}/> Approved</span>} key="approved">
                        <DataTable size="small" columns={prColumn} dataSource={prData.filter(el => el.status==="APPROVED")} bordered/>
                    </TabPane>
                    <TabPane tab={<span><CloseCircleOutlined style={{color:'red'}}/> Rejected</span>} key="rejected">
                        <DataTable size="small" columns={prColumn} dataSource={prData.filter(el => el.status==="REJECTED")} bordered/>
                    </TabPane>
                </Tabs>
                : <Card><Spinner/></Card>
            }

        </Card>
    );
}

export default Index;
