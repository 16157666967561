import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Typography } from 'antd'
import { PageTitle, PrintPdfButton, Spinner } from '../../../../components'
import ApprovalLog from '../../ApprovalLog'
import DetailHeader from '../../DetailHeader'
import { useParams } from 'react-router-dom';
import { SentralModel } from '../../../../models/SentralModel';
import { toIdr } from '../../../../utils/currencyHandler'

const { Text } = Typography

const Detail = () => {
    const { commissionId } = useParams()
    const [commission, setCommission] = useState(null)

    const fetchReimbursement = (id) => {
        SentralModel.get('SubmissionCommission', {}, id).then((res) => {
            setCommission(res.data)
        })
    }

    useEffect(() => {
        fetchReimbursement(commissionId)
    }, [commissionId]);

    const printData = () => {
        window.open(process.env.REACT_APP_API_URL + '/print/commission/' + commissionId)
    }

    return (
        <Card className="content-container">
            <div>
                <PageTitle title="Commission" breadcrumbs={[['Commission', '/commission'], ['Detail']]}></PageTitle>
            </div>
            {
                (commission) ?
                    <div>
                        <Row className="mb-3">
                            <Col span={24} >
                                <PrintPdfButton right onPrint={printData} />
                            </Col>
                        </Row>
                        <DetailHeader
                            name={commission.employee.name}
                            date={commission.created_at}
                            status={commission.status} />
                        <Card bordered className="mb-4">
                            <Text strong>COMMISSION DETAIL</Text>
                            <Row className="mb-4 mt-1">
                                <Col span={12}>
                                    <table className="table-collapse table-default" style={{ width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <th>Employee Name</th><td>{commission.employee.name}</td>
                                            </tr>
                                            <tr>
                                                <th>Division</th><td>{commission.employee.division.division_name}</td>
                                            </tr>
                                            <tr>
                                                <th>Position</th><td>{commission.employee.position.position_name}</td>
                                            </tr>
                                            <tr>
                                                <th>Description</th><td>{commission.title}</td>
                                            </tr>
                                            <tr>
                                                <th>Total Amount</th><td>{toIdr(commission.total_amount)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </Card>
                        {
                            commission.employee.division_id === 9 ?
                                <Card bordered className="mb-4">
                                    <Text strong>COMMISSION TICKET</Text>
                                    <Row className="mb-4 mt-1">
                                        <Col span={24}>
                                            <table className="table-collapse table-default" style={{ width: '100%' }}>
                                                <thead>
                                                    <tr>
                                                        <th>Ticket Number</th>
                                                        <th>Company Name</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        commission.tickets.map((el, key) => (
                                                            <tr key={key}>
                                                                <td>{el.ticket_no}</td>
                                                                <td>{el.company_name}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </Col>
                                    </Row>
                                </Card>
                                : null
                        }
                        <Card bordered className="mb-4">
                            <Text strong>COMMISSION ITEM</Text>
                            <Row className="mb-4 mt-1">
                                <Col span={24}>
                                    <table className="table-collapse table-default" style={{ width: '100%' }}>
                                        <thead>
                                            <tr>
                                                <th>No Invoice</th>
                                                <th>No SO</th>
                                                <th>Category</th>
                                                <th>Sub Category</th>
                                                <th>Quantity</th>
                                                <th>Amount</th>
                                                <th>Attachment</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                commission.items.map((el, key) => (
                                                    <tr key={key}>
                                                        <td>{el.no_invoice ?? "-"}</td>
                                                        <td>{el.no_so ?? "-"}</td>
                                                        <td>{el.category ?? "-"}</td>
                                                        <td>{el.sub_category ?? "-"}</td>
                                                        <td>{el.quantity ?? "-"}</td>
                                                        <td>{toIdr(el.amount)}</td>
                                                        <td>
                                                            {
                                                                (el.attachment) ?
                                                                    <a rel="noopener noreferrer" target='_blank' href={process.env.REACT_APP_API_FILE + '/' + el.attachment}>
                                                                        show attachment
                                                                    </a> : '-'
                                                            }
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th colSpan={5} id="total">Total</th>
                                                <td>{toIdr(commission.total_amount)}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </Col>
                            </Row>
                        </Card>
                        <Card bordered>
                            <ApprovalLog category="COMMISSION"
                                approvers={commission.approver} />
                        </Card>
                    </div> : <Spinner />
            }

        </Card>
    );
}

export default Detail;
