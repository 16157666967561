import React, { useEffect, useState } from 'react';
import { Card, Row, Col } from 'antd';
import { PageTitle, Spinner, PrintPdfButton, DataTable } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import { useParams } from 'react-router-dom'
import moment from 'moment'

const ApplicantDetail = () => {
    const { applicantId } = useParams();
    const [applicant, setApplicant] = useState([]);
    const [education, setEducation] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const fetchRecruitment = (applicantId) => {
        setLoaded(false)
        SentralModel.list('RecruitmentApplicant', { filter: [['rec_applicant_id', applicantId]] }, 0).then(res => {
            setApplicant(res.data[0])
            setLoaded(true)
        })
    }

    const fetchEducation = (applicantId) => {
        SentralModel.list('RecruitmentApplicantEducation', { filter: [['rec_applicant_id', applicantId]] }, 0).then(res => {
            setEducation(res.data)
        })
    }

    const columns = [{
        title: 'No',
        dataIndex: 'no',
        key: 'name',
        align: 'center'
    },
    {
        title: 'Education',
        render: (row) => (
            <div className="text-center">
                {row.education_tier.description}
            </div>
        ),
        key: 'education',
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name'
    },
    {
        title: 'Period',
        render: (row) => (
            row.year_in !== '-' & row.year_out !== '-' ?
                <div className="text-center">
                    {row.year_in} - {row.year_out}
                </div> :
                <div className="text-center">
                    -
                </div>
        ),
        key: 'period'
    },
    {
        title: 'Majors',
        render: (row) => (
            row.majors === '-' || row.majors === "" ?
                <div>
                    -
                </div> :
                <div>
                    {row.majors}
                </div>
        ),
        key: 'majors'
    },
    {
        title: 'Pass Value',
        render: (row) => (
            parseInt(row.pass_value) === 0 ?
                <div className="text-center">
                    -
                </div> :
                <div className="text-center">
                    {row.pass_value}
                </div>
        ),
        key: 'pass_value'
    }
    ];

    useEffect(() => {
        fetchRecruitment(applicantId)
        fetchEducation(applicantId)
    }, [applicantId]);

    const date = `${moment(applicant.date_of_birth).format("DD MMMM YYYY")}`;

    const exportReport = () => {
        window.open(process.env.REACT_APP_API_URL + `/report/vacancy/detail/applicant/${applicantId}`)
    }

    return (
        <Card className="content-container">
            <div>
                <PageTitle title="Applicant Detail" breadcrumbs={[['Report', '/report/vacancy'], ['Applicant Detail']]}></PageTitle>
            </div>

            {
                loaded ?
                    <div>
                        <Row className="mb-3">
                            <Col span={24} >
                                <PrintPdfButton right onPrint={exportReport} />
                            </Col>
                        </Row>
                        <Card bordered className="mb-4">
                            <Row className="mb-4 mt-1">
                                <Col span={12}>
                                    <table className="table-collapse table-default" style={{ width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <th>Name</th><td>{applicant.name}</td>
                                            </tr>
                                            <tr>
                                                <th>Gender</th><td>{applicant.gender_detail.description}</td>
                                            </tr>
                                            <tr>
                                                <th>Place & Date of Birth</th><td>{applicant.place_of_birth}, {date}</td>
                                            </tr>
                                            <tr>
                                                <th>Martial Status</th><td>{applicant.marital_status_detail.description}</td>
                                            </tr>
                                            <tr>
                                                <th>Religion</th><td>{applicant.religion_detail.description}</td>
                                            </tr>
                                            <tr>
                                                <th>Hobby</th><td>{applicant.hobbies}</td>
                                            </tr>
                                            <tr>
                                                <th>Phone Number</th><td>{applicant.mobile_phone_no}</td>
                                            </tr>
                                            <tr>
                                                <th>Address</th><td>{applicant.current_address}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </Card>
                        <Card>
                            <DataTable dataSource={education} columns={columns} />
                        </Card>
                    </div> : <Spinner />
            }
        </Card >
    );
}

export default ApplicantDetail;
