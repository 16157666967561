import React, { useState } from 'react';
import { Card, message } from 'antd';
import { PageTitle } from '../../../components'
import RequestForm from './RequestForm'
import { SentralModel } from '../../../models/SentralModel';
import { useHistory } from 'react-router-dom'

const Create = () => {
    const history = useHistory()
    const [saving, setSaving] = useState(false)

    const submitLeave = (v) => {
        if (v.limit >= 0 && v.total_days > v.limit) {
            message.error('Batas pengajuan cuti adalah ' + v.limit + ' hari', 3)
        } else {
            setSaving(true)
            SentralModel.action('Submission', 'saveLeave', v, 0).then(() => {
                message.destroy()
                message.success('Leave submitted', 1)
                setSaving(false)
                setTimeout(() => {
                    history.push('/leave');
                }, 1000);
            }).catch((err) => {
                setSaving(false)
            })
        }
    }
    return (
        <Card className="content-container">
            <PageTitle title="Leave Submission" breadcrumbs={[['Leave', '/leave'], ['Create']]}></PageTitle>
            <RequestForm onSubmit={(v) => submitLeave(v)} saving={saving} />
        </Card>
    );
}

export default Create;
