import React, {useState} from 'react';
import {Card, Row, Col, Select, Empty, DatePicker, Typography} from 'antd';
import {PageTitle, Spinner} from '../../components'
import {SentralModel} from '../../models/SentralModel'
import authData from '../../helpers/authData'
import {toFullDate} from '../../utils/dateHandler'
import {getSessionStorage} from '../../helpers/sessionStorage'
import {toIdr} from '../../utils/currencyHandler'
import moment from 'moment'

const {Text} = Typography
const {Option} = Select
const Monthly = () => {

    const [loaded, setLoaded] = useState(true);
    const [periods, setPeriods] = useState([]);
    const [payrolls, setPayrolls] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState(null);

    const fetchPeriods = (year) => {
        SentralModel.list('PayrollPeriod', { filter: [['year', year]] }, {orderBy: 'from_date', order: 'desc'}).then(res=> {
            setPeriods(res.data)
            setInitialValue()
        })
    }

    const setInitialValue = () => {
        let period = getSessionStorage('payrollMonthlyPeriod')
        if(period){
            setSelectedPeriod(period)
            changePeriod(period)
        }else{
            setLoaded(true)
        }
    }

    const onChangeYear = (v) => {
        let year = moment(v).format('YYYY');
        setPeriods([])
        fetchPeriods(year)
    }

    const changePeriod = (v) => {
        setSelectedPeriod(v)
        setLoaded(false)
        sessionStorage.setItem('payrollMonthlyPeriod', v)
        SentralModel.action('Payroll','getActivitySummary',{employee_code: authData.employee_code, period_id:v},0).then((res)=>{
            setPayrolls(res.data)
            setLoaded(true)
        })
    }

    // const getLeaveCount = (type) =>{
    //     let total=0;
    //     payrolls.leaves.forEach( el=> {
    //         if(el.type===type){
    //             total+=el.total
    //         }
    //     });
    //     return total
    // }
    // const getAlphaCount = () =>{
    //     return payrolls.period.total_working_days - payrolls.period.work_from_home - payrolls.period.working_days - getLeaveCount('PAID') - getLeaveCount('UNPAID')
    // }

    const disabledDate = (current) =>{
        let currentMonth = moment().format('MM');
        let addMonth = 1;

        if (currentMonth === '12') {
            addMonth = 2;
        }

        let customDate = moment(new Date()).add(addMonth, 'y').format('YYYY');
        return current && current > moment(customDate, "YYYY");
        
    }; 

    return (
        
        <div>
            <Card className="content-container">
                <PageTitle title="Payroll" breadcrumbs={[['Payroll']]}></PageTitle>
                
                <Row justify="end" className="mb-3">
                    <Col span={24} >
                        <span style={{float:'right'}}>
                            <Select value={(selectedPeriod) ? parseInt(selectedPeriod) : null} placeholder="Select Period" style={{ width: 200, marginRight:10 }} onChange={changePeriod}>
                                {
                                    periods.map((el, key) => (
                                        <Option key={key} value={el.payroll_period_id}>{el.period}</Option>
                                    ))
                                }
                            </Select>
                        </span>
                        <span style={{float:'right', marginRight: '20px'}}>
                            <DatePicker picker="year" onChange={onChangeYear} disabledDate={disabledDate} placeholder="Select Year"/>
                        </span>
                    </Col>
                </Row>
                {
                    loaded ?
                        payrolls.length !== 0 ? 
                        <div>
                        <Row style={{marginBottom:20}}>
                            <Col span={24}>
                                <table className="table-collapse table-payroll" style={{width:'100%',}}>
                                    <thead>
                                        <tr>
                                            <th className="table-period-title" style={{width:100/4+'%'}}>Name</th>
                                            <th className="table-period-title" style={{width:100/4+'%'}}>Division</th>
                                            <th className="table-period-title" style={{width:100/4+'%'}}>Position</th>
                                            <th className="table-period-title" style={{width:100/4+'%'}}>Period</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{authData.name}</td>
                                            <td>{authData.division.division_name}</td>
                                            <td>{authData.position.position_name}</td>
                                            <td>{toFullDate(payrolls.period.from)} - {toFullDate(payrolls.period.to)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                        <Row style={{marginBottom:20}}>
                            <Col span={24}>
                                <table className="table-collapse table-payroll" style={{width:'100%',}}>
                                    <thead>
                                        <tr>
                                            <th colSpan="7" className="table-period-title">
                                                Attendance
                                            </th>
                                        </tr>
                                        <tr>
                                            <th style={{width:100/7+'%'}}>Working Days (WFO)</th>
                                            <th style={{width:100/7+'%'}}>WFH</th>
                                            <th style={{width:100/7+'%'}}>Alpha</th>
                                            <th style={{width:100/7+'%'}}>Paid Leave</th>
                                            <th style={{width:100/7+'%'}}>Unpaid Leave</th>
                                            <th style={{width:100/7+'%'}}>Weekend</th>
                                            <th style={{width:100/7+'%'}}>Public Holiday</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{payrolls.period.working_days} / {payrolls.period.total_working_days}</td>
                                            <td>{payrolls.period.work_from_home}</td>
                                            <td>{payrolls.period.alpha}</td>
                                            {/* <td>{getLeaveCount('PAID')}</td> */}
                                            {/* <td>{getLeaveCount('UNPAID')}</td> */}
                                            <td>{payrolls.period.paid_leave}</td>
                                            <td>{payrolls.period.unpaid_leave}</td>
                                            <td>{payrolls.period.weekend}</td>
                                            <td>{payrolls.period.public_holidays}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                        <Row style={{marginBottom:20}}>
                            <Col span={24}>
                                <table className="table-collapse table-payroll" style={{width:'100%',}}>
                                    <thead>
                                        <tr>
                                            <th colSpan="2" className="table-period-title">
                                                Other
                                            </th>
                                            <th colSpan={payrolls.lates.length} className="table-period-title">
                                                Late
                                            </th>
                                        </tr>
                                        <tr>
                                            <td style={{width:100/7+'%'}}><Text strong>Claim Medical</Text></td>
                                            <td style={{width:100/7+'%'}}><Text strong>Weekly Incentive</Text></td>
                                            {
                                                payrolls.lates.map((el, key)=>(
                                                    <th key={key} className="text-center" style={{width:100/7+'%'}}>
                                                        <Text strong>{el.description}</Text>
                                                    </th>
                                                ))
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {
                                                    (payrolls) ? toIdr(payrolls.period.claim_medical > 0 ? payrolls.period.claim_medical : 0) : 'Rp 0'
                                                }
                                            </td>
                                            <td>
                                                {payrolls.period.weekly_incentive ? payrolls.period.weekly_incentive : 0}
                                            </td>
                                            {
                                                payrolls.lates.map((el, key)=>(
                                                    <td  key={key} className="text-center">
                                                        {el.total}
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                        <Row style={{marginBottom:20}}>
                            <Col span={24}>
                                <table className="table-collapse table-payroll" style={{width:'100%',}}>
                                    <thead>
                                        <tr>
                                            <th colSpan={payrolls.overtimes.length} className="table-period-title">
                                                Overtime
                                            </th>
                                        </tr>
                                        <tr>
                                            {
                                                payrolls.overtimes.map((el, key)=>(
                                                    <th key={key} className="text-center" style={{width:100/payrolls.overtimes.length+'%'}}>
                                                        <Text strong>{el.description}</Text>
                                                    </th>
                                                ))
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            {
                                                payrolls.overtimes.map((el, key)=>{
                                                    let hours = Math.floor(el.total /60)
                                                    let minutes = el.total % 60 
                                                    let td = 
                                                        <td key={key} className="text-center">
                                                            {el.total} kali
                                                        </td>

                                                    if (el.sub_category !== 'MORE20') {
                                                        if (el.total === 0) {
                                                            td = 
                                                                <td key={key} className="text-center">
                                                                    {el.total} menit
                                                                </td>
                                                        } else {
                                                            td = 
                                                                <td key={key} className="text-center">
                                                                    {el.total} menit ({hours} jam {minutes} menit)
                                                                </td>
                                                        }
                                                    }

                                                    return (td)
                                                })
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                        </div>
                        : <Empty />
                    : <Spinner />
                }
            </Card>
        </div>
    );
}

export default Monthly;
