import { useState, useEffect } from 'react'
import { SentralModel } from '../../../models/SentralModel'
import { Button, Card, Row, Form, Select, InputNumber, message, Space } from 'antd'
import { DataTable, DeleteButton, FormModal, PageTitle, Spinner } from '../../../components'

const Index = () => {
    const [settingData, setSettingData] = useState([])
    const [employees, setEmployees] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [modalShow, setModalShow] = useState(false)
    const [modalTitle, setModalTitle] = useState("")

    const fetchSettingData = () => {
        setLoaded(false)
        SentralModel.action("ManualAttendanceSetting", "list", null, null).then((res) => {
            setSettingData(res.data)
            setLoaded(true)
        })
    }

    const fetchEmployees = () => {
        SentralModel.list('Employee', { filter: [['is_active', '=', '1'], ['employee_status', ['ESPR', 'ESPB', 'ESKN']]], orderBy: 'name', order: 'asc' })
            .then(res => {
                const v = res.data
                let arr = []

                v.forEach((el) => {
                    arr.push({ value: el.employee_code, label: el.name })
                })
                setEmployees(arr)
            })
    }

    useEffect(() => {
        fetchSettingData()
        fetchEmployees()

    }, [])

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'name',
            width: '5%'
        },
        {
            title: 'Employee',
            render: (row) => (
                row?.employee?.name
            ),
            key: 'employee'
        },
        {
            title: 'Limit Days',
            dataIndex: 'limit_days',
            key: 'limit_days'
        },
        {
            title: 'Action',
            render: (row) => (
                <Space>
                    <DeleteButton onConfirm={() => deleteData(row?.manual_attendance_setting_id)} />
                </Space>
            ),
            key: 'action',
            align: 'center',
            widht: '3%'
        }
    ]

    const onShowAddModal = () => {
        setModalShow(true)
        setModalTitle("Add Setting")
    }

    const deleteData = (v) => {
        SentralModel.action("ManualAttendanceSetting", "deleteData", null, v).then((res) => {
            message.success("Success Delete Data")
            fetchSettingData()
        }).catch((err) => {
            message.success("Success Delete Data")
        })
    }

    const saveSetting = (v) => {
        let payload = {
            ...v
        }

        SentralModel.action("ManualAttendanceSetting", "create", payload, null).then((res) => {
            message.success("Success Add Setting")
            fetchSettingData()
            setModalShow(false)
        }).catch((err) => {
            message.success("Failed Add Setting")
        })
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Manual Attendance Setting" breadcrumbs={[['Manual Attendance Setting']]}></PageTitle>
                <Card title={
                    <Row justify='end'>
                        <Button type="primary" onClick={onShowAddModal}>
                            Add Setting
                        </Button>
                    </Row>
                }>
                    {
                        loaded ?
                            <DataTable dataSource={settingData} columns={columns} bordered size="small" />
                            : <Spinner />
                    }
                </Card>
            </Card>

            {
                modalShow ?
                    <FormModal title={modalTitle} showModal={modalShow} onCloseModal={() => setModalShow(false)} submitForm={saveSetting}>
                        <Form.Item label="Employee" name="employee_code" rules={[{ required: true }]}>
                            <Select
                                showSearch
                                placeholder="Select Employee"
                                options={employees}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            />
                        </Form.Item>
                        <Form.Item label="Limit Days" name="limit_days" rules={[{ required: true }]}>
                            <InputNumber max={30} min={1} style={{ width: '100%' }} />
                        </Form.Item>
                    </FormModal>
                    : null
            }
        </div>
    )
}

export default Index