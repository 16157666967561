import { useState, useEffect } from 'react'
import { Card } from 'antd'
import { PageTitle } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'

const SasaranMutu = () => {
    const [loaded, setLoaded] = useState(false)
    const [qualityGoals, setQualityGoals] = useState([])

    const fetchQualityGoals = () => {
        SentralModel.action('ChangeRequestForm', 'qualityGoals', null, null).then((res) => {
            setQualityGoals(res.data)
            setLoaded(true)
        }).catch((err) => {
        })
    }

    useEffect(() => {
        fetchQualityGoals()

    }, [])

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Sasaran Mutu" breadcrumbs={[['Change Request Form', '/crf'], ['Sasaran Mutu']]}></PageTitle>
                <Card title={`Sasaran Mutu ${new Date().getFullYear()}`}>
                    {
                        loaded ?
                            <table className="styled-table">
                                <thead>
                                    <tr>
                                        <th style={{ border: '0.5px solid grey', width: 40 }}></th>
                                        <th style={{ border: '0.5px solid grey', width: 25 }}></th>
                                        <th style={{ border: '0.5px solid grey', width: 30 }}></th>
                                        <th style={{ border: '0.5px solid grey' }}>JAN</th>
                                        <th style={{ border: '0.5px solid grey' }}>FEB</th>
                                        <th style={{ border: '0.5px solid grey' }}>MAR</th>
                                        <th style={{ border: '0.5px solid grey' }}>APR</th>
                                        <th style={{ border: '0.5px solid grey' }}>MEI</th>
                                        <th style={{ border: '0.5px solid grey' }}>JUN</th>
                                        <th style={{ border: '0.5px solid grey' }}>JUL</th>
                                        <th style={{ border: '0.5px solid grey' }}>AGU</th>
                                        <th style={{ border: '0.5px solid grey' }}>SEP</th>
                                        <th style={{ border: '0.5px solid grey' }}>OKT</th>
                                        <th style={{ border: '0.5px solid grey' }}>NOV</th>
                                        <th style={{ border: '0.5px solid grey' }}>DES</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ border: '0.5px solid grey', width: 40 }}>
                                            Pencapaian kompetensi personil software developer di dept IT sesuai dengan matriks pengembangan rekayasa perangkat lunak 100% ≤ 1 tahun
                                        </td>
                                        <td style={{ border: '0.5px solid grey', width: 25 }}>
                                            Meningkatkan Mutu Dalam Pengembangan Software
                                        </td>
                                        <td style={{ border: '0.5px solid grey', width: 25 }}>
                                            Melakukan evaluasi bulanan terkait dengan pencapaian kompetensi sesuai levelnya.
                                        </td>
                                        {
                                            qualityGoals.total.map((el, key) => (
                                                <td className='text-center' style={{ border: '0.5px solid grey' }} key={key}>
                                                    {
                                                        el.percent === null ? `0 %` : `${Math.ceil(el.percent)}%`
                                                    }
                                                </td>
                                            ))
                                        }
                                    </tr>
                                    <tr>
                                        <td style={{ border: '0.5px solid grey', width: 40 }}>
                                            (Software Development Life Cycle) dalam setiap pengembangan perangkat lunak secara lengkap meliputi:
                                            <ul>
                                                <li>Change Request Form</li>
                                                <li>Permintaan aplikasi</li>
                                                <li>Funtional Requirements</li>
                                                <li>Timeline</li>
                                                <li>Design</li>
                                                <li>Prototype</li>
                                                <li>User Manual</li>
                                                <li>Fuctional test</li>
                                                <li>UAT</li>
                                                <li>Vulnerability</li>
                                                <li>Penetration Test (New Application)</li>
                                                <li>Deployment</li>
                                                <li>BAST</li>
                                            </ul>
                                            Untuk pengukuran SDLC tersebut sesuai dengan banyaknya SDLC yang telah selesai dikerjakan.
                                        </td>
                                        <td style={{ border: '0.5px solid grey', width: 25 }}>
                                            Mendukung Pengembangan Software
                                        </td>
                                        <td style={{ border: '0.5px solid grey', width: 25 }}>
                                            Melakukan audit SDLC setiap penyelesaian Aplikasi.
                                        </td>

                                        {
                                            qualityGoals.crf.map((el, key) => (
                                                <td style={{ border: '0.5px solid grey', textAlign: 'center' }} key={key}>
                                                    {
                                                        el.closed === 0 && el.open === 0 ? 0 :
                                                            Math.ceil((el.closed / (el.closed + el.open)) * 100)
                                                    }%
                                                </td>
                                            ))
                                        }
                                    </tr>
                                </tbody>
                            </table >
                            : null
                    }
                </Card>
            </Card>
        </div>
    )
}

export default SasaranMutu