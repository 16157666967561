import React, { useState, useEffect } from 'react';
import { Typography, Form, Input, message, DatePicker, Select } from 'antd'
import moment from 'moment'
import { FormModal, DataTable, Spinner, EditButton, AddButton } from '../../../components';
import { SentralModel } from '../../../models/SentralModel';
import { toIdr, extractNumber } from '../../../utils/currencyHandler'
const { Title } = Typography
const { RangePicker } = DatePicker

const OvertimeCategory = () => {
    const [overtimeForm] = Form.useForm()
    const [formModal, setFormModal] = useState(false)
    const [overtimeCategories, setOvertimeCategories] = useState([])
    const [saving, setSaving] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [formState, setFormState] = useState('add')

    const fetchOvertimeCategories = () => {
        setLoaded(false)
        SentralModel.list('OvertimeCategory').then(res => {
            setOvertimeCategories(res.data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchOvertimeCategories()
    }, []);

    const editComponent = (row) => {
        setFormState('edit')
        overtimeForm.setFieldsValue({
            overtime_category_id: row.overtime_category_id,
            description: row.description,
            category: row.category,
            rate: toIdr(row.rate),
            time_range: [moment(row.from_time, 'HH:mm:ss'), moment(row.to_time, 'HH:mm:ss')],
        })
        setFormModal(true)
    }

    const columns= [{
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'name',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        }, {
            title: 'Category',
            dataIndex: 'category',
            align: 'center',
            key: 'category'
        }, {
            title: 'From',
            dataIndex: 'from_time',
            align: 'center',
            key: 'from_time'
        }, {
            title: 'To',
            dataIndex: 'to_time',
            align: 'center',
            key: 'to_time'
        }, {
            title: 'Rate',
            dataIndex: 'rate',
            key: 'rate',
            render: (text) => (
                toIdr(text)
            )
        }, {
            title: 'Action',
            align: 'center',
            width: '10%',
            render: (row) => (
                <EditButton onEdit={() => editComponent(row)} />
            )
        },
    ];

    const addComponent = () => {
        setFormState('add')
        overtimeForm.resetFields()
        overtimeForm.setFieldsValue({ overtime_category_id: 0, category: null })
        setFormModal(true)
    }


    const saveOvertime = (v) => {
        setSaving(true)
        let data = {
            overtime_category_id: v.overtime_category_id,
            description: v.description,
            category: v.category,
            from_time: moment(v.time_range[0]).format('HH:mm:ss'),
            to_time: moment(v.time_range[1]).format('HH:mm:ss'),
            rate: extractNumber(v.rate)
        }
        SentralModel.save('OvertimeCategory', data, v.overtime_category_id).then(() => {
            fetchOvertimeCategories()
            message.success('Overtime Category saved');
            setSaving(false)
            setFormModal(false)
        })
    }

    const changeRate = (v) => {
        if (v.target.value) {
            overtimeForm.setFieldsValue({ rate: toIdr(extractNumber(v.target.value)) })
        } else {
            overtimeForm.setFieldsValue({ rate: 0 })
        }
    }

    return (
        <div>
            <Title level={4}>Overtime Category</Title>
            <div className="text-right mb-2">
                <AddButton onAdd={addComponent} title="Add Overtime Category" />
            </div>
            {
                loaded ?
                    <DataTable bordered size="small" columns={columns} dataSource={overtimeCategories} />
                    : <Spinner />
            }

            <FormModal form={overtimeForm} title={(formState === "add") ? 'Add new Overtime Category' : 'Edit Overtime Category'}
                showModal={formModal}
                onCloseModal={() => setFormModal(false)}
                submitForm={(v) => saveOvertime(v)}
                saving={saving}>
                <Form.Item name="overtime_category_id" hidden>
                    <Input />
                </Form.Item>
                <Form.Item name="description" label="Overtime Description">
                    <Input />
                </Form.Item>
                <Form.Item name="category" label="Category">
                    <Select>
                        <Select.Option value="ALL">ALL</Select.Option>
                        <Select.Option value="WEEKDAY">WEEKDAY</Select.Option>
                        <Select.Option value="WEEKEND">WEEKEND</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item name="time_range" label="Time">
                    <RangePicker picker="time" />
                </Form.Item>
                <Form.Item name="rate" label="Rate">
                    <Input onChange={(v) => changeRate(v)} />
                </Form.Item>
            </FormModal>
        </div>
    );
}

export default OvertimeCategory;
