import React, {useEffect, useState} from 'react';
import {Card, Typography,Space, Button, Select, Empty, Tabs, Row} from 'antd'
import {Link} from 'react-router-dom'
import {ZoomInOutlined} from '@ant-design/icons'
import { PageTitle, Spinner} from '../../../components'
import {SentralModel} from '../../../models/SentralModel'

const {Text} = Typography
const {Option} = Select
const {TabPane} = Tabs;

const Akumulasi = () => {
    const [appraisals, setAppraisals] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [periods, setPeriods] = useState([])
    const [selectedPeriod, setSelectedPeriod] = useState(null)
    const [selectedDivision, setSelectedDivision] = useState(null)
    const [selectedPosition, setSelectedPosition] = useState(null)
    const [divisions, setDivisions] = useState([])
    const [positions, setPositions] = useState([])


    const fetchPeriods = () => {
        SentralModel.list('KpiAppraisalPeriod',{orderBy:'year',order:'asc'},null).then((res)=>{
            setPeriods(res.data)
            let currentPeriod = res.data.filter(el => el.is_active);
            setSelectedPeriod(parseInt(currentPeriod[0].year))
        })
    }

    const fetchAppraisals = (divisionId=null, positionId=null, year=null) =>{
        setLoaded(false)
        SentralModel.action('Appraisal','getAppraisalAkumulasi',{division_id : divisionId, position_id:positionId, year:year}).then(res=>{
            setAppraisals(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }
    const fetchDivisions  = () =>{
        SentralModel.list('Division', {fields:"division_id,division_name"}).then((res) => {
            setDivisions(res.data)
        })
    }
    const fetchPositions  = () =>{
        SentralModel.list('Position', {fields:"position_id,position_name"}).then((res) => {
            setPositions(res.data)
        })
    }

    useEffect(() => {
        let divisionId = sessionStorage.getItem('selectedAppraisalDivision') || 0
        if(divisionId){
            setSelectedDivision(parseInt(divisionId))
        }else{
            setSelectedDivision(0)
        }
        let positionId = sessionStorage.getItem('selectedAppraisalPosition') || 0
        if(positionId){
            setSelectedPosition(parseInt(positionId))
        }else{
            setSelectedPosition(0)
        }
        fetchPeriods()
        fetchDivisions()
        fetchPositions()
        fetchAppraisals(divisionId, positionId)
    }, []);

    const changeDivision = (v)=>{
        setLoaded(false)
        fetchAppraisals(v, selectedPosition)
        setSelectedDivision(v)
        sessionStorage.setItem('selectedAppraisalDivision', v)
    }
    const changePosition = (v)=>{
        setLoaded(false)
        fetchAppraisals(selectedDivision, v)
        setSelectedPosition(v)
        sessionStorage.setItem('selectedAppraisalPosition', v)
    }

    const changePeriod = (v) => {
        setSelectedPeriod(v)
        setLoaded(false)
        fetchAppraisals(selectedDivision, selectedPosition, v)
    }

    // const orderedData = (v) => {
    //     let x =  v.sort((a, b) => {
    //         if(a.appraisal && b.appraisal){
    //             return (a.appraisal.kpi_value < b.appraisal.kpi_value) ? 1 : -1
    //         }else{
    //             return 9999;
    //         }
    //     })
    // }
    
    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Akumulasi Appraisal" breadcrumbs={[['Appraisal','/management/appraisal'],['Akumulasi']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>Akumulasi Penilaian</Text>
                    </div> }>
                        <div>
                            <Row>
                                <div className="full-width">
                                    <span className="mb-3" style={{float:'right'}}>
                                        <Space>
                                            <Select placeholder="Select Period" value={selectedPeriod} style={{ width: 250}} onChange={changePeriod} loading={periods.length===0}>
                                                {
                                                    periods.map((el, key)=>(
                                                        <Option key={'period_'+key} value={el.year}>{el.year}</Option>
                                                    ))
                                                }
                                            </Select>
                                            <Select placeholder="Select Division" value={selectedDivision} style={{ width: 250}} onChange={changeDivision} loading={divisions.length===0}>
                                                <Option key="division_all" value={0}>Semua Divisi</Option>
                                                {
                                                    divisions.map((el, key)=>(
                                                        <Option key={'division_'+key} value={el.division_id}>{el.division_name}</Option>
                                                    ))
                                                }
                                            </Select>
                                            <Select placeholder="Select Position" value={selectedPosition} style={{ width: 250}} onChange={changePosition} loading={positions.length===0}>
                                                <Option key="position_all" value={0}>Semua Posisi</Option>
                                                {
                                                    positions.map((el, key)=>(
                                                        <Option key={'position_'+key} value={el.position_id}>{el.position_name}</Option>
                                                    ))
                                                }
                                            </Select>
                                        </Space>
                                    </span>
                                </div>
                            </Row>
                            {
                                (loaded) ?
                                <div>
                                    <Tabs defaultActiveKey="appraisal" type="card">
                                        <TabPane tab={<span>Permanent</span>} key="permanent">
                                            <table className="table-collapse table-default" style={{width:'100%'}}>
                                                <thead>
                                                    <tr>
                                                        <th>No</th>
                                                        <th>Employee Name</th>
                                                        <th>Division Name</th>
                                                        <th>Position Name</th>
                                                        <th className="text-center">KPI</th>
                                                        <th>Action </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        appraisals.filter(el => el.employee_status === 'ESPR').map((el, i)=>(
                                                            <tr key={i}>
                                                                <td style={{verticalAlign:'top', textAlign:'center'}}>{i + 1}</td>
                                                                <td style={{verticalAlign:'top'}}>{el.name}</td>
                                                                <td style={{verticalAlign:'top'}}>{(el.division) ? el.division.division_name : ''}</td>
                                                                <td style={{verticalAlign:'top'}}>{(el.position) ? el.position.position_name : ''}</td>
                                                                <td className="text-center">
                                                                    {(el.appraisal) ? el.appraisal.kpi_value : ''}
                                                                </td>
                                                                <td className="text-center">
                                                                    {
                                                                        (el.appraisal && el.appraisal.evaluators.length>0)?
                                                                            <Link to={'/management/appraisal/detail?appraisalId='+el.appraisal.kpi_appraisal_id}>
                                                                                <Button type="link" icon={<ZoomInOutlined />}/>
                                                                            </Link>
                                                                        : null
                                                                    }
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                    {
                                                        (appraisals.length===0) ?
                                                        <tr><td colSpan={5}><Empty/></td></tr> : null
                                                    }
                                                </tbody>

                                            </table>
                                        </TabPane>

                                        <TabPane tab={<span>Probation</span>} key="probation">
                                            <table className="table-collapse table-default" style={{width:'100%'}}>
                                                <thead>
                                                    <tr>
                                                        <th>No</th>
                                                        <th>Employee Name</th>
                                                        <th>Division Name</th>
                                                        <th>Position Name</th>
                                                        <th className="text-center">KPI</th>
                                                        <th>Action </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        appraisals.filter(el => el.employee_status === 'ESPB').map((el, i)=>(
                                                            <tr key={i}>
                                                                <td style={{verticalAlign:'top', textAlign:'center'}}>{i + 1}</td>
                                                                <td style={{verticalAlign:'top'}}>{el.name}</td>
                                                                <td style={{verticalAlign:'top'}}>{(el.division) ? el.division.division_name : ''}</td>
                                                                <td style={{verticalAlign:'top'}}>{(el.position) ? el.position.position_name : ''}</td>
                                                                <td className="text-center">
                                                                    {(el.appraisal) ? el.appraisal.kpi_value : ''}
                                                                </td>
                                                                <td className="text-center">
                                                                    {
                                                                        (el.appraisal && el.appraisal.evaluators.length>0)?
                                                                            <Link to={'/management/appraisal/detail/'+el.appraisal.kpi_appraisal_id}>
                                                                                <Button type="link" icon={<ZoomInOutlined />}/>
                                                                            </Link>
                                                                        : null
                                                                    }
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                    {
                                                        (appraisals.length===0) ?
                                                        <tr><td colSpan={5}><Empty/></td></tr> : null
                                                    }
                                                </tbody>

                                            </table>
                                        </TabPane>
                                    </Tabs>
                                </div>
                                :
                                <Spinner/>
                            }
                        </div>
                        
                </Card>
            </Card>
        </div>
    );
}

export default Akumulasi;
