import React, { useState } from 'react';
import { Card, message } from 'antd';
import { PageTitle } from '../../../../components'
import RequestForm from './RequestForm'
import { useHistory } from 'react-router-dom'
import { SentralModel } from '../../../../models/SentralModel'

const Create = () => {
    const history = useHistory()

    const [saving, setSaving] = useState(false)

    const submitEntertainment = (v) => {
        setSaving(true)
        SentralModel.action('Submission', 'saveEntertainment', v, 0).then(() => {
            setSaving(false)
            message.destroy()
            message.success('Entertainment submitted', 1)
            setSaving(false)
            setTimeout(() => {
                history.push('/entertainment');
            }, 1000);
        }).catch(() => {
            setSaving(false)
            message.destroy()
            message.error('oops, something went wrong', 2)
            setSaving(false)
        })
    }
    return (
        <Card className="content-container">
            <PageTitle title="Entertainment Submission" breadcrumbs={[['Entertainment', '/entertainment'], ['Create']]}></PageTitle>
            <RequestForm onSubmit={(v) => submitEntertainment(v)} saving={saving} />
        </Card>
    );
}

export default Create;
