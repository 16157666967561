import { useEffect, useState } from 'react';
import { Card, Typography, Space, Form, Input, message, Select, Button, Row, Col, Collapse, Image } from 'antd'
import { PageTitle, Spinner, DataTable, AddButton, EditButton, FormModal, Uploader, DeleteButton, BasicModal } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import currentUser from '../../../helpers/authData';

const { Option } = Select
const { Text } = Typography
const { Panel } = Collapse

const Index = () => {
    const [iso, setIso] = useState([])
    const [company, setCompany] = useState([])
    const [defaultValues, setDefaultValues] = useState({})
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false)
    const [saving, setSaving] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [modalAttachment, setModalAttachment] = useState(false)
    const [modalTitle, setModalTitle] = useState(null)
    const [attachmentFile, setAttachmentFile] = useState(null)
    const [attachmentUrl, setAttachmentUrl] = useState('')

    const fetchDocumentIso = () => {
        setLoaded(false)
        SentralModel.action('Documentation', 'getCertificateIso', {}, 0).then((res) => {
            setIso(res.data)
            setLoaded(true)
        })
    }

    const fetchCompany = () => {
        SentralModel.list('GeneralDetail', { filter: [['general_code', 'ISOP']], orderBy: 'order_key', order: 'asc' })
            .then(res => {
                setCompany(res.data)
            })
    }

    useEffect(() => {
        fetchDocumentIso()
        fetchCompany()

    }, []);


    const fileUploaded = (v) => {
        setAttachmentFile(v.message);
    }

    const dataSource = [
        ...iso.map(el => {
            return {
                doc_certification_iso_id: el.doc_certification_iso_id,
                company: el.company?.description,
                iso_company: el.iso_company,
                document_no: el.document_no,
                title: el.title,
                attachment: el.attachment
            }
        })
    ];

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'name',
            align: 'center',
            width: '5%'
        },
        {
            title: 'Company',
            dataIndex: 'company',
            key: 'company'
        },
        {
            title: 'Document No',
            dataIndex: 'document_no',
            key: 'document_no',
            align: 'center'
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            align: 'center'
        },
        {
            title: 'Attachment',
            render: (row) => (
                row.attachment ?
                    row.attachment.includes('.png') || row.attachment.includes('.jpg') || row.attachment.includes('.jpeg') ?
                        <Image
                            width={200}
                            src={`${process.env.REACT_APP_API_FILE}/${row.attachment}`}
                        /> :
                        row.attachment.includes('.pdf') ?
                            <Button type='link' onClick={() => onShowModal(row.attachment)}>
                                Show Attachment
                            </Button> :
                            <Button type='link' onClick={() => window.open(`${process.env.REACT_APP_API_FILE}/${row.attachment}`)}>
                                Show Attachment
                            </Button>
                    : '-'
            ),
            key: 'attachment',
            align: 'center'
        },
    ];

    if (currentUser.employee_code === "ADM001" || currentUser.employee_code === "SMI20200803205") {
        columns.push({
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => editIso(row)} />
                        <DeleteButton onConfirm={() => deleteIso(row.doc_certification_iso_id)} />
                    </Space>
                </div>
            ),
            key: 'action',
            align: 'center'
        })
    }

    const onShowModal = (v) => {
        setModalAttachment(true)
        setAttachmentUrl(v)
    }

    const addIso = () => {
        setModalTitle('Add Iso')
        setDefaultValues({
            doc_certification_iso_id: 0,
            iso_company: null,
            document_no: null,
            title: "",
            attachment: ""
        })
        setFormModal(true)
    }

    const editIso = (v) => {
        setModalTitle('Edit Iso')
        setDefaultValues({
            doc_certification_iso_id: v.doc_certification_iso_id,
            iso_company: v.iso_company,
            document_no: v.document_no,
            title: v.title,
        })
        setFormModal(true)
    }

    const deleteIso = (v) => {
        SentralModel.action("Documentation", "deleteDocIso", {}, v).then((res) => {
            message.success("Success Delete document")
            fetchDocumentIso()
        }).catch((err) => {
            message.success("Failed Delete document")
        })
    }

    const saveIso = (v) => {
        setSaving(true)
        let payload = {
            doc_certification_iso_id: v.doc_certification_iso_id,
            iso_company: v.iso_company,
            document_no: v.document_no,
            title: v.title,
            attachment: attachmentFile
        }
        SentralModel.action('Documentation', 'saveDocIso', payload, v.doc_certification_iso_id).then((res) => {
            message.success('Iso saved', 2);
            setFormModal(false)
            fetchDocumentIso()
        }).finally(() => {
            setSaving(false)
        })
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Certificate ISO" breadcrumbs={[['Certificate ISO']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{ float: 'left' }}>List Certificate ISO</Text>
                        {
                            currentUser.employee_code === "ADM001" || currentUser.employee_code === "SMI20200803205" ?
                                <AddButton right onAdd={addIso} title="New Document" />
                                :
                                null
                        }
                    </div>}>

                    {
                        (loaded) ?
                            <Collapse defaultActiveKey={['1']}>
                                <Panel header="SMI" key="1">
                                    <DataTable size='small' bordered dataSource={dataSource.filter((el) => el.iso_company === 'ISOPM')} columns={columns} pagination={false} search={false} />
                                </Panel>
                                <Panel header="SKI" key="2">
                                    <DataTable size='small' bordered dataSource={dataSource.filter((el) => el.iso_company === 'ISOPK')} columns={columns} pagination={false} search={false} />
                                </Panel>
                                <Panel header="SST" key="3">
                                    <DataTable size='small' bordered dataSource={dataSource.filter((el) => el.iso_company === 'ISOPT')} columns={columns} pagination={false} search={false} />
                                </Panel>
                            </Collapse>
                            : <Spinner />
                    }
                </Card>

                {
                    (formModal) ?
                        <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => saveIso(v)} width={600} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving || uploading}>
                            <Form.Item name="doc_certification_iso_id" hidden>
                                <Input placeholder="input placeholder" />
                            </Form.Item>
                            <Form.Item name="iso_company" label="Company">
                                <Select showSearch allowClear placeholder="Select Company" optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {
                                        company.map((el, key) => (
                                            <Option key={key} value={el.detail_code}>{el.description}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item label="Document Number" name="document_no" rules={[{ required: true }]}>
                                <Input placeholder="Document Number" />
                            </Form.Item>
                            <Form.Item label="Title" name="title" rules={[{ required: true }]}>
                                <Input placeholder="Title" />
                            </Form.Item>
                            <Form.Item label="Attachment">
                                <Uploader dragger folder="doc-iso" accept={'.png,.jpg,.jpeg,.pdf,.doc,.docx,.xls,.xlsx,.ppt'} onUploaded={(v) => fileUploaded(v)} isUploading={(v) => setUploading(v)} />
                            </Form.Item>
                        </FormModal>
                        : null
                }
            </Card>

            {
                modalAttachment ?
                    <BasicModal title="Attachment" showModal={modalAttachment} onCloseModal={() => setModalAttachment(false)} width={800}>
                        <Row>
                            <Col span={24}>
                                <iframe width={750} height={500} src={`${process.env.REACT_APP_API_FILE}/${attachmentUrl}`} title='attachment' />
                            </Col>
                        </Row>
                    </BasicModal>
                    : null
            }
        </div>
    );
}

export default Index;
