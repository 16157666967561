import React from 'react';
import {Col, Typography} from 'antd';

const {Text} = Typography;

const ProfileItem = (props) => {
    const {span=8, onEdited=false, image=false} = props
    return (
        <Col span={span}>
            <div className="full-width p-2">
                <Text strong >{props.title}</Text> <br/>
                {
                    onEdited ?
                    <Text type="secondary" editable={{tooltip:false,onChange:props.onEdited}}>{props.children}</Text> : 
                    image ? props.children :
                    <Text type="secondary" >{props.children}</Text> 
                }
            </div>
        </Col>
    );
}

export default ProfileItem;
