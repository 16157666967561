import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Space, Empty, Button, DatePicker, Select } from 'antd';
import DashboardItem from './DashboardItem'
import { BasicModal, DataTable, PageTitle, Spinner, ViewButton, ViewButton2 } from '../../../components';
import { SentralModel } from '../../../models/SentralModel'
import { ZoomInOutlined } from '@ant-design/icons'
import { Bar } from 'react-chartjs-2'
import moment from 'moment';

const {Option} = Select

const Admin = () => {
    const [loaded, setLoaded] = useState(true);
    const [divisions, setDivisions] = useState([])
    const [training, setTraining] = useState([]);
    const [recruitment, setRecruitment] = useState([]);
    const [lto, setLto] = useState([]);
    const [probation, setProbation] = useState([]);
    const [detail, setDetail] = useState([]);
    const [detailModal, setDetailModal] = useState(false);
    const [detailTitle, setDetailTitle] = useState(null);
    const [showDivision, setShowDivision] = useState(false);
    const [loadedTraining, setLoadedTraining] = useState(true);
    const [loadedRecruitment, setLoadedRecruitment] = useState(false);
    const [loadedLto, setLoadedLto] = useState(false);
    const [loadedProbation, setLoadedProbation] = useState(false);
    const [selectedDivision, setSelectedDivision] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(null);

    const fetchDivisions  = () =>{
        setLoaded(false)
        SentralModel.action('Dashboard', 'getDivision', {}, 0).then((res) => {
            setDivisions(res.data)
            setLoaded(true)
        })
    }

    const fetchTraining = (division_id, year, month) => {
        setLoadedTraining(false)
        SentralModel.action('Dashboard', 'training', {division_id: division_id, year: year, month: month}, 0).then((res) => {
            setTraining(res.data)
            setLoadedTraining(true)
        })
    }

    const fetchRecruitment = () => {
        setLoadedRecruitment(false)
        SentralModel.action('Dashboard', 'recruitment', {}, 0).then((res) => {
            setRecruitment(res.data)
            setLoadedRecruitment(true)
        })
    }

    const fetchLto = () => {
        setLoadedLto(false)
        SentralModel.action('Dashboard', 'lto', {}, 0).then((res) => {
            setLto(res.data)
            setLoadedLto(true)
        })
    }

    const fetchProbation = () => {
        setLoadedProbation(false)
        SentralModel.action('Dashboard', 'probation', {}, 0).then((res) => {
            setProbation(res.data)
            setLoadedProbation(true)
        })
    }

    useEffect(() => {
        fetchDivisions();
        fetchRecruitment();
        fetchLto();
        fetchProbation();
    }, []);

    const getDetail = (title, show, row) => {
        setDetailModal(true);
        setDetailTitle(title);
        setShowDivision(show);

        setDetail(row);
    }

    const recruitmentColumn = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Subject',
            dataIndex: 'subject',
            key: 'subject',
            width: '30%'
        },
        {
            title: 'Date',
            width: '25%',
            render: (row) => (
                <div className="text-center">
                    {moment(row.from_date).format('DD-MMM-YYYY')} &nbsp;&nbsp;<b>-</b>&nbsp;&nbsp; {moment(row.to_date).format('DD-MMM-YYYY')}
                </div>
            )
        },
        {
            title: 'Quota',
            dataIndex: 'quota',
            key: 'quota',
            width: '14%',
            render: (text) =>(
                <div className="text-center">
                    {text}
                </div>
            )
        },
        {
            title: 'Applicant',
            width: '13%',
            align: 'center',
            render: (row) => (
                <ViewButton2 onView={() => getDetail('Applicant', false, row.applicant)}>
                    {row.total_applicant}
                </ViewButton2>
            )
        },
        {
            title: 'Recruited',
            width: '13%',
            align: 'center',
            render: (row) =>(
                <ViewButton2 onView={() => getDetail('Recruited', true, row.recruited)} color="#47b538">
                    {row.total_recruited}
                </ViewButton2>
            )
        },
        {
            title: 'Action',
            width:120,
            render:(row) =>(
                <div className="text-center">
                    <Space>
                        <ViewButton link to={"/management/vacancy/detail/" + row.rec_vacancy_id}/>
                    </Space>
                </div>
            )
        },
    ];

    const detailColumn = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '95%'
        }
    ];

    const detailColumn2 = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '40%'
        },
        {
            title: 'Division',
            dataIndex: 'division_name',
            key: 'division_name',
            width: '40%'
        },
        {
            title: 'Status',
            dataIndex: 'employee_status',
            key: 'employee_status',
            width: '15%'
        }
    ];

    const probationColumn = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '30%'
        },
        {
            title: 'Division',
            dataIndex: 'division_name',
            key: 'division_name',
            width: '25%'
        },
        {
            title: 'Probation Date',
            width: '30%',
            render: (row) => (
                <div className="text-center">
                    {moment(row.probation?.probation_start).format('DD-MMM-YYYY')} &nbsp;&nbsp;<b>-</b>&nbsp;&nbsp; {moment(row.probation?.probation_end).format('DD-MMM-YYYY')}
                </div>
            )
        },
        {
            title: 'Aging Days',
            width: '10%',
            render: (row) => (
                <div className="text-center">
                    {row.probation?.aging_days} days
                </div>
            )
        }
    ];

    const trainingData = {
        labels: (training) ? training.employees : [],
        datasets: [
        {
            label: 'Training in Minutes',
            data: (training) ? training.total_hours : [],
            backgroundColor: (training) ? training.total_hours?.map(v => v > 0 ? 'rgba(0, 255, 0, 0.3)' : 'rgba(255, 99, 132, 0.3)') : 'rgba(0, 255, 0, 0.3)',
            borderColor: (training) ? training.total_hours?.map(v => v > 0 ? '#2ecc71' : '#ff7675') : '#2ecc71',
            borderWidth: 1
        }]
    }

    const ltoData = {
        labels: (lto) ? lto.months : [],
        datasets: [
        {
            label: 'LTO',
            data: (lto) ? lto.percents : [],
            backgroundColor: 'rgba(45, 200, 255, 0.3)',
            borderColor: '#4ca3ff',
            borderWidth: 1
        }]
    }

    const changeDivision = (v) => {
        setSelectedDivision(v)
    }

    const onChangePeriod = (v) => {
        let year = moment(v).format('YYYY');
        let month = moment(v).format('MM');

        setSelectedYear(year);
        setSelectedMonth(month);
    }

    const filterTraining = () => {
        fetchTraining(selectedDivision, selectedYear, selectedMonth)
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Admin" breadcrumbs={[['Admin']]}></PageTitle>
                {
                    loaded ?
                        <div>
                            {/* Training */}
                            <Row className="mb-3">
                                <Col span={24} className="p-1">
                                    <DashboardItem title={
                                        <div>
                                            Training Hours
                                            <Space style={{float:'right'}}>
                                                <Select placeholder="Select Division" style={{ width: 250}} onChange={changeDivision} loading={divisions.length===0}>
                                                    {
                                                        divisions.map((el, key)=>(
                                                            <Option key={'division_'+key} value={el.division_id}>{el.division_name}</Option>
                                                        ))
                                                    }
                                                </Select>
                                                <DatePicker picker="month" style={{ marginRight:10 }} onChange={onChangePeriod} />
                                                <Button icon={<ZoomInOutlined />} disabled={selectedDivision == null || selectedYear == null} loading={!loadedTraining} onClick={() => filterTraining()} type="primary">
                                                    Show
                                                </Button>
                                            </Space>
                                        </div>
                                    } height={training.length !== 0 ? 400 : 160}>
                                    {
                                        training.length !== 0 ? 
                                            (loadedTraining) ? 
                                                <Bar
                                                    data={trainingData}
                                                    width={100}
                                                    height={320}
                                                    options={{ maintainAspectRatio: false }}
                                                />
                                            : <Spinner/>
                                        : <Empty />
                                    }
                                    </DashboardItem>
                                </Col>
                            </Row>

                            {/* Recruitment */}
                            <Row className="mb-3">
                                <Col span={24} className="p-1">
                                    <DashboardItem title={<div>Recruitments</div>}>
                                    {
                                        (loadedRecruitment) ? 
                                            <DataTable bordered dataSource={recruitment} columns={recruitmentColumn} size="small"/>
                                        : <Spinner/>
                                    }
                                    </DashboardItem>
                                </Col>
                            </Row>

                            {/* LTO */}
                            <Row className="mb-3">
                                <Col span={24} className="p-1">
                                    <DashboardItem title={
                                        <div>
                                            LTO (Labour Turn Over) Employees
                                        </div>
                                    } height={400}>
                                    {
                                        (loadedLto) ? 
                                            <Bar
                                                data={ltoData}
                                                width={100}
                                                height={320}
                                                options={{ maintainAspectRatio: false }}
                                            />
                                        : <Spinner/>
                                    }
                                    </DashboardItem>
                                </Col>
                            </Row>

                            {/* Probation */}
                            <Row className="mb-3">
                                <Col span={24} className="p-1">
                                    <DashboardItem title={<div>Employee Probations</div>}>
                                    {
                                        (loadedProbation) ? 
                                            <DataTable bordered dataSource={probation} columns={probationColumn} size="small"/>
                                        : <Spinner/>
                                    }
                                    </DashboardItem>
                                </Col>
                            </Row>
                        </div>
                    : <Spinner />
                }
            </Card>

            <BasicModal width={1000} onCloseModal={() => setDetailModal(false)} showModal={detailModal} onCancel={() => setDetailModal(false)} title={detailTitle}>
                {
                    <DataTable bordered dataSource={detail} columns={!showDivision ? detailColumn : detailColumn2} size="small"/>
                }
            </BasicModal>
        </div>
    );
}

export default Admin;
