import moment from 'moment';

export function formatDateTime(time) {
    var format = 'DD MMMM YYYY HH:mm';
    return moment(time).format(format);
}

export function formatDateTime2(time) {
    var format = 'DD MMM YYYY HH:mm';
    return moment(time).format(format);
}