import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Input, Select, DatePicker, Typography, Card, Checkbox, TimePicker } from 'antd';
import moment from 'moment'
import { AddButton, SubmitButton } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'

const { Text } = Typography
const { TextArea } = Input

// const {RangePicker} = DatePicker
const { Option } = Select

const TrainingForm = (props) => {
    const { trainingData = null } = props
    const [trainingForm] = Form.useForm()
    const [limit, setLimit] = useState(null)
    const [trainingTypes, setTrainingTypes] = useState([])
    const [certificate, setCertificate] = useState(false)
    const [isCount, setIsCount] = useState(false)
    const [trainerCount, setTrainerCount] = useState(1)
    const [trainers, setTrainers] = useState([{ name: null, source: null }])
    const [loaded, setLoaded] = useState(false)
    const [isExternal, setIsExternal] = useState(false)
    const [location, setLocation] = useState(null)

    const fetchTrainingTypes = () => {
        setLoaded(false)
        SentralModel.list('GeneralDetail', { filter: [['general_code', 'PS']] }).then((res) => {
            setTrainingTypes(res.data)
            setLoaded(true)
        })
    }

    const setDefaultFormValue = (trainingData, trainingForm) => {
        setLimit(moment(trainingData.training_date, 'YYYY-MM-DD'))
        setLocation(trainingData.location)
        trainingForm.setFieldsValue({
            description: trainingData.description,
            training_type: trainingData.type,
            category: trainingData.category,
            title: trainingData.title,
            quota: trainingData.quota,
            register_limit: moment(trainingData.register_limit, ('YYYY-MM-DD HH:mm:ss')),
            training_date: moment(trainingData.training_date, 'YYYY-MM-DD HH:mm:ss'),
            start_time: moment(trainingData.start_time, 'HH:mm'),
            end_time: moment(trainingData.end_time, 'HH:mm'),
            location: trainingData.location
        })
        let t = [];
        trainingData.trainers.forEach((el, key) => {
            t.push({ name: el.name, source: el.source })
            let trainerName = 'trainer_name[' + key + ']'
            let source = 'source[' + key + ']'
            trainingForm.setFieldsValue({
                [trainerName]: el.name,
                [source]: el.source
            })
        });
        setTrainers(t)
        if (trainingData.type === 'PSE') {
            setIsExternal(true)
        }
    }

    useEffect(() => {
        fetchTrainingTypes()
        if (trainingData) {
            setDefaultFormValue(trainingData, trainingForm)
            setCertificate(!!trainingData.certificate)
            setIsCount(trainingData.is_count === '1' ? true : false)
        }
    }, [trainingData, trainingForm]);

    const submitTraining = (v) => {
        let data = {
            training_id: (trainingData) ? trainingData.training_id : null,
            type: v.training_type,
            category: v.category,
            title: v.title,
            quota: v.quota,
            description: v.description,
            certificate: certificate ? 1 : 0,
            is_count: isCount ? '1' : '0',
            training_date: moment(v.training_date).format('YYYY-MM-DD'),
            start_time: moment(v.start_time).format('HH:mm'),
            end_time: moment(v.end_time).format('HH:mm'),
            register_limit: moment(v.register_limit).format('YYYY-MM-DD HH:mm'),
            location: location,
            trainer: trainers
        }

        props.onSubmit(data)
    }

    const addItem = () => {
        let x = trainers
        x.push({ name: null, source: null })
        setTrainerCount(trainerCount + 1)
        setTrainers(x)
    }

    const changeTrainerName = (v, key) => {
        let x = trainers
        x[key].name = v.target.value
        setTrainers(x)
    }

    const changeSource = (v, key) => {
        let x = trainers
        x[key].source = v.target.value
        setTrainers(x)
    }

    const changeType = (v) => {
        if (v === 'PSE') {
            setIsExternal(true)
        } else {
            setIsExternal(false)
            setLocation(null)
            trainingForm.setFieldsValue({
                location: null
            })
        }
    }

    const changeLocation = (v) => {
        setLocation(v.target.value);
    }

    // const removeTrainer = (i) => {
    //     let x=trainers
    //     x.splice(i,1)
    //     setTrainerCount(trainerCount-1)
    //     setTrainers(x)
    // }

    const disabledTrainingDate = (current) => {
        return current < moment().startOf('day');
    }

    return (
        <Form form={trainingForm} size="middle" layout="vertical" onFinish={submitTraining}>
            <Row justify="space-around" className="mb-3">
                <Col span={12} style={{ paddingRight: '10px' }}>
                    <Form.Item name="title" label="Title" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="start_time" label="Start Time" rules={[{ required: true }]}>
                        <TimePicker format="HH:mm" style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item name="register_limit" label="Registration Limit" rules={[{ required: true }]}>
                        {/* <DatePicker disabled={!limit && !trainingData} showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm" disabledDate={d => !d || d.isSameOrAfter(moment(limit).add(1, "days").format('YYYY-MM-DD'))} style={{width:'100%'}} /> */}
                        <DatePicker disabled={!limit && !trainingData} showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm" style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item name="training_type" label="Training Type" rules={[{ required: true }]}>
                        <Select loading={!loaded} onChange={(v) => changeType(v)}>
                            {
                                trainingTypes.map((lt, key) => (
                                    <Option key={key} value={lt.detail_code}>{lt.description}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name="category" label="Category" rules={[{ required: true }]}>
                        <Select placeholder="Select Category">
                            <Option value="TRAINING">TRAINING</Option>
                            <Option value="COFFEE TALK">COFFEE TALK</Option>
                            <Option value="PICA">PICA</Option>
                        </Select>
                    </Form.Item>
                    {
                        isExternal ?
                            <Form.Item name="location" label="Location" rules={[{ required: true }]}>
                                <Input onChange={(v) => changeLocation(v)} />
                            </Form.Item>
                            : null
                    }
                    <Row>
                        <Form.Item name="certificate">
                            <Checkbox checked={certificate} onChange={() => setCertificate(!certificate)}>Certificate</Checkbox>
                        </Form.Item>
                        <Form.Item name="is_count">
                            <Checkbox checked={isCount} onChange={() => setIsCount(!isCount)}>Count Hour</Checkbox>
                        </Form.Item>
                    </Row>
                </Col>
                <Col span={12}>
                    <Form.Item name="training_date" label="Training Date" rules={[{ required: true }]}>
                        {/* <RangePicker showTime={{ format: 'HH:mm' }} onChange={(v) => setLimit(v)} format="YYYY-MM-DD HH:mm" style={{width:'100%'}} /> */}
                        <DatePicker format="YYYY-MM-DD" onChange={(v) => setLimit(v)} style={{ width: '100%' }} disabledDate={disabledTrainingDate} />
                    </Form.Item>
                    <Form.Item name="end_time" label="End Time" rules={[{ required: true }]}>
                        <TimePicker format="HH:mm" style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item name="quota" label="Quota" rules={[{ required: true }]}>
                        <Input type="number" />
                    </Form.Item>
                    <Form.Item name="description" label="Description" rules={[{ required: true }]}>
                        <TextArea />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Card title={
                        <div className="full-width">
                            <Text strong style={{ float: 'left' }}>Trainer</Text>
                            <AddButton right onAdd={() => addItem()} title="Add Item" />
                        </div>}>
                        <table className="table-item" style={{ width: '100%' }}>
                            <thead>
                                <tr>
                                    <th >Trainer Name</th>
                                    <th >Division / External</th>
                                    {/* <th>Remove</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    trainers.map((el, key) => (
                                        <tr key={'rmb_item' + key}>
                                            <td>
                                                <Form.Item name={'trainer_name[' + key + ']'}>
                                                    <Input placeholder="Trainer Name" onChange={(v) => changeTrainerName(v, key)} />
                                                </Form.Item>
                                            </td>
                                            <td>
                                                <Form.Item name={'source[' + key + ']'}>
                                                    <Input placeholder="Division / External" onChange={(v) => changeSource(v, key)} />
                                                </Form.Item>
                                            </td>
                                            {/* <td style={{width:'50px', textAlign:'center'}}>
                                                <Button onClick={() => removeTrainer(key)} type="danger"  icon={<DeleteOutlined />}/>
                                            </td> */}
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </Card>
                </Col>
                <Col span={24} className="text-right mt-3">
                    <SubmitButton loading={props.saving} />
                </Col>
            </Row>
        </Form>
    );
}

export default TrainingForm;
