import React, {useState, useEffect} from 'react';
import {Card, Select, Tag, Space, Form, Button, DatePicker} from 'antd';
import {Link} from 'react-router-dom'
import { ShowButton, PageTitle, Spinner, DataTable } from '../../../components';
import { SentralModel } from '../../../models/SentralModel';
import {setSessionStorage, getSessionStorage} from '../../../helpers/sessionStorage';
import moment from 'moment'

const {Option} = Select
const Report = () => {
    const [searchForm] = Form.useForm()
    const [loaded, setLoaded] = useState(true)
    const [divisions, setDivisions] = useState([])
    const [periods, setPeriods] = useState([])
    const [employees, setEmployees] = useState([])
    const [selectedPeriod, setSelectedPeriod] = useState(null)

    const fetchEmployeeSchema = (division, period) =>{
        setLoaded(false)
        SentralModel.action('Payroll','getPaymentList',{division_id:division, period_id:period},0).then((res)=>{
            setEmployees(res.data)
            setLoaded(true)
        })
    }

    const fetchPeriods = (year) =>{
        SentralModel.list('PayrollPeriod', { filter: [['year', year]] }, {orderBy:'from_date', order:'DESC'}).then((res)=>{
            setPeriods(res.data)
        })
    }

    const fetchDivisions = () =>{
        SentralModel.list('Division').then((res)=>{
            setDivisions(res.data)
        })
    }

    const onChangeYear = (v) => {
        let year = moment(v).format('YYYY');
        setPeriods([])
        fetchPeriods(year)
    }

    const disabledDate = (current) =>{
        let currentMonth = moment().format('MM');
        let addMonth = 1;

        if (currentMonth === '12') {
            addMonth = 2;
        }

        let customDate = moment(new Date()).add(addMonth, 'y').format('YYYY');
        return current && current > moment(customDate, "YYYY");
        
    }; 

    useEffect(() => {
        let division = getSessionStorage('payrollPaymentDivision')
        let period = getSessionStorage('payrollPaymentPeriod')
        if(division && period){
            searchForm.setFieldsValue({
                division: parseInt(division),
                period: parseInt(period)
            })
            setSelectedPeriod(period)
            fetchEmployeeSchema(division, period)
            setLoaded(true)
        }

        fetchDivisions()
    }, [searchForm]);

    const columns= [{
            title: 'No',
            dataIndex: 'no',
            key: 'name',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },{
            title: 'Position',
            dataIndex: 'position_name',
            key: 'position_name',
        },{
            title: 'Schema',
            width:'20%',
            align:'center',
            render:(row)=>(
                <Link to={'/payroll/schema/'+row.employee_code}>
                    {
                        (row.payroll_schema===0) ? 
                        <Tag style={{width:150, cursor:'pointer'}} color="red">SCHEMA NOT FOUND</Tag> :
                        <Tag style={{width:150, cursor:'pointer'}} color="green">SCHEMA AVAILABLE</Tag>
                    }
                </Link>
            )
        },{
            title: 'Payment',
            width:'20%',
            dataIndex: 'payment',
            align:'center',
            render:(text)=>(
                (text===0) ? 
                <Tag style={{width:100}} color="red">UNPAID</Tag> :
                <Tag style={{width:100}} color="green">PAID</Tag>
            )
        },{
            title: 'Action',
            align:'center',
            width:'5%',
            render: (row)=>(
                <ShowButton link to={'/payroll/payment/'+row.employee_code+'/'+selectedPeriod}/>
            )
        }
    ];
    const filterPayroll = (v) =>{
        setSelectedPeriod(v.period)
        fetchEmployeeSchema(v.division, v.period)
        setSessionStorage('payrollPaymentDivision', v.division)
        setSessionStorage('payrollPaymentPeriod', v.period)
    }
    
    return (
        <Card className="content-container">
            <PageTitle title="Payroll" breadcrumbs={[['Payroll Schema']]}></PageTitle>
            <div className="full-width">
                <span className="mb-3" style={{float:'right'}}>
                    <Space>
                        <Form form={searchForm} onFinish={filterPayroll} layout="inline">
                            <Form.Item name="division" rules={[{ required: true, message: 'select division' }]}>
                                <Select placeholder="Select Division" style={{ width: 250}}>
                                    {
                                        divisions.map((el, key)=>(
                                            <Option key={'division_'+key} value={el.division_id}>{el.division_name}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item name="year" rules={[{ required: true, message: 'select year' }]}>
                                <DatePicker picker="year" onChange={onChangeYear} disabledDate={disabledDate} placeholder="Select Year"/>
                            </Form.Item>
                            <Form.Item name="period" rules={[{ required: true, message: 'select period' }]}>
                                <Select placeholder="Select Period"style={{ width: 250}}>
                                    {
                                        periods.map((el, key)=>(
                                            <Option key={'period_'+key} value={el.payroll_period_id}>{el.period}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item style={{marginRight:0}}>
                                <Button htmlType="submit" type="primary">Show</Button>
                            </Form.Item>
                        </Form>
                    </Space>
                </span>
            </div>
            {
                (loaded) ? 
                <DataTable bordered size="small" columns={columns} dataSource={employees}/>
                : <Spinner/>
            }
        </Card>
    );
}

export default Report;
