import React, {useState} from 'react';
import {Card, message} from 'antd';
import {PageTitle} from '../../../components'
import TrainingForm from './TrainingForm'
import { SentralModel } from '../../../models/SentralModel';
import {useHistory} from 'react-router-dom'

const Create = () => {
    const history = useHistory()
    const [saving, setSaving] = useState(false)

    const submitTraining = (v)=>{
        setSaving(true)
        SentralModel.action('Training', 'saveTraining', v, 0).then(()=>{
            message.destroy()
            message.success('Training submitted', 1)
            setSaving(false)
            setTimeout(() => {
                history.push('/training');
            }, 1000);
        }).catch((err) => {
            message.destroy()
            message.error(err.message, 2)
            setSaving(false)
        })
    }
    return (
        <Card className="content-container">
            <PageTitle title="Pelatihan dan Sertifikasi" breadcrumbs={[['Pelatihan dan Sertifikasi','/training'],['Buat Baru']]}></PageTitle>
            <TrainingForm onSubmit={(v) => submitTraining(v)} saving={saving}/>
        </Card>
    );
}

export default Create;
