import React, {useState} from 'react';
import {Card, message} from 'antd';
import {PageTitle} from '../../../../components'
import RequestForm from './RequestForm'
import {SentralModel} from '../../../../models/SentralModel'
import {useHistory} from 'react-router-dom'

const Create = () => {
    const history = useHistory()
    const [saving, setSaving] = useState(false)

    const submitMedical = (v)=>{
        v.status='WAITING';
        setSaving(true)
        message.loading('Saving Data', 0)
        SentralModel.saveWithApproval('SubmissionMedical','medical', v,0).then((res)=>{
            message.destroy()
            message.success('Medical reimbursement submitted', 1)
            setSaving(false)
            setTimeout(() => {
                history.push('/medical');
            }, 1000);
        }).catch((err) => {
            message.destroy()
            message.error(err.message, 2)
            setSaving(false)
        })
    }
    
    return (
        <Card className="content-container">
            <PageTitle title="Medical Submission" breadcrumbs={[['Medical','/medical'],['Create']]}></PageTitle>
            <RequestForm onSubmit={(v) => submitMedical(v)} saving={saving}/>
        </Card>
    );
}

export default Create;
