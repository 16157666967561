import React from 'react';
import OvertimeRequest from './request/Index'
// import RealizationRequest from './realization/Index'

const Index = (props) => {
    return (
        <div>
            <OvertimeRequest data={props.data.requests} requestNotif={props.requestNotif} />
            {/* <Tabs defaultActiveKey="request">
                <TabPane tab="Overtime Request" key="request">
                </TabPane>
                <TabPane tab="Realization Request" key="realization">
                    <RealizationRequest data={props.data.realizations}/>
                </TabPane>
            </Tabs> */}
        </div>
    );
}

export default Index;
