import { useDispatch, useSelector } from 'react-redux'
import { Button, Card, DatePicker, Form, Input, Modal, Typography, Col, Row, Progress, message } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { DataTable, DeleteButton, Uploader } from '../../../../../../components'
import {
    changeModalDailyStatusInfra,
    changeUploading,
    fetchDailyActivities,
    fetchListProgressTask,
    fileUploaded,
    removeDailyProgress,
    showAddProgress,
    updateDailyProgressInfra
} from '../../../../../../redux/slices/Dashboard/myDailyActivitySlice'
import moment from 'moment'

const { Text } = Typography

const ModalEditProgressDailyInfra = (props) => {
    const dispatch = useDispatch()
    const defaultValueEdit = useSelector((state) => state.myDailyActivity.defaultValueEdit);
    const editProgressModalTitle = useSelector((state) => state.myDailyActivity.editProgressModalTitle);
    const editProgressDailyInfraModal = useSelector((state) => state.myDailyActivity.editProgressDailyInfraModal);
    const attachmentInfo = useSelector((state) => state.myDailyActivity.attachmentInfo);
    const defaultStat = useSelector((state) => state.myDailyActivity.defaultStat);
    const dailyProgressData = useSelector((state) => state.myDailyActivity.dailyProgressData);
    const disabled = useSelector((state) => state.myDailyActivity.disabled);
    const is_attachment = useSelector((state) => state.myDailyActivity.is_attachment);
    const attachmentFile = useSelector((state) => state.myDailyActivity.attachmentFile);

    const onFinishUpdate = (v) => {
        if (attachmentFile === null) {
            return message.warning("Attachment Cannot be empty")
        }

        let payload = {
            ...v,
            data_from: v.data_from,
            daily_progress: dailyProgressData,
            attachment: attachmentFile
        }

        dispatch(updateDailyProgressInfra(payload)).then(() => {
            dispatch(fetchDailyActivities({ start_date: null, end_date: null }))
            dispatch(fetchListProgressTask(v.all_app_task_id))
        })
    }

    const onRemove = (v) => {
        dispatch(removeDailyProgress(v))
    }

    const columnDailyProgress = [
        {
            title: "Job Description",
            dataIndex: "job_description",
            key: "job_description"
        },
        {
            title: "Date",
            render: (row) => (
                moment(row?.created_at).format("DD/MM/YY HH:mm")
            ),
            key: "date",
        },
        {
            title: "Time",
            render: (row) => (
                `${row?.time_start} - ${row?.time_end}`
            ),
            key: "time"
        },
        {
            title: "Progress",
            render: (row) => (
                row?.progress ?
                    <Progress percent={row.progress} size="small" />
                    :
                    <Progress percent={0} size="small" />
            ),
            align: "center",
            key: "progress"
        },
        {
            title: "Action",
            render: (row) => (
                defaultStat === 'show' ?
                    <Button disabled type='link' icon={<DeleteOutlined />} /> :
                    <DeleteButton onConfirm={() => onRemove(row)} />
            ),
            key: "action",
            width: "5%",
            align: "center"
        }
    ];

    return (
        <Modal centered title={editProgressModalTitle} width={800} visible={editProgressDailyInfraModal} onCancel={() => dispatch(changeModalDailyStatusInfra(false))} footer={null}>
            <Form layout='vertical' initialValues={defaultValueEdit} onFinish={(v) => onFinishUpdate(v)}>
                <Form.Item name="all_app_task_id" hidden>
                    <Input placeholder="all_app_task_id" />
                </Form.Item>
                <Form.Item name="emp_task_id" hidden>
                    <Input placeholder="ID" />
                </Form.Item>
                <Form.Item name="employee_code" hidden>
                    <Input placeholder="Employee Code" />
                </Form.Item>
                <Form.Item name="data_from" hidden>
                    <Input placeholder="Data From" />
                </Form.Item>
                <Form.Item name="start_date" hidden>
                    <DatePicker />
                </Form.Item>
                <Form.Item label="Description" name="description" rules={[{ required: true }]}>
                    <Input.TextArea rows={4} disabled placeholder="Description" />
                </Form.Item>
                <Form.Item>
                    {
                        attachmentInfo === null ?
                            <Button disabled onClick={() => window.open(`${process.env.REACT_APP_API_FILE}/${attachmentInfo}`)}>No Attachment</Button>
                            :
                            <Button onClick={() => window.open(`${process.env.REACT_APP_API_FILE}/${attachmentInfo}`)}>Show Attachment</Button>
                    }
                </Form.Item>
                <Card style={{ marginBottom: 10 }} title={
                    <Row justify='space-between'>

                        <Col>
                            <Text>Daily Progress</Text>
                        </Col>
                        {
                            defaultStat === 'show' ?
                                null :
                                <Col>
                                    <Button disabled={disabled} type="primary" onClick={() => dispatch(showAddProgress())}>Add Progress</Button>
                                </Col>
                        }
                    </Row>
                }>
                    <DataTable size="small" columns={columnDailyProgress} dataSource={dailyProgressData} bordered search={false} pagination={false} />
                </Card>
                <div style={{ marginBottom: 10 }}>
                    {
                        is_attachment ?
                            <Form.Item label="Upload Attachment">
                                <Uploader folder="attachment-infra" onUploaded={(v) => dispatch(fileUploaded(v))} isUploading={(v) => dispatch(changeUploading(v))} view={true} />
                            </Form.Item>
                            : null
                    }
                </div>
                {
                    defaultStat === 'show' ?
                        null :
                        <Form.Item>
                            <Button type='primary' htmlType='submit'>Save</Button>
                        </Form.Item>
                }
            </Form>
        </Modal>
    )
}

export default ModalEditProgressDailyInfra