import { useDispatch, useSelector } from 'react-redux'
import { Col, Form, Input, InputNumber, Row, Slider, TimePicker } from 'antd'
import { FormModal } from '../../../../../../components'
import { addDailyProgress, changeProgressDailyValue, closeAddProgress } from '../../../../../../redux/slices/Dashboard/myDailyActivitySlice'

const ModalAddProgress = () => {
    const dispatch = useDispatch()
    const showModalAddProgress = useSelector((state) => state.myDailyActivity.showModalAddProgress)
    const progressDailyValue = useSelector((state) => state.myDailyActivity.progressDailyValue)

    return (
        <FormModal isAdd title="Add Progress" submitForm={(v) => dispatch(addDailyProgress(v))} width={500} showModal={showModalAddProgress} onCloseModal={() => dispatch(closeAddProgress())}>
            <Form.Item label="Job Description" name="job_description" rules={[{ required: true }]}>
                <Input.TextArea rows={4} placeholder="Job Description" />
            </Form.Item>
            <Form.Item label="Time" name="time" rules={[{ required: true }]}>
                <TimePicker.RangePicker format="HH:mm" />
            </Form.Item>
            <Form.Item label="Progress">
                <Row justify='space-around'>
                    <Col span={16}>
                        <Slider min={0} max={100} value={progressDailyValue} onChange={(v) => dispatch(changeProgressDailyValue(v))} />
                    </Col>
                    <Col span={4}>
                        <InputNumber
                            min={0}
                            max={100}
                            value={progressDailyValue}
                            onChange={(v) => dispatch(changeProgressDailyValue(v))}
                        />
                    </Col>
                </Row>
            </Form.Item>
        </FormModal>
    )
}

export default ModalAddProgress