import React, { useEffect, useState } from 'react';
import { Card, Form, Row, Col, Input, InputNumber, Checkbox, message } from 'antd';
import { PageTitle, Spinner, FormModal, PrintPdfButton } from '../../../../components'
import { SentralModel } from '../../../../models/SentralModel'
import { useParams } from 'react-router-dom'
import moment from 'moment'

function useForceUpdate() {
    // eslint-disable-next-line
    const [value, setValue] = useState(0);
    return () => setValue(value => ++value);
}

const Detail = () => {
    const { objectiveId } = useParams()
    const forceUpdate = useForceUpdate()
    const [loaded, setLoaded] = useState(false)
    const [saving, setSaving] = useState(false)
    const [formObjective] = Form.useForm()
    const [formProgress] = Form.useForm()
    const [months, setMonths] = useState([])
    const [objectives, setObjectives] = useState([])
    const [totalObjectives, setTotalObjectives] = useState([])
    const [formModalObjective, setFormModalObjective] = useState(false)
    const [formModalProgress, setFormModalProgress] = useState(false)
    const [objectiveItems, setObjectiveItems] = useState([])
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [selectedObjective, setSelectedObjective] = useState([])

    const fetchObjective = (objectiveId) => {
        SentralModel.action('SoftDev', 'getObjectiveDetail', { objective_id: objectiveId }).then(res => {
            setLoaded(false)
            setObjectives(res.data.progress)
            setTotalObjectives(res.data.total)
        }).finally(() => {
            setLoaded(true)
        })
    }

    const fetchObjectiveItem = (objectiveId) => {
        SentralModel.list('ObjectiveItem', { filter: [['objective_id', objectiveId]] }).then(res => {
            setLoaded(false)
            setObjectiveItems(res.data)
        }).finally(() => {
            setLoaded(true)
        })
    }

    const fecthMonth = () => {
        setMonths(moment.monthsShort());
    }

    useEffect(() => {
        fetchObjective(objectiveId);
        fetchObjectiveItem(objectiveId)
        fecthMonth();
    }, [objectiveId]);

    const saveObjectiveEmployee = (v) => {
        setSaving(true)
        let payload = {
            employee_code: selectedEmployee,
            objectives: selectedObjective
        }
        SentralModel.action('SoftDev', 'saveObjectiveEmployee', payload, v.objective_item_id).then((res) => {
            setFormModalObjective(false)
            fetchObjective(objectiveId);
            message.success('Objective saved', 2);
            setSaving(false)
        }).catch(() => {
            message.error('Something went wrong', 2);
            setSaving(false)
        })
    }

    const saveProgress = (v) => {
        setSaving(true)
        let payload = {
            progress: v.progress,
        }
        SentralModel.action('SoftDev', 'saveProgress', payload, v.objective_member_id).then((res) => {
            setFormModalProgress(false)
            fetchObjective(objectiveId);
            message.success('Progress saved', 2);
            setSaving(false)
        }).catch(() => {
            message.error('Something went wrong', 2);
            setSaving(false)
        })
        // SentralModel.save('ObjectiveMember', payload, v.objective_member_id).then((res) => {
        //     setFormModalProgress(false)
        //     fetchObjective(objectiveId);
        //     message.success('Progress saved',2);
        // }).finally(() => {
        //     setSaving(false)
        // })
    }

    const editObjectiveItems = (v) => {
        setSelectedEmployee(v.employee_code)
        let p = [];
        v.items.forEach(el => {
            p.push(el.objective_item_id)
        });
        setSelectedObjective(p)
        setFormModalObjective(true)
    }

    const editProgress = (v) => {
        formProgress.setFieldsValue({
            objective_member_id: v.objective_member_id,
            progress: v.progress ?? 0,
        })
        setFormModalProgress(true)
    }

    const selectLeave = (e, v) => {
        let p = selectedObjective
        if (e.target.checked) {
            p.push(v)
        } else {
            p.splice(p.findIndex((el) => el === v), 1)
        }
        setSelectedObjective(p)
        // console.log(p)
        forceUpdate()
    }

    const printData = () => {
        window.open(process.env.REACT_APP_API_URL + '/print/objective/' + objectiveId)
    }

    return (
        <Card className="content-container">
            <PageTitle title="Objective" breadcrumbs={[['Software Development', '/softdev'], ['Objective']]}></PageTitle>
            {
                loaded ?
                    <div>
                        <Row className="mb-3">
                            <Col span={24} >
                                <PrintPdfButton right onPrint={printData} />
                            </Col>
                        </Row>
                        <table className="table-collapse table-default" style={{ width: '100%' }}>
                            <thead>
                                <tr>
                                    <th className="text-center">No</th>
                                    <th>Employee</th>
                                    <th>Objective</th>
                                    {
                                        months.map(month => (
                                            <th className="text-center">
                                                {month}
                                            </th>
                                        ))
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    objectives.map((employee, key) => (
                                        <>
                                            <tr>
                                                <td className="text-center" width="7%" rowSpan={employee.items.length > 0 ? employee.items.length : 1}> {key + 1} </td>
                                                <td style={{ cursor: 'pointer', verticalAlign: 'center' }} onClick={() => { editObjectiveItems(employee) }} rowSpan={employee.items.length > 0 ? employee.items.length : 1}> {employee.name} </td>
                                                <td>{employee.items.length > 0 ? employee.items[0].description : ''}</td>
                                                {
                                                    employee.items.length > 0 ?
                                                        employee.items[0].progresses.map((prog, i) => (
                                                            <td className="text-center" style={{ cursor: 'pointer', verticalAlign: 'center' }} onClick={() => { editProgress(prog) }}> {prog.progress === null ? '-' : prog.progress + '%'} </td>
                                                        )) :
                                                        months.map((month) => (
                                                            <td className="text-center">
                                                                -
                                                            </td>
                                                        ))
                                                }
                                            </tr>

                                            {
                                                employee.items.map((item, row) => (
                                                    row > 0 ?
                                                        <tr>
                                                            <td>{item.description}</td>
                                                            {
                                                                item.progresses.map((prog, i) => (
                                                                    <td className="text-center" style={{ cursor: 'pointer', verticalAlign: 'center' }} onClick={() => { editProgress(prog) }}> {prog.progress === null ? '-' : prog.progress + '%'} </td>
                                                                ))
                                                            }
                                                        </tr>
                                                        : null
                                                ))
                                            }
                                        </>
                                    ))
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th colSpan={3} id="total">Total</th>
                                    {
                                        totalObjectives.map((total, i) => (
                                            <td className="text-center" style={{ verticalAlign: 'center' }}> {total.percent === null ? '-' : parseFloat(total.percent).toFixed(2) + '%'} </td>
                                        ))
                                    }
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    : <Spinner />
            }

            {
                (formModalObjective) ?
                    <FormModal form={formObjective} title="Assign Objective" submitForm={(v) => saveObjectiveEmployee(v)} width={400} showModal={formModalObjective} onCloseModal={() => setFormModalObjective(false)} saving={saving}>
                        {
                            objectiveItems.map((item, i) => (
                                <div className="full-width" key={"item_" + i}>
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item name={'select_' + item.objective_item_id} style={{ padding: 0, margin: '0px' }}>
                                                <Checkbox checked={selectedObjective.includes(item.objective_item_id)} onChange={(v) => selectLeave(v, item.objective_item_id)}>
                                                    {item.description}
                                                </Checkbox>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <br />
                                </div>
                            ))
                        }
                    </FormModal>
                    : null
            }

            {
                (formModalProgress) ?
                    <FormModal form={formProgress} title="Update Progress" submitForm={(v) => saveProgress(v)} width={400} showModal={formModalProgress} onCloseModal={() => setFormModalProgress(false)} saving={saving}>
                        <Form.Item name="objective_member_id" hidden>
                            <Input placeholder="ID" />
                        </Form.Item>
                        <Form.Item label="Progress" name="progress" rules={[{ required: true }]}>
                            <InputNumber min={0} max={100} style={{ width: "100%" }} />
                        </Form.Item>
                    </FormModal>
                    : null
            }
        </Card>
    );
}

export default Detail;
