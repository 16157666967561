import { useState, useEffect } from 'react'
import { Button, Card, Col, Form, Row, Space, Select, Input, message } from 'antd'
import { DataTable, DeleteButton, EditButton, FormModal, PageTitle, Spinner } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import authData from '../../../helpers/authData'

const { Option } = Select

const Index = () => {
    const [form] = Form.useForm()
    const [loaded, setLoaded] = useState(false)
    const [masterJobDesc, setMasterJobDesc] = useState([])
    const [division, setDivision] = useState([])
    const [position, setPosition] = useState([])
    const [showFormModal, setShowFormModal] = useState(false)
    const [formModalTitle, setFormModalTitle] = useState("")

    const fetchMasterJob = (v) => {
        setLoaded(false)
        SentralModel.action("MasterJobDescription", "getMasterData", v, null).then((res) => {
            setMasterJobDesc(res.data)
            setLoaded(true)
        })
    }

    const fetchDivision = () => {
        SentralModel.list('Division', { filter: [["is_active", "1"]], orderBy: "division_name", order: "asc" }).then(res => {
            const v = res.data
            let arr = []
            v.forEach(e => {
                arr.push({ label: e.division_name, value: e.division_id })
            });
            setDivision(arr)
        })
    }

    const fetchPosition = () => {
        SentralModel.list('Position', { filter: [["is_active", "1"]], orderBy: "position_name", order: "asc" }).then(res => {
            const v = res.data
            let arr = []
            v.forEach(e => {
                arr.push({ label: e.position_name, value: e.position_id })
            })
            setPosition(arr)
        })
    }

    useEffect(() => {
        fetchMasterJob({ division_id: authData.division_id, position_id: authData.position_id, job_category: null })
        fetchDivision()
        fetchPosition()

    }, [])

    const columns = [
        {
            title: "No",
            dataIndex: 'no',
            align: 'center',
            key: 'name',
        },
        {
            title: 'Job Category',
            dataIndex: 'job_category',
            key: 'job_category'
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: 'Division',
            render: (row) => (
                row?.division?.division_name
            ),
            key: 'division'
        },
        {
            title: 'Position',
            render: (row) => (
                row?.position?.position_name
            ),
            key: 'position'
        },
        {
            title: 'Action',
            render: (row) => (
                <Space>
                    <EditButton onEdit={() => editData(row)} />
                    <DeleteButton onConfirm={() => deleteData(row.master_job_desc_id)} />
                </Space>
            ),
            key: 'action',
            align: 'center',
            width: '10%'
        }
    ]

    const onChangeDivision = (v) => {
        fetchMasterJob({ division_id: v, position_id: null, job_category: null })
    }

    const addData = () => {
        setFormModalTitle("Add Master Job Desc")
        setShowFormModal(true)
        form.setFieldsValue({
            master_job_desc_id: 0,
            job_category: null,
            description: null,
            division_id: null,
            position_id: null
        })
    }

    const editData = (v) => {
        setFormModalTitle("Edit Master Job Desc")
        setShowFormModal(true)
        form.setFieldsValue({
            master_job_desc_id: v.master_job_desc_id,
            job_category: v.job_category,
            description: v.description,
            division_id: v.division_id,
            position_id: v.position_id
        })
    }

    const deleteData = (v) => {
        SentralModel.action("MasterJobDescription", "deleteData", null, v).then((res) => {
            message.success("Success delete Data")
            fetchMasterJob({ division_id: authData.division_id, position_id: authData.position_id, job_category: null })
        }).catch((err) => {
            message.warning("Failed delete data")
        })
    }

    const saveMasterJob = (v) => {
        let payload = {
            ...v
        }

        SentralModel.action("MasterJobDescription", "saveMasterJobDesc", payload, v.master_job_desc_id).then((res) => {
            message.success(v.master_job_desc_id === 0 ? "Success add data" : "Success edit data")
            fetchMasterJob({ division_id: 34, position_id: 66, job_category: null })
            setShowFormModal(false)
        }).catch((err) => {
            message.warning(v.master_job_desc_id === 0 ? "Failed add data" : "Failed edit data")
        })
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Master Job Desc" breadcrumbs={[['Master Job Desc']]}></PageTitle>
                <Card title={
                    <Row justify='end'>
                        <Col>
                            <Space>
                                <Button type='primary' onClick={addData}>Add Master Job</Button>
                                <Select
                                    showSearch
                                    placeholder="Select Division"
                                    options={division}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    onChange={onChangeDivision}
                                    style={{ width: 200 }}
                                />
                            </Space>
                        </Col>
                    </Row>
                }>
                    {
                        loaded ?
                            <DataTable bordered size="small" columns={columns} dataSource={masterJobDesc} /> :
                            <Spinner />
                    }
                </Card>
            </Card>

            {
                showFormModal ?
                    <FormModal showModal={showFormModal} onCloseModal={() => setShowFormModal(false)} title={formModalTitle} width={600} submitForm={saveMasterJob} form={form}>
                        <Form.Item name="master_job_desc_id" hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Job Category" name="job_category">
                            <Select placeholder="Select Job Category">
                                <Option value="DAILY">DAILY</Option>
                                <Option value="WEEKLY">WEEKLY</Option>
                                <Option value="MONTHLY">MONTHLY</Option>
                                <Option value="QUARTERLY">QUARTERLY</Option>
                                <Option value="SEMESTER">SEMESTER</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label="Description" name="description">
                            <Input.TextArea rows={3} />
                        </Form.Item>
                        <Form.Item label="Division" name="division_id">
                            <Select
                                showSearch
                                placeholder="Select Division"
                                options={division}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                        <Form.Item label="Position" name="position_id">
                            <Select
                                showSearch
                                placeholder="Select Position"
                                options={position}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </FormModal>
                    : null
            }
        </div>
    )
}

export default Index