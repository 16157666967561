import React, { useEffect, useState } from 'react';
import { Card, Typography, Descriptions, Button } from 'antd'
import { DataTable, PageTitle, Spinner } from '../../../../components'
import { SentralModel } from '../../../../models/SentralModel'
import { useParams } from 'react-router-dom';
import { formatDateTime, toFullDate } from '../../../../utils/dateHandler';
import { FilePdfOutlined } from '@ant-design/icons';
import moment from 'moment'

const { Text } = Typography
const Detail = () => {
    const { trainingId } = useParams()
    const [training, setTraining] = useState([])
    const [participants, setParticipants] = useState([])
    const [loaded, setLoaded] = useState(false)

    const fetchTraining = (trainingId) => {
        setLoaded(false)
        SentralModel.get('Training', {}, trainingId).then(res => {
            setTraining(res.data)
        }).finally(() => {
            setLoaded(true)
        })
    }

    const fetchTrainingParticipants = (trainingId) => {
        setLoaded(false)
        SentralModel.list('TrainingParticipant', { filter: [['training_id', trainingId]], orderBy: 'training_id', order: 'ASC' }, 0).then((res) => {
            setParticipants(res.data);
            setLoaded(true);
        })
    }

    useEffect(() => {
        fetchTraining(trainingId)
        fetchTrainingParticipants(trainingId)
    }, [trainingId]);

    const trainingSource = [
        ...participants.map(el => {
            return {
                employee_code: el.employee_code,
                name: el.employee.name,
                division: el.employee.division.division_name,
            }
        })
    ]

    const trainingColumn = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'name',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Division',
            dataIndex: 'division',
            key: 'division',
        },
    ];

    const exportReport = () => {
        window.open(process.env.REACT_APP_API_URL + `/report/training/${trainingId}`);
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Training Report Detail" breadcrumbs={[['Training Report', '/report/training'], ['Detail']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{ float: 'left' }}>Training Detail</Text>
                    </div>}>

                    {
                        (loaded) ?
                            <Descriptions layout="horizontal" bordered column={1}>
                                <Descriptions.Item label={<span className="text-bold">Judul</span>}>
                                    {training.title}
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className="text-bold">Tanggal</span>}>
                                    {toFullDate(training.training_date)}
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className="text-bold">Waktu</span>}>
                                    {moment(training.start_time, 'HH:mm:ss').format('HH:mm')} - {moment(training.end_time, 'HH:mm:ss').format('HH:mm')}
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className="text-bold">Batas Registrasi</span>}>
                                    {formatDateTime(training.register_limit)}
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className="text-bold">Deskripsi</span>}>
                                    {training.description}
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className="text-bold">Kuota</span>}>
                                    {training.quota}
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className="text-bold">Sertifikat</span>}>
                                    {training.certificate ? 'Ya' : 'Tidak'}
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className="text-bold" style={{ verticalAlign: 'top' }}>Trainer</span>}>
                                    <ol style={{ paddingLeft: 20 }}>
                                        {
                                            training.trainers?.map((el, key) => (
                                                <li key={key}>{el.name}</li>
                                            ))
                                        }
                                    </ol>
                                </Descriptions.Item>
                            </Descriptions>
                            :
                            <Spinner />
                    }
                </Card>
                <br />
                <Card title={
                    <div className="full-width">
                        <Text strong style={{ float: 'left' }}>Participants</Text>
                        <Button style={{ float: 'right', borderRadius: 5 }} type="primary" onClick={() => exportReport()} icon={<FilePdfOutlined />}>Export Participants</Button>
                    </div>}>
                    {
                        (loaded) ?
                            <DataTable size="small" columns={trainingColumn} dataSource={trainingSource} bordered />
                            : <Spinner />
                    }
                </Card>
            </Card>

        </div>
    );
}

export default Detail;
