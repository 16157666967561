import React from 'react';
import { Card, Tabs, Tag } from 'antd'
import { FieldTimeOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { DataTable, ShowButton } from '../../../components'
import { toIdr } from '../../../utils/currencyHandler'

const { TabPane } = Tabs;

const getStatus = (status) => {
    if (status === "WAITING") {
        return <Tag style={{ color: '#808080', width: 120 }}>Waiting Approval</Tag>
    } else if (status === "APPROVED") {
        return <Tag color="green" style={{ width: 120 }}>APPROVED</Tag>
    } else if (status === "REJECTED") {
        return <Tag color="red" style={{ width: 120 }}>REJECTED</Tag>
    }
}

const Index = (props) => {
    const commissionColumn = [{
        title: 'No',
        dataIndex: 'no',
        align: 'center',
        key: 'name',
    },
    {
        title: 'Employee Name',
        dataIndex: 'name',
        key: 'name'
    }, {
        title: 'Description',
        dataIndex: 'title',
        key: 'title'
    }, {
        title: 'Total Amount',
        dataIndex: 'total_amount',
        key: 'total_amount',
    }, {
        title: 'Status',
        align: 'center',
        dataIndex: 'status',
        render: (text) => (
            getStatus(text)
        )
    }, {
        title: 'Action',
        width: '10%',
        render: (row) => (
            <div className="text-center">
                <ShowButton link to={'/submission-list/commission/' + row.id} />
            </div>
        )
    }
    ];
    const rmbsData = [
        ...props.data.map((el) => {
            return {
                id: el.request_id,
                name: el.name,
                title: el.title,
                total_amount: toIdr(el.total_amount),
                division_name: el.division_name,
                status: el.status,
                submitted_at: el.submitted_at
            }
        })
    ]
    return (
        <Card>
            <Tabs defaultActiveKey="waiting">
                <TabPane tab={<span><FieldTimeOutlined style={{ color: '#4ca3ff' }} /> Waiting Approval</span>} key="waiting">
                    <DataTable size="small" columns={commissionColumn} dataSource={rmbsData.filter(el => el.status === 'WAITING')} bordered />
                </TabPane>
                <TabPane tab={<span><CheckCircleOutlined style={{ color: 'green' }} /> Approved</span>} key="approved">
                    <DataTable size="small" columns={commissionColumn} dataSource={rmbsData.filter(el => el.status === 'APPROVED')} bordered />
                </TabPane>
                <TabPane tab={<span><CloseCircleOutlined style={{ color: 'red' }} /> Rejected</span>} key="rejected">
                    <DataTable size="small" columns={commissionColumn} dataSource={rmbsData.filter(el => el.status === 'REJECTED')} bordered />
                </TabPane>
            </Tabs>
        </Card>
    );
}

export default Index;
