import React, {useEffect, useState} from 'react';
import {Card, Typography, Space, Form, Input, message} from 'antd'
import {NewButton, ShowButton, PageTitle, Spinner, DataTable, CustomButton, FormModal} from '../../../../components'
import {SentralModel} from '../../../../models/SentralModel'
import {MessageOutlined} from '@ant-design/icons'

const {Text} = Typography
const Index = () => {
    const [goodsReceipts, setGoodsReceipts] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [saving, setSaving] = useState(false)

    const fetchGoodsReceipts = () =>{
        SentralModel.list('StationeryGoodsReceipt').then(res=>{
            setGoodsReceipts(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchGoodsReceipts()
    }, []);
    
    const dataSource = [
        ...goodsReceipts.map( el => {
            return {
                id: el.stationery_goods_receipt_id,
                goods_receipt_number: el.goods_receipt_number,
                purchase_number: el.purchase_number,
                vendor_name: el.vendor ? el.vendor.vendor_name : null,
                date: el.date,
                remarks: el.remarks
            }
        })
    ];

    const columns = [{
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'no',
        },
        {
          title: 'GR Number',
          dataIndex: 'goods_receipt_number',
          key: 'goods_receipt_number',
        },
        {
          title: 'PO Number',
          dataIndex: 'purchase_number',
          key: 'purchase_number',
        },
        {
          title: 'Vendor',
          dataIndex: 'vendor_name',
          key: 'vendor_name',
        },
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'date',
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <ShowButton link to={'/stationery/goods-receipt/' + row.id} />
                        <CustomButton icon={<MessageOutlined />} onEdit={() => editRemarks(row)}/>
                    </Space>
                </div>
            )
        },
    ];

    const editRemarks = (v) =>{
        setDefaultValues({
            stationery_goods_receipt_id: v.id,
            remarks: v.remarks,
        })
        setFormModal(true)
    }

    const savePosition = (v) => {
        setSaving(true)
        let payload={
            remarks: v.remarks,
        }
        SentralModel.save('StationeryGoodsReceipt', payload, v.stationery_goods_receipt_id).then((res)=>{
            setFormModal(false)
            fetchGoodsReceipts()
            message.success('Good receipt saved', 2);
        }).finally(() => {
            setSaving(false)
        })
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Goods Receipt" breadcrumbs={[['Stationery'],['Goods Receipt']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>Goods Receipt List</Text>
                        <NewButton right to="/stationery/goods-receipt/create" title={'New Goods Receipt'}/>
                    </div> }>

                    {
                        (loaded) ?
                        <DataTable size='small' bordered dataSource={dataSource} columns={columns}/>
                        :
                        <Spinner/>
                    }
                </Card>

                {
                    (formModal) ?
                    <FormModal defaultValues={defaultValues} title="Edit Remarks" submitForm={(v)=>savePosition(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                        <Form.Item name="stationery_goods_receipt_id" hidden>
                            <Input placeholder="Stationery GR ID" />
                        </Form.Item>
                        <Form.Item label="Remarks" name="remarks">
                            <Input placeholder="Remarks" />
                        </Form.Item>
                    </FormModal> :
                    null
                }
            </Card>
        </div>
    );
}

export default Index;
