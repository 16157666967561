import { useState } from 'react';
import moment from 'moment';
import { Button, Descriptions, Row, Tag } from 'antd';
import { BsClock, BsCheckCircle } from "react-icons/bs";
import { BasicModal } from '../../../../components/Modal';

const Semester = (props) => {
    const { data } = props
    const [detailJob, setDetailJob] = useState({})
    const [showModal, setShowModal] = useState(false)

    const showDetailJob = (v) => {
        if (v) {
            setDetailJob(v)
            setShowModal(true)
        }
    }

    return (
        <div>
            <table className="styled-table">
                <thead>
                    <tr>
                        <th style={{ border: '0.5px solid grey' }}>NO</th>
                        <th style={{ border: '0.5px solid grey' }}>Job Description</th>
                        <th style={{ border: '0.5px solid grey' }}>SEMESTER 1 (Jan - Jun)</th>
                        <th style={{ border: '0.5px solid grey' }}>SEMESTER 2 (Jul - Des)</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((el, key) => (
                            <tr style={{ cursor: 'pointer', textAlign: 'start' }} key={key} className="active-row">
                                <td style={{ border: '0.5px solid grey', textAlign: 'center' }}>{key + 1}</td>
                                <td style={{ border: '0.5px solid grey', textAlign: 'center' }}>
                                    {el?.master_job?.description ?? "-"}
                                </td>
                                <td style={{ border: '0.5px solid grey', textAlign: 'center' }}>
                                    {
                                        (el?.employee?.filter(item => (new Date(item.start_date).getMonth() + 1) === 1)).length > 0 ?
                                            (el?.employee?.filter(item => (new Date(item.start_date).getMonth() + 1) === 1)).map((e, key) => (
                                                <div key={key} onClick={() => showDetailJob(e)}>
                                                    <Row justify='center'>
                                                        <p style={{ fontSize: 12, color: "#828282" }}>{`${moment(e.start_date).format("YYYY-MM-DD")} - ${moment(e.end_date).format("YYYY-MM-DD")}`}</p>
                                                    </Row>
                                                    <Row justify='center'>
                                                        {
                                                            e.status === 'Finished' ?
                                                                <BsCheckCircle style={{ fontSize: "20px", fontWeight: 700 }} color="#24e551" /> :
                                                                <BsClock style={{ fontSize: "20px" }} color="#efae2b" />
                                                        }
                                                    </Row>
                                                    <Row justify='center'>
                                                        <p style={{ fontSize: 12, color: "#828282" }}>
                                                            {e?.employee?.name}
                                                        </p>
                                                    </Row>
                                                </div>
                                            )) : null
                                    }
                                </td>
                                <td style={{ border: '0.5px solid grey', textAlign: 'center' }}>
                                    {
                                        (el?.employee?.filter(item => (new Date(item.start_date).getMonth() + 1) === 2)).length > 0 ?
                                            (el?.employee?.filter(item => (new Date(item.start_date).getMonth() + 1) === 2)).map((e, key) => (
                                                <div key={key} onClick={() => showDetailJob(e)}>
                                                    <Row justify='center'>
                                                        <p style={{ fontSize: 12, color: "#828282" }}>{`${moment(e.start_date).format("YYYY-MM-DD")} - ${moment(e.end_date).format("YYYY-MM-DD")}`}</p>
                                                    </Row>
                                                    <Row justify='center'>
                                                        {
                                                            e.status === 'Finished' ?
                                                                <BsCheckCircle style={{ fontSize: "20px", fontWeight: 700 }} color="#24e551" /> :
                                                                <BsClock style={{ fontSize: "20px" }} color="#efae2b" />
                                                        }
                                                    </Row>
                                                    <Row justify='center'>
                                                        <p style={{ fontSize: 12, color: "#828282" }}>
                                                            {e?.employee?.name}
                                                        </p>
                                                    </Row>
                                                </div>
                                            )) : null
                                    }
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>

            {
                showModal ?
                    <BasicModal title="Detail Job" width={800} showModal={showModal} onCloseModal={() => setShowModal(false)}>
                        <div style={{ marginBottom: 10 }}>
                            {
                                detailJob &&
                                <Descriptions bordered>
                                    <Descriptions.Item label="Description" span={2}>{detailJob.job_desc.description}</Descriptions.Item>
                                    <Descriptions.Item label="Status">
                                        {
                                            <Tag color={detailJob.status === 'In Progress' ? 'blue' : detailJob.status === 'Finished' ? 'green' : 'red'}>
                                                {detailJob.status}
                                            </Tag>
                                        }
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Job Category" span={2}>{detailJob.job_category}</Descriptions.Item>
                                    <Descriptions.Item label="Employee">{detailJob.employee.name}</Descriptions.Item>
                                </Descriptions>
                            }
                        </div>
                        <Button onClick={() => window.open(`${process.env.REACT_APP_API_FILE}/${detailJob.attachment}`)} disabled={detailJob.attachment === null ? true : false}>Show Attachment</Button>
                    </BasicModal>
                    : null
            }
        </div>
    )
}

export default Semester