import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { SentralModel } from "../../../models/SentralModel";
import moment from "moment";
import { message } from "antd";
import currentUser from "../../../helpers/authData";

export const fetchDailyActivities = createAsyncThunk(
    'dailyActivity/fetchDailyActivities',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await SentralModel.action("DailyActivity", "dailyActivities", { start_date: payload.start_date, end_date: payload.end_date })
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchMeasurement = createAsyncThunk(
    'dailyActivity/fetchMeasurement',
    async ({ rejectWithValue }) => {
        try {
            const response = await SentralModel.list('Measurement')
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchCompany = createAsyncThunk(
    'dailyActivity/fetchCompany',
    async ({ rejectWithValue }) => {
        try {
            const response = await SentralModel.action("Company", "getCompany", null, 0)
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchListProgressTask = createAsyncThunk(
    'dailyActicity/fetchListProgressTask',
    async (arg, { rejectWithValue }) => {
        try {
            SentralModel.action("Task", "listProgressTask", null, arg).then((res) => {
                const v = res.data
                const daily = v.filter((el) => el.data_from === "DAILY ACTIVITY" || el.data_from === "DAILY ATTENDANCE")
                return daily
            })
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetcListhWorkingLocation = createAsyncThunk(
    'dailyAcivity/fetcListhWorkingLocation',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await SentralModel.action("WorkingLocation", "getWorkingLocation", { employee_code: currentUser.employee_code }, 0)

            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const fetchMasterJobDesc = createAsyncThunk(
    'dailyActivity/fetchMasterJobDesc',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await SentralModel.action("MasterJobDescription", "getMasterData", { division_id: payload.division_id, position_id: payload.position_id, job_category: payload.job_category })
            return { data: response.data, category: payload.job_category }
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const saveDailyActivity = createAsyncThunk(
    'dailyActivity/saveDailyActivity',
    async (payload, { rejectWithValue }) => {
        try {
            await SentralModel.action("DailyActivity", "addDailyActivity", payload, null);
            message.success("Success Add Daily Activity")
        } catch (error) {
            message.warning("Failed Add Daily Activity")
            return rejectWithValue(error)
        }
    }
)

export const saveInfraActivity = createAsyncThunk(
    'dailyActivity/saveInfraActiity',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await SentralModel.action("DailyActivity", "saveDailyJobInfra", payload, null)
            message.success("Success Save Infra Activity")
            return response.data
        } catch (error) {
            console.log(error)
            message.warning("Failed Save Infra Activity")
            return rejectWithValue(error)
        }
    }
)

export const saveWorkingLocation = createAsyncThunk(
    'dailyActivity/saveWorkingLocation',
    async (payload, { rejectWithValue }) => {
        try {
            await SentralModel.action("WorkingLocation", "addWorkingLocation", payload, payload.all_app_task_id)
            message.success("Success Add Working Location");
        } catch (error) {
            message.success("Failed Add Working Location");
            return rejectWithValue(error)
        }
    }
)

export const updateDailyProgress = createAsyncThunk(
    'dailyActivity/updateDailyProgress',
    async (payload, { rejectWithValue }) => {
        try {
            await SentralModel.action("Task", "updateProgress", payload, 0)
            message.success("Success Update Progress", 2)

            return payload;
        } catch (error) {
            message.warning("Failed Update Progress")
            return rejectWithValue(error)
        }
    }
)

export const updateDailyProgressInfra = createAsyncThunk(
    'dailyActivity/updateDailyProgressInfra',
    async (payload, { rejectWithValue }) => {
        try {
            await SentralModel.action("DailyActivity", "updateProgressDailyInfra", payload, 0)
            message.success("Success Update Progress", 2)

            return payload;
        } catch (error) {
            message.warning("Failed Update Progress")
            return rejectWithValue(error)
        }
    }
)

export const removeDailyProgress = createAsyncThunk(
    'dailyActivity/removeDailyProgress',
    async (payload, { rejectWithValue }) => {
        try {
            if (payload.all_app_task_id !== 0) {
                await SentralModel.action("Task", "deleteProgress", null, payload.all_progress_task_id)
            }

            return payload
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

const myDailyActivity = createSlice({
    name: 'myDailyActivity',
    initialState: {
        loaded: false,
        measurements: [],
        companies: [],
        listWorkingLocation: [],
        datas: [],
        masterJob: [],
        dailyProgressData: [],
        defaultValueEdit: {},
        defaultStat: "",
        attachmentInfo: null,
        showModalAddDailyActivity: false,
        editProgressDailyModal: false,
        editProgressDailyInfraModal: false,
        showModalAddProgress: false,
        uploading: false,
        disabled: false,
        is_attachment: false,
        is_infra: false,
        editProgressModalTitle: "",
        titleAddDailyActivity: "",
        progressDailyValue: 0,
        job_category: null,
        attachmentFile: null,
        jobDescId: null,
        jobDesc: "",
    },
    reducers: {
        addDailyActivity: (state, action) => {
            state.progressDailyValue = 0
            state.showModalAddDailyActivity = true
            state.job_category = null
            state.titleAddDailyActivity = "Add Daily Activity"
        },
        changeAddDailyModal: (state, action) => {
            state.showModalAddDailyActivity = action.payload
        },
        editDaily: (state, action) => {
            const v = action.payload.data
            const stat = action.payload.stat

            state.defaultValueEdit = {
                all_app_task_id: v.id,
                emp_task_id: v.task_id,
                employee_code: v.created_by,
                category: state.category,
                data_from: v.data_from,
                task_to: v.assign_to,
                description: v.description,
                start_date: moment(v.start_date),
                deadline: moment(v.due_date),
                note: v.reply,
                finished_date: v.finished_date === null ? null : moment(v.finished_date),
                status: v.status === 'Finished' ? true : false,
            }

            state.dailyProgressData = v.progress;

            state.defaultStat = stat
            state.attachmentInfo = v.attachment
            state.editProgressDailyModal = true
            state.editProgressModalTitle = "Update Daily Activity"
        },
        editDailyInfra: (state, action) => {
            const v = action.payload.data
            const stat = action.payload.stat
            state.is_infra = true;

            state.defaultValueEdit = {
                all_app_task_id: v.id,
                emp_task_id: v.task_id,
                employee_code: v.created_by,
                category: state.category,
                data_from: v.data_from,
                task_to: v.assign_to,
                description: v.description,
                start_date: moment(v.start_date),
                deadline: moment(v.due_date),
                note: v.reply,
                finished_date: v.finished_date === null ? null : moment(v.finished_date),
                status: v.status === 'Finished' ? true : false,
            }

            state.dailyProgressData = v.progress;

            state.defaultStat = stat
            state.attachmentInfo = v.attachment
            state.editProgressDailyInfraModal = true
            state.editProgressModalTitle = "Update Daily Activity"
            state.attachmentFile = null
        },
        changeModalDailyStatus: (state, action) => {
            state.editProgressDailyModal = false
        },
        changeModalDailyStatusInfra: (state, action) => {
            state.editProgressDailyInfraModal = false
        },
        showAddProgress: (state, action) => {
            state.showModalAddProgress = true
            state.progressDailyValue = 0
        },
        closeAddProgress: (state, action) => {
            state.showModalAddProgress = false
        },
        addDailyProgress: (state, action) => {
            let arr = []
            let key = current(state.dailyProgressData).length

            if (state.progressDailyValue === 100 && state.is_infra === true) {
                state.disabled = true
                state.is_attachment = true
            }

            arr.push({
                key: key,
                all_app_task_id: 0,
                job_description: action.payload.job_description,
                time: action.payload.time,
                progress: state.progressDailyValue,
                time_start: moment(action.payload.time[0]).format("HH:mm"),
                time_end: moment(action.payload.time[1]).format("HH:mm")
            })

            if (state.dailyProgressData.length > 0) {
                const newData = state.dailyProgressData.concat(arr)
                state.dailyProgressData = newData
            } else {
                state.dailyProgressData = arr
            }

            state.showModalAddProgress = false
        },
        changeProgressDailyValue: (state, action) => {
            state.progressDailyValue = action.payload
        },
        changeJobCategory: (state, action) => {
            state.job_category = action.payload
        },
        fileUploaded: (state, action) => {
            const v = action.payload
            state.attachmentFile = v.message
            state.uploading = true
        },
        changeUploading: (state, action) => {
            state.uploading = action.payload
        },
        changeJobDesc: (state, action) => {
            state.jobDescId = action.payload
            state.jobDesc = current(state.masterJob).filter((el) => el.value === action.payload)[0].label
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchDailyActivities.pending, (state) => {
            state.loaded = false
        })
        builder.addCase(fetchDailyActivities.fulfilled, (state, { payload }) => {
            state.datas = payload
            state.loaded = true
        })
        builder.addCase(fetchDailyActivities.rejected, (state) => {
            state.datas = []
            state.loaded = true
        })
        builder.addCase(fetchMeasurement.fulfilled, (state, { payload }) => {
            const data = payload.filter((el) => el.measurement_id === 1 || el.measurement_id === 6)
            let arr = []
            data.forEach((el) => {
                arr.push({ value: el.measurement_id, label: el.title })
            })
            state.measurements = arr
        })
        builder.addCase(fetchCompany.fulfilled, (state, { payload }) => {
            let arr = []
            payload.forEach((el) => {
                arr.push({ value: el.company_id, label: el.company_name })
            })
            state.companies = arr
        })
        builder.addCase(fetcListhWorkingLocation.fulfilled, (state, { payload }) => {
            let data = []
            payload.map((el, key) => {
                return data.push({ ...el, index: key })
            })
            state.listWorkingLocation = data
        })
        builder.addCase(fetchMasterJobDesc.fulfilled, (state, { payload }) => {
            let arr = []
            if (payload.category === "DAILY") {
                arr.push({ value: 0, label: "Other" })
            }

            payload.data.forEach((el) => {
                arr.push({ value: el.master_job_desc_id, label: el.description })
            })

            state.masterJob = arr
        })
        builder.addCase(saveDailyActivity.fulfilled, (state) => {
            state.showModalAddDailyActivity = false
        })
        builder.addCase(saveInfraActivity.fulfilled, (state) => {
            state.showModalAddDailyActivity = false
        })
        builder.addCase(saveWorkingLocation.fulfilled, (state, { payload }) => {
            fetcListhWorkingLocation();
        })
        builder.addCase(updateDailyProgress.fulfilled, (state, { payload }) => {
            state.editProgressDailyModal = false
        })
        builder.addCase(removeDailyProgress.fulfilled, (state, action) => {
            let newData
            if (action.payload.all_app_task_id === 0) {
                newData = current(state.dailyProgressData).filter((el) => el.key !== action.payload.key)
            } else {
                newData = current(state.dailyProgressData).filter((el) => el.all_progress_task_id !== action.payload.all_progress_task_id)
            }

            state.dailyProgressData = newData
        })
        builder.addCase(updateDailyProgressInfra.fulfilled, (state, action) => {
            state.editProgressDailyInfraModal = false
        })
    }
})

export const {
    addDailyActivity,
    changeAddDailyModal,
    editDaily,
    editDailyInfra,
    changeModalDailyStatus,
    changeModalDailyStatusInfra,
    showAddProgress,
    closeAddProgress,
    addDailyProgress,
    changeProgressDailyValue,
    changeJobCategory,
    fileUploaded,
    changeUploading,
    changeJobDesc
} = myDailyActivity.actions

export default myDailyActivity.reducer