import React, { useState, useEffect } from 'react';
import { Card, Select, DatePicker, Form, Button, Drawer, Space } from 'antd';
import { FilterOutlined, FileExcelOutlined } from '@ant-design/icons'
import { PageTitle, DataTable, Spinner } from '../../../components';
import { formatDateTime } from '../../../utils/dateFormat'
import { SentralModel } from '../../../models/SentralModel';
import {toFullDate} from '../../../utils/dateHandler'
import moment from 'moment'

const { RangePicker } = DatePicker
const { Option } = Select

const MonthlyReport = () => {
    const [loaded, setLoaded] = useState(true)
    const [showFilter, setShowFilter] = useState(false)
    const [divisions, setDivisions] = useState([])
    const [datas, setDatas] = useState([])
    const [selectedDivision, setSelectedDivision] = useState(null)
    const [selectedDateRange, setSelectedDateRange] = useState([])

    const fetchDivision = () => {
        SentralModel.list('Division', { orderBy: 'division_name', order: 'ASC' }, 0).then((res) => {
            setDivisions(res.data);
        })
    }

    useEffect(() => {
        fetchDivision()
    }, []);

    const filterData = (v) => {
        let ft = {
            division_id: v.division_id ? v.division_id : null,
            date_range: (v.date_range) ? [moment(v.date_range[0]).format('YYYY-MM-DD'), moment(v.date_range[1]).format('YYYY-MM-DD')] : []
        }
        fetchReport(ft)
        setShowFilter(false)
    }

    const fetchReport = (ft) => {
        setLoaded(false)
        SentralModel.action('Report', 'monthlyReport', ft, 0).then((res) => {
            // console.log(res.data)
            setDatas(res.data)
            setLoaded(true)
        })
    }

    const dataSources = [
        ...datas.map(el => {
            return {
                id: el.sub_medical_id,
                employee_code: el.employee_code,
                name: el.name,
                division_name: el.division_name,
                title: el.title,
                report_type: el.report_type,
                submit_date: toFullDate(el.submit_date),
                description: el.description,
                request_date: formatDateTime(el.created_at)
            }
        })
    ]

    const dataColumns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            fixed: 'left',
        }, {
            title: 'Employee Name',
            dataIndex: 'name',
            width: '200px',
            fixed: 'left',
        }, {
            title: 'Division',
            dataIndex: 'division_name',
            width: '200px',
            fixed: 'left',
        }, {
            title: 'Request Date',
            dataIndex: 'request_date',
            width: '180px',
        }, {
            title: 'Title',
            dataIndex: 'title',
            width: '200px',
            key: 'title',
        }, {
            title: 'Type',
            dataIndex: 'report_type',
            width: '200px',
            key: 'report_type'
        }, {
            title: 'Submit Email Date',
            dataIndex: 'submit_date',
            width: '180px',
            key: 'submit_date'
        }, {
            title: 'Description',
            dataIndex: 'description',
            width: '400px',
            key: 'description'
        }
    ];

    const changeDateRange = (v) => {
        if (v) {
            setSelectedDateRange([moment(v[0]).format('YYYY-MM-DD'), moment(v[1]).format('YYYY-MM-DD')])
        } else {
            setSelectedDateRange([])
        }
    }

    const exportReport = () => {
        window.open(process.env.REACT_APP_API_URL + '/report/monthly-report?division_id=' + selectedDivision + '&from=' + selectedDateRange[0] + '&to=' + selectedDateRange[1])
    }

    return (
        <Card className="content-container">
            <PageTitle title="Monthly Report" breadcrumbs={[['Monthly Report']]}></PageTitle>

            <div className="text-right mb-2">
                <Space>
                    <Button type="primary" onClick={() => exportReport()} shape="circle" icon={<FileExcelOutlined />}></Button>
                    <Button shape="circle" icon={<FilterOutlined />} onClick={() => setShowFilter(true)}></Button>
                </Space>
            </div>
            {
                (loaded) ?
                    <DataTable scroll={{ x: 1500 }} pagination={false} size="small" columns={dataColumns} dataSource={dataSources} bordered />
                    : <Spinner />
            }

            <Drawer
                title="Filter Report"
                width={400}
                placement="right"
                closable={false}
                onClose={() => setShowFilter(false)}
                visible={showFilter}>
                <Form layout="vertical" onFinish={filterData}>
                    <Form.Item name="division_id" label="Division">
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Select Division"
                            onChange={(v) => {
                                setSelectedDivision(v)
                            }}
                            allowClear>
                            {
                                divisions.map((el, key) => (
                                    <Option key={key} value={el.division_id}>{el.division_name}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name="date_range" label="Date Range">
                        <RangePicker onChange={(v) => changeDateRange(v)} />
                    </Form.Item>
                    <Form.Item >
                        <div className="text-right">
                            <Button htmlType="submit" type="primary" size="middle" icon={<FilterOutlined />}> Show</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Drawer>
        </Card>
    );
}

export default MonthlyReport;
