import React, {useState, useEffect} from 'react';
import {Card, Select, Tag} from 'antd';
import { ShowButton, PageTitle, Spinner, DataTable } from '../../../components';
import { SentralModel } from '../../../models/SentralModel';
import {setSessionStorage, getSessionStorage} from '../../../helpers/sessionStorage';

const {Option} = Select
const Index = () => {
    const [loaded, setLoaded] = useState(false)
    const [divisions, setDivisions] = useState([])
    const [employees, setEmployees] = useState([])

    const fetchEmployeeSchema = (v) =>{
        setLoaded(false)
        SentralModel.action('Payroll', 'getEmployees', {division_id:v}, 0).then((res)=>{
            setEmployees(res.data)
            setLoaded(true)
        })
    }

    const initialDivision = () => {
        if(getSessionStorage('payrollSchemaDivision')){
            return parseInt(getSessionStorage('payrollSchemaDivision'))
        }
        return null
    } 

    const fetchDivisions = () =>{
        SentralModel.list('Division').then((res)=>{
            setDivisions(res.data)
            setLoaded(true)
        })
    }

    useEffect(fetchDivisions, []);

    useEffect(() => {
        fetchDivisions()
        if(getSessionStorage('payrollSchemaDivision')){
            fetchEmployeeSchema(getSessionStorage('payrollSchemaDivision'))
            setSessionStorage(getSessionStorage('payrollSchemaDivision'))
        }
    }, []);

    const columns= [{
            title: 'No',
            dataIndex: 'no',
            key: 'name',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },{
            title: 'Position',
            dataIndex: 'position_name',
            key: 'position',
        },{
            title: 'Salary Schema',
            width:'20%',
            dataIndex: 'payroll_schema',
            align:'center',
            render:(text)=>(
                <div className="text-center">
                    {
                        (text>0) ? 
                        <Tag style={{width:150}} color="green">SCHEMA AVAILABLE</Tag> : 
                        <Tag style={{width:150}} color="red">SCHEMA NOT FOUND</Tag>
                    }
                </div>
            )
        },{
            title: 'Action',
            align:'center',
            width:'5%',
            render: (row)=>(
                <div className="text-center">
                    <ShowButton link to={'/payroll/schema/'+row.employee_code}/>
                </div>
            )
        }
    ];

    const changeDivision = (v)=>{
        fetchEmployeeSchema(v)
        setSessionStorage('payrollSchemaDivision', v)
    }
    return (
        <Card className="content-container">
            <PageTitle title="Payroll Schema" breadcrumbs={[['Payroll Schema']]}></PageTitle>
            <div className="full-width">
                <span className="mb-3" style={{float:'right'}}>
                    <Select defaultValue={initialDivision} style={{ width: 250}} onChange={changeDivision} placeholder="Select Division">
                        {
                            divisions.map((el, key)=>(
                                <Option key={'division_'+key} value={el.division_id}>{el.division_name}</Option>
                            ))
                        }
                    </Select>
                </span>
            </div>
            {
                (loaded) ?   
                    <DataTable bordered size="small" columns={columns} dataSource={employees}/>
                : <Spinner/>
            }
        </Card>
    );
}

export default Index;
