import React, {useState, useEffect} from 'react';
import {Card, message} from 'antd';
import {PageTitle, Spinner} from '../../../components'
import RequestForm from './RequestForm'
import { SentralModel } from '../../../models/SentralModel';
import {useHistory, useParams} from 'react-router-dom'

const Create = () => {
    const {leaveId} = useParams()
    const history = useHistory()
    const [saving, setSaving] = useState(false)
    const [leave, setLeave] = useState(null)

    const fetchLeave = (leaveId) => {
        SentralModel.get('SubmissionLeave',{}, leaveId).then((res) => {
            setLeave(res.data)
        })
    }

    useEffect(() => {
        fetchLeave(leaveId)
    }, [leaveId]);

    const submitLeaveCancellation = (v)=>{
        message.loading('Saving Data', 0)
        setSaving(true)
        let data={
            leave_id: leaveId,
            cancellation_reason:v.cancellation_reason
        }
        SentralModel.action('Submission','saveLeaveCancellation', data,0).then(()=>{
            setSaving(false)
            message.destroy()
            message.success('Leave Cancellation submitted', 1)
            setTimeout(() => {
                history.push('/leave');
            }, 1000);
        }).catch((err) => {
            setSaving(false)
            message.destroy()
            message.error(err.message, 2)
        })
    }
    return (
        <Card className="content-container">
            <PageTitle title="Leave Submission" breadcrumbs={[['Leave','/leave'],['Create']]}></PageTitle>
            {
                (leave) ?
                    <RequestForm onSubmit={(v) => submitLeaveCancellation(v)} saving={saving} leaveData={leave}/>
                : <Spinner/>
            }
        </Card>
    );
}

export default Create;
