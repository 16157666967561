import React, {useState, useEffect} from 'react';
import {Card, Button,  Input, Select, message, Popconfirm} from 'antd';
import {useParams} from 'react-router-dom'
import { CloseCircleOutlined, PlusCircleOutlined,CheckCircleOutlined, SaveOutlined,UndoOutlined } from '@ant-design/icons';
import { PageTitle, Spinner} from '../../../components'
import {toIdr, extractNumber} from '../../../utils/currencyHandler'
// import TaxCalculation from '../TaxCalculation'
import PaymentHeader from './PaymentHeader'
import { SentralModel } from '../../../models/SentralModel';
import ConfirmPayment from './ConfirmPayment'
import PaidDetail from './PaidDetail'
const {Option} = Select


function useForceUpdate(){
    // eslint-disable-next-line
    const [value, setValue] = useState(0);
    return () => setValue(value => ++value);
}

const Detail = () => {
    const {employeeCode, periodId} = useParams()
    const forceUpdate = useForceUpdate()
    const [confirmModal, setConfirmModal] = useState(false)
    const [confirmPaymentData, setConfirmPaymentData] = useState(null)
    const [allowances, setAllowances] = useState([])
    const [deductions, setDeductions] = useState([])
    const [lates, setLates] = useState([])
    const [overtimes, setOvertimes] = useState([])
    const [employee, setEmployee] = useState(null)
    const [components, setComponents] = useState([])
    const [saving, setSaving] = useState(false)
    const [schemaId, setSchemaId] = useState(null)
    const [loaded, setLoaded] = useState(false)
    const [absentPenalty, setAbsentPenalty] = useState(0)
    const [totalAlpha, setTotalAlpha] = useState(0)
    const [dailyIncentive, setDailyIncentive] = useState(0)
    const [weeklyIncentive, setWeeklyIncentive] = useState(0)
    const [totalWorkingDays, setTotalWorkingDays] = useState(0)
    const [totalWorkingWeeks, setTotalWorkingWeeks] = useState(0)
    const [leave, setLeave] = useState({paid:0, unpaid:0})
    const [paymentId, setPaymentId] = useState(null)

    const fetchComponents = () => {
        SentralModel.list('PayrollComponent').then((res)=>{
            setComponents(res.data)
        })
    }

    const fetchPaymentHeader = (employeeCode, periodId) => {
        setLoaded(false)
        SentralModel.action('Payroll','getEmployeePaymentHeader',{employee_code:employeeCode, period_id:periodId}, 0).then((res)=>{
            setEmployee(res.data)
            setLoaded(true)
        })
    }
    const fetchSchema = (employeeCode, periodId) =>{
        setLoaded(false)
        SentralModel.action('Payroll', 'getSchemaPayment', {employee_code: employeeCode, period_id: periodId},0).then((res)=>{
            setSchemaId(res.data.schema.employee_payroll_schema_id)
            setLates(res.data.late)
            setOvertimes(res.data.overtime)
            setAllowances(res.data.schema.allowances)
            setDeductions(res.data.schema.deductions)
            setLoaded(true)
            setAbsentPenalty(res.data.absent_penalty)
            setTotalAlpha(res.data.total_alpha)
            setDailyIncentive(res.data.daily_incentive * res.data.total_working_days)
            setTotalWorkingDays(res.data.total_working_days)
            setWeeklyIncentive(res.data.weekly_incentive * res.data.total_working_weeks)
            setTotalWorkingWeeks(res.data.total_working_weeks)
            setLeave({paid:res.data.paid_leave, unpaid: res.data.unpaid_leave})
            setPaymentId(res.data.payment_id)
            
        })
    }

    useEffect(() => {
        fetchComponents()
        fetchSchema(employeeCode, periodId)
        fetchPaymentHeader(employeeCode, periodId)
    }, [employeeCode, periodId]);

    const addAllowances = () => {
        let x = allowances;
        x.push({
            component_code:null,
            description:null,
            amount:null,
            type:null
        })
        setAllowances(x)
        forceUpdate()
    }

    const addDeductions = () => {
        let x = deductions;
        x.push({
            component_code:null,
            description:null,
            amount:null,
            type:null
        })
        setDeductions(x)
        forceUpdate()
    }

    const generatePayment =() =>{
        setSaving(true)
        let l=[];
        lates.forEach(el =>{
            if(el.total > 0 && el.amount > 0){
                l.push({total: el.total,amount:el.amount,description:el.description})
            }
        })
        let ov=[];
        overtimes.forEach(el =>{
            if(el.total > 0 && el.amount >0){
                ov.push({total: el.total,amount:el.amount,description:el.description, category:el.category})
            }
        })
        const data = {
            employee_code: employeeCode,
            period_id: periodId,
            schema_id: schemaId,
            lates:l,
            overtimes:ov,
            allowances: allowances,
            deductions: deductions,
            total_alpha: totalAlpha,
            absent_penalty: absentPenalty,
            paid_leave:leave.paid,
            unpaid_leave:leave.unpaid,
            total_working_weeks:totalWorkingWeeks,
            weekly_incentive:weeklyIncentive,
            total_working_days:totalWorkingDays,
            daily_incentive:dailyIncentive
        }
        SentralModel.action('Payroll', 'generatePayment', data, 0).then(() =>{
            message.success("Payment generated")
            setSaving(false)
            fetchSchema(employeeCode,periodId)
            fetchPaymentHeader(employeeCode, periodId)
        })
    }

    const openConfirmModal = () =>{
        setConfirmModal(true)
        SentralModel.action('Payroll','finalDetail',{employee_code:employeeCode, period_id:periodId},0).then((res)=>{
            setConfirmPaymentData(res.data)
        })
    }

    const closeConfirmModal = () => {
        setConfirmModal(false)
        setConfirmPaymentData(null)
    }

    const resetPayment = () => {
        SentralModel.action('Payroll', 'resetPayment', {employee_code: employeeCode, period_id:periodId}, 0).then(() =>{
            message.success("Payment Reset")
            setSaving(false)
            fetchSchema(employeeCode,periodId)
            fetchPaymentHeader(employeeCode, periodId)
        })
    }

    
    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Payment" breadcrumbs={[['Payroll Payment','/payroll/payment'],['Edit']]}></PageTitle>
                {
                    (loaded && employee) ?
                        <div>
                            <PaymentHeader employee={employee}/>
                            {
                                (employee.payment !== 'PAID') ?
                                    <div>
                                        <div className="full-width mt-2 mb-2 text-right">
                                            {
                                                (employee.payment==='GENERATED') ?
                                                <Popconfirm title="Reset generated payment...?" okText="Yes" cancelText="No" onConfirm={() => resetPayment()}>
                                                    <Button size="middle" type="primary" icon={<UndoOutlined />}>Reset</Button> &nbsp;
                                                </Popconfirm>
                                                : null
                                            }
                                            <Button disabled={(employee.payment!=='GENERATED')} size="middle" onClick={() => openConfirmModal()} type="danger" icon={<CheckCircleOutlined />}>Confirm Payment</Button>
                                        </div>
                                
                                        <table className="table-default table-collapse" style={{width:'100%'}}>
                                            <thead>
                                                <tr>
                                                    <th className="text-center" style={{width:'50%'}}>Allowances</th>
                                                    <th className="text-center" >Deductions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{verticalAlign:"top"}}>
                                                        <table className="table-noborder" style={{width:'100%'}}>
                                                            <thead>
                                                                    <tr>
                                                                        <th style={{width:'60%'}}>Component</th>
                                                                        <th>Value</th>
                                                                        <th style={{width:'5%'}}>
                                                                            {
                                                                                (!employee.payment) ?
                                                                                <Button type="primary" onClick={addAllowances} icon={<PlusCircleOutlined />}/>
                                                                                : null
                                                                            }
                                                                        </th>
                                                                    </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    allowances.map((alw, key)=>(
                                                                        <tr key={'allowance_item_'+key}>
                                                                            <td>
                                                                                <Select value={alw.component_code} style={{width:'100%'}} placeholder="select component" onChange={(v, e)=>{
                                                                                    let x=allowances
                                                                                    x[key].component_code=v
                                                                                    x[key].description=e.children
                                                                                    let f = components.find(a => a.component_code===v)
                                                                                    x[key].type=f.type
                                                                                    setAllowances(x)
                                                                                    forceUpdate()
                                                                                }}>
                                                                                    {
                                                                                        components.filter(el => el.category==="ALLOWANCE").map((el, key2)=>(
                                                                                            <Option key={key2} value={el.component_code}>{el.description}</Option>
                                                                                        ))
                                                                                    }
                                                                                </Select>
                                                                            </td>
                                                                            <td>
                                                                                <Input value={toIdr(alw.amount)} onChange={(v)=>{
                                                                                    let x=allowances
                                                                                    x[key].amount=extractNumber(v.target.value)
                                                                                    setAllowances(x)
                                                                                    forceUpdate()
                                                                                }}/>
                                                                            </td>
                                                                            <td className="text-center">
                                                                                {
                                                                                    (!employee.payment) ?
                                                                                        <Button type="danger" onClick={()=>{
                                                                                            let x=allowances
                                                                                            x.splice(key, 1);
                                                                                            setAllowances(x)
                                                                                            forceUpdate()
                                                                                        }} icon={<CloseCircleOutlined />}/>
                                                                                    : null
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                                {
                                                                    overtimes.map((ovt, key)=>(
                                                                        (ovt.total>0  && ovt.amount > 0) ?
                                                                            <tr key={'late_item_'+key}>
                                                                                <td>
                                                                                    <Input readOnly value={'Overtime : '+ovt.description+'('+ovt.total+')'}/>
                                                                                </td>
                                                                                <td>
                                                                                    <Input value={toIdr(ovt.amount)} readOnly/>
                                                                                </td>
                                                                                <td className="text-center"></td>
                                                                            </tr>
                                                                        : null
                                                                    ))
                                                                }
                                                                {
                                                                    (dailyIncentive > 0) ?
                                                                        <tr>
                                                                            <td>
                                                                                <Input readOnly value={'Daily Incentive('+totalWorkingDays+')'}/>
                                                                            </td>
                                                                            <td>
                                                                                <Input value={toIdr(dailyIncentive)} readOnly/>
                                                                            </td>
                                                                            <td className="text-center"></td>
                                                                        </tr>
                                                                    : null
                                                                }
                                                                {
                                                                    (weeklyIncentive > 0) ?
                                                                        <tr>
                                                                            <td>
                                                                                <Input readOnly value={'Weekly Incentive('+totalWorkingWeeks+')'}/>
                                                                            </td>
                                                                            <td>
                                                                                <Input value={toIdr(weeklyIncentive)} readOnly/>
                                                                            </td>
                                                                            <td className="text-center"></td>
                                                                        </tr>
                                                                    : null
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                    <td style={{verticalAlign:"top"}}>
                                                        <table className="table-noborder" style={{width:'100%'}}>
                                                            <thead>
                                                                <tr>
                                                                    <th style={{width:'60%'}}>Component</th>
                                                                    <th>Value</th>
                                                                    <th style={{width:'5%'}}>
                                                                        {
                                                                            (!employee.payment) ?
                                                                                <Button type="primary"  onClick={addDeductions} icon={<PlusCircleOutlined />}/>
                                                                            : null
                                                                        }
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    deductions.map((ded, key)=>(
                                                                        <tr key={'deduction_item_'+key}>
                                                                            <td>
                                                                                <Select value={ded.component_code} style={{width:'100%'}} placeholder="select component" onChange={(v, e)=>{
                                                                                    let x=deductions
                                                                                    x[key].component_code=v
                                                                                    x[key].description=e.children
                                                                                    setDeductions(x)
                                                                                    forceUpdate()
                                                                                }}>
                                                                                    {
                                                                                        components.filter(el => el.category==="DEDUCTION").map((el, key2)=>(
                                                                                            <Option key={key2} value={el.component_code}>{el.description}</Option>
                                                                                        ))
                                                                                    }
                                                                                </Select>
                                                                            </td>
                                                                            <td>
                                                                                <Input value={toIdr(ded.amount)} onChange={(v)=>{
                                                                                    let x=deductions
                                                                                    x[key].amount=extractNumber(v.target.value)
                                                                                    setDeductions(x)
                                                                                    forceUpdate()
                                                                                }}/>
                                                                            </td>
                                                                            <td className="text-center">
                                                                                {
                                                                                    (!employee.payment) ?
                                                                                        <Button type="danger" onClick={()=>{
                                                                                            let x=deductions
                                                                                            x.splice(key, 1);
                                                                                            setDeductions(x)
                                                                                            forceUpdate()
                                                                                        }} icon={<CloseCircleOutlined />}/>
                                                                                    : null
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                                {
                                                                    lates.map((late, key)=>(
                                                                        (late.total>0 && late.amount > 0) ?
                                                                            <tr key={'late_item_'+key}>
                                                                                <td>
                                                                                    <Input readOnly value={'Late : '+late.description+' ('+late.total+')'}/>
                                                                                </td>
                                                                                <td>
                                                                                    <Input value={toIdr(late.amount * late.total)} readOnly/>
                                                                                </td>
                                                                                <td className="text-center"></td>
                                                                            </tr>
                                                                        : null
                                                                    ))
                                                                }
                                                                {
                                                                    (absentPenalty) ?
                                                                    <tr key={'absent_penalty_item_'}>
                                                                        <td>
                                                                            <Input readOnly value={'Alpha & Unpaid Leave ('+totalAlpha+')'}/>
                                                                        </td>
                                                                        <td>
                                                                            <Input value={toIdr(absentPenalty)} readOnly/>
                                                                        </td>
                                                                        <td className="text-center"></td>
                                                                    </tr>
                                                                    : null
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    
                                        <div className="full-width text-right mt-2 mb-2">
                                            <Button disabled={employee.payment==='GENERATED' || employee.payment==='PAID'} loading={saving} type="primary" onClick={generatePayment} icon={<SaveOutlined />} size="large">Generate Payment</Button>
                                        </div>
                                    </div>
                                : <PaidDetail data={{allowances:allowances, deductions:deductions}}/>
                            }
                            {/* {
                                (employee.payment) ?
                                    <TaxCalculation data={{allowances:allowances, deductions:deductions, lates:lates, overtimes:overtimes}}/>
                                : null
                            } */}
                        </div>
                    :   
                    <Spinner/>
                }
                <br/>
            </Card>
            <ConfirmPayment data={confirmPaymentData} paymentId={paymentId} employee={employee} setModal={(v) => closeConfirmModal(v)} showModal={confirmModal}/>
        </div>
        
    );
}

export default Detail;
