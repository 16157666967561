import React, { useState, useEffect } from 'react'
import { Button, Card, DatePicker, Drawer, Form, Select, Space } from 'antd'
import { DataTable, PageTitle, Spinner } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import { FilterOutlined, FileExcelOutlined } from '@ant-design/icons'
import moment from 'moment'

const { Option } = Select

const Index = () => {
    const year = moment().year();
    const [loaded, setLoaded] = useState(false)
    const [appraisals, setAppraisals] = useState([])
    const [showFilter, setShowFilter] = useState(false)
    const [divisions, setDivisions] = useState([])
    const [categories, setCategories] = useState([])
    const [filter, setFilter] = useState({})

    const fetchAppraisals = (ft) => {
        setLoaded(false)

        SentralModel.action('Report', 'appraisal', ft, 0).then(res => {
            setAppraisals(res.data)
            setLoaded(true)
        })
    }

    const fetchCategories = () => {
        setLoaded(false);
        SentralModel.list('KpiItemCategory', { orderBy: 'order', order: 'ASC' }, 0).then((res) => {
            setCategories(res.data);
            
            SentralModel.action('Report', 'appraisal', 0).then(res => {
                setAppraisals(res.data)
                setLoaded(true)
            })
        })
    }

    const fetchDivision = () => {
        SentralModel.list('Division', { orderBy: 'division_name', order: 'ASC' }, 0).then((res) => {
            setDivisions(res.data);
        })
    }

    useEffect(() => {
        fetchCategories()
        fetchDivision()
    }, []);

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            fixed: 'left',
            width: 50
        },
        {
            title: 'Employee Name',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            width: 250
        },
        {
            title: 'Division',
            dataIndex: 'division_name',
            key: 'division_name',
        },
        {
            title: 'Position',
            dataIndex: 'position_name',
            key: 'position_name',
        },
        {
            title: 'Join Date',
            dataIndex: 'join_date',
            key: 'join_date',
            align: 'center',
        },
    ];

    const moreColumn = [
        ...categories.map(cat => {
            return {
                title: cat.name,
                render: (row) => (
                    <div className="text-center">
                        {parseFloat(row.result?.find((el) => el.kpi_item_category_id === cat.kpi_item_category_id)?.avg ?? '0').toFixed(2)}
                    </div>
                )
            }
        })
    ]

    columns.push(...moreColumn);
    columns.push(
        {
            title: 'Average',
            render: (row) => (
                <div className="text-center">
                    {/* {(row.result.length !== 0 ? (row.result.reduce((total, val) => total + parseFloat(val.avg), 0) / row.result.length) : 0).toFixed(2)} */}
                    {(row.result.length !== 0 ? row.result.reduce((total, val) => total + parseFloat(val.avg), 0) : 0).toFixed(3)}
                </div>
            )
        }
    );

    const filterData = (v) => {
        let ft = {
            division_id: v.division_id ?? '',
            year: moment(v.year).format('YYYY')
        }

        fetchAppraisals(ft)
        setFilter(ft)
        setShowFilter(false)
    }

    const exportReport = () => {
        window.open(process.env.REACT_APP_API_URL + `/report/appraisal?division_id=${filter.division_id}&year=${filter.year === undefined ? year : filter.year}`)
    }

    return (
        <Card className="content-container">
            <PageTitle title="Appraisal Report" breadcrumbs={[['Appraisal Report']]}></PageTitle>
            <div className="text-right mb-2">
                <Space>
                    <Button type="primary" onClick={() => exportReport()} shape="circle" icon={<FileExcelOutlined />}></Button>
                    <Button shape="circle" icon={<FilterOutlined />} onClick={() => setShowFilter(true)}></Button>
                </Space>
            </div>

            {
                loaded ?
                    <DataTable scroll={{ x: 3000 }} pagination={false} size='small' dataSource={appraisals} columns={columns} bordered />
                    : <Spinner />
            }

            <Drawer
                title="Filter Report"
                width={400}
                placement="right"
                closable={false}
                onClose={() => setShowFilter(false)}
                visible={showFilter}
            >
                <Form layout="vertical" onFinish={filterData}>
                    <Form.Item name="division_id" label="Division">
                        <Select style={{ width: '100%' }} placeholder="Select Division" allowClear>
                            {
                                divisions.map((el, key) => (
                                    <Option key={key} value={el.division_id}>{el.division_name}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name="year" label="Year">
                        <DatePicker style={{ width: '100%' }} picker="year" />
                    </Form.Item>
                    <Form.Item >
                        <div className="text-right">
                            <Button type="primary" htmlType="submit" size="middle" icon={<FilterOutlined />}> Show</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Drawer>
        </Card>
    )
}

export default Index