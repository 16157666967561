import React, {useState, useEffect} from 'react';
import {Card, Row, Col, Typography} from 'antd'
import { PageTitle, Spinner} from '../../../components'
import ApprovalLog from '../ApprovalLog'
import DetailHeader from '../DetailHeader'
import {useParams} from 'react-router-dom';
import { SentralModel } from '../../../models/SentralModel';
import {toIdr} from '../../../utils/currencyHandler'

const {Text} = Typography

const Detail = () => {
    const {requestId} = useParams()
    const [reimbursement, setReimbursement] = useState(null)

    const fetchReimbursement = (id) => {
       SentralModel.get('SubmissionReimbursement', {}, id).then((res)=>{
           setReimbursement(res.data)
       })
    }

    useEffect(() => {
        fetchReimbursement(requestId)
    }, [requestId]);

    const statusUpdated = () =>{
        setReimbursement(null)
        fetchReimbursement(requestId)
        window.location.reload();
    }

    return (
        <Card className="content-container">
            <div>
                <PageTitle title="Reimbursement Request" breadcrumbs={[['Submission List','/submission-list'],['Detail']]}></PageTitle>
            </div>
            {
                (reimbursement) ? 
                <div>
                    <DetailHeader 
                        name={reimbursement.employee.name} 
                        date={reimbursement.created_at}
                        status={reimbursement.status}/>
                    <Card bordered className="mb-4">
                        <Text strong>REIMBURSEMENT DETAIL</Text>
                        <Row className="mb-4 mt-1">
                            <Col span={12}>
                                <table className="table-collapse table-default" style={{width:'100%'}}>
                                    <tbody>
                                        <tr>
                                            <th>Employee Name</th><td>{reimbursement.employee.name}</td>
                                        </tr>
                                        <tr>
                                            <th>Division</th><td>{reimbursement.employee.division.division_name}</td>
                                        </tr>
                                        <tr>
                                            <th>Position</th><td>{reimbursement.employee.position.position_name}</td>
                                        </tr>
                                        <tr>
                                            <th>Description</th><td>{reimbursement.title}</td>
                                        </tr>
                                        <tr>
                                            <th>Total Amount</th><td>{toIdr(reimbursement.total_amount)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Card>
                    {
                        reimbursement.employee.division_id === 9 ?
                        <Card bordered className="mb-4">
                            <Text strong>REIMBURSEMENT TICKET</Text>
                            <Row className="mb-4 mt-1">
                                <Col span={24}>
                                    <table className="table-collapse table-default" style={{width:'100%'}}>
                                        <thead>
                                            <tr>
                                                <th>Ticket Number</th>
                                                <th>Company Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                reimbursement.tickets.map((el, key) => (
                                                    <tr key={key}>
                                                    <td>{el.ticket_no}</td>
                                                    <td>{el.company_name}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </Card>
                        : null
                    }
                    <Card bordered className="mb-4">
                        <Text strong>REIMBURSEMENT ITEM</Text>
                        <Row className="mb-4 mt-1">
                            <Col span={24}>
                                <table className="table-collapse table-default" style={{width:'100%'}}>
                                    <thead>
                                        <tr>
                                            <th>Item Name</th>
                                            <th>Amount</th>
                                            <th>Attachment</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            reimbursement.items.map((el, key) => (
                                                <tr key={key}>
                                                   <td>{el.description}</td>
                                                   <td>{toIdr(el.amount)}</td>
                                                   <td>
                                                        {
                                                            (el.attachment) ?
                                                            <a rel="noopener noreferrer" target='_blank' href={process.env.REACT_APP_API_FILE+'/'+el.attachment}>
                                                                show attachment
                                                            </a> : '-'
                                                        }
                                                   </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th id="total">Total</th>
                                            <td>{toIdr(reimbursement.total_amount)}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </Col>
                        </Row>
                    </Card>
                    <Card bordered>
                        <ApprovalLog category="REIMBURSEMENT" 
                            requestId={reimbursement.sub_reimbursement_id} 
                            approvers={reimbursement.approver} 
                            onSubmitted={() => statusUpdated()}/>
                    </Card>
                </div> : <Spinner/>
            }
            
        </Card>
    );
}

export default Detail;
