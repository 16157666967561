import { configureStore } from "@reduxjs/toolkit";
import dashboardReducer from "./slices/Dashboard/dashboardSlice";
import dailyActivityReducer from "./slices/Dashboard/dailyActivitySlice";
import myDailyActivityReducer from "./slices/Dashboard/myDailyActivitySlice";
import myTaskReducer from "./slices/Dashboard/myTaskSlice";
import karajoTicketReducer from "./slices/Dashboard/karajoTicketSlice";
import workingLocationReducer from "./slices/Dashboard/workingLocationSlice";

const store = configureStore({
    reducer: {
        dashboard: dashboardReducer,
        dailyActivity: dailyActivityReducer,
        myDailyActivity: myDailyActivityReducer,
        myTask: myTaskReducer,
        karajoTicket: karajoTicketReducer,
        workingLocation: workingLocationReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})

export default store;