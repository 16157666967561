import React, { useEffect, useState } from 'react';
import { Card, Typography, Descriptions, Button, Popconfirm, message, Row, Col } from 'antd'
import { SaveOutlined } from '@ant-design/icons'
// import {useParams} from 'react-router-dom'
import { PageTitle, PrintPdfButton, Spinner } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import { CustomModel } from '../../../models/CustomModel'
import { toFullDate } from '../../../utils/dateHandler'
import { getQuery } from '../../../helpers/url'
import { toIdr } from '../../../utils/currencyHandler'

const { Text } = Typography
const AppraisalForm = () => {
    // const {appraisalId} = useParams()
    const [appraisal, setAppraisal] = useState(null)
    const [loaded, setLoaded] = useState(false)
    const [saving, setSaving] = useState(false)
    const [salesDivision] = useState([21, 22, 28, 29, 30, 36])
    const [dataAppraisal, setDataAppraisal] = useState({})
    const [evaluator, setEvaluator] = useState(0)

    const fetchAppraisal = (appraisalId, salesDivision) => {
        setLoaded(false)
        SentralModel.action('PraAppraisal', 'getAppraisalDetail', { pra_appraisal_id: appraisalId }).then(res => {
            setAppraisal(res.data)
            if (salesDivision.some(el => el === res.data.employee.division_id)) {
                if (res.data.employee.slp_code) {
                    CustomModel.boost('APISAP', 'data_appraisal/' + res.data.employee.employee_code, {}).then(res => {
                        setDataAppraisal(res.data)
                    })
                }
            }

            var evaluator = 0;
            res.data.evaluators.forEach(ev => {
                if (ev.kpi_value) {
                    evaluator += 1;
                }
            });
            setEvaluator(evaluator)
        }).finally(() => {
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchAppraisal(getQuery('appraisalId'), salesDivision)
    }, [salesDivision]);

    const submitAppraisal = () => {
        let payload = {
            kpi_value: (appraisal.total_kpi) ? appraisal.total_kpi / appraisal.evaluators.length : 0,
            pra_appraisal_id: getQuery('appraisalId')
        }

        setSaving(true);
        SentralModel.action('PraAppraisal', 'submitAppraisal', payload).then((res) => {
            message.success('Penilaian telah disimpan', 2);
            fetchAppraisal(getQuery('appraisalId'))
            setSaving(false)
        }).catch(() => {
            setSaving(false)
        })
    }

    const printData = () => {
        window.open(process.env.REACT_APP_API_URL + '/print/appraisal/' + getQuery('appraisalId'))
    }

    return (
        <div >
            <Card className="content-container">
                <PageTitle title="Pra Appraisals" breadcrumbs={[['Pra Appraisal', '/management/pra-appraisal'], ['Form Penilaian']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{ float: 'left' }}>Pra Appraisal Form</Text>
                    </div>}>

                    {
                        (loaded) ?
                            <div>
                                <Row className="mb-3">
                                    <Col span={24} >
                                        <PrintPdfButton right onPrint={printData} />
                                    </Col>
                                </Row>
                                <Row justify='space-between'>
                                    <Descriptions className="mb-3" layout="horizontal" column={1} bordered size="small" style={{ width: "500px" }}>
                                        <Descriptions.Item label="Name">{appraisal.employee.name}</Descriptions.Item>
                                        <Descriptions.Item label="Division">{appraisal.employee.division.division_name}</Descriptions.Item>
                                        <Descriptions.Item label="Position">{appraisal.employee.position.position_name || ''}</Descriptions.Item>
                                        <Descriptions.Item label="Join Date">{toFullDate(appraisal.employee.joinDate)}</Descriptions.Item>
                                    </Descriptions>
                                </Row>

                                <table className="table-collapse table-default" style={{ width: '100%' }}>
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th className="text-left">Component</th>
                                            {
                                                appraisal.evaluators.map((ev, inEv) => (
                                                    <th className="text-center" key={inEv}>
                                                        {ev.employee.name}
                                                    </th>
                                                ))
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            appraisal.categories.map((el, i) => (
                                                (el.items.length > 0) ?
                                                    <React.Fragment key={'category_' + i}>
                                                        <tr >
                                                            <th className="text-left" colSpan={2 + appraisal.evaluators.length}>{el.name}</th>
                                                        </tr>
                                                        {
                                                            el.items.map((item, j) => (
                                                                <>
                                                                    {/* {el.kpi_item_category_id === 7 && j === 0 && dataAppraisal.yearly_target ?
                                                                        <tr key={'a' + j} style={{ backgroundColor: '#f1c40f' }}>
                                                                            <td colSpan={2 + appraisal.evaluators.length}>Target : <strong>{toIdr(dataAppraisal.yearly_target)}</strong> | Achievement (SMI, SKI, SST) : <strong>{toIdr(dataAppraisal.yearly_achievement)} ({parseFloat((dataAppraisal.yearly_achievement / dataAppraisal.yearly_target) * 100).toFixed(2)})%</strong></td>
                                                                        </tr>
                                                                        : null} */}

                                                                    {
                                                                        el.kpi_item_category_id === 3 && (item.kpi_item_id === 567) && dataAppraisal.cancel_so ?
                                                                            <tr key={'c' + j} style={{ backgroundColor: '#f1c40f' }}>
                                                                                <td colSpan={7}>Total SO Cancel : <strong>{dataAppraisal.cancel_so}</strong></td>
                                                                            </tr> : null
                                                                    }

                                                                    {el.kpi_item_category_id === 2 && j === 0 ?
                                                                        <tr key={'b' + j} style={{ backgroundColor: '#f1c40f' }}>
                                                                            <td colSpan={2 + appraisal.evaluators.length}>Total Leave : <strong>{appraisal.statistic.total_leave}</strong> | Total Sick : <strong>{appraisal.statistic.total_sick}</strong> | Total Alpha : <strong>{appraisal.statistic.total_absence}</strong></td>
                                                                        </tr>
                                                                        : null}

                                                                    {el.kpi_item_category_id === 10 && j === 0 ?
                                                                        <tr key={'c' + j} style={{ backgroundColor: '#f1c40f' }}>
                                                                            <td colSpan={2 + appraisal.evaluators.length}>Total Training : <strong>{appraisal.statistic.total_training}</strong></td>
                                                                        </tr>
                                                                        : null}

                                                                    {(el.kpi_item_category_id === 3 || el.kpi_item_category_id === 7) && (item.kpi_item_id === 229 || item.kpi_item_id === 256 || item.kpi_item_id === 528 || item.kpi_item_id === 556 || item.kpi_item_id === 558) && dataAppraisal.yearly_target ?
                                                                        <tr key={'d' + j} style={{ backgroundColor: '#f1c40f' }}>
                                                                            <td colSpan={2 + appraisal.evaluators.length}>Target Perbulan : <strong>{toIdr(dataAppraisal.monthly_target)}</strong> Achievement Perbulan (SMI, SKI, SST) : <strong>{toIdr(dataAppraisal.monthly_achievement ?? 0)}</strong>   Persentase : <strong>({parseFloat((dataAppraisal.monthly_achievement / dataAppraisal.monthly_target) * 100).toFixed(2)})%</strong></td>
                                                                        </tr>
                                                                        : null}

                                                                    {el.kpi_item_category_id === 3 && (item.kpi_item_id === 230 || item.kpi_item_id === 257 || item.kpi_item_id === 529 || item.kpi_item_id === 555) && dataAppraisal.yearly_target ?
                                                                        <tr key={'e' + j} style={{ backgroundColor: '#f1c40f' }}>
                                                                            <td colSpan={2 + appraisal.evaluators.length}>Penjualan Tahun Sebelumnya (SMI, SKI, SST) : <strong>{toIdr(dataAppraisal.previous_year)}</strong> Penjualan Tahun Ini (SMI, SKI, SST) : <strong>{toIdr(dataAppraisal.yearly_achievement)}</strong> Growth : <strong>({parseFloat(((dataAppraisal.yearly_achievement - dataAppraisal.previous_year) / dataAppraisal.yearly_achievement) * 100).toFixed(2)})%</strong></td>
                                                                        </tr>
                                                                        : null}

                                                                    {(el.kpi_item_category_id === 3 || el.kpi_item_category_id === 7) && (item.kpi_item_id === 232 || item.kpi_item_id === 259 || item.kpi_item_id === 553 || item.kpi_item_id === 564) && dataAppraisal.yearly_target ?
                                                                        <tr key={'f' + j} style={{ backgroundColor: '#f1c40f' }}>
                                                                            <td colSpan={2 + appraisal.evaluators.length}>Penambahan Account Persemester (SMI, SKI, SST) : <strong>{dataAppraisal.monthly_account}</strong></td>
                                                                        </tr>
                                                                        : null}

                                                                    {(el.kpi_item_category_id === 3 || el.kpi_item_category_id === 7) && (item.kpi_item_id === 233 || item.kpi_item_id === 260 || item.kpi_item_id === 552 || item.kpi_item_id === 561) && dataAppraisal.yearly_target ?
                                                                        <tr key={'g' + j} style={{ backgroundColor: '#f1c40f' }}>
                                                                            <td colSpan={2 + appraisal.evaluators.length}>Total Quotation : <strong>{toIdr(dataAppraisal.quotation)}</strong>, Total Invoicing: <strong>{toIdr(dataAppraisal.invoicing)}</strong>, Persentase Quotation menjadi Invoice (SMI, SKI, SST) : <strong>{dataAppraisal.percentage_invoice}%</strong></td>
                                                                        </tr>
                                                                        : null}

                                                                    {el.kpi_item_category_id === 3 && (item.kpi_item_id === 239 || item.kpi_item_id === 266 || item.kpi_item_id === 546) && dataAppraisal.yearly_target ?
                                                                        <tr key={'h' + j} style={{ backgroundColor: '#f1c40f' }}>
                                                                            <td colSpan={2 + appraisal.evaluators.length}>Total Open DO (SMI, SKI, SST) : <strong>{dataAppraisal.open_do}</strong></td>
                                                                        </tr>
                                                                        : null}

                                                                    <tr key={'value_' + j}>
                                                                        <td className="text-center" style={{ verticalAlign: 'top' }}>{j + 1}</td>
                                                                        <td>{item.description} ({item.rate}%)</td>
                                                                        {
                                                                            appraisal.evaluators.map((ev, inEv) => (
                                                                                <td className="text-center" style={{ verticalAlign: 'top' }} key={inEv}>
                                                                                    {
                                                                                        (item.values.filter((v) => v.evaluator_code === ev.evaluator_code)[0]) ? item.values.filter((v) => v.evaluator_code === ev.evaluator_code)[0].value : '-'
                                                                                    }
                                                                                </td>
                                                                            ))
                                                                        }
                                                                    </tr>
                                                                </>
                                                            ))
                                                        }
                                                    </React.Fragment>
                                                    : null
                                            ))
                                        }
                                        <tr>
                                            <th colSpan={2} className="text-right">KPI</th>
                                            {
                                                appraisal.evaluators.map((ev, inEv) => (
                                                    <th className="text-center" key={inEv}>
                                                        {ev.kpi_value || '-'}
                                                    </th>
                                                ))
                                            }
                                        </tr>
                                        <tr>
                                            <th colSpan={2} className="text-right">Rata - Rata</th>
                                            <th colSpan={appraisal.evaluators.length}>
                                                {
                                                    (appraisal.total_kpi) ? (appraisal.total_kpi / evaluator).toFixed(3) : 0
                                                }
                                            </th>
                                        </tr>
                                    </tbody>

                                </table>
                                {
                                    (appraisal.is_complete && !appraisal.kpi_value) ?
                                        <div className="full-width text-right mt-3">
                                            <Popconfirm
                                                title={<span>Submit Penilaian..?<br /> Data yang sudah disubmit tidak bisa dirubah.</span>}
                                                onConfirm={submitAppraisal}
                                                okText="Ya"
                                                cancelText="Tidak"
                                            >
                                                <Button type="primary" loading={saving} icon={<SaveOutlined />} >Submit Penilaian</Button>
                                            </Popconfirm>
                                        </div>
                                        : null
                                }
                            </div>
                            :
                            <Spinner />
                    }
                </Card>
            </Card>
        </div>
    );
}

export default AppraisalForm;
