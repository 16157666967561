// import React, { useEffect, useState } from 'react';
// import { Card, Typography, Space, Button, Select, Empty, Tabs, Row } from 'antd'
// import { Link } from 'react-router-dom'
// import { PageTitle, Spinner } from '../../../components'
// import { SentralModel } from '../../../models/SentralModel'
// import { SettingOutlined, CalendarOutlined, BarChartOutlined } from '@ant-design/icons'
// import authData from '../../../helpers/authData'
// import { decrypt } from '../../../helpers/crypto'
// import { setSessionStorage, getSessionStorage } from '../../../helpers/sessionStorage';

// const { Text } = Typography
// const { Option } = Select
// const { TabPane } = Tabs;

const KPI = () => {
//     const [appraisals, setAppraisals] = useState([])
//     const [evaluators, setEvaluators] = useState([])
//     const [loaded, setLoaded] = useState(false)
//     const [loadedEvaluator, setLoadedEvaluator] = useState(false)
//     const [activeYear, setActiveYear] = useState('')
//     const [selectedDivision, setSelectedDivision] = useState(null)
//     const [divisions, setDivisions] = useState([])
//     const [roles, setRoles] = useState([])

//     const initialDivision = () => {
//         if (getSessionStorage('appraisalDivision')) {
//             return parseInt(getSessionStorage('appraisalDivision'))
//         }
//         return null
//     }

//     const fetchAppraisals = (divisionId = 0) => {
//         const role = localStorage.getItem('role') ? JSON.parse(decrypt(localStorage.getItem('role'))) : []
//         setLoaded(false)
//         SentralModel.action('Appraisal', 'getAppraisal', { division_id: divisionId, role: (role.includes('HR') || role.includes('SUPERADMIN')) ? 'HR' : 'USER' }).then(res => {
//             setAppraisals(res.data.employees)
//             setActiveYear(res.data.year)
//             setRoles(role)
//         }).finally(() => {
//             setLoaded(true)
//         })
//     }

//     const fetchEvaluators = () => {
//         setLoadedEvaluator(false)
//         SentralModel.action('Appraisal', 'checkEvaluator', {}).then(res => {
//             setEvaluators(res.data)
//         }).finally(() => {
//             setLoadedEvaluator(true)
//         })
//     }

//     const fetchDivisions = () => {
//         SentralModel.list('Division', { fields: "division_id,division_name", orderBy: "division_name", order: "ASC" }).then((res) => {
//             setDivisions(res.data)
//         })
//     }

//     useEffect(() => {
//         let divisionId = sessionStorage.getItem('appraisalDivision') || 0
//         if (divisionId) {
//             setSelectedDivision(parseInt(divisionId))
//         } else {
//             setSelectedDivision(0)
//         }
//         fetchDivisions()

//         fetchAppraisals(divisionId)
//         fetchEvaluators()
//         // eslint-disable-next-line
//     }, []);

//     const changeDivision = (v) => {
//         setLoaded(false)
//         fetchAppraisals(v)
//         setSessionStorage('appraisalDivision', v)
//         setSelectedDivision(v)
//     }

//     return (
//         <div>
//             <Card className="content-container">
//                 <PageTitle title={'Appraisal ' + activeYear} breadcrumbs={[['Appraisal']]}></PageTitle>
//                 <Card title={
//                     <div className="full-width">
//                         <Text strong style={{ float: 'left' }}>Appraisal List</Text>
//                         {
//                             roles.includes('HR') || roles.includes("SUPERADMIN") || roles.includes('HEAD') ?
//                                 <Space style={{ float: 'right' }}>
//                                     {/* {
//                                         roles.includes('HR') || roles.includes("SUPERADMIN") ?
//                                             <Link to="/management/appraisal/set-period">
//                                                 <Button type="primary" icon={<CalendarOutlined />}>
//                                                     Setup Periode
//                                                 </Button>
//                                             </Link>
//                                             : null
//                                     } */}
//                                 </Space>
//                                 : null
//                         }
//                     </div>}>

//                     <Row>
//                         <div className="full-width">
//                             <span className="mb-3" style={{ float: 'right' }}>
//                                 <Space>
//                                     <Select placeholder="Select Division" defaultValue={initialDivision} style={{ width: 250 }} onChange={changeDivision} loading={divisions.length === 0}>
//                                         <Option key="division_all" value={0}>Semua Divisi</Option>
//                                         {
//                                             divisions.map((el, key) => (
//                                                 <Option key={'division_' + key} value={el.division_id}>{el.division_name}</Option>
//                                             ))
//                                         }
//                                     </Select>
//                                 </Space>
//                             </span>
//                         </div>
//                     </Row>

//                     {
//                         (loaded) ?
//                             <div>
//                                 <Tabs defaultActiveKey="appraisal" type="card">
//                                     <TabPane tab={<span>Permanent</span>} key="permanent">
//                                         <table className="table-collapse table-default" style={{ width: '100%' }}>
//                                             <thead>
//                                                 <tr>
//                                                     <th>No</th>
//                                                     <th>Employee Name</th>
//                                                     <th>Division Name</th>
//                                                     {
//                                                         (roles.includes('HR') || roles.includes('SUPERADMIN')) ?
//                                                             <th>Evaluator</th>
//                                                             : null
//                                                     }
//                                                     <th className="text-center">KPI</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody>
//                                                 {
//                                                     appraisals.filter(el => el.employee_status === 'ESPR').map((el, i) => (
//                                                         <tr key={i}>
//                                                             <td style={{ verticalAlign: 'top', textAlign: 'center' }}>{i + 1}</td>
//                                                             <td style={{ verticalAlign: 'top' }}>{el.name}</td>
//                                                             <td style={{ verticalAlign: 'top' }}>{(el.division) ? el.division.division_name : ''}</td>
//                                                             {
//                                                                 (roles.includes('HR') || roles.includes('SUPERADMIN')) ?
//                                                                     <td style={{ padding: '0px' }}>
//                                                                         {
//                                                                             (el.appraisal) ?
//                                                                                 <table className="table-noborder" style={{ width: '100%', margin: '0px' }}>
//                                                                                     <tbody>
//                                                                                         {
//                                                                                             el.appraisal.evaluators.map((ev, x) => (
//                                                                                                 <tr key={x}>
//                                                                                                     <td>{ev.employee.name}</td>
//                                                                                                     <td className="text-center" style={{ width: '70px' }}>{ev.kpi_value}</td>
//                                                                                                 </tr>
//                                                                                             ))
//                                                                                         }
//                                                                                     </tbody>
//                                                                                 </table>
//                                                                                 : null
//                                                                         }
//                                                                     </td>
//                                                                     : null
//                                                             }
//                                                             <td className="text-center" style={{ verticalAlign: 'top' }}>
//                                                                 {
//                                                                     (el.appraisal) ?
//                                                                         (roles.includes('HR') || roles.includes('SUPERADMIN')) ?
//                                                                             el.appraisal.kpi_value : el.appraisal.evaluators[0].kpi_value
//                                                                         : ''
//                                                                 }
//                                                             </td>
//                                                         </tr>
//                                                     ))
//                                                 }
//                                                 {
//                                                     (appraisals.length === 0) ?
//                                                         <tr><td colSpan={5}><Empty /></td></tr> : null
//                                                 }
//                                             </tbody>

//                                         </table>
//                                     </TabPane>

//                                     <TabPane tab={<span>Probation</span>} key="probation">
//                                         <table className="table-collapse table-default" style={{ width: '100%' }}>
//                                             <thead>
//                                                 <tr>
//                                                     <th>No</th>
//                                                     <th>Employee Name</th>
//                                                     <th>Division Name</th>
//                                                     {
//                                                         (roles.includes('HR') || roles.includes('SUPERADMIN')) ?
//                                                             <th>Evaluator</th>
//                                                             : null
//                                                     }
//                                                     <th className="text-center">KPI</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody>
//                                                 {
//                                                     appraisals.filter(el => el.employee_status === 'ESPB').map((el, i) => (
//                                                         <tr key={i}>
//                                                             <td style={{ verticalAlign: 'top', textAlign: 'center' }}>{i + 1}</td>
//                                                             <td style={{ verticalAlign: 'top' }}>{el.name}</td>
//                                                             <td style={{ verticalAlign: 'top' }}>{(el.division) ? el.division.division_name : ''}</td>
//                                                             {
//                                                                 (roles.includes('HR') || roles.includes('SUPERADMIN')) ?
//                                                                     <td style={{ padding: '0px' }}>
//                                                                         {
//                                                                             (el.appraisal) ?
//                                                                                 <table className="table-noborder" style={{ width: '100%', margin: '0px' }}>
//                                                                                     <tbody>
//                                                                                         {
//                                                                                             el.appraisal.evaluators.map((ev, x) => (
//                                                                                                 <tr key={x}>
//                                                                                                     <td>{ev.employee.name}</td>
//                                                                                                     <td className="text-center" style={{ width: '70px' }}>{ev.kpi_value}</td>
//                                                                                                 </tr>
//                                                                                             ))
//                                                                                         }
//                                                                                     </tbody>
//                                                                                 </table>
//                                                                                 : null
//                                                                         }
//                                                                     </td>
//                                                                     : null
//                                                             }
//                                                             <td className="text-center" style={{ verticalAlign: 'top' }}>
//                                                                 {
//                                                                     (el.appraisal) ?
//                                                                         (roles.includes('HR') || roles.includes('SUPERADMIN')) ?
//                                                                             el.appraisal.kpi_value : el.appraisal.evaluators[0].kpi_value
//                                                                         : ''
//                                                                 }
//                                                             </td>
//                                                         </tr>
//                                                     ))
//                                                 }
//                                                 {
//                                                     (appraisals.length === 0) ?
//                                                         <tr><td colSpan={5}><Empty /></td></tr> : null
//                                                 }
//                                             </tbody>

//                                         </table>
//                                     </TabPane>

//                                     <TabPane tab={<span>Contract</span>} key="contract">
//                                         <table className="table-collapse table-default" style={{ width: '100%' }}>
//                                             <thead>
//                                                 <tr>
//                                                     <th>No</th>
//                                                     <th>Employee Name</th>
//                                                     <th>Division Name</th>
//                                                     {
//                                                         (roles.includes('HR') || roles.includes('SUPERADMIN')) ?
//                                                             <th>Evaluator</th>
//                                                             : null
//                                                     }
//                                                     <th className="text-center">KPI</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody>
//                                                 {
//                                                     appraisals.filter(el => el.employee_status === 'ESKN').map((el, i) => (
//                                                         <tr key={i}>
//                                                             <td style={{ verticalAlign: 'top', textAlign: 'center' }}>{i + 1}</td>
//                                                             <td style={{ verticalAlign: 'top' }}>{el.name}</td>
//                                                             <td style={{ verticalAlign: 'top' }}>{(el.division) ? el.division.division_name : ''}</td>
//                                                             {
//                                                                 (roles.includes('HR') || roles.includes('SUPERADMIN')) ?
//                                                                     <td style={{ padding: '0px' }}>
//                                                                         {
//                                                                             (el.appraisal) ?
//                                                                                 <table className="table-noborder" style={{ width: '100%', margin: '0px' }}>
//                                                                                     <tbody>
//                                                                                         {
//                                                                                             el.appraisal.evaluators.map((ev, x) => (
//                                                                                                 <tr key={x}>
//                                                                                                     <td>{ev.employee.name}</td>
//                                                                                                     <td className="text-center" style={{ width: '70px' }}>{ev.kpi_value}</td>
//                                                                                                 </tr>
//                                                                                             ))
//                                                                                         }
//                                                                                     </tbody>
//                                                                                 </table>
//                                                                                 : null
//                                                                         }
//                                                                     </td>
//                                                                     : null
//                                                             }
//                                                             <td className="text-center" style={{ verticalAlign: 'top' }}>
//                                                                 {
//                                                                     (el.appraisal) ?
//                                                                         (roles.includes('HR') || roles.includes('SUPERADMIN')) ?
//                                                                             el.appraisal.kpi_value : el.appraisal.evaluators[0].kpi_value
//                                                                         : ''
//                                                                 }
//                                                             </td>
//                                                         </tr>
//                                                     ))
//                                                 }
//                                                 {
//                                                     (appraisals.length === 0) ?
//                                                         <tr><td colSpan={5}><Empty /></td></tr> : null
//                                                 }
//                                             </tbody>

//                                         </table>
//                                     </TabPane>
//                                 </Tabs>
//                             </div>
//                             :
//                             <Spinner />
//                     }
//                 </Card>
//             </Card>
//         </div>
//     );
}

export default KPI;
