import { useState, useEffect, useRef } from 'react';
import { Button, Card, Col, Descriptions, Progress, Row, Statistic, Typography, Carousel, Space, Drawer, Form, Select } from 'antd';
import { FilterOutlined, RightOutlined, LeftOutlined } from '@ant-design/icons';
import { SentralModel } from '../../models/SentralModel';
import { Pie } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import currentUser from '../../helpers/authData';
import moment from 'moment';
import TableGantt from './table';

const { Title, Text } = Typography
const { Option } = Select

Chart.register(ChartDataLabels);
Chart.defaults.set('plugins.datalabels', {
    color: 'white',
    font: {
        size: 20
    }
});

const DashboardActionPlan = () => {
    const ref = useRef()
    const [data, setData] = useState([])
    const [allData, setAllData] = useState([])
    const [dataFilter, setDataFiter] = useState([])
    const [dataActionPlanSoftware, setDataActionPlanSoftware] = useState([])
    const [dataActionPlanBizdev, setDataActionPlanBizdev] = useState([])
    const [dataActionPlanIt, setDataActionPlanIt] = useState([])
    const [dataActionPlantSki, setDataActionPlanSki] = useState([])
    const [division, setDivision] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [isFilter, setIsFilter] = useState(false)

    const fetchActionPlan = () => {
        let payload = {
            employee_code: currentUser.employee_code,
            division_id: currentUser.division_id
        }

        SentralModel.action('ActionPlan', 'getActionPlan', payload).then((res) => {
            const v = res.data
            let allData = []
            let software = []
            let bizdev = []
            let ski = []
            let it = []

            v.map((el) => {
                let range = []
                let done = []

                for (let i = parseInt(moment(el.start_date).format('M')); i <= parseInt(moment(el.end_date).format('M')); i++) {
                    range.push(i)
                }

                if (el.progress !== 0) {
                    for (let i = 0; i < Math.ceil((el.progress / (100 / range.length))); i++) {
                        done.push(range[i])
                    }
                }

                Object.assign(el, { range_date: range, date_realization: parseInt(moment(el.realization_date).format('M')), progress_average: (100 / range.length), done: done })

                if (el.division_id === 24) {
                    software.push(el)
                }
                if (el.division_id === 13) {
                    bizdev.push(el)
                }
                if (el.division_id === 33) {
                    ski.push(el)
                }
                if (el.division_id === 34) {
                    it.push(el)
                }



                return allData.push(el)
            })

            setAllData(v)
            setDataActionPlanSoftware(software)
            setDataActionPlanBizdev(bizdev)
            setDataActionPlanIt(it)
            setDataActionPlanSki(ski)
            setLoaded(true)
        })
    }

    const fetchActionPlanData = () => {
        SentralModel.action('ActionPlan', 'actionPlanDivisionProgress', 0).then((res) => {
            setData(res.data)
            setLoaded(true)
        })
    }

    const fetchDivision = () => {
        SentralModel.list('Division').then((res) => {
            const v = res.data
            let data = []
            v.map((el) => {
                if (el.division_id === 13 || el.division_id === 24 || el.division_id === 33 || el.division_id === 34) {
                    return data.push(el)
                }

                return null;
            })

            setDivision(data)
        })
    }

    useEffect(() => {
        fetchActionPlanData()
        fetchActionPlan()
        fetchDivision()

    }, [])

    const finishFilter = (v) => {
        const filter = allData.filter(e => e.division_id === v.division_id)
        setIsFilter(true)
        setDataFiter(filter)
        setDrawerOpen(false)
    }

    return (
        <div>
            <Card className="content-container">
                {
                    loaded ?
                        <>
                            <div style={{ marginBottom: 15 }}>
                                <Row justify='space-between'>
                                    <Col>
                                        <Card title="Legend">
                                            <Space>
                                                <Space>
                                                    <div className='color-table-row' style={{ width: 20, height: 20 }}></div>
                                                    <Text>Timeline</Text>
                                                </Space>
                                                <Space>
                                                    <div className='color-table-progress' style={{ width: 20, height: 20 }}></div>
                                                    <Text>On Progress</Text>
                                                </Space>
                                                <Space>
                                                    <div className='color-table-done' style={{ width: 20, height: 20 }}></div>
                                                    <Text>Done</Text>
                                                </Space>
                                                <Space>
                                                    <div className='color-table-overdue' style={{ width: 20, height: 20 }}></div>
                                                    <Text>Overdue</Text>
                                                </Space>
                                            </Space>
                                        </Card>
                                    </Col>
                                    <Col>
                                        <Space>
                                            <Button type='primary' shape="circle" onClick={() => ref.current.prev()}><LeftOutlined /></Button>
                                            <Button type='primary' shape="circle" onClick={() => ref.current.next()}><RightOutlined /></Button>
                                        </Space>
                                    </Col>
                                </Row>
                            </div>
                            <Carousel dots={true} dotPosition="top" autoplay draggable ref={ref}>
                                <div style={{ padding: '10px 15px' }}>
                                    <Card title={
                                        <Row justify="space-between">
                                            <Col>
                                                <Text>All Data</Text>
                                            </Col>
                                            <Col>
                                                <Space>
                                                    <Button onClick={() => setDrawerOpen(true)} type="primary" shape="round">Filter <FilterOutlined /></Button>
                                                    <Button onClick={() => setIsFilter(false)} type='primary' danger shape='round'>Reset Filter</Button>
                                                </Space>
                                            </Col>
                                        </Row>
                                    }>
                                        {
                                            !isFilter ?
                                                <TableGantt data={allData} />
                                                :
                                                <TableGantt data={dataFilter} />
                                        }
                                    </Card>
                                </div>
                                <div style={{ padding: '10px 15px' }}>
                                    <Card title={
                                        <Row>
                                            <Col>
                                                <Text>Data IT</Text>
                                            </Col>
                                        </Row>
                                    }>
                                        <TableGantt data={dataActionPlanIt} />
                                    </Card>
                                </div>
                                <div style={{ padding: '10px 15px' }}>
                                    <Card title={
                                        <Row>
                                            <Col>
                                                <Text>Business Development Enterprise</Text>
                                            </Col>
                                        </Row>
                                    }>
                                        <TableGantt data={dataActionPlanBizdev} />
                                    </Card>
                                </div>
                                <div style={{ padding: '10px 15px' }}>
                                    <Card title={
                                        <Row>
                                            <Col>
                                                <Text>IT Software Development</Text>
                                            </Col>
                                        </Row>
                                    }>
                                        <TableGantt data={dataActionPlanSoftware} />
                                    </Card>
                                </div>
                                <div style={{ padding: '10px 15px' }}>
                                    <Card title={
                                        <Row>
                                            <Col>
                                                <Text>Business Development Commerce & Partner</Text>
                                            </Col>
                                        </Row>
                                    }>
                                        <TableGantt data={dataActionPlantSki} />
                                    </Card>
                                </div>
                                <div style={{ padding: '10px 15px' }}>
                                    <Row justify="space-between">
                                        {
                                            data.map((el, key) => (
                                                <Col key={key} span={5}>
                                                    <Card style={{ borderRadius: 10 }}>
                                                        <div className='mb-4'>
                                                            <Title style={{ fontSize: 16 }}>{el.division}</Title>
                                                        </div>
                                                        <div style={{ marginBottom: 10 }}>
                                                            <Descriptions layout="vertical" bordered>
                                                                <Descriptions.Item label="Total Action Plan">
                                                                    <div className='text-center'>
                                                                        <Statistic value={el.total_action_plan} />
                                                                    </div>
                                                                </Descriptions.Item>
                                                                <Descriptions.Item label="Progress">
                                                                    <Progress type="circle" width={80} percent={el.action_plan_progress.toFixed(1)} />
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                        </div>
                                                        <Card>
                                                            <Row justify='center'>
                                                                <Col span={24}>
                                                                    {
                                                                        el.realization !== 0 && el.overdue !== 0 ?
                                                                            <Pie width={"80%"} data={{
                                                                                labels: ['Overdue', 'Realization'],
                                                                                datasets: [
                                                                                    {
                                                                                        data: [el.overdue, el.realization],
                                                                                        backgroundColor: [
                                                                                            '#f50',
                                                                                            '#87d068',
                                                                                        ],
                                                                                        borderColor: [
                                                                                            '#f50',
                                                                                            '#87d068',
                                                                                        ],
                                                                                        borderWidth: 1,
                                                                                    },
                                                                                ],
                                                                            }} />
                                                                            :
                                                                            <Text>No Realization Data</Text>
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </Card>
                                                </Col>
                                            ))
                                        }
                                        <Col span={15}>
                                        </Col>
                                    </Row>
                                </div>
                            </Carousel>
                        </>
                        : null
                }

                <Drawer
                    title="Filter Data"
                    width={400}
                    placement="right"
                    closable={true}
                    onClose={() => setDrawerOpen(false)}
                    visible={drawerOpen}
                    key="right"
                >
                    <Form layout='vertical' onFinish={(v) => finishFilter(v)}>
                        <Form.Item label="Select Division" name="division_id">
                            <Select>
                                {
                                    division.map((el, key) => (
                                        <Option key={key} value={el.division_id}>
                                            {el.division_name}
                                        </Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Button type='primary' htmlType='submit'>Submit</Button>
                    </Form>
                </Drawer>
            </Card>
        </div>
    )
}

export default DashboardActionPlan