import React, { useEffect, useState } from 'react';
import { Card, Form, Row, Col, Input, message } from 'antd';
import { PageTitle, SubmitButton, Spinner } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import { useHistory, useParams } from 'react-router-dom'
// import { Editor } from '@tinymce/tinymce-react';
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css'
import {
    align,
    font,
    fontColor,
    fontSize,
    formatBlock,
    hiliteColor,
    horizontalRule,
    lineHeight,
    list,
    paragraphStyle,
    table,
    template,
    textStyle,
    image,
    link,
} from "suneditor/src/plugins"

const InstructionForm = () => {
    const history = useHistory();
    const [form] = Form.useForm();
    const { instructionId } = useParams();
    const [loaded, setLoaded] = useState(false);
    const [saving, setSaving] = useState(false);
    const [content, setContent] = useState(null);

    const fetchInstruction = (instructionId, form) => {
        setLoaded(false);
        if (instructionId === '0') {
            form.resetFields();
        } else {
            SentralModel.get('Instruction', {}, instructionId).then(res => {
                form.setFieldsValue({
                    instruction_id: res.data.announcement_id,
                    title: res.data.title,
                    code: res.data.code,
                    content: res.data.content
                });
                setContent(res.data.content);
            });
        }
        setLoaded(true);
    }

    useEffect(() => {
        fetchInstruction(instructionId, form);
    }, [instructionId, form]);

    const saveAnnouncement = (v) => {
        setSaving(true);

        let data = {
            instruction_id: instructionId,
            title: v.title,
            code: v.code,
            content: content,
        }

        SentralModel.save('Instruction', data, instructionId).then((res) => {
            message.success('Instruction saved', 2);
            setTimeout(() => {
                history.push('/setting/instruction');
            }, 1000);
            setSaving(false);
        });
    }

    const handleEditorChange = (content) => {
        setContent(content);
    }

    return (
        <Card className="content-container">
            <PageTitle title="Instruction" breadcrumbs={[['Instruction', '/setting/instruction'], ['Detail']]}></PageTitle>
            {
                loaded ?
                    <Form size="middle" form={form} layout="vertical" onFinish={saveAnnouncement}>
                        <Form.Item name="instruction_id" hidden>
                            <Input placeholder="Instruction ID" />
                        </Form.Item>
                        <Row justify="space-around">
                            <Col span={20}>
                                <Form.Item label="Title" name="title" required rules={[{ required: true }]}>
                                    <Input placeholder="Title" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="space-around">
                            <Col span={20}>
                                <Form.Item label="Code" name="code" required rules={[{ required: true }]}>
                                    <Input placeholder="Code" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Col md={{ span: 20, offset: 2 }} style={{ marginBottom: 20 }}>
                            <SunEditor setOptions={{
                                showPathLabel: false,
                                minHeight: "100vh",
                                maxHeight: "100vh",
                                placeholder: "Enter your text here!!!",
                                plugins: [
                                    align,
                                    font,
                                    fontColor,
                                    fontSize,
                                    formatBlock,
                                    hiliteColor,
                                    horizontalRule,
                                    lineHeight,
                                    list,
                                    paragraphStyle,
                                    table,
                                    template,
                                    textStyle,
                                    image,
                                    link
                                ],
                                buttonList: [
                                    ["undo", "redo"],
                                    ["font", "fontSize", "formatBlock"],
                                    ["paragraphStyle"],
                                    [
                                        "bold",
                                        "underline",
                                        "italic",
                                        "strike",
                                        "subscript",
                                        "superscript"
                                    ],
                                    ["fontColor", "hiliteColor"],
                                    ["removeFormat"],
                                    "/", // Line break
                                    ["outdent", "indent"],
                                    ["align", "horizontalRule", "list", "lineHeight"],
                                    ["table", "link", "image"]
                                ],
                                formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
                                font: [
                                    "Arial",
                                    "Calibri",
                                    "Comic Sans",
                                    "Courier",
                                    "Garamond",
                                    "Georgia",
                                    "Impact",
                                    "Lucida Console",
                                    "Palatino Linotype",
                                    "Segoe UI",
                                    "Tahoma",
                                    "Times New Roman",
                                    "Trebuchet MS"
                                ]
                            }} height={500} setContents={content} onChange={(value) => handleEditorChange(value)} />
                            {/* <Editor
                                apiKey={process.env.REACT_APP_TINYMCE_KEY}
                                value={content}
                                init={{
                                    height: 500,
                                    menubar: true,
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar:
                                        'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                                }}
                                onEditorChange={(value) => handleEditorChange(value)}
                            /> */}
                        </Col>
                        <Row justify="space-around">
                            <Col span={20} className="text-right">
                                <SubmitButton loading={saving} />
                            </Col>
                        </Row>
                    </Form>
                    : <Spinner />
            }
        </Card>
    );
}

export default InstructionForm;
