import React from 'react';
import {Tabs, Space, Button} from 'antd'
import {FieldTimeOutlined,CheckCircleOutlined, CloseCircleOutlined, LoginOutlined} from '@ant-design/icons'
import { DataTable, ShowButton} from '../../../components'
import {Link} from 'react-router-dom'
import {toFullDate} from '../../../utils/dateHandler'
import {toIdr} from '../../../utils/currencyHandler'

const {TabPane} = Tabs;

const Request = (props) => {

    const requestData = [
        ...props.data.map(el =>{
            return {
                id: el.sub_cash_advance_id,
                from_date: el.from_date,
                to_date: el.to_date,
                purpose: el.purpose,
                plan: el.plan,
                // type: el.type.detail_code,
                planning_cost: el.total_amount,
                total_days: el.total_days,
                total_amount: toIdr(el.total_amount),
                status: el.status,
                approver: el.approver,
                created_at: el.created_at
            }
        })
    ]

    const column = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
        },{
            title: 'Date',
            render: (row) => (
                <span>{toFullDate(row.from_date)} - {toFullDate(row.to_date)}</span>
            )
        },{
            title: 'Purpose',
            dataIndex: 'purpose',
            key: 'purpose',
        },{
            title: 'Total Amount',
            dataIndex: 'total_amount',
            key: 'total_amount',
        },{
            title: 'Action',
            align:'center',
            render : (row) => (
                <Space>
                    <ShowButton link to={'/cash-advance/' + row.id} />
                    {
                        (row.status === 'APPROVED') ? 
                            <Link to={`/cash-advance/create-realization/`+row.id}>
                                <Button type="link" icon={<LoginOutlined />} />
                            </Link>
                        : null
                    }
                </Space>
            )
        }
    ];


    return (
        <div>
            <Tabs defaultActiveKey="waiting" type="card">
                <TabPane tab={<span><FieldTimeOutlined style={{color:'#4ca3ff'}}/> Waiting Approval</span>} key="waiting">
                    <DataTable size="small" columns={column} dataSource={requestData.filter(el => el.status==="WAITING")} bordered/>
                </TabPane>
                <TabPane tab={<span><CheckCircleOutlined style={{color:'green'}}/> Approved</span>} key="approved">
                    <DataTable size="small" columns={column} dataSource={requestData.filter(el => el.status==="APPROVED")} bordered/>
                </TabPane>
                <TabPane tab={<span><CloseCircleOutlined style={{color:'red'}}/> Rejected</span>} key="rejected">
                    <DataTable size="small" columns={column} dataSource={requestData.filter(el => el.status==="REJECTED")} bordered/>
                </TabPane>
            </Tabs>
        </div>
    );
}

export default Request;
