import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {Card, Form, Input, Typography, Button, message} from 'antd'
import {EditButton, DeleteButton, FormModal, AddButton} from '../../../../components'
import {SentralModel} from '../../../../models/SentralModel'
import {PlusOutlined} from '@ant-design/icons'

const {Text} = Typography
const Index = (props) => {
    const {resignId} = useParams();
    const [workForm] = Form.useForm()
    const [detailForm] = Form.useForm()
    const [title, setTitle]= useState(null)
    const [formModal, setFormModal] = useState(false)
    const [saving, setSaving] = useState(false)
    const [modalItemTitle, setModalItemTitle]= useState(null)
    const [formModalDetail, setFormModalDetail] = useState(false)

    // Work
    const addWork = () => {
        setTitle('Add Work')
        workForm.setFieldsValue({
            sub_resign_work_id: 0,
            sub_resign_id: resignId,
            work: '',
        })
        setFormModal(true)
    }

    const editWork = (v) => {
        setTitle('Edit Work')
        workForm.setFieldsValue({
            sub_resign_work_id: v.id,
            sub_resign_id: resignId,
            work: v.work,
        })
        setFormModal(true)
    }

    const saveData = (v) => {
        setSaving(true)
        let payload = {
            sub_resign_id: v.sub_resign_id,
            work: v.work,
        }
        SentralModel.save('SubmissionResignWork', payload, v.sub_resign_work_id).then((res) => {
            setFormModal(false)
            props.onFetch();
            message.success('Work saved', 2);
        }).finally(() => {
            setSaving(false)
        })
    }

    const deleteWork = (id) =>{
        message.loading('Deleting work')
        SentralModel.deleteById('SubmissionResignWork', id).then((res) => {
            message.destroy();
            message.success('Work deleted', 2);
            props.onFetch();
        })
    }

    // Detail
    const addDetail=(v)=>{
        setModalItemTitle('Add Work Detail')
        detailForm.setFieldsValue({
            sub_resign_work_detail_id: 0,
            sub_resign_work_id: v,
            title: '',
            description: ''
        })
        setFormModalDetail(true)
    }

    const editDetail = (v) => {
        setModalItemTitle('Edit Work Detail')
        detailForm.setFieldsValue({
            sub_resign_work_detail_id: v.sub_resign_work_detail_id,
            sub_resign_work_id: v.sub_resign_work_id,
            title: v.title,
            description: v.description,
        })
        setFormModalDetail(true)
    }

    const saveDetail = (v) => {
        setSaving(true)
        let payload={
            sub_resign_work_id: v.sub_resign_work_id,
            title: v.title,
            description: v.description,
        }
        SentralModel.save('SubmissionResignWorkDetail', payload, v.sub_resign_work_detail_id).then((res)=>{
            setFormModalDetail(false)
            props.onFetch();
            message.success('Work detail saved',2);
        }).finally(() => {
            setSaving(false)
        })
    }

    const deleteDetail = (id) =>{
        message.loading('Deleting work detail')
        SentralModel.deleteById('SubmissionResignWorkDetail', id).then((res) => {
            message.destroy();
            message.success('Work detail deleted', 2);
            props.onFetch();
        })
    }

    return (
        <div>
            <Card title={
                <div className="full-width">
                    <Text strong style={{float:'left'}}>Work List</Text>
                    <AddButton right onAdd={addWork} title="New Work"/>
                </div> }>

                <table className="table-collapse table-default" style={{width:'100%'}}>
                    <thead>
                        <tr>
                            <th>No</th>
                            <th>Work</th>
                            <th>Detail</th>
                            <th className="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.data.map((work, index) => (
                                <tr key={index} style={{verticalAlign: "top"}}>
                                    <td className="text-center" width="5%"> {index + 1} </td>
                                    <td width="20%"> {work.work} </td>
                                    <td style={{padding:0}}>
                                        <table className="table-collapse table-default" style={{width:'100%'}}>
                                            <tbody>
                                                {
                                                    work.details.map((detail, row) => (
                                                        <tr key={"detail" + row}>
                                                            <td style={{width:'20%'}}>{detail.title}</td>
                                                            <td style={{width:'50%'}}>{detail.description}</td>
                                                            <td className="text-center" style={{width:'10%'}}>
                                                                <EditButton onEdit={() => editDetail(detail)} />
                                                                &nbsp;
                                                                <DeleteButton onConfirm={() => deleteDetail(detail.sub_resign_work_detail_id)} />
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                                <tr key={"add_" + index}>
                                                    <td colSpan="2">
                                                        <Button type="link" size="small" shape="circle" onClick={() => addDetail(work.sub_resign_work_id)} icon={<PlusOutlined />}>Add Detail</Button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td className="text-center" width="8%">
                                        <EditButton onEdit={() => editWork(work)} />
                                        &nbsp;
                                        <DeleteButton onConfirm={() => deleteWork(work.sub_resign_work_id)} />
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

                {/* <DataTable size="small" columns={dataColumn} dataSource={dataSource} bordered/> */}
            </Card>

            {
                (formModal) ?
                <FormModal form={workForm} title={title} submitForm={(v)=>saveData(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                    <Form.Item name="sub_resign_work_id" hidden>
                        <Input placeholder="Work ID" />
                    </Form.Item>
                    <Form.Item name="sub_resign_id" hidden>
                        <Input placeholder="Resign ID" />
                    </Form.Item>
                    <Form.Item label="Work" name="work" rules={[{required:true}]}>
                        <Input.TextArea rows={3} placeholder="Work" />
                    </Form.Item>
                </FormModal>
                : null
            }
            
            {
                (formModalDetail) ?
                <FormModal form={detailForm} title={modalItemTitle} submitForm={(v)=>saveDetail(v)} width={400} showModal={formModalDetail} onCloseModal={() => setFormModalDetail(false)} saving={saving}>
                    <Form.Item name="sub_resign_work_detail_id" hidden>
                        <Input placeholder="Work Detail ID" />
                    </Form.Item>
                    <Form.Item name="sub_resign_work_id" hidden>
                        <Input placeholder="Work ID" />
                    </Form.Item>
                    <Form.Item label="Title" name="title" rules={[{required:true}]}>
                        <Input placeholder="Title" />
                    </Form.Item>
                    <Form.Item label="Description" name="description" rules={[{required:true}]}>
                        <Input.TextArea rows={3} placeholder="Description" />
                    </Form.Item>
                </FormModal>
                : null
            }
        </div>
    );
}

export default Index;
