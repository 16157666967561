import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Typography } from 'antd'
import { PageTitle, PrintPdfButton, Spinner } from '../../../components'
import ApprovalLog from '../ApprovalLog'
import DetailHeader from '../DetailHeader'
import { useParams } from 'react-router-dom';
import { SentralModel } from '../../../models/SentralModel';
import { toFullDate } from '../../../utils/dateHandler'

const { Text } = Typography

const Detail = () => {
    const { overtimeId } = useParams()
    const [overtime, setOvertime] = useState(null)

    const fetchOvertime = (id) => {
        SentralModel.get('SubmissionOvertime', {}, id).then((res) => {
            setOvertime(res.data)
        })
    }

    useEffect(() => {
        fetchOvertime(overtimeId)
    }, [overtimeId]);

    const printData = () => {
        window.open(process.env.REACT_APP_API_URL + '/print/overtime-realization/' + overtimeId)
    }

    return (
        <Card className="content-container">
            <div>
                <PageTitle title="Overtime Realization" breadcrumbs={[['Overtime', '/overtime'], ['Realization Detail']]}></PageTitle>
            </div>
            {
                (overtime) ?
                    <div>
                        <Row className="mb-3">
                            <Col span={24} >
                                <PrintPdfButton right onPrint={printData} />
                            </Col>
                        </Row>
                        <DetailHeader
                            name={overtime.employee.name}
                            date={overtime.created_at}
                            status={overtime.status_realization} />
                        <Card bordered className="mb-4">
                            <Text strong>OVERTIME REALIZATION DETAIL</Text>
                            <Row className="mb-4 mt-1">
                                <Col span={12}>
                                    <table className="table-collapse table-default" style={{ width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <th>Employee Name</th><td>{overtime.employee.name}</td>
                                            </tr>
                                            <tr>
                                                <th>Division</th><td>{overtime.employee.division.division_name}</td>
                                            </tr>
                                            <tr>
                                                <th>Position</th><td>{overtime.employee.position.position_name}</td>
                                            </tr>
                                            <tr>
                                                <th>Overtime Date</th><td>{toFullDate(overtime.from_date_realization)}</td>
                                            </tr>
                                            <tr>
                                                <th>Time</th><td>{overtime.from_time_realization} - {overtime.to_time_realization}</td>
                                            </tr>
                                            <tr>
                                                <th>Overtime Information</th><td>{overtime.overtime_type.description}</td>
                                            </tr>
                                            <tr>
                                                <th>Necessity</th><td>{overtime.description}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </Card>
                        <Card bordered>
                            <ApprovalLog category="OVERTIMEREALIZATION"
                                approvers={overtime.approver_realization} />
                        </Card>
                    </div> : <Spinner />
            }

        </Card>
    );
}

export default Detail;
