import React, {useEffect, useState } from 'react';
import { Row, Col, Card, Form, Input, Typography, Select, InputNumber} from 'antd';
import { SubmitButton, Spinner } from '../../../components';
import authData from '../../../helpers/authData';
import { SentralModel } from '../../../models/SentralModel';

const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;

function useForceUpdate() {
    // eslint-disable-next-line
    const [value, setValue] = useState(0);
    return () => setValue(value => ++value);
}

const RequestForm = (props) => {
    const forceUpdate = useForceUpdate();
    const [voucherForm] = Form.useForm();
    const [loaded, setLoaded] = useState(false);
    const [itemCount, setItemCount] = useState(0);
    const [details, setDetails] = useState([{ necessity: null }]);
    const [types, setTypes] = useState([]);
    const [selectedType, setSelectedType] = useState(null);

    const fetchType = () => {
        SentralModel.list('GeneralDetail', {filter:[["general_code", "VT"]], orderBy:"order_key", order:"asc"}).then(res => {
            setTypes(res.data);
            setLoaded(true);
        })
    }

    useEffect(() => {
        fetchType();
    }, []);

    const submitVoucher = (v) => {
        let submittedData = {
            title: v.title,
            employee_code: authData.employee_code,
            type: selectedType,
            total: v.total,
            description: v.description,
            details: details
        }
        props.onSubmit(submittedData);
    }

    const changeTotal = (v) => {
        let x = [];
        for (let i = 0; i < v; i++) {
            x.push({ necessity: null });
            voucherForm.setFieldsValue({
                ['necessity[' + i + ']']: null
            });
        }
        setDetails(x);
        setItemCount(v);
        forceUpdate();
    }

    const changeType = e => {
        setSelectedType(e);
    }

    const changeNecessity = (v, key) => {
        let x = details;
        x[key].necessity = v.target.value;
        setDetails(x);
    }

    return (
        loaded ?
            <Form size="middle" form={voucherForm} layout="vertical" onFinish={submitVoucher}>
                <Row justify="center">
                    <Col span={20}>
                        <Row justify='space-between'>
                            <Col span={18}>
                                <Form.Item name="title" placeholder="Title" label="Title" rules={[{ required: true, message: 'Title is required' }]}>
                                    <Input placeholder="Title" />
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item name="total" placeholder="Total Voucher" label="Total Voucher" rules={[{ required: true, message: 'Total Voucher is required' }]}>
                                    <InputNumber placeholder="Total Voucher" min={1} style={{ width: '100%' }} onChange={changeTotal} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify='space-between'>
                            <Col span={11}>
                                <Form.Item name="type" placeholder="Voucher Type" label="Voucher Type" rules={[{ required: true, message: 'Voucher Type is required' }]}>
                                    <Select showSearch style={{ width: "100%" }} placeholder="Select Voucher" optionFilterProp="children" onChange={changeType} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {
                                            types.map((el, key) => (
                                                <Option key={key} value={el.detail_code}>{el.description}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="description" placeholder="Description" label="Description (Optional)">
                                    <TextArea placeholder="Description" autoSize={{ minRows: 2 }} />
                                </Form.Item>
                            </Col>
                        </Row>

                        {
                            itemCount > 0 ?
                            <Row>
                                <Col span={24}>
                                    <Card title={
                                        <div className="full-width">
                                            <Text strong style={{ float: 'left' }}>Detail Voucher</Text>
                                        </div>}>
                                        <table className="table-item" style={{ width: '100%' }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ width: '100%', textAlign: 'left' }}>Necessity</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    details.map((el, key) => (
                                                        <tr key={'necessity_' + key}>
                                                            <td>
                                                                <Form.Item name={'necessity[' + key + ']'} rules={[{ required: true, message: 'Necessity is required' }]}>
                                                                    <Input placeholder={"Voucher " + (key + 1)} onChange={(v) => changeNecessity(v, key)} />
                                                                </Form.Item>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </Card>
                                </Col>
                            </Row>
                            : null
                        }
                        
                        <br />
                        <Row>
                            <Col span={24} className="text-right">
                                <SubmitButton loading={props.saving} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        : <Spinner />
    );
}

export default RequestForm;
