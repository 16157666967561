import React, {useEffect, useState} from 'react';
import {Card, Typography, Space} from 'antd'
import {NewButton, ShowButton, PageTitle, Spinner, DataTable} from '../../../../components'
import {SentralModel} from '../../../../models/SentralModel'

const {Text} = Typography
const Index = () => {
    const [distributions, setDistributions] = useState([])
    const [loaded, setLoaded] = useState(false)

    const fetchDistribution = () =>{
        SentralModel.list('AssetDistribution').then(res=>{
            setDistributions(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchDistribution()
    }, []);
    
    const dataSource = [
        ...distributions.map( el => {
            return {
                id: el.asset_distribution_id,
                distribution_number: el.distribution_number,
                employee_name: el.employee ? el.employee.name : null,
                date: el.date
            }
        })
    ];

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'no',
        },
        {
          title: 'Distribution Number',
          dataIndex: 'distribution_number',
          key: 'distribution_number',
        },
        {
          title: 'Employee',
          dataIndex: 'employee_name',
          key: 'employee_name',
        },
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'date',
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <ShowButton link to={'/asset/goods-distribution/' + row.id} />
                    </Space>
                </div>
            )
        },
    ];

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Goods Distributions" breadcrumbs={[['Asset'],['Goods Distribution']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>Goods Distribution List</Text>
                        <NewButton right to="/asset/goods-distribution/create" title={'New Goods Distribution'}/>
                    </div> }>

                    {
                        (loaded) ?
                        <DataTable size='small' bordered dataSource={dataSource} columns={columns}/>
                        : <Spinner/>
                    }
                </Card>
            </Card>
        </div>
    );
}

export default Index;
