import { useEffect, useState } from 'react'
import { Button, Card, Row, Typography, Col, Select, DatePicker, Tag, Space, message } from 'antd'
// import { FileExcelOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { SentralModel } from '../../../models/SentralModel'
import { DataTable, Spinner } from '../../../components'
import { BsFingerprint } from 'react-icons/bs'
import moment from 'moment'

const { Title } = Typography

const DashboardWorkingLocation = () => {
    const history = useHistory()
    // eslint-disable-next-line no-unused-vars
    const [periodId, setPeriodId] = useState(0)
    const [selectedDivision, setSelectedDivsion] = useState(null)
    const [selectedDate, setSelectedDate] = useState(null)
    const [division, setDivision] = useState([])
    const [data, setData] = useState([])
    const [loaded, setLoaded] = useState(false)

    const fetchWorkingLocationData = (v) => {
        setLoaded(false)
        SentralModel.dashboardData('WorkingLocation', 'dashboardWorkingLocation', v, null)
            .then((res) => {
                const v = res.data.data_dashboard
                setPeriodId(res.data?.period?.payroll_period_id)
                setData(v)
                setLoaded(true)
            })
    }

    const sync = () => {
        setLoaded(false)
        SentralModel.dashboardData("WorkingLocation", "scynData", { date: selectedDate }, null).then(() => {
            fetchWorkingLocationData({ division_id: selectedDivision, date: selectedDate })
            message.success("Sync Success")
        }).catch((err) => {
            message.warning("Sync Failed")
        })
    }

    const fetchDivision = () => {
        SentralModel.dashboardData('Division', 'getDivision', null, null).then((res) => {
            const v = res.data
            let arr = []

            arr.push({ value: 99, label: "All IT Bizdev" })
            v.forEach((el) => {
                arr.push({ value: el.division_id, label: el.division_name })
            })

            setDivision(arr)
        })
    }

    const onChangeDivision = (v) => {
        setSelectedDivsion(v)
        fetchWorkingLocationData({ division_id: v, date: selectedDate })
    }

    const onChangeDate = (v) => {
        setSelectedDate(moment(v).format("YYYY-MM-DD"))
        fetchWorkingLocationData({ division_id: selectedDivision, date: v })
    }

    useEffect(() => {
        fetchDivision()
        fetchWorkingLocationData({ division_id: selectedDivision, date: selectedDate })

    }, [selectedDivision, selectedDate])

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'name',
            width: '5%',
            align: 'center'
        },
        {
            title: 'Employee Name',
            render: (row) => (
                row?.employee?.name
            ),
            key: 'employee_name'
        },
        {
            title: 'Working Location',
            render: (row) => (
                <>
                    <div style={{ marginBottom: 5 }}>
                        <table className='table-sub'>
                            <thead>
                                <tr>
                                    <th style={{ width: "300px" }}>Company</th>
                                    <th>Date</th>
                                    <th style={{ textAlign: "center" }}>Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    row?.working_location.map((el, key) => (
                                        <tr key={key}>
                                            <td style={{ width: "300px" }}>
                                                {
                                                    el?.description === "Alpha" || el?.description === "Leave" ?
                                                        <Tag color="red">
                                                            {el?.description}
                                                        </Tag>
                                                        :
                                                        el?.absence_description === "Machine" ?
                                                            <Space>
                                                                {el?.company?.company_name}
                                                                <BsFingerprint color='#1987e8' />
                                                            </Space>
                                                            :
                                                            el?.company?.company_name
                                                }
                                            </td>
                                            <td>{moment(el?.date).format("DD/MM/YYYY")}</td>
                                            <td style={{ textAlign: "center" }}>{el?.time_start} - {el?.time_end}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    {
                        row?.attendance_staging.length > 0 && row?.attendance_staging[0].address_absence !== null ?
                            <div>
                                <table className='table-sub'>
                                    <thead>
                                        <tr>
                                            <th style={{ textAlign: "center" }}>Actual Location</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: "center" }}>{row?.attendance_staging[0]?.address_absence}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            : null
                    }
                </>
            ),
            key: 'working_location'
        }
    ]

    // const exportReport = () => {
    //     window.open(`${process.env.REACT_APP_API_URL}/report/dashboardWorkingLocation?period_id=${periodId}&division_id=${selectedDivision}`)
    // }

    return (
        <Card style={{ margin: '15px 10px 0 10px' }} className="content-container">
            <div style={{ marginBottom: 15 }}>
                <div>
                    <Row justify='space-between'>
                        <Col span={8}>
                            <Card>
                                <div style={{ marginBottom: 30 }}>
                                    <Title level={4}>DASHBOARD WORKING LOCATION</Title>
                                </div>
                                <div style={{ marginBottom: 10 }}>
                                    <Row justify='space-between'>
                                        <Col span={11}>
                                            <Select
                                                placeholder="Select Division"
                                                options={division}
                                                style={{ width: "100%" }}
                                                onChange={(v) => onChangeDivision(v)}
                                            />
                                        </Col>
                                        <Col span={11}>
                                            <DatePicker onChange={(v) => onChangeDate(v)} style={{ width: "100%" }}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                {/* <div>
                                    <Row justify='start'>
                                        <Col span={11}>
                                            <Button onClick={exportReport} disabled={periodId === 0 ? true : false} style={{ width: "100%" }} type='primary'><FileExcelOutlined /> Export Data</Button>
                                        </Col>
                                    </Row>
                                </div> */}
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Row style={{ marginBottom: 10 }}>
                                    <Button style={{ width: 160, fontSize: 11, fontWeight: 'bold' }} type='primary' onClick={() => history.push('/action-plan-home')}>ACTION PLAN</Button>
                                </Row>
                                <Row style={{ marginBottom: 10 }}>
                                    <Button disabled style={{ width: 160, fontSize: 11, fontWeight: 'bold' }} type='primary' onClick={() => null}>DAILY ACTIVITY</Button>
                                </Row>
                                <Row style={{ marginBottom: 10 }}>
                                    <Button style={{ width: 160, fontSize: 11, fontWeight: 'bold' }} type='primary' onClick={() => history.push('/working-location-dashboard')}>WORKING LOCATION</Button>
                                </Row>
                                <Row style={{ marginBottom: 10 }}>
                                    <Button style={{ width: 160, fontSize: 11, fontWeight: 'bold' }} type='primary' onClick={() => history.push('/digitalisasi-home')}>DIGITALISASI DOKUMEN</Button>
                                </Row>
                                <Row>
                                    <Button style={{ width: 160, fontSize: 11, fontWeight: 'bold' }} type='primary' onClick={() => history.push('/dashboard-project')}>PROJECT BOOST</Button>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>

            <Card title={
                <Row justify='spacec-between'>
                    <Col span={13}>
                        Working Location
                    </Col>
                    <Col span={11}>
                        <Row justify='end'>
                            <Button onClick={sync} type='primary'>Sync Data</Button>
                        </Row>
                    </Col>
                </Row>
            } style={{ marginBottom: 20 }}>
                {
                    loaded ?
                        <DataTable dataSource={data} columns={columns} bordered size="small" />
                        : <Spinner />
                }
            </Card>
        </Card >
    )
}

export default DashboardWorkingLocation