import { Button, Descriptions, Form, Input, Modal, Switch } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { changeShowEditTicket, changeStatus, fetchListTicket, fetchTicketAssign, updateTicketGeneral } from '../../../../../../../redux/slices/Dashboard/karajoTicketSlice'
import moment from 'moment'

const EditModalGeneral = () => {
    const dispatch = useDispatch()
    const showModalEditTicket = useSelector((state) => state.karajoTicket.showModalEditTicket)
    const info = useSelector((state) => state.karajoTicket.info)
    const status = useSelector((state) => state.karajoTicket.status)

    const onFinish = (v) => {
        let payload = {
            all_app_task_id: info.id,
            description: v.description,
            remarks: v.remarks,
            status: status === true ? 1 : 0
        }

        dispatch(updateTicketGeneral(payload)).then(() => {
            dispatch(fetchListTicket())
            dispatch(fetchTicketAssign())
        })
    }

    return (
        <Modal visible={showModalEditTicket} onCancel={() => dispatch(changeShowEditTicket(false))} title="Update Ticket" width={1000}
            footer={
                <Button onClick={() => dispatch(changeShowEditTicket(false))}>
                    Close
                </Button>
            }>
            <div style={{ marginBottom: 10 }}>
                <Descriptions title="General Ticket Info" bordered>
                    <Descriptions.Item span={2} label="Employee">{info?.multiple_task_to[0]?.task_to?.name ?? "-"}</Descriptions.Item>
                    <Descriptions.Item label="Due Date">{moment(info?.due_date).format("DD MMMM YYYY")}</Descriptions.Item>
                    <Descriptions.Item span={3} label="Description">{info?.description ?? "-"}</Descriptions.Item>
                </Descriptions>
            </div>

            <Form layout='vertical' onFinish={onFinish}>
                <Form.Item label="Description" name="description" required rules={[{ required: true }]}>
                    <Input.TextArea rows={4} />
                </Form.Item>
                <Form.Item label="Remark" name="remarks" required rules={[{ required: true }]}>
                    <Input.TextArea rows={2} />
                </Form.Item>
                <Form.Item label="Status" name="status">
                    <Switch checkedChildren="Finish" unCheckedChildren="Progress" defaultChecked={status} onChange={(v) => dispatch(changeStatus(v))} />
                </Form.Item>
                <Form.Item>
                    <Button type='primary' htmlType='submit'>
                        Update
                    </Button>
                </Form.Item>
            </Form>
        </Modal >
    )
}

export default EditModalGeneral