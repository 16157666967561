import React, { useState, useEffect } from 'react';
import { Card, Tabs, Tag } from 'antd'
import { PageTitle, Spinner } from '../../components'
import PersonalData from './PersonalData'
import Education from './Education'
import Document from './Document'
import WorkExperience from './WorkExperience'
import Language from './Language'
import Skill from './Skill'
import Certification from './Certification'
import Medical from './Medical'
import Swab from './Swab'
import Family from './Family'
import Spouse from './Spouse'
import Bank from './Bank'
import Employment from './Employment'
import Contract from './Contract'
import Probation from './Probation'
import History from './History'
import ChangePassword from './ChangePassword'
import { SentralModel } from '../../models/SentralModel'
import authData from '../../helpers/authData'
import { useParams } from 'react-router-dom'
import { decrypt } from '../../helpers/crypto'
import SocialMedia from './SocialMedia';

const { TabPane } = Tabs

function useForceUpdate() {
    /* eslint-disable */
    const [value, setValue] = useState(0);
    /* eslint-enable */
    return () => setValue(value => ++value);
}

const Index = () => {
    const forceUpdate = useForceUpdate()

    const { employeeCode } = useParams()
    const [user, setUser] = useState()
    const [generals, setGenerals] = useState([])
    const [divisions, setDivisions] = useState([])
    const [positions, setPositions] = useState([])
    const [companies, setCompanies] = useState([])
    const [cities, setCities] = useState([])

    const role = localStorage.getItem('role') ? JSON.parse(decrypt(localStorage.getItem('role'))) : []

    const fetchUser = (employeeCode) => {
        SentralModel.get('Employee', [], employeeCode ?? authData.employee_code).then((res) => {
            setUser(res.data)
        })
    }
    const fetchDivisions = () => {
        SentralModel.list('Division', { filter: [["is_active", "=", 1]], orderBy: "division_name", order: "asc" }).then((res) => {
            let arr = []
            res.data.forEach((el, key) => {
                arr.push({ value: el.division_id, label: el.division_name })
            })
            setDivisions(arr)
        })
    }
    const fetchPositions = () => {
        SentralModel.list('Position', { filter: [["is_active", "=", 1]], orderBy: "position_name", order: "asc" }).then((res) => {
            let arr = []
            res.data.forEach((el, key) => {
                arr.push({ value: el.position_id, label: el.position_name })
            })
            setPositions(arr)
        })
    }
    const fetchGeneral = () => {
        SentralModel.list('GeneralDetail', { orderBy: 'order_key', order: 'asc' }).then((res) => {
            setGenerals(res.data)
        })
    }
    const fetchCompanies = () => {
        SentralModel.list('Company', { filter: [["company_code", "!=", null]], orderBy: "company_id", order: "asc" }).then(res => {
            setCompanies(res.data)
        })
    }
    const fetchCity = () => {
        SentralModel.list('City', { orderBy: 'city_name', order: 'asc' }).then((res) => {
            setCities(res.data)
        })
    }

    const updateUser = (v) => {
        setUser(v)
        forceUpdate()
    }

    useEffect(() => {
        fetchUser(employeeCode)
        fetchDivisions()
        fetchPositions()
        fetchGeneral()
        fetchCompanies()
        fetchCity()
    }, [employeeCode]);

    const renderItem = (v) => {
        if (v) return v;
        return '-';
    }

    const changeProfileTab = (v) => {
        sessionStorage.setItem('selectedProfileTab', v)
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title={<div>
                    Profile
                    {
                        (user && !user.is_active) ?
                            <Tag color={'red'} style={{ marginLeft: '5px' }}>Inactive</Tag>
                            : ''
                    }
                </div>
                } breadcrumbs={employeeCode ? [['Employee', '/organization/employee'], ['Profile']] : [['Profile']]}></PageTitle>
                {
                    (user) ?
                        <Tabs defaultActiveKey="personalData" tabPosition="left" type="card" onChange={changeProfileTab} tabBarStyle={{ marginLeft: 0, paddingLeft: 0 }}>
                            <TabPane tab="Personal Data" key="personalData">
                                <PersonalData generals={generals} data={user} renderItem={(v) => renderItem(v)} updateUser={(v) => updateUser(v)} />
                            </TabPane>
                            {/* {
                                (role.includes('SUPERADMIN') || role.includes('HR')) ? */}
                            <TabPane tab="Employment" key="employment">
                                <Employment emp_code={user.employee_code} statuses={generals.filter((el) => el.general_code === 'ES')} data={user} renderItem={(v) => renderItem(v)} updateUser={(v) => updateUser(v)} divisions={divisions} positions={positions} companies={companies} grades={generals.filter((el) => el.general_code === 'GR')} stations={cities} />
                            </TabPane>
                            {/* : null
                            } */}
                            {
                                user.employee_status === 'ESKN' ?
                                    <TabPane tab="Contract" key="contract">
                                        <Contract isHr={role.includes('HR')} emp_code={user.employee_code} data={user} renderItem={(v) => renderItem(v)} updateUser={(v) => updateUser(v)} />
                                    </TabPane>
                                    : null
                            }
                            {
                                user.employee_status === 'ESPB' ?
                                    <TabPane tab="Probation" key="probation">
                                        <Probation isHr={role.includes('HR')} emp_code={user.employee_code} data={user} renderItem={(v) => renderItem(v)} updateUser={(v) => updateUser(v)} />
                                    </TabPane>
                                    : null
                            }
                            <TabPane tab="Bank Account" key="bank">
                                <Bank emp_code={user.employee_code} data={user.banks} renderItem={(v) => renderItem(v)} fetchBank={fetchUser} />
                            </TabPane>
                            <TabPane tab="Education" key="education">
                                <Education emp_code={user.employee_code} data={user.education} renderItem={(v) => renderItem(v)} />
                            </TabPane>
                            <TabPane tab="Family" key="family">
                                <Family emp_code={user.employee_code} data={user.family} renderItem={(v) => renderItem(v)} />
                            </TabPane>
                            {
                                user.marital_status === 'MSM' ?
                                    <TabPane tab="Spouse" key="spouse">
                                        <Spouse emp_code={user.employee_code} spouse={user.spouse} children={user.children} renderItem={(v) => renderItem(v)} />
                                    </TabPane>
                                    : null
                            }
                            <TabPane tab="Work Experience" key="workExperience">
                                <WorkExperience emp_code={user.employee_code} data={user.experience} renderItem={(v) => renderItem(v)} />
                            </TabPane>
                            <TabPane tab="Language" key="language">
                                <Language emp_code={user.employee_code} data={user.language_skill} renderItem={(v) => renderItem(v)} />
                            </TabPane>
                            <TabPane tab="Skill" key="skill">
                                <Skill emp_code={user.employee_code} data={user.skill} renderItem={(v) => renderItem(v)} />
                            </TabPane>
                            <TabPane tab="Training and Certification" key="certification">
                                <Certification emp_code={user.employee_code} data={user.certification} renderItem={(v) => renderItem(v)} />
                            </TabPane>
                            <TabPane tab="Medical" key="medical">
                                <Medical emp_code={user.employee_code} data={user.medical} renderItem={(v) => renderItem(v)} />
                            </TabPane>
                            <TabPane tab="Swab/PCR" key="swab">
                                <Swab emp_code={user.employee_code} data={user.swab} renderItem={(v) => renderItem(v)} />
                            </TabPane>
                            <TabPane tab="Document" key="document">
                                <Document emp_code={user.employee_code} data={user.document} renderItem={(v) => renderItem(v)} updateUser={(v) => updateUser(v)} />
                            </TabPane>
                            <TabPane tab="Social Media" key="socialMedia">
                                <SocialMedia emp_code={user.employee_code} data={user.social_media} renderItem={(v) => renderItem(v)} updateUser={(v) => updateUser(v)} />
                            </TabPane>
                            <TabPane tab="History" key="history">
                                <History emp_code={user.employee_code} data={user} />
                            </TabPane>
                            {
                                !employeeCode ?
                                    <TabPane tab="Change Password" key="changePassword">
                                        <ChangePassword emp_code={user.employee_code} data={user} />
                                    </TabPane>
                                    : null
                            }
                        </Tabs>
                        : <Spinner />
                }
            </Card>
        </div>
    );
}

export default Index;
