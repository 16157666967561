import React from 'react';
import {Tag, Badge} from 'antd'

const Status = (props) => {

    const getStatus = (status) => {
        const {blink=false} = props
        if(status==="WAITING"){
            return <Tag style={{color:'#808080',width:120}}>
                        {
                            blink ? 
                                <Badge dot className="blinking-badge">
                                    WAITING
                                </Badge>
                            : 'WAITING'
                        }
                    </Tag>
        }else if(status==="APPROVED"){
            return <Tag color="green" style={{width:120}}>APPROVED</Tag>
        }else if(status==="REJECTED"){
            return <Tag color="red" style={{width:120}}>REJECTED</Tag>
        }
    }
    return (
        <div>
            {getStatus(props.status)}
        </div>
    );
}

export default Status;
