import { useState, useEffect } from 'react'
import { Button, Card, Form, Input, InputNumber, Select, Space, Switch, Tag, message } from 'antd'
import { DataTable, DeleteButton, EditButton, FormModal, PageTitle } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import { formatterNumber, parserNumber } from '../../../helpers/currency'

const { Option } = Select

const Index = () => {
    const [form] = Form.useForm()
    const [comission, setComission] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [status, setStatus] = useState(false)

    const fetchMasterComission = () => {
        SentralModel.action("Comission", "getMasterComission", null, null).then((res) => {
            setLoaded(true)
            setComission(res.data)
        })
    }

    useEffect(() => {
        fetchMasterComission()
    }, [])

    const columns = [
        {
            title: "No",
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category'
        },
        {
            title: 'Sub Category',
            dataIndex: 'sub_category',
            key: 'sub_category'
        },
        {
            title: 'Customer Status',
            key: 'customer_status',
            render: (row) => (
                row?.customer_status === null ? "-" : 
                row?.customer_status === "renewal" ? "Renewal" : 
                row?.customer_status === "new_account" ? "New Customer" : 
                row?.customer_status === "government" ? "Government" : "SMB"
            ),
        },
        {
            title: 'Rental Month',
            key: 'rental_month',
            render: (row) => (
                <div className="text-center">
                    {row.rental_month ?? '-'}
                </div>
            ),
        },
        {
            title: 'Sales Comission',
            key: 'sales_comission',
            render: (row) => (
                `Rp. ${formatterNumber(row.sales_comission)}`
            ),
        },
        {
            title: 'Status',
            key: 'is_active',
            dataIndex: 'is_active',
            render: (text) => (
                <div className="text-center">
                    <Tag color={text === '1' ? "green" : "red"}>{(text === '1') ? 'Active' : 'Inactive'}</Tag>
                </div>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (row) => (
                <Space>
                    <EditButton onEdit={() => onShowEditModal(row)} />
                    <DeleteButton onConfirm={() => onDelete(row.master_comission_id)} />
                </Space>
            ),
        },
    ]

    const onShowAddModal = () => {
        setShowModal(true)
        setModalTitle('Add Master Comission')
        form.setFieldsValue({
            master_comission_id: 0,
            category: null,
            sub_category: null,
            customer_status: null,
            rental_month: null,
            sales_comission: null,
            is_active: false,
        })
        setStatus(false)
    }

    const onShowEditModal = (v) => {
        setShowModal(true)
        setModalTitle('Edit Master Comission')
        form.setFieldsValue({
            master_comission_id: v.master_comission_id,
            category: v.category,
            sub_category: v.sub_category,
            customer_status: v.customer_status,
            rental_month: v.rental_month,
            sales_comission: v.sales_comission,
            is_active: v.is_active === '1' ? true : false,
        })
        setStatus(v.is_active === '1' ? true : false)
    }

    const onDelete = (v) => {
        SentralModel.action("Comission", "deleteMaster", v, 0).then((res) => {
            message.success("Success Delete Master Comission")
            fetchMasterComission()
        }).catch((err) => {
            message.warning("Failed Delete Master Comission")
        })
    }

    const onFinish = (v) => {
        let payload = {
            ...v,
            is_active: status ? '1' : '0'
        }

        SentralModel.action("Comission", "saveMaster", payload, v.master_comission_id === 0 ? 0 : v.master_comission_id)
            .then((res) => {
                if (v.master_comission_id === 0) {
                    message.success("Success Add Master Comission")
                } else {
                    message.success("Success Edit Master Comission")
                }
                fetchMasterComission()
                setShowModal(false)
            }).catch((err) => {
                if (v.master_comission_id === 0) {
                    message.warning("Failed Add Master Comission")
                } else {
                    message.warning("Failed Edit Master Comission")
                }
            })
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Master Comission" breadcrumbs={[['Master Comission']]}></PageTitle>
                <Card title={
                    <Button type="primary" onClick={onShowAddModal}>
                        Add Master Comission
                    </Button>
                }>
                    {
                        loaded ?
                            <DataTable bordered size="small" dataSource={comission} columns={columns} />
                            : null
                    }
                </Card>

                {
                    showModal ?
                        <FormModal form={form} submitForm={onFinish} title={modalTitle} showModal={showModal} onCloseModal={() => setShowModal(false)} width={700}>
                            <Form.Item name="master_comission_id" hidden>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Category" name="category" rules={[{ required: true }]}>
                                <Input placeholder='Category' />
                            </Form.Item>
                            <Form.Item label="Sub Category" name="sub_category" rules={[{ required: true }]}>
                                <Input placeholder='Sub Category' />
                            </Form.Item>
                            <Form.Item label="Customer Status" name="customer_status">
                                <Select placeholder="Select Customer Status">
                                    <Option value="renewal">Renewal</Option>
                                    <Option value="new_account">New Account</Option>
                                    <Option value="government">Government</Option>
                                    <Option value="smb">SMB</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item label="Rental Month" name="rental_month" rules={[{ required: false }]}>
                                <InputNumber
                                    style={{ width: "50%" }}
                                    min={0}
                                />
                            </Form.Item>
                            <Form.Item label="Sales Comission" name="sales_comission" rules={[{ required: true }]}>
                                <InputNumber
                                    style={{ width: "50%" }}
                                    formatter={(value) => formatterNumber(value)}
                                    parser={(value) => parserNumber(value)}
                                />
                            </Form.Item>
                            <Form.Item label="Status" name="is_active" rules={[{required:true}]}>
                                <Switch checkedChildren="Active" unCheckedChildren="Inactive" onChange={(v) => setStatus(v)} defaultChecked={status} />
                            </Form.Item>
                        </FormModal>
                        : null
                }
            </Card>
        </div>
    )
}

export default Index