import React from 'react';
import { Card, Tabs } from 'antd'
import { FieldTimeOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { DataTable, ShowButton } from '../../../components'
import { toFullDate } from '../../../utils/dateHandler'

const { TabPane } = Tabs;

const Index = (props) => {
    const leaveColumn = [{
        title: 'No',
        dataIndex: 'no',
        align: 'center',
        key: 'name',
    },
    {
        title: 'Employee',
        dataIndex: 'name',
        key: 'name'
    }, {
        title: 'Date',
        render: (row) => (
            <div>
                {
                    (row.total_days === 1) ?
                        <span>{toFullDate(row.from_date)}</span>
                        : <span>{toFullDate(row.from_date)} - {toFullDate(row.to_date)}</span>
                }
                {
                    (row.cancellation_status === 'APPROVED') ? <span style={{ color: 'red' }}> <br />cancelled</span> : ''
                }
            </div>
        )
    }, {
        title: 'Necessity',
        dataIndex: 'reason',
        key: 'reason',
    }, {
        title: 'Action',
        width: '10%',
        render: (row) => (
            <div className="text-center">
                <ShowButton link to={'/submission-list/leave-realization/' + row.id} />
            </div>
        )
    }];

    const leaveData = [
        ...props.data.map((el) => {
            return {
                id: el.request_id,
                name: el.name,
                leave_type: el.description,
                from_date: el.from_date,
                to_date: el.to_date,
                total_days: el.total_days,
                reason: el.reason,
                division_name: el.division_name,
                status: el.status,
                cancellation_status: el.cancellation_status,
                submitted_at: el.submitted_at
            }
        })
    ]

    return (
        <Card>
            <Tabs defaultActiveKey="waiting">
                <TabPane tab={<span><FieldTimeOutlined style={{ color: '#4ca3ff' }} /> Waiting Approval</span>} key="waiting">
                    <DataTable size="small" columns={leaveColumn} dataSource={leaveData.filter(el => el.status === 'WAITING')} bordered />
                </TabPane>
                <TabPane tab={<span><CheckCircleOutlined style={{ color: 'green' }} /> Approved</span>} key="approved">
                    <DataTable size="small" columns={leaveColumn} dataSource={leaveData.filter(el => el.status === 'APPROVED')} bordered />
                </TabPane>
                <TabPane tab={<span><CloseCircleOutlined style={{ color: 'red' }} /> Rejected</span>} key="rejected">
                    <DataTable size="small" columns={leaveColumn} dataSource={leaveData.filter(el => el.status === 'REJECTED')} bordered />
                </TabPane>
            </Tabs>
        </Card>
    );
}

export default Index;
