import React, { useState, useEffect } from 'react';
import { Tag, Button, Space, Modal, Typography, Input, message } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { formatDateTime } from '../../utils/dateFormat'
import authData from '../../helpers/authData'
import { SentralModel } from '../../models/SentralModel';

const { TextArea } = Input
const { Text } = Typography
const ApprovalLog = (props) => {
    const { approvers = [] } = props
    const [approvalStatus, setApprovalStatus] = useState("approved")
    const [approvalModal, setApprovalModal] = useState(false)
    const [remark, setRemark] = useState(null)
    const [saving, setSaving] = useState(false)
    const [approveButton, setApproveButton] = useState(false)
    const [approverLevel, setApproverLevel] = useState(null)

    useEffect(() => {
        let empLevel = 0;
        let isSet = false;
        approvers.forEach((el) => {
            if (el.employee_code === authData.employee_code.toString()) {
                if (isSet === false) {
                    empLevel = el.level - 1
                    setApproverLevel(el.level)
                    if (el.status === 'WAITING') {
                        isSet = true
                    }
                }
            }
        })
        if (empLevel === 0 && approvers[0].status === "WAITING") {
            setApproveButton(true)
        } else if (empLevel !== 0 && approvers[empLevel - 1].status === "APPROVED" && approvers[empLevel].status === "WAITING") {
            setApproveButton(true)
        } else {
            setApproveButton(false)
        }
    }, [approvers]);

    const getStatus = (status) => {
        if (status === "WAITING") {
            return <Tag style={{ color: '#808080', width: 120 }}>Waiting Approval</Tag>
        } else if (status === "APPROVED") {
            return <Tag color="green" style={{ width: 120 }}>APPROVED</Tag>
        } else if (status === "REJECTED") {
            return <Tag color="red" style={{ width: 120 }}>REJECTED</Tag>
        }
    }

    const changeRemark = (v) => {
        setRemark(v.target.value)
    }

    const submitApproval = () => {
        setSaving(true)
        const approvalData = {
            request_id: props.requestId,
            category: props.category,
            employee_code: authData.employee_code,
            status: approvalStatus,
            remarks: remark,
            level: approverLevel
        }
        SentralModel.action('Approval', 'setStatus', approvalData, 0).then((res) => {
            setSaving(false)
            setApprovalModal(false)
            message.success("Submission status updated", 2)
            props.onSubmitted()
        }).catch(() => {
            setApprovalModal(false)
            setSaving(false)
        })
    }

    const setApproval = (status) => {
        setApprovalStatus(status)
        setApprovalModal(true)
    }
    return (
        <div>
            <div className="full-width" >
                <Text strong style={{ float: 'left' }}>APPROVAL LOGS</Text>
                {
                    approveButton ?
                        <Space className="mb-3" style={{ float: 'right' }}>
                            <Button type="primary" onClick={() => setApproval("approved")} icon={<CheckCircleOutlined />}>APPROVE</Button>
                            <Button type="danger" onClick={() => setApproval("rejected")} icon={<CloseCircleOutlined />}>REJECT</Button>
                        </Space>
                        : null
                }
            </div>
            <table className="table-collapse table-default" style={{ width: '100%' }}>
                <thead>
                    <tr>
                        <th className="text-center" style={{ width: '5%' }}>Level</th>
                        <th style={{ width: '40%' }}>Name</th>
                        <th className="text-center" style={{ width: '30%' }}>Status</th>
                        <th className="text-center" style={{ width: '25%' }}>Submit Date</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        approvers.map((el, key) => (
                            <tr key={key}>
                                <td className="text-center">{el.level}</td>
                                <td>{el.employee.name} {props.category === 'LEAVE' && el.level === 1 && el.employee.name === props.responsible ? '(Responsible)' : ''}</td>
                                <td className="text-center" >
                                    {
                                        getStatus(el.status)
                                    }
                                    <br />
                                    <span>{el.remarks}</span>
                                </td>
                                <td className="text-center" >{el.submitted_at == null ? '-' : formatDateTime(el.submitted_at)}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            <Modal
                title={(approvalStatus === "approved") ? "Approve Request" : "Reject Request"}
                visible={approvalModal}
                onCancel={() => setApprovalModal(false)}
                footer={
                    <div className="full-width text-right">
                        <Button onClick={() => setApprovalModal(false)}>Cancel</Button>
                        <Button loading={saving} type={(approvalStatus === "approved") ? "primary" : "danger"} onClick={() => submitApproval(false)}>{(approvalStatus === "approved") ? "Approve" : "Reject"}</Button>
                    </div>
                }>
                {/* <Text strong level={4}>Request Number : RQ.000.000-09/5456</Text> */}
                <TextArea placeholder="Write a remark" rows={2} onChange={changeRemark} />
            </Modal>
        </div>
    );
}

export default ApprovalLog;
