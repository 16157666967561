import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { getDatesInRange } from "../../../helpers/dateRange";
import authData from '../../../helpers/authData';

const dailyActivitySlice = createSlice({
    name: 'dailyActivity',
    initialState: {
        loaded: false,
        loadedSummary: false,
        isTicketPresales: false,
        isMilestoneTask: false,
        disabled: false,
        uploading: false,

        // Modal
        modalAddTask: false,
        modalAddTaskTitle: "",
        editProgressTaskModal: false,
        editProgressModalTitle: "",
        editProgressDailyModal: false,
        modalCompany: false,
        modalConfirm: false,
        modalAddTaskProgress: false,
        titleModalCompany: "",

        myTasks: [],
        assignTasks: [],
        measurements: [],
        taskProgressData: [],
        multipleAssign: [],
        diffDate: [],
        dailyProgressData: [],

        tab: "in",
        confirmFinishedValue: "",
        defaultStat: "",
        action: "",
        category: "TASK/DAILY ACTIVITY",

        divisionId: 0,
        myProgressPercentage: 0,
        percentageTask: 0,
        progressValue: 0,

        ticketInfo: {},
        defaultValues: { job_id: 0, finished: false },
        defaultValueEdit: {},
        saveDataTask: {},
        showAttachmentFinished: null,
        attachmentInfo: null,
        attachmentFile: null,
        attachmentConfirm: null,
        remarkFinished: null
    },
    reducers: {
        changeTab: (state, action) => {
            state.tab = action.payload
        },
        changeLoaded: (state, action) => {
            state.loaded = action.payload
        },
        changeLoadedSummary: (state, action) => {
            state.loadedSummary = action.payload
        },
        changeTaskData: (state, action) => {
            const data = action.payload

            if (data.my_task.length > 0) {
                data.my_task.forEach((el) => {
                    state.myTasks.push(el)
                })
            } else {
                state.myTasks = []
            }

            if (data.assign_task.length > 0) {
                data.assign_task.map((el) => {
                    return state.assignTasks.push(el)
                })
            } else {
                state.assignTasks = []
            }

            state.loaded = true
        },
        changeMeasurementData: (state, action) => {
            const res = action.payload
            const data = res.filter((el) => el.measurement_id === 1 || el.measurement_id === 6)
            state.measurements.push(data)
        },
        disableDate: (state, action) => {
            let currentMonth = moment().format('MM');
            let addMonth = 1;

            if (currentMonth === '12') {
                addMonth = 2;
            }

            let customDate = moment(new Date()).add(addMonth, 'y').format('YYYY');
            return action.payload && action.payload > moment(customDate, "YYYY");
        },
        changeDivision: (state, action) => {
            state.divisionId = action.payload
        },
        changeCategory: (state, action) => {
            state.category = action.payload
        },
        fileUploaded: (state, action) => {
            const v = action.payload
            state.attachmentFile = v.message
            state.uploading = true
        },
        fileUploadFinish: (state, action) => {
            const v = action.payload
            state.attachmentConfirm = v.message
            state.uploading = true
        },
        changeUploading: (state, action) => {
            state.uploading = action.payload
        },
        changeRemark: (state, action) => {
            state.remarkFinished = action.payload
        },
        showModalAddCompany: (state, action) => {
            state.modalCompany = true
            state.titleModalCompany = 'Add Company'
        },
        closeModalAddCompany: (state, action) => {
            state.modalCompany = false
        },
        showModalAddTask: (state, action) => {
            state.divisionId = 0
            state.modalAddTask = true
            state.action = 'add'
            state.defaultValues = {
                emp_task_id: 0,
                category: state.category,
                employee_code: authData.employee_code,
                description: null,
                deadline: null
            }
            state.disabled = false
            state.modalAddTaskTitle = "Add Daily Task"
        },
        closeModalAddTask: (state, action) => {
            state.modalAddTask = false
        },
        showModalConfirm: (state, action) => {
            const v = action.payload
            state.modalConfirm = true
            state.saveDataTask = {
                ...v
            }
        },
        closeModalConfirm: (state, action) => {
            state.modalConfirm = false
        },
        addTaskProgress: (state, action) => {
            const v = action.payload
            let arr = []
            let percentage = 0
            let myPercentage = 0

            arr.push({
                all_progress_task_id: 0,
                job_description: v.job_description,
                remarks: v.remarks,
                progress: state.progressValue,
                progress_date: moment(Date.now()).format("YYYY-MM-DD"),
                employee_code: authData.employee_code
            })

            if (state.taskProgressData.length > 0) {
                const newData = state.taskProgressData.concat(arr)
                newData.forEach((el) => {
                    if (el.employee_code === authData.employee_code) {
                        myPercentage += el.progress
                    }
                    percentage += el.progress
                })

                state.percentageTask = percentage / state.multipleAssign.length
                state.myProgressPercentage = myPercentage
                state.taskProgressData = newData
            } else {
                arr.forEach((el) => {
                    percentage += el.progress
                    myPercentage += el.progress
                })

                state.percentageTask = percentage / state.multipleAssign.length
                state.myProgressPercentage = myPercentage
                state.taskProgressData = arr
            }

            state.modalAddTaskProgress = false
        },
        onClickAddProgress: (state, action) => {
            state.modalAddTaskProgress = true
            state.progressValue = 0
        },
        onClickShowModalAddProgress: (state, action) => {
            state.modalAddTaskProgress = action.payload
        },
        editTask: (state, action) => {
            const v = action.payload.data
            const stat = action.payload.stat

            if (v.data_from === "MILESTONE TASK") {
                state.isMilestoneTask = true
            } else {
                state.isMilestoneTask = false
            }

            state.defaultValues = {
                all_app_task_id: v.id,
                emp_task_id: v.task_id,
                employee_code: v.created_by,
                category: state.category,
                category_task_code: v.category_task_code,
                milestone_id: v.milestone_id,
                milestone_task_id: v.milestone_task_id,
                data_from: v.data_from,
                task_to: v.assign_to,
                description: v.description,
                remarks_presales: v.progress[0]?.job_description,
                start_date: moment(v.start_date),
                deadline: moment(v.due_date),
                note: v.reply,
                finished_date: v.finished_date === null ? null : moment(v.finished_date),
                status: v.status === 'Finished' ? true : false,
                confirm_finished: v.confirm_finished,
                attachment_clause: v.attachment_clause,
                remark_finished: v.remark_finished
            }

            state.showAttachmentFinished = v.finished_attachment
            state.confirmFinishedValue = v.confirm_finished
            state.attachmentInfo = v.attachment
            v.progress?.forEach((el) => {
                state.taskProgressData.push(el)
            })
            state.defaultStat = stat
            state.editProgressTaskModal = true
            state.editProgressModalTitle = "Update Progress Task"

            let progress = 0
            let myProgress = 0
            let arrAssign = []
            v.progress.forEach((el) => {
                if (el.employee_code === authData.employee_code) {
                    myProgress += el.progress
                }

                progress += el.progress
            })

            v.multiple_task_to.forEach((el) => {
                arrAssign.push(el)
            });

            let progressAverage = progress / parseInt(v.multiple_task_to.length)
            // let progressAverage = progress
            state.myProgressPercentage = myProgress
            state.percentageTask = progressAverage
            state.multipleAssign = arrAssign
            const date1 = new Date(v.start_date);
            const date2 = (Date.parse(moment(new Date()).format('YYYY-MM-DD')) > Date.parse(moment(v.due_date).format('YYYY-MM-DD'))) ? new Date() : new Date(v.due_date);
            const dates = getDatesInRange(date1, date2)
            state.diffDate = dates
        },
        closeEditTask: (state, action) => {
            state.editProgressTaskModal = false
        },
        editDaily: (state, action) => {
            const v = action.payload.data
            const stat = action.payload.stat

            state.defaultValueEdit = {
                all_app_task_id: v.id,
                emp_task_id: v.task_id,
                employee_code: v.created_by,
                category: state.category,
                data_from: v.data_from,
                task_to: v.assign_to,
                description: v.description,
                start_date: moment(v.start_date),
                deadline: moment(v.due_date),
                note: v.reply,
                finished_date: v.finished_date === null ? null : moment(v.finished_date),
                status: v.status === 'Finished' ? true : false,
            }

            state.dailyProgressData.push(v.progress);

            state.defaultStat = stat
            state.attachmentInfo = v.attachment
            state.editProgressDailyModal = true
            state.editProgressModalTitle = "Update Daily Activity"
        },
        closeEditDaily: (state, action) => {
            state.editProgressDailyModal = false
        }
    }
})

export const {
    changeTab,
    changeLoaded,
    changeLoadedSummary,
    changeTaskData,
    changeMeasurementData,
    disableDate,
    changeDivision,
    changeCategory,
    fileUploaded,
    fileUploadFinish,
    changeUploading,
    changeRemark,
    showModalAddCompany,
    closeModalAddCompany,
    showModalAddTask,
    closeModalAddTask,
    showModalConfirm,
    addTaskProgress,
    onClickAddProgress,
    onClickShowModalAddProgress,
    closeModalConfirm,
    editTask,
    closeEditTask,
    editDaily,
    closeEditDaily
} = dailyActivitySlice.actions

export default dailyActivitySlice.reducer