import React, { useEffect, useState } from 'react';
import { Layout, Affix, Avatar, Menu } from 'antd';
import { Link } from 'react-router-dom'
import { managementMenus, submissionMenus, payrollMenus, settingMenus, documentationMenus, organizationMenus, dashboardMenus, reportMenus, generalAffairMenus, actionPlanMenus, dashboardHrMenus, itInfraMenu } from './Header/MenuArray'
import Icon from '../components/Icon'
import authData from '../helpers/authData'
import { decrypt } from '../helpers/crypto'
import currentUser from '../helpers/authData';

const { Sider } = Layout;
const { SubMenu } = Menu

const SideBar = (props) => {
    const [collapsed, setCollapsed] = useState(true)

    const role = localStorage.getItem('role') ? JSON.parse(decrypt(localStorage.getItem('role'))) : []

    const getMenu = (menu, title, to) => {
        return (
            (!collapsed) ?
                <Link to={to} key={to}>
                    <Icon name={menu} /> {title}
                </Link>
                :
                <Link to={to} key={to}><Icon name={menu} /></Link>
        )
    }
    const checkRoles = (access) => {

        if (access.includes("USER")) return true

        if (role.includes("SUPERADMIN")) return true

        return role.some(function (el) {
            return access.includes(el)
        })
    }
    const isSelectedMenu = (menu) => {
        let x = sessionStorage.getItem('selectedMenu')
        if (x) {
            return (x === menu) ? 'ant-menu-submenu-active' : ''
        } else {
            return ''
        }
    }
    const generateMenu = (menu, name) => {

        for (var key in menu) {
            if (menu[key].name === 'Exit Clearance' && (authData ? authData.employee_status : '') !== 'ESRS') {
                menu.splice(menu[key], 1);
            } else if (menu[key].name === 'Extension' && !role.includes('SUPERADMIN') && !role.includes('INFRA')) {
                menu.splice(key, 1);
            } else if (menu[key].name === 'Employee') {
                for (var index in menu[key].items) {
                    if (menu[key].items[index].name === 'Medical Allowance' && authData.employee_code !== 'SMI20100628033' && authData.employee_code !== 'SMI20220323229') {
                        menu[key].items.splice(index, 1);
                    }
                }
                // } else if (menu[key].name === 'KPI' && (authData?.employee_code ?? '') !== 'SMI20200622202' && (authData?.employee_code ?? '') !== 'SMI20220323229' && (authData?.employee_code ?? '') !== 'SMI20100628033') {
                //     menu.splice(key, 1);
            } else if (menu[key].name === 'Overtime' && (authData?.employee_grade ?? '') >= '3A') {
                menu.splice(key, 1);
            } else if (menu[key].key === 'report-menu-kpi' && !role.includes('HR')) {
                menu.splice(key, 1);
            } else if (menu[key].key === 'management-menu-comission' && (authData?.employee_code ?? '') === 'SMI20210607185') {
                menu.splice(key, 1);
            } else if (menu[key].key === 'management-menu-comission-head' && (authData?.employee_code ?? '') !== 'SMI20210607185') {
                menu.splice(key, 1);
            }
        }

        return (
            menu.map((el, i) => (
                (checkRoles(el.role) || (el.key === 'report-menu-daily-activity' && (authData.employee_code === 'SMI20150601108' || authData.employee_code === 'ADM001'))) ? (
                    el.items ?
                        <SubMenu className="sider-submenu" title={el.name} icon={el.icon} key={name + '_menu_' + i}>
                            {
                                el.items.map((submenu, index) => (
                                    (checkRoles(submenu.role)) ?
                                        <Menu.Item className="sider-submenu" key={name + '_submenu_' + index + el.key}>
                                            <Link to={submenu.to} key={name + 'sub_menu_' + index + el.key} component={submenu.component}>{submenu.icon} {submenu.name}</Link>
                                        </Menu.Item>
                                        : null
                                ))
                            }
                        </SubMenu>
                        :
                        <Menu.Item className="sider-submenu" key={name + '_menu_' + i + el.key}>
                            <Link to={el.to} component={el.component}>{el.icon} {el.name}</Link>
                        </Menu.Item>
                ) : null

            ))
        )
    }

    const collapseMenu = (v) => {
        if (props.display === 'auto') {
            localStorage.setItem('menu-collapsed', JSON.stringify(v))
            setCollapsed(v)
        }
    }

    // const lockMenu = (v) => {
    //     setLocked(v)
    //     localStorage.setItem('menu-locked', JSON.stringify(v))
    // }

    useEffect(() => {
        // let defaultLocked = localStorage.getItem('menu-locked')
        // setLocked(defaultLocked==='true')
        // let defaultCollapsed = localStorage.getItem('menu-collapsed')
        // if(!defaultCollapsed){
        //     setCollapsed(true)
        // }else{
        //     setCollapsed(defaultCollapsed==='true')
        // }
        if (props.display === 'collapsed' || props.display === 'auto') {
            setCollapsed(true)
        } else {
            setCollapsed(false)
        }
    }, [props])

    return (
        <div>
            <Affix offsetTop={0}>
                <Sider
                    className="sider-shadow scrollbar-custom"
                    theme="dark"
                    onMouseEnter={() => collapseMenu(false)}
                    onMouseLeave={() => collapseMenu(true)}
                    collapsed={props.display === 'collapsed' || (props.display === 'auto' && collapsed)}
                    trigger={null}
                    collapsible
                    width="200px"
                    style={{ padding: '0px', height: '100vh', overflow: 'auto', paddingTop: '15px', paddingLeft: '0px', textAlign: 'center', backgroundColor: '#004995' }}
                >
                    <Link to="/">
                        {
                            (collapsed) ?
                                <Avatar shape="square" src={process.env.PUBLIC_URL + '/images/logo-white.png'} style={{ height: 50, width: 50, borderRadius: '5px' }} />
                                :
                                <img alt="logo-teamwork" src={process.env.PUBLIC_URL + '/images/teamwork-white.png'} style={{ height: 100 }} />
                        }
                    </Link>
                    <Menu theme="dark" mode="inline" style={{ minHeight: '100vh', paddingTop: '10px', backgroundImage: 'linear-gradient(180deg, #004995, #3495e5)' }}>
                        {
                            role.includes("SUPERADMIN") || role.includes("HR") ?
                                <SubMenu icon={<Icon name="home" />} key="home" title={(!collapsed) ? " Dashboard" : null}>
                                    {generateMenu(dashboardMenus, 'dashboard')}
                                </SubMenu> :
                                <Menu.Item key="home" title="Dashboard">
                                    {getMenu("dashboard", "Dashboard", "/")}
                                </Menu.Item>
                        }

                        {
                            currentUser && (currentUser?.employee_code === 'SMI20150601108' || currentUser?.employee_code === 'ADM001') ?
                                <Menu.Item key="workingLocation" title="Dashboard Working Location">
                                    {getMenu("report", "Dashboard Working Location", "/working-location-dashboard")}
                                </Menu.Item> : null
                        }

                        {
                            role.includes("SUPERADMIN") || role.includes("HR") ?
                                <SubMenu icon={<Icon name="home" />} key="dashboardHr" title={(!collapsed) ? " Dashboard HR" : null}>
                                    {generateMenu(dashboardHrMenus, 'dashboardHr')}
                                </SubMenu>
                                : null
                        }

                        {
                            (currentUser && (currentUser?.employee_code === "SMI20220204225" || currentUser?.employee_code === "SMI20220907242" || currentUser?.employee_code === "SMI20190102153" || currentUser?.employee_code === "SMI20150601108")) || currentUser?.division_id === 25 ?
                                <SubMenu icon={<Icon name="itInfra" />} key="itInfraMenu" title={(!collapsed) ? " IT Infra" : null}>
                                    {generateMenu(itInfraMenu, 'itInfraMenu')}
                                </SubMenu>
                                : null
                        }

                        {
                            currentUser && (currentUser?.employee_code === 'SMI20210607185' || currentUser?.employee_code === 'ADM001') ?
                                <Menu.Item key="comissionList" className={isSelectedMenu('comissionList')} title="Comission List">
                                    {getMenu("document", "Comission List", "/comission-list")}
                                </Menu.Item>
                                : null
                        }

                        <Menu.Item key="taskApproval" className={isSelectedMenu('approval')} title="Task & Approval">
                            {getMenu("approvalCenter", "Task & Approval", "/submission-list")}
                        </Menu.Item>

                        <Menu.Item key="referralInputData" className={isSelectedMenu('referral')} title="Referral">
                            {getMenu("submission", "Referral", "/referral")}
                        </Menu.Item>

                        {
                            authData ?
                                ["SMI20000101001", "SMI20000101002", "SMI20000101003", "SMI20100628033", "SMI20150601108"].includes(authData.employee_code)
                                    ||
                                    authData.division_id === 22 || authData.division_id === 25 || authData.division_id === 36 ?
                                    <Menu.Item key="sales_achivement" className={isSelectedMenu('referral')} title="Sales Achivement">
                                        {getMenu("report", "Sales Achivement", "/sales-achivement")}
                                    </Menu.Item> : null
                                : null
                        }

                        <SubMenu icon={<Icon name="submission" />} key="submission" title={(!collapsed) ? " Submission" : null}>
                            {generateMenu(submissionMenus, 'submission')}
                        </SubMenu>
                        <SubMenu icon={<Icon name="document" />} key="document" title={(!collapsed) ? " Document" : null}>
                            {generateMenu(documentationMenus, 'document')}
                        </SubMenu>
                        {
                            role.includes("SUPERADMIN") || role.includes("HR") ?
                                <Menu.Item key="reward_punishment" title="Reward & Punishment">
                                    {getMenu("users", "Reward & Punishment", "/list-reward-punishment")}
                                </Menu.Item>
                                : null
                        }
                        {
                            role.includes("SUPERADMIN") || role.includes("HEAD") || role.includes("HR") ?
                                <SubMenu icon={<Icon name="management" />} key="management" title={(!collapsed) ? " Management" : null}>
                                    {generateMenu(managementMenus, 'management')}
                                </SubMenu>
                                : null
                        }
                        <SubMenu icon={<Icon name="payroll" />} key="payroll" title={(!collapsed) ? " Payroll" : null}>
                            {generateMenu(payrollMenus, 'payroll')}
                        </SubMenu>
                        <SubMenu icon={<Icon name="users" />} key="organization" title={(!collapsed) ? " Organization" : null}>
                            {generateMenu(organizationMenus, 'organization')}
                        </SubMenu>
                        {
                            role.includes("SUPERADMIN") || role.includes("HR") ?
                                <SubMenu icon={<Icon name="setting" />} key="setting" title={(!collapsed) ? " Setting" : null}>
                                    {generateMenu(settingMenus, 'setting')}
                                </SubMenu>
                                : null
                        }
                        {
                            role.includes("GA") || role.includes("SUPERADMIN") ?
                                <SubMenu icon={<Icon name="general-affair" />} key="general-affair" title={(!collapsed) ? " General Affair" : null}>
                                    {generateMenu(generalAffairMenus, 'general-affair')}
                                </SubMenu>
                                : null
                        }
                        {
                            role.includes("SUPERADMIN") || role.includes("HR") || role.includes("GA") || role.includes("ACC") || role.includes("OPR") || (currentUser && currentUser?.employee_code === 'SMI20131021081') ?
                                <SubMenu icon={<Icon name="report" />} key="report" title={(!collapsed) ? " Report" : null}>
                                    {generateMenu(reportMenus, 'report')}
                                </SubMenu>
                                : null
                        }
                        {
                            currentUser &&
                                (
                                    (
                                        currentUser?.division_id === 21 || currentUser?.division_id === 22 || currentUser?.division_id === 36
                                    )
                                    ||
                                    (
                                        (
                                            currentUser?.division_id === 13 || currentUser?.division_id === 24 || currentUser?.division_id === 25 || currentUser?.division_id === 34 || currentUser?.division_id === 33
                                        )
                                        &&
                                        (
                                            currentUser?.position_id === 16 || currentUser?.position_id === 112 || currentUser?.position_id === 94 || currentUser?.position_id === 78 || currentUser?.position_id === 55 || currentUser?.position_id === 93 || currentUser?.position_id === 52 || currentUser?.position_id === 30 ||
                                            currentUser?.position_id === 125 || currentUser?.position_id === 42 || currentUser?.position_id === 23 || currentUser?.position_id === 124
                                        )
                                    )
                                    ||
                                    (
                                        currentUser?.division_id === 34 && currentUser?.position_id === 123
                                    )
                                ) ?
                                <SubMenu icon={<Icon name="actionPlan" />} key="actionPlan" title={(!collapsed) ? " Action Plan" : null}>
                                    {generateMenu(actionPlanMenus, 'actionPlan')}
                                </SubMenu>
                                : null
                        }
                    </Menu>
                </Sider>
            </Affix>
        </div>
    );
}

export default SideBar;
