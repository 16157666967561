import React, {useEffect, useState} from 'react';
import {Card, Typography, message, Space, Tabs, Form, Input, DatePicker, TimePicker} from 'antd'
import {EditButton, CustomButton, PageTitle, Spinner, DataTable, FormModal} from '../../../components'
import {ClockCircleOutlined, CheckCircleOutlined, DownloadOutlined} from '@ant-design/icons'
import {SentralModel} from '../../../models/SentralModel'
import moment from 'moment'
import authData from '../../../helpers/authData'

const {Text} = Typography
const {TabPane} = Tabs;

const Index = () => {
    const [crfs, setCRF] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [tab, setTab] = useState('waiting');
    const [formModal, setFormModal] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [saving, setSaving] = useState(false)

    const fetchChangeRequestForms = () => {
        SentralModel.list('ChangeRequestForm', {filter: [authData.division_id !== 24 ? ['division_id', authData.division_id] : []], orderBy: 'change_request_form_code', order: 'desc'}).then(res=>{
            setLoaded(false)
            setCRF(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchChangeRequestForms()
    }, []);
    
    const dataSource = [
        ...crfs.map( el => {
            return {
                id: el.change_request_form_id,
                requester_name: el.employee.name,
                crf_code: el.change_request_form_code,
                email: el.employee.work_email,
                extension_no: el.employee.extension_no,
                request_date: el.request_date,
                status: el.status,
                uat_date: el.uat_date,
                uat_time: el.uat_time
            }
        })
    ];

    const column = [
        {
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'no',
        },
        {
            title: 'Requester Name',
            dataIndex: 'requester_name',
            key: 'requester_name',
        },
        {
            title: 'CRF Code',
            dataIndex: 'crf_code',
            key: 'crf_code',
        },
        {
            title: 'Email / Ext',
            render: (row) => (
                <div>
                    {row.email} / {row.extension_no}
                </div>
            )
        },
        {
            title: 'Request Date',
            dataIndex: 'request_date',
            key: 'request_date',
            render: (text) => (
                <div className="text-center">
                    {moment(text).format('DD MMMM YYYY')}
                </div>
            )
        },
    ];

    if (tab === 'confirmed') {
        column.push(
            {
                title: 'UAT Schedule',
                key: 'uat_schedule',
                render: (row) => (
                    <div className="text-center">
                        {row.uat_date === null ? '-' : moment(row.uat_date).format('DD MMMM YYYY')} {row.uat_time}
                    </div>
                )
            },
            {
                title: 'Action',
                width:120,
                render:(row) =>(
                    <div className="text-center">
                        <Space>
                            <EditButton onEdit={() => editSchedule(row)}/>
                            <CustomButton icon={<DownloadOutlined />} onEdit={() => printData(row.id)}/>
                        </Space>
                    </div>
                )
            },
        )
    } else {
        column.push(
            {
                title: 'Action',
                width:120,
                render:(row) =>(
                    <div className="text-center">
                        <Space>
                            <EditButton onEdit={() => editSchedule(row)}/>
                            <CustomButton icon={<DownloadOutlined />} onEdit={() => printData(row.id)}/>
                        </Space>
                    </div>
                )
            },
        )
    }

    const editSchedule = (v) =>{
        setDefaultValues({
            change_request_form_id: v.id,
            uat_date: v.uat_date === null ? null : moment(v.uat_date, 'YYYY-MM-DD'),
            uat_time: v.uat_time === null ? null : moment(v.uat_time, 'HH:mm:ss')
        })
        setFormModal(true)
    }

    const saveSchedule = (v) => {
        setSaving(true)
        let payload={
            change_request_form_id: v.change_request_form_id,
            uat_date: moment(v.uat_date, 'DD MMMM YYYY').format('YYYY-MM-DD'),
            uat_time: moment(v.uat_time).format('HH:mm:ss')
        }
        SentralModel.action('ChangeRequestForm', 'saveSchedule', payload, v.change_request_form_id).then((res)=>{
            setFormModal(false)
            fetchChangeRequestForms()
            message.success('Schedule saved',2);
        }).finally(() => {
            setSaving(false)
        })
    }

    const changeTab = e => {
        setTab(e)
    }

    const printData = (v) => {
        window.open(process.env.REACT_APP_API_URL + '/print/crf/' + v)
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="User Acceptance Testing (UAT)" breadcrumbs={[['User Acceptance Testing']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>User Acceptance Testing List</Text>
                    </div> }>

                    {
                        loaded ?
                        <Tabs defaultActiveKey="task" type="card" onChange={changeTab}>
                            <TabPane tab={<span><ClockCircleOutlined style={{color:'#4ca3ff'}}/> Waiting</span>} key="waiting">
                                <DataTable size="small" columns={column} dataSource={dataSource.filter(el => el.status === 'FINISHED' && el.uat_date === null)} bordered/>
                            </TabPane>
                            <TabPane tab={<span><CheckCircleOutlined style={{color:'green'}}/> Confirmed</span>} key="confirmed">
                                <DataTable size="small" columns={column} dataSource={dataSource.filter(el => el.status === 'FINISHED' && el.uat_date !== null)} bordered/>
                            </TabPane>
                        </Tabs>
                    : <Spinner />
                    }

                    {
                        (formModal) ?
                        <FormModal defaultValues={defaultValues} title={'Edit UAT Schedule'} submitForm={(v)=>saveSchedule(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                            <Form.Item name="change_request_form_id" hidden>
                                <Input placeholder="Position ID" />
                            </Form.Item>
                            <Form.Item label="Date" name="uat_date" rules={[{required:true}]}>
                                <DatePicker format="DD MMMM YYYY" style={{width:'100%'}}/>
                            </Form.Item>
                            <Form.Item label="Time" name="uat_time" rules={[{required:true}]}>
                                <TimePicker format="HH:mm" style={{width:'100%'}} placeholder="Select Time"/>
                            </Form.Item>
                        </FormModal> :
                        null
                    }
                </Card>
            </Card>
        </div>
    );
}

export default Index;
