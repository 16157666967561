import React from 'react';
import {Card, Tabs} from 'antd'
import {PageTitle} from '../../../components'
import Applicant from '../applicant/Index'
import Question from '../question/Index'
// import Test from '../test/Index'
import {useParams} from 'react-router-dom'

const {TabPane} = Tabs;

const Index = () => {
    const {vacancyId} = useParams()
    const {applicantId} = useParams()

    return (
        <Card className="content-container">
            <PageTitle title="Applicant" breadcrumbs={applicantId ? [['Vacancy', '/management/vacancy'], ['Detail', '/management/vacancy/detail/' + vacancyId], ['Applicant']] : [['Recruitment']]}></PageTitle>
            <Tabs defaultActiveKey="request" type="card">
                <TabPane tab="Data" key="data">
                    <Applicant applicantId={applicantId}/>
                </TabPane>
                <TabPane tab="Question" key="question">
                    <Question applicantId={applicantId}/>
                </TabPane>
                {/* <TabPane tab="Test" key="test">
                    <Test applicantId={applicantId}/>
                </TabPane> */}
            </Tabs>
        </Card>
    );
}

export default Index;
