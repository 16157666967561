import React,{useState} from 'react';
import {Descriptions, Typography, Tag, Modal, Button} from 'antd'
import { SentralModel } from '../../../models/SentralModel';
import { Spinner } from '../../../components';
const {Text} = Typography
const PaymentHeader = (props) => {
    const {employee} = props
    const [activityModal, setActivityModal] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [activity, setActivity] = useState(null)

    const showActivityDetail =() =>{
        setLoaded(false)
        setActivityModal(true)
        SentralModel.action('Payroll','getActivitySummary',{employee_code: employee.employee_code, period_id:employee.period_id},0).then((res)=>{
            setActivity(res.data)
            setLoaded(true)
        })
    }

    const getLeaveCount = (type) =>{
        let total=0;
        activity.leaves.forEach( el=> {
            if(el.type===type){
                total+=el.total
            }
        });
        return total
    }

    const getAlphaCount = () =>{
        return activity.period.total_working_days - activity.period.working_days - getLeaveCount('PAID') - getLeaveCount('UNPAID')
    }
    return (
        <div>
            <Descriptions className="mb-3" size="middle" column={5} bordered layout="vertical">
                <Descriptions.Item label={<div className="text-center"><Text strong>Employee Name</Text></div>}>
                    <div className="text-center">{employee.name}</div>
                </Descriptions.Item>
                <Descriptions.Item label={<div className="text-center"><Text strong>Position / Division</Text></div>}>
                    <div className="text-center">{employee.division_name} / {employee.position_name}</div>
                </Descriptions.Item>
                <Descriptions.Item label={<div className="text-center"><Text strong>Period</Text></div>}>
                    <div className="text-center">{employee.period_name}</div>
                </Descriptions.Item>
                <Descriptions.Item label={<div className="text-center"><Text strong>Payment Status</Text></div>}>
                    <div className="text-center">
                        {
                            (employee.payment==='GENERATED')?
                                <Tag color="blue">GENERATED</Tag> : null
                        }
                        {
                            (employee.payment==='PAID')?
                                <Tag color="green">PAID</Tag> : null
                        }
                        {
                            (!employee.payment)?
                                <Tag color="red">UNPAID</Tag> : null
                        }
                    </div>
                </Descriptions.Item>
                <Descriptions.Item label={<div className="text-center"><Text strong>Activities</Text></div>}>
                    <div className="text-center">
                        <Tag color="blue" style={{cursor:'pointer'}} onClick={showActivityDetail}>SHOW ACTIVITIES</Tag>
                    </div>
                </Descriptions.Item>
            </Descriptions>
            <Modal
                title="Activity Detail"
                style={{top:20}}
                visible={activityModal}
                width={1000}
                onCancel={() => setActivityModal(false)}
                footer={null}>
                <Descriptions className="mb-3" size="middle" column={3} bordered layout="vertical">
                    <Descriptions.Item label={<div className="text-center"><Text strong>Employee Name</Text></div>}>
                        <div className="text-center">{employee.name}</div>
                    </Descriptions.Item>
                    <Descriptions.Item label={<div className="text-center"><Text strong>Division /Position</Text></div>}>
                        <div className="text-center">{employee.division_name} / {employee.position_name}</div>
                    </Descriptions.Item>
                    <Descriptions.Item label={<div className="text-center"><Text strong>Period</Text></div>}>
                        <div className="text-center">{employee.period_name}</div>
                    </Descriptions.Item>
                </Descriptions>
                {
                    (loaded) ? 
                    <div>
                        <br/>
                        <Text strong>Working Days</Text>
                        <Descriptions size="small" column={6} bordered layout="vertical">
                            <Descriptions.Item className="text-center" label={<Text strong>Working Days</Text>}>
                                {activity.period.working_days} / {activity.period.total_working_days}
                            </Descriptions.Item>
                            <Descriptions.Item className="text-center" label={<Text strong>Paid Leave</Text>}>
                                {getLeaveCount('PAID')}
                            </Descriptions.Item>
                            <Descriptions.Item className="text-center" label={<Text strong>Unpaid Leave</Text>}>
                                {getLeaveCount('UNPAID')}
                            </Descriptions.Item>
                            <Descriptions.Item className="text-center" label={<Text strong>Alpha</Text>}>
                                {getAlphaCount()}
                            </Descriptions.Item>
                            <Descriptions.Item className="text-center" label={<Text strong>Weekend</Text>}>
                                {activity.period.weekend}
                            </Descriptions.Item>
                            <Descriptions.Item className="text-center" label={<Text strong>Public Holiday</Text>}>
                                {activity.period.public_holidays}
                            </Descriptions.Item>
                        </Descriptions>
                        <br/>
                        <Text strong>Late</Text>
                        <Descriptions size="small" column={activity.lates.length} bordered layout="vertical">
                            {
                                activity.lates.map((el, key)=>(
                                    <Descriptions.Item key={key} className="text-center" label={<Text strong>{el.description}</Text>}>
                                        {el.total}
                                    </Descriptions.Item>
                                ))
                            }
                        </Descriptions>
                        <br/>
                        <Text strong>Overtime</Text>
                        <Descriptions size="small" column={activity.overtimes.length} bordered layout="vertical">
                            {
                                activity.overtimes.map((el, key)=>(
                                    <Descriptions.Item key={key} className="text-center" label={<Text strong>{el.description}</Text>}>
                                        {el.total} hours
                                    </Descriptions.Item>
                                ))
                            }
                        </Descriptions>
                        <br/>
                    </div>
                    : <Spinner/>
                }
                
                <div className="full-width mt-2 text-right">
                    <Button size="middle" onClick={() => setActivityModal(false)}>Close</Button>
                </div>
            </Modal>
        </div>
    );
}

export default PaymentHeader;
