import { createSlice } from "@reduxjs/toolkit";

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: {
        dailyActivityTab: 'my_daily'
    },
    reducers: {
        changeDailyActivityTab: (state, action) => {
            state.dailyActivityTab = action.payload
        }
    }
})

export const {
    changeDailyActivityTab,
} = dashboardSlice.actions

export default dashboardSlice.reducer