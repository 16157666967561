import { useEffect, useState } from 'react'
import { Button, Card, DatePicker, Row, Space } from 'antd'
import { FileExcelOutlined } from '@ant-design/icons'
import { PageTitle } from '../../components'
import { SentralModel } from '../../models/SentralModel'
import { generateMonth } from '../../helpers/generateMonth'
import dayjs from 'dayjs'
import authData from '../../helpers/authData'
import Sales from './table/Sales'
import Head from './table/Head'

const Index = () => {
    const management = ["ADM001", "SMI20000101001", "SMI20000101002", "SMI20000101003", "SMI20100628033", "SMI20150601108", "SST20231201019"]
    const [achivementData, setAchivementData] = useState([])
    const [achivementDataHead, setAchivementDataHead] = useState([])
    const [year, setYear] = useState(new Date())

    const fetchSalesAchivement = (year) => {
        SentralModel.action("AchivementSales", "getSalesAchivement", { employee_code: authData.employee_code, year: dayjs(year).format("YYYY") }, null).then((res) => {
            let arr = []
            const obj = res?.data?.data[0]?.periode
            const key = Object?.keys(obj)
            key.forEach((el) => {
                arr.push({
                    ...obj[el],
                    period: generateMonth(el)
                })
            })

            setAchivementData(arr)
            setAchivementDataHead(res.data.data)
        })
    }

    useEffect(() => {
        fetchSalesAchivement(year)
    }, [year])

    const onChangeDate = (v) => {
        setYear(dayjs(v))
        fetchSalesAchivement(v)
    }

    const exportReport = () => {
        window.open(process.env.REACT_APP_API_URL + `/report/sales-achievement?employee_code=${authData.employee_code}&year=${dayjs(year).format("YYYY")}`);
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title={`Sales Achivement ${dayjs(year).format("YYYY")}`} breadcrumbs={[['Sales Achivement']]}></PageTitle>
                <Row justify='end' style={{ marginBottom: 10 }}>
                    <Space>
                        <Button type="primary" onClick={() => exportReport()} icon={<FileExcelOutlined />}> Export</Button>
                        <DatePicker picker="year" value={dayjs(year)} onChange={onChangeDate} />
                    </Space>
                </Row>
                {
                    management.includes(authData.employee_code) ?
                        <Head data={achivementDataHead} /> :
                        <Sales data={achivementData} />
                }
            </Card>
        </div>
    )
}

export default Index