import React, {useEffect, useState} from 'react';
import { Card, message, Space, Tabs } from 'antd'
import { DataTable, DeleteButton, ShowButton } from '../../../components'
import { toFullDate } from '../../../utils/dateHandler'
import { FieldTimeOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { SentralModel } from '../../../models/SentralModel';
import moment from 'moment'

const { TabPane } = Tabs;

const Index = (props) => {
    const [requests, setRequests] = useState([])

    const fetchRequest = (props) => {
        setRequests(props.realizations);
    }

    useEffect(() => {
        fetchRequest(props)
    }, [props]);

    const realizationColumn = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'name',
        }, {
            title: 'Employee Name',
            dataIndex: 'name',
            key: 'name'
        }, {
            title: 'Necessity',
            dataIndex: 'necessity',
            key: 'necessity'
        }, {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        }, {
            title: 'Time',
            align: 'center',
            render: (row) => (
                <span>{moment(row.from_time, 'HH:mm:ss').format('HH:mm')} - {moment(row.to_time, 'HH:mm:ss').format('HH:mm')}</span>
            )
        }, {
            title: 'Action',
            width: '10%',
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <ShowButton link to={'/submission-list/overtime-realization/' + row.request_id} />
                        {
                            row.status === 'WAITING' && requests.filter(el => el.request_id === row.request_id).length > 1 ?
                                <DeleteButton onConfirm={() => deleteSubmission(row.id, row.request_id)}/>
                            : null
                        }
                    </Space>
                </div>
            )
        }
    ];

    const realizationData = [
        ...props.realizations.map((el) => {
            return {
                id: el.approval_pool_id,
                request_id: el.request_id,
                name: el.name,
                necessity: el.description,
                date: toFullDate(el.from_date_realization),
                from_time: el.from_time_realization,
                to_time: el.to_time_realization,
                division_name: el.division_name,
                status: el.status,
                submitted_at: el.submitted_at
            }
        })
    ]

    const deleteSubmission = (id, requestId) => {
        message.loading('deleting request')

        SentralModel.deleteById('ApprovalPool', id).then((res)=>{
            message.destroy()
            message.success('Request deleted', 2)
            let tmp = requests
            let index = tmp.findIndex(el => el.approval_pool_id===id)
            tmp.splice(index, 1);
            setRequests([])
            setRequests(tmp)
        })
    }

    return (
        <Card>
            <Tabs defaultActiveKey="waiting">
                <TabPane tab={<span><FieldTimeOutlined style={{ color: '#4ca3ff' }} /> Waiting Approval</span>} key="waiting">
                    <DataTable size="small" columns={realizationColumn} dataSource={realizationData.filter(el => el.status === 'WAITING')} bordered />
                </TabPane>
                <TabPane tab={<span><CheckCircleOutlined style={{ color: 'green' }} /> Approved</span>} key="approved">
                    <DataTable size="small" columns={realizationColumn} dataSource={realizationData.filter(el => el.status === 'APPROVED')} bordered />
                </TabPane>
                <TabPane tab={<span><CloseCircleOutlined style={{ color: 'red' }} /> Rejected</span>} key="rejected">
                    <DataTable size="small" columns={realizationColumn} dataSource={realizationData.filter(el => el.status === 'REJECTED')} bordered />
                </TabPane>
            </Tabs>
        </Card>
    );
}

export default Index;
