import React, {useEffect, useState} from 'react';
import {Alert, Card, Tabs} from 'antd'
import {PageTitle, Spinner} from '../../../components'
import {SentralModel} from '../../../models/SentralModel'
import {useParams} from 'react-router-dom'

import GenerateDocument from './GenerateDocument'
import ListDocument from './ListDocument'

const {TabPane} = Tabs;

const Detail = () => {
    const {letterId} = useParams()
    const [masters, setMasters] = useState([])
    const [documents, setDocuments] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [activeTab, setActiveTab] = useState('project');
    const [isAlert, setIsAlert] = useState(false)
    const [number, setNumber] = useState("")

    const fetchMasters = (letterId) => {
        SentralModel.list('LetterMaster', {filter: [['letter_id', letterId]], orderBy: 'letter_master_id', order: 'desc'}).then(res => {
            setLoaded(false)
            setMasters(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    const fetchDocuments = (letterId) => {
        SentralModel.action('Letter', 'getDocumentLetter', {}, letterId).then(res => {
            setLoaded(false)
            setDocuments(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchMasters(letterId)
        fetchDocuments(letterId)
    }, [letterId]);

    const changeTab = e => {
        setActiveTab(e)
    }

    const onClose = () => {
        setIsAlert(false)
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Letters" breadcrumbs={[['Letter','/letter'], ['Detail']]}></PageTitle>
                    {
                        loaded ?
                        <>
                        {
                            isAlert ?
                            <Alert
                                message={"Succes generate letter number, your letter number is " + number}
                                type="success"
                                closable
                                onClose={onClose}
                                style={{marginBottom: 20}}
                            />
                            : null
                        }

                        <Tabs defaultActiveKey={activeTab} type="card" onChange={changeTab}>
                            <TabPane tab={<span>Generate Document Number</span>} key="generate">
                                <GenerateDocument id={letterId} data={masters} onFetch={() => fetchMasters()} onGenerate={(v) => {fetchDocuments(); setNumber(v); setIsAlert(true);}}/>
                            </TabPane>
                            <TabPane tab={<span>List Document Number</span>} key="list">
                                <ListDocument id={letterId} data={documents} onFetch={() => fetchDocuments()}/>
                            </TabPane>
                        </Tabs>
                        </>
                    : <Spinner />
                    }
            </Card>
        </div>
    );
}

export default Detail;
