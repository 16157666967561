import React, {useEffect, useRef} from 'react';
import {message} from 'antd'
import {AuthModel} from '../../models/AuthModel'
import {getQuery} from '../../helpers/url'
import { Spinner } from '../../components';

const SSO = () => {
    const token = useRef('');

    const fetchEmployees = () => {
        AuthModel.authSSO(token.current).then().catch(() => {
            message.destroy()
            message.error('invalid credentials', 2)
            
            setTimeout(() => {
                window.location.href="/login"
            }, 1000)
        })
    }

    useEffect(() => {
        token.current = getQuery('token')
        fetchEmployees();
    }, []);

    return (
        <div>
            <Spinner/>
        </div>
    );
}

export default SSO;
