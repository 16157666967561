import React, { useState, useEffect } from 'react'
import { Row, message, Select, DatePicker } from 'antd'
import moment from 'moment'
import QRCode from "react-qr-code"
import ProfileItem from './ProfileItem';
import { EditButton, BasicModal, Uploader } from '../../components'
import { ImageViewer } from '../../components/ImageViewer'
import { SentralModel } from '../../models/SentralModel'
import { useParams } from 'react-router-dom'
import authData from '../../helpers/authData'

const PersonalData = (props) => {
    const { employeeCode } = useParams()
    const [showEditProfilePicture, setShowEditProfilePicture] = useState(false)
    const [showEditSignature, setShowEditSignature] = useState(false)
    const [uploadingProfilePicture, setUploadingProfilePicture] = useState(false)
    const [uploadingSignature, setUploadingSignature] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [canEdit, setCanEdit] = useState(false)
    const [isEmployee, setIsEmployee] = useState(false)

    const QrCodeText = `BEGIN:VCARD\nVERSION:3.0\nN:${props.data.name};\nFN:${props.data.name}\nTEL;TYPE=work:${props.data.mobile_phone}\nEMAIL;TYPE=internet,work:${props.data.work_email}\nEND:VCARD`

    useEffect(() => {
        if (authData.employee_code === 'SMI20200622202' || authData.employee_code === 'SMI20180322139' || authData.employee_code === 'SMI20220323229') {
            setCanEdit(true)
        }
        if (authData.employee_code === employeeCode || employeeCode === undefined || authData.employee_code === 'SMI20220323229') {
            setIsEmployee(true)
        } else {
            setIsEmployee(false)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updatePersonalData = (v, field) => {
        let payload = {
            field: field,
            employee_code: props.data.employee_code
        };
        payload[field] = v
        if (field === 'work_email') {
            SentralModel.action('Profile', 'changeEmail', { email: v, employee_code: props.data.employee_code }, 0)
        }
        SentralModel.action('Profile', 'updateData', payload, null).then((res) => {
            let employee = props.data
            employee[field] = v
            props.updateUser(employee)
        })

        // SentralModel.save('Employee', payload, props.data.employee_code).then(() => {
        // })
    }

    const profilePictureUploaded = (v) => {
        if (uploadingProfilePicture) {
            setUploadingProfilePicture(false)
        }
        SentralModel.action('Profile', 'changeProfilePicture', { photo: v.message, employee_code: props.data.employee_code }, 0).then(() => {
            message.success('Profile Picture Updated', 2)
            let employee = props.data
            if (employee.document) {
                employee.document.photo = v.message
            } else {
                employee.document = { photo: v.message }
            }
            props.updateUser(employee)
            setShowEditProfilePicture(false)
        })
    }

    const signatureUploaded = (v) => {
        if (uploadingSignature) {
            setUploadingSignature(false)
        }
        SentralModel.action('Profile', 'changeSignature', { signature: v.message, employee_code: props.data.employee_code }, 0).then(() => {
            message.success('Signature Updated', 2)
            let employee = props.data
            employee.document.signature = v.message
            props.updateUser(employee)
            setShowEditSignature(false)
        })
    }

    const submitReligion = (v, t) => {
        updatePersonalData(v, 'religion')
        setShowEdit(null)
        let employee = props.data
        employee.religion = v
        employee.religion_detail = {
            general_detail_code: v,
            description: t.children
        }
        props.updateUser(employee)
    }

    const submitMaritalStatus = (v, t) => {
        updatePersonalData(v, 'marital_status')
        setShowEdit(null)
        let employee = props.data
        employee.marital_status = v
        employee.marital_status_detail = {
            description: t.children,
            general_detail_code: v
        }
        props.updateUser(employee)
    }

    const submitGender = (v, t) => {
        updatePersonalData(v, 'gender')
        setShowEdit(null)
        let employee = props.data
        employee.gender = v
        employee.gender_detail = {
            description: t.children,
            general_detail_code: v
        }
        props.updateUser(employee)
    }

    const submitTaxStatus = (v, t) => {
        updatePersonalData(v, 'tax_status')
        setShowEdit(null)
        let employee = props.data
        employee.tax_status = v
        employee.tax_status_detail = {
            description: t.children,
            general_detail_code: v
        }
        props.updateUser(employee)
    }

    const submitBloodType = (v, t) => {
        updatePersonalData(v, 'blood_type')
        setShowEdit(null)
        let employee = props.data
        employee.blood_type = v
        employee.blood_type_detail = {
            description: t.children,
            general_detail_code: v
        }
        props.updateUser(employee)
    }

    const submitBirthDate = (v, t) => {
        updatePersonalData(moment(v).format('YYYY-MM-DD'), 'date_of_birth')
        setShowEdit(null)
        let employee = props.data
        employee.date_of_birth = moment(v).format('YYYY-MM-DD')
        props.updateUser(employee)
    }

    return (
        <div>
            <Row>
                <ProfileItem image title="Profile Picture">
                    <div style={{ width: 300, padding: '10px' }}>
                        <ImageViewer onEdit={canEdit || isEmployee ? () => setShowEditProfilePicture(true) : null} style={{ width: '50%' }} previewable src={(!props.data.document || !props.data.document.photo) ? process.env.PUBLIC_URL + '/images/user-male.png' : process.env.REACT_APP_API_FILE + "/" + props.data.document.photo} />
                    </div>
                </ProfileItem>
                <ProfileItem title="Signature">
                    <div style={{ width: 300, padding: '10px' }}>
                        <ImageViewer onEdit={canEdit || isEmployee ? () => setShowEditSignature(true) : null} style={{ width: '100%' }} previewable src={props.data.document === null ? process.env.PUBLIC_URL + '/images/noimage.png' : process.env.REACT_APP_API_FILE + "/" + props.data.document.signature} alt="signatureimage" />
                    </div>
                </ProfileItem>
                <ProfileItem title="QR">
                    <div style={{ textAlign: 'center', padding: '10px' }}>
                        <QRCode size="150" value={QrCodeText} />
                    </div>
                </ProfileItem>
            </Row>
            <Row>
                <ProfileItem onEdited={canEdit ? (v) => updatePersonalData(v, 'name') : false} title="Name">{props.renderItem(props.data.name)}</ProfileItem>
                <ProfileItem onEdited={canEdit || isEmployee ? (v) => updatePersonalData(v, 'mobile_phone') : false} title="Phone Number">{props.renderItem(props.data.mobile_phone)}</ProfileItem>
                <ProfileItem onEdited={canEdit ? (v) => updatePersonalData(v, 'work_email') : false} title="Email">{props.renderItem(props.data.work_email)}</ProfileItem>
                <ProfileItem onEdited={canEdit || isEmployee ? (v) => updatePersonalData(v, 'personal_email') : false} title="Private Email">{props.renderItem(props.data.personal_email)}</ProfileItem>
                <ProfileItem title="Marital Status">
                    {
                        (showEdit !== 'marital' && props.data.marital_status_detail) ?
                            props.renderItem(props.data.marital_status_detail.description)
                            : null
                    }
                    {
                        canEdit ?
                            (showEdit === 'marital') ?
                                <Select defaultValue={props.data.marital_status} onChange={(v, t) => submitMaritalStatus(v, t)} style={{ width: '80%' }}>
                                    {
                                        props.generals.filter(g => g.general_code === 'MS').map((el, key) => (
                                            <Select.Option key={key} value={el.detail_code}>{el.description}</Select.Option>
                                        ))
                                    }
                                </Select>
                                : <EditButton onEdit={() => setShowEdit('marital')} />
                            : null
                    }
                </ProfileItem>
                <ProfileItem onEdited={canEdit || isEmployee ? (v) => updatePersonalData(v, 'address') : false} title="Address">{props.renderItem(props.data.address)}</ProfileItem>
                <ProfileItem title="Tax Status">
                    {
                        (showEdit !== 'taxstatus' && props.data.tax_status_detail) ?
                            props.renderItem(props.data.tax_status_detail.description)
                            : null
                    }
                    {
                        canEdit ?
                            (showEdit === 'taxstatus') ?
                                <Select defaultValue={props.data.tax_status} onChange={(v, t) => submitTaxStatus(v, t)} style={{ width: '80%' }}>
                                    {
                                        props.generals.filter(g => g.general_code === 'TS').map((el, key) => (
                                            <Select.Option key={key} value={el.detail_code}>{el.description}</Select.Option>
                                        ))
                                    }
                                </Select>
                                : <EditButton onEdit={() => setShowEdit('taxstatus')} />
                            : null
                    }
                </ProfileItem>
                <ProfileItem title="Religion">
                    {
                        (showEdit !== 'religion' && props.data.religion_detail) ?
                            props.renderItem(props.data.religion_detail.description)
                            : null
                    }
                    {
                        canEdit || isEmployee ?
                            (showEdit === 'religion') ?
                                <Select defaultValue={props.data.religion} onChange={(v, t) => submitReligion(v, t)} style={{ width: '80%' }}>
                                    {
                                        props.generals.filter(g => g.general_code === 'R').map((el, key) => (
                                            <Select.Option key={key} value={el.detail_code}>{el.description}</Select.Option>
                                        ))
                                    }
                                </Select> : <EditButton onEdit={() => setShowEdit('religion')} />
                            : null
                    }
                </ProfileItem>
                <ProfileItem onEdited={canEdit ? (v) => updatePersonalData(v, 'place_of_birth') : false} title="Birth Place">{props.renderItem(props.data.place_of_birth)}</ProfileItem>
                <ProfileItem title="Birth Date">
                    {
                        (showEdit !== 'birthdate' && props.data.date_of_birth) ?
                            props.renderItem(props.data.date_of_birth)
                            : null
                    }
                    {
                        canEdit ?
                            (showEdit === 'birthdate') ?
                                <DatePicker value={(props.data.date_of_birth) ? moment(props.data.date_of_birth) : null} onChange={(v) => submitBirthDate(v)} />
                                : <EditButton onEdit={() => setShowEdit('birthdate')} />
                            : null
                    }
                </ProfileItem>
                <ProfileItem title="Blood Type">
                    {
                        (showEdit !== 'bloodtype' && props.data.blood_type_detail) ?
                            props.renderItem(props.data.blood_type_detail.description)
                            : null
                    }
                    {
                        canEdit || isEmployee ?
                            (showEdit === 'bloodtype') ?
                                <Select defaultValue={props.data.blood_type} onChange={(v, t) => submitBloodType(v, t)} style={{ width: '80%' }}>
                                    {
                                        props.generals.filter(g => g.general_code === 'BT').map((el, key) => (
                                            <Select.Option key={key} value={el.detail_code}>{el.description}</Select.Option>
                                        ))
                                    }
                                </Select>
                                : <EditButton onEdit={() => setShowEdit('bloodtype')} />
                            : null
                    }
                </ProfileItem>
                <ProfileItem title="Gender">
                    {
                        (showEdit !== 'gender' && props.data.gender_detail) ?
                            props.renderItem(props.data.gender_detail.description)
                            : null
                    }
                    {
                        canEdit ?
                            (showEdit === 'gender') ?
                                <Select defaultValue={props.data.gender} onChange={(v, t) => submitGender(v, t)} style={{ width: '80%' }}>
                                    {
                                        props.generals.filter(g => g.general_code === 'G').map((el, key) => (
                                            <Select.Option key={key} value={el.detail_code}>{el.description}</Select.Option>
                                        ))
                                    }
                                </Select>
                                : <EditButton onEdit={() => setShowEdit('gender')} />
                            : null
                    }
                </ProfileItem>
                <ProfileItem onEdited={canEdit || isEmployee ? (v) => updatePersonalData(v, 'family_card_no') : false} title="KK">{props.renderItem(props.data.family_card_no)}</ProfileItem>
                <ProfileItem onEdited={canEdit ? (v) => updatePersonalData(v, 'identity_card_no') : null} title="KTP">{props.renderItem(props.data.identity_card_no)}</ProfileItem>
                <ProfileItem onEdited={canEdit || isEmployee ? (v) => updatePersonalData(v, 'npwp_no') : null} title="NPWP">{props.renderItem(props.data.npwp_no)}</ProfileItem>
                <ProfileItem onEdited={canEdit || isEmployee ? (v) => updatePersonalData(v, 'bpjs_employment_no') : false} title="BPJS Employment">{props.renderItem(props.data.bpjs_employment_no)}</ProfileItem>
                <ProfileItem onEdited={canEdit || isEmployee ? (v) => updatePersonalData(v, 'bpjs_health_no') : false} title="BPJS Healthy">{props.renderItem(props.data.bpjs_health_no)}</ProfileItem>
                <ProfileItem onEdited={canEdit || isEmployee ? (v) => updatePersonalData(v, 'extension_no') : false} title="Extension">{props.renderItem(props.data.extension_no)}</ProfileItem>
            </Row>
            <BasicModal title="Change Profile Picture" showModal={showEditProfilePicture} onCloseModal={() => setShowEditProfilePicture(false)}>
                {
                    (showEditProfilePicture) ?
                        <Uploader dragger folder="photo" onUploaded={(v) => profilePictureUploaded(v)} isUploading={(v) => setUploadingProfilePicture(v)} />
                        : null
                }
            </BasicModal>
            <BasicModal title="Change Signature" showModal={showEditSignature} onCloseModal={() => setShowEditSignature(false)}>
                {
                    (showEditSignature) ?
                        <Uploader dragger folder="signature" onUploaded={(v) => signatureUploaded(v)} isUploading={(v) => setUploadingSignature(v)} />
                        : null
                }
            </BasicModal>
        </div>
    );
}

export default PersonalData;
