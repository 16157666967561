import { useEffect } from 'react';
import { Row, Col, Button, Form, Input, DatePicker, Select } from 'antd';
import { Uploader } from '../../../../components';
import { showModalAddCompany } from '../../../../redux/slices/Dashboard/dailyActivitySlice';
import { useDispatch, useSelector } from 'react-redux';
import {
    changeCategory,
    changeCompany,
    changeUploading,
    fetchCompanies,
    fetchListTicket,
    fetchTicketAssign,
    fileUploaded,
    saveSubmitPresalesTicket
} from '../../../../redux/slices/Dashboard/karajoTicketSlice';

const { Option } = Select;

export const FormatTicketPresales = (props) => {
    const dispatch = useDispatch()
    const companies = useSelector((state) => state.karajoTicket.companies)
    const categoryRequest = useSelector((state) => state.karajoTicket.categoryRequest)
    const attachmentFile = useSelector((state) => state.karajoTicket.attachmentFile)
    const companyName = useSelector((state) => state.karajoTicket.companyName)

    useEffect(() => {
        dispatch(fetchCompanies())

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const saveSubmit = (v) => {
        let payload = {
            ...v,
            attachment: attachmentFile,
            company_name: companyName,
        }

        dispatch(saveSubmitPresalesTicket(payload)).then(() => {
            dispatch(fetchListTicket())
            dispatch(fetchTicketAssign())
        })
    }

    return (
        <Form layout='vertical' onFinish={(v) => saveSubmit(v)}>
            <Form.Item label="Category Request" name="category_request" required rules={[{ required: true }]}>
                <Select placeholder="Select Category" onChange={(v) => dispatch(changeCategory(v))}>
                    <Option value="Product Recomendation">Product Recomendation</Option>
                    <Option value="Support Project">Support Project</Option>
                    <Option value="Other">Other</Option>
                </Select>
            </Form.Item>
            {
                categoryRequest ?
                    <>
                        <Row justify='space-between'>
                            <Col span={17}>
                                <Form.Item label="Company" name="company_id" required rules={[{ required: true }]}>
                                    <Select showSearch allowClear placeholder="Select Company" style={{ width: "100%" }} optionFilterProp="children" onChange={(v) => dispatch(changeCompany(v))} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {
                                            companies.map((el, key) => (
                                                <Option key={key} value={el.company_id}>{el.company_name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <div style={{ marginTop: 30 }}>
                                    <Button type='primary' onClick={() => dispatch(showModalAddCompany())}>Add Company</Button>
                                </div>
                            </Col>
                        </Row>
                        <Form.Item label="SLA" name="sla" required rules={[{ required: true }]}>
                            <Select placeholder="Select SLA">
                                <Option value="Urgent">Urgent</Option>
                                <Option value="Hight">High</Option>
                                <Option value="Medium">Medium</Option>
                                <Option value="Low">Low</Option>
                            </Select>
                        </Form.Item>
                    </> :
                    <>
                        <Form.Item label="Nama Pengadaaan" name="procurement_name" required rules={[{ required: true }]}>
                            <Input placeholder='Input Procurement' />
                        </Form.Item>
                        <Form.Item label="Link" name="link" required rules={[{ required: true }]}>
                            <Input placeholder='Link' />
                        </Form.Item>
                        <Form.Item label="Tanggal Aanwijzing" name="aanwijzing_date" required rules={[{ required: true }]}>
                            <DatePicker style={{ width: "100%" }} />
                        </Form.Item>
                    </>
            }
            <Form.Item label="Attachment" required rules={[{ required: true }]}>
                <Uploader folder="attachment-ticket-presales" onUploaded={(v) => dispatch(fileUploaded(v))} isUploading={(v) => dispatch(changeUploading(v))} view={true} />
            </Form.Item>
            <Form.Item label="Description Request" name="description" required rules={[{ required: true }]}>
                <Input.TextArea rows={5} />
            </Form.Item>
            <Row justify='end'>
                <Col>
                    <Form.Item>
                        <Button type='primary' htmlType='submit'>Save</Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}

export default FormatTicketPresales