import React, { useEffect, useState } from 'react';
import { Card, Typography, Descriptions } from 'antd'
import { PageTitle, Spinner } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import { useParams } from 'react-router-dom';
import { toFullDate } from '../../../utils/dateHandler';
import moment from 'moment'

const { Text } = Typography

const ExternalDetail = () => {
    const { trainingId } = useParams()
    const [training, setTraining] = useState([])
    const [loaded, setLoaded] = useState(false)

    const fetchTraining = (trainingId) => {
        setLoaded(false)
        SentralModel.get('TrainingExternal', {}, trainingId).then(res => {
            setTraining(res.data)
        }).finally(() => {
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchTraining(trainingId)
    }, [trainingId]);

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Training and Certification" breadcrumbs={[['Training and Certification', '/request-training'], ['Detail']]}></PageTitle>
                <Card title={<Text strong>Training and Certification Detail</Text>}>

                    {
                        (loaded) ?
                            <Descriptions layout="horizontal" bordered column={1}>
                                <Descriptions.Item label={<span className="text-bold">Judul</span>}>
                                    {training.title}
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className="text-bold">Tanggal</span>}>
                                    {toFullDate(training.date)}
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className="text-bold">Waktu</span>}>
                                    {moment(training.start_time, 'HH:mm:ss').format('HH:mm')} - {moment(training.end_time, 'HH:mm:ss').format('HH:mm')}
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className="text-bold">Organizer</span>}>
                                    {training.organizer}
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className="text-bold">Trainer</span>}>
                                    <Text style={{whiteSpace:"pre-line"}}>
                                        {training.trainer}
                                    </Text>
                                </Descriptions.Item>
                                <Descriptions.Item label={<span className="text-bold">Attachment</span>}>
                                    {
                                        (training.attachment) ?
                                        <a rel="noopener noreferrer" target='_blank' href={process.env.REACT_APP_API_FILE + '/' + training.attachment}>
                                            Show Attachment
                                        </a> : '-'
                                    }
                                </Descriptions.Item>
                            </Descriptions>
                            : <Spinner />
                    }
                </Card >
            </Card >

        </div >
    );
}

export default ExternalDetail;
