import React, { useEffect, useState } from 'react';
import { Card, Typography, message, Space, Tabs, Button } from 'antd'
import { NewButton, EditButton, DeleteButton, PageTitle, Spinner, DataTable, CustomButton } from '../../../components'
import { CalendarOutlined, ClockCircleOutlined, CheckCircleOutlined, FieldTimeOutlined, CloseSquareOutlined, CheckSquareOutlined, IssuesCloseOutlined, SendOutlined, DownloadOutlined } from '@ant-design/icons'
import { SentralModel } from '../../../models/SentralModel'
import moment from 'moment'
import authData from '../../../helpers/authData'
import { useHistory } from 'react-router-dom';

const { Text } = Typography
const { TabPane } = Tabs;

const Index = () => {
    const history = useHistory()
    const [crfs, setCRF] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [tab, setTab] = useState('open');

    const fetchChangeRequestForms = () => {
        SentralModel.list('ChangeRequestForm', { filter: [authData.division_id !== 24 ? ['division_id', authData.division_id] : []], orderBy: 'change_request_form_code', order: 'desc' }).then(res => {
            setLoaded(false)
            setCRF(res.data)
        }).finally(() => {
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchChangeRequestForms()
    }, []);

    const dataSource = [
        ...crfs.map(el => {
            return {
                id: el.change_request_form_id,
                requester_name: el.employee.name,
                crf_code: el.change_request_form_code,
                email: el.employee.work_email,
                extension_no: el.employee.extension_no,
                request_date: el.request_date,
                status: el.status,
                uat_date: el.uat_date,
                uat_time: el.uat_time,
                developers: el.developers.map((el) => el.employee.name).join(", "),
                finish_date: el?.developers[0]?.finish_date ?? '-'

            }
        })
    ];

    const column = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
        },
        {
            title: 'Requester Name',
            dataIndex: 'requester_name',
            key: 'requester_name',
        },
        {
            title: 'CRF Code',
            dataIndex: 'crf_code',
            key: 'crf_code',
        },
        {
            title: 'Email / Ext',
            render: (row) => (
                <div>
                    {row.email} / {row.extension_no}
                </div>
            )
        },
        {
            title: 'Request Date',
            dataIndex: 'request_date',
            key: 'request_date',
            render: (text) => (
                <div className="text-center">
                    {moment(text).format('DD MMMM YYYY')}
                </div>
            )
        },
    ];

    if (tab === 'waiting') {
        column.push(
            {
                title: 'Action',
                width: 120,
                render: (row) => (
                    <div className="text-center">
                        <Space>
                            <EditButton link to={"/crf/detail/" + row.id} />
                            <DeleteButton onConfirm={() => deleteCRF(row.id)} />
                            <CustomButton icon={<DownloadOutlined />} onEdit={() => printData(row.id)} />
                        </Space>
                    </div>
                )
            },
        )
    } else if (tab === 'progress') {
        column.push(
            {
                title: 'Developer',
                key: 'developers',
                render(row) {
                    return {
                        props: {
                            style: { background: [row.developers].includes(authData.name) ? "#2efe64" : "white" }
                        },
                        children: <div>{row.developers}</div>
                    };
                }
            },
            {
                title: 'Action',
                width: 120,
                render: (row) => (
                    <div className="text-center">
                        <Space>
                            <EditButton link to={"/crf/detail/" + row.id} />
                            <CustomButton icon={<DownloadOutlined />} onEdit={() => printData(row.id)} />
                        </Space>
                    </div>
                )
            },
        )
    } else if (tab === 'finish') {
        column.push(
            {
                title: 'Finish Date',
                key: 'finish_date',
                render: (row) => (
                    moment(row?.finish_date).format('DD MMMM YYYY')
                ),
                align: 'center'
            },
            {
                title: 'UAT Schedule',
                key: 'uat_schedule',
                render: (row) => (
                    <div className="text-center">
                        {row.uat_date === null ? '-' : moment(row.uat_date).format('DD MMMM YYYY')} {row.uat_time}
                    </div>
                )
            },
            {
                title: 'Action',
                width: 120,
                render: (row) => (
                    <div className="text-center">
                        <Space>
                            <EditButton link to={"/crf/detail/" + row.id} />
                            <CustomButton icon={<DownloadOutlined />} onEdit={() => printData(row.id)} />
                            <CustomButton icon={<SendOutlined />} onEdit={() => sendUat(row.id)} />
                        </Space>
                    </div>
                )
            },
        )
    }
    else if (tab === 'close') {
        column.push(
            {
                title: 'Finish Date',
                key: 'finish_date',
                render: (row) => (
                    moment(row?.finish_date).format('DD MMMM YYYY')
                ),
                align: 'center'
            },
            {
                title: 'Action',
                width: 120,
                render: (row) => (
                    <div className="text-center">
                        <Space>
                            <EditButton link to={"/crf/detail/" + row.id} />
                            <CustomButton icon={<DownloadOutlined />} onEdit={() => printData(row.id)} />
                        </Space>
                    </div>
                )
            },
        )
    }
    else {
        column.push(
            {
                title: 'Action',
                width: 120,
                render: (row) => (
                    <div className="text-center">
                        <Space>
                            <EditButton link to={"/crf/detail/" + row.id} />
                            <CustomButton icon={<DownloadOutlined />} onEdit={() => printData(row.id)} />
                        </Space>
                    </div>
                )
            },
        )
    }

    const deleteCRF = (id) => {
        message.loading('deleting crf')
        SentralModel.deleteById('ChangeRequestForm', id).then((res) => {
            message.destroy()
            message.success('crf deleted', 2)
            let tmp = crfs
            let index = tmp.findIndex(el => el.change_request_form_id === id)
            tmp.splice(index, 1);
            setCRF([])
            setCRF(tmp)
        })
    }

    const changeTab = e => {
        setTab(e)
    }

    const printData = (v) => {
        window.open(process.env.REACT_APP_API_URL + '/print/crf/' + v)
    }

    const sendUat = (v) => {
        message.loading('sending notification', 0)
        SentralModel.action('ChangeRequestForm', 'sendUAT', { change_request_form_id: v }, 0).then((res) => {
            message.destroy()
            message.success('Email sent', 2)
        }).catch(() => {
            message.destroy()
        })
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Change Request Form" breadcrumbs={[['Change Request Form']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{ float: 'left' }}>Change Request Form List</Text>
                        <div style={{ float: 'right' }}>
                            <Space>
                                {
                                    authData.division_id === 24 || authData.employee_code === 'ADM001' ?
                                        <Button type='primary' onClick={() => history.push('/crf/dashboard')}>Dashboard</Button>
                                        : null
                                }

                                {
                                    authData.division_id === 24 || authData.employee_code === 'ADM001' ?
                                        <Button type='primary' onClick={() => history.push('/crf/sasaran-mutu')}>Sasaran Mutu</Button>
                                        : null
                                }
                                <NewButton to="/crf/detail/0" title="New CRF" />
                            </Space>
                        </div>
                    </div>}>

                    {
                        loaded ?
                            <Tabs defaultActiveKey="task" type="card" onChange={changeTab}>
                                <TabPane tab={<span><CalendarOutlined style={{ color: '#4ca3ff' }} /> Waiting Approval</span>} key="waiting">
                                    <DataTable size="small" columns={column} dataSource={dataSource.filter(el => el.status === 'WAITING')} bordered />
                                </TabPane>
                                <TabPane tab={<span><CalendarOutlined style={{ color: '#4ca3ff' }} /> Open</span>} key="open">
                                    <DataTable size="small" columns={column} dataSource={dataSource.filter(el => el.status === 'OPEN')} bordered />
                                </TabPane>
                                <TabPane tab={<span><ClockCircleOutlined style={{ color: '#4ca3ff' }} /> On Progress</span>} key="progress">
                                    <DataTable size="small" columns={column} dataSource={dataSource.filter(el => el.status === 'ONPROGRESS')} bordered />
                                </TabPane>
                                <TabPane tab={<span><FieldTimeOutlined style={{ color: 'orange' }} /> Hold</span>} key="hold">
                                    <DataTable size="small" columns={column} dataSource={dataSource.filter(el => el.status === 'HOLD')} bordered />
                                </TabPane>
                                <TabPane tab={<span><CheckCircleOutlined style={{ color: 'green' }} /> Finished</span>} key="finish">
                                    <DataTable size="small" columns={column} dataSource={dataSource.filter(el => el.status === 'FINISHED')} bordered />
                                </TabPane>
                                <TabPane tab={<span><IssuesCloseOutlined style={{ color: 'orange' }} /> Revision</span>} key="revision">
                                    <DataTable size="small" columns={column} dataSource={dataSource.filter(el => el.status === 'REVISION')} bordered />
                                </TabPane>
                                <TabPane tab={<span><CheckSquareOutlined style={{ color: 'green' }} /> Closed</span>} key="close">
                                    <DataTable size="small" columns={column} dataSource={dataSource.filter(el => el.status === 'CLOSED')} bordered />
                                </TabPane>
                                <TabPane tab={<span><CloseSquareOutlined style={{ color: 'red' }} /> Rejected</span>} key="rejected">
                                    <DataTable size="small" columns={column} dataSource={dataSource.filter(el => el.status === 'REJECTED')} bordered />
                                </TabPane>
                            </Tabs>
                            : <Spinner />
                    }
                </Card>
            </Card>
        </div>
    );
}

export default Index;
