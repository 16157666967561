import React, {useEffect, useState} from 'react';
import {Card, Typography, Form, Input, message, Space} from 'antd'
import {AddButton, EditButton, DeleteButton, PageTitle, Spinner, FormModal, DataTable} from '../../../components'
import {SentralModel} from '../../../models/SentralModel'

const {Text} = Typography
const Index = () => {
    const [countries, setCountries] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [saving, setSaving] = useState(false)
    const [modalTitle, setModalTitle]= useState(null)
    const fetchCountries = () =>{
        SentralModel.list('Country', {orderBy:"country_name", order:"asc"}).then(res=>{
            setCountries(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchCountries()
    }, []);
    
    const dataSource = [
        ...countries.map( el => {
            return {
                id: el.country_id,
                country_name: el.country_name
            }
        })
    ];

    const columns = [{
            title: 'No',
            dataIndex: 'no',
            key: 'name',
        },
        {
          title: 'Country Name',
          dataIndex: 'country_name',
          key: 'country_name'
        },
        {
            title: 'Action',
            width:120,
            render:(row) =>(
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => editCountries(row)}/>
                        <DeleteButton onConfirm={() => deleteCountries(row.id)}/>
                    </Space>
                </div>
            )
        },
    ];

    const addCountry=()=>{
        setModalTitle('Add Country')
        setDefaultValues({
            country_id: 0,
            country_name: ''
        })
        setFormModal(true)
    }

    const editCountries = (v) =>{
        setModalTitle('Edit Countries')
        setDefaultValues({
            country_id: v.id,
            country_name: v.country_name
        })
        setFormModal(true)
    }

    const deleteCountries = (id) =>{
        message.loading('deleting country')
        SentralModel.deleteById('Country', id).then((res)=>{
            message.destroy()
            message.success('Countries deleted', 2)
            let tmp = countries
            let index = tmp.findIndex(el => el.country_id===id)
            tmp.splice(index, 1);
            setCountries([])
            setCountries(tmp)
        })
    }

    const saveCountry = (v) => {
        setSaving(true)
        let payload={
            country_id: v.id,
            country_name: v.country_name
        }
        SentralModel.save('Country', payload, v.country_id).then((res)=>{
            setFormModal(false)
            fetchCountries()
            message.success('Country saved',2);
        }).finally(() => {
            setSaving(false)
        })
    }


    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Countries" breadcrumbs={[['Country']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>Country List</Text>
                        <AddButton right onAdd={addCountry} title="New Country"/>
                    </div> }>

                    {
                        (loaded) ?
                        <DataTable size='small' bordered dataSource={dataSource} columns={columns}/>
                        :
                        <Spinner/>
                    }
                </Card>
                {
                    (formModal) ?
                    <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v)=>saveCountry(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                        <Form.Item name="country_id" hidden>
                            <Input placeholder="input placeholder" />
                        </Form.Item>
                        <Form.Item label="Country Name" name="country_name"  required rules={[{required:true}]}>
                            <Input placeholder="Country Name" />
                        </Form.Item>
                    </FormModal> :
                    null
                }
            </Card>
        </div>
    );
}

export default Index;
