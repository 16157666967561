import { useState, useEffect } from 'react'
import { Card, Space, Form, Input, Typography, InputNumber, message, Tag, Row, Col, Button } from 'antd'
import { AddButton, BasicModal, DataTable, EditButton, FormModal, PageTitle, Spinner } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import authData from '../../../helpers/authData'

const { Text } = Typography

const Index = () => {
    const [form] = Form.useForm()
    const [scoreCardWeightId, setScoreCardWeightId] = useState(0)
    const [listWeight, setListWeight] = useState([])
    const [listWeightCategory, setListWeightCategory] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [showModalCategory, setShowModalCategory] = useState(false)
    const [showModalAddCategory, setShowModalAddCategory] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [modalCategoryTitle, setModalCategoryTitle] = useState('')
    const [modalAddCategoryTitle, setModalAddCategoryTitle] = useState('')

    const fetchScoreCardWeight = (v) => {
        SentralModel.action("ScoreCardWeight", "get", { division_id: authData.division_id }, null).then((res) => {
            setListWeight(res.data)
            if (v) {
                setLoaded(true)
            }
        })
    }

    useEffect(() => {
        fetchScoreCardWeight(true);

    }, [])

    const addWeight = () => {
        setShowModal(true)
        setModalTitle('Add Progress')
        form.setFieldsValue({
            score_card_weight_id: 0,
            type: "",
            description: "",
            weight: 0,
        })
    }

    const editWeight = (v) => {
        setShowModal(true)
        setModalTitle('Edit Progress')
        form.setFieldsValue({
            score_card_weight_id: v.score_card_weight_id,
            type: v.type,
            weight: v.weight,
        })
    }

    const onShowCategoryModal = (v) => {
        setScoreCardWeightId(v.score_card_weight_id)
        setListWeightCategory(v?.score_card_category)
        setShowModalCategory(true)
        setModalCategoryTitle("Score Card Category")
    }

    const addCategory = () => {
        form.setFieldsValue({
            score_card_category_weight_id: 0,
            description: "",
            weight: 0,
        })
        setShowModalAddCategory(true)
        setModalAddCategoryTitle("Add Category Weight")
    }

    const editCategory = (v) => {
        form.setFieldsValue({
            score_card_category_weight_id: v.score_card_category_weight_id,
            score_card_weight_id: v.score_card_weight_id,
            description: v.description,
            weight: v.weight,
        })
        setShowModalAddCategory(true)
        setModalAddCategoryTitle("Edit Category Weight")
    }

    const saveWeight = (v) => {
        let payload = {
            ...v,
            division_id: authData.division_id
        }

        SentralModel.action("ScoreCardWeight", v.score_card_weight_id === 0 ? "save" : "update", payload, v.score_card_weight_id).then((res) => {
            if (v.score_card_weight_id === 0) {
                message.success("Success Add Weight")
            } else {
                message.success("Success Update Weight")
            }
            fetchScoreCardWeight(false)
            setShowModal(false)
        }).catch((err) => {
            message.warning("Failed Add Weight")
        })
    }

    const saveWeightCategory = (v) => {
        let payload = {
            ...v,
            score_card_weight_id: scoreCardWeightId
        }

        SentralModel.action("ScoreCardWeight", v.score_card_category_weight_id === 0 ? "saveCategory" : "updateCategory", payload, v.score_card_category_weight_id).then((res) => {
            if (v.score_card_category_weight_id === 0) {
                message.success("Success Add Weight Category")
            } else {
                message.success("Success Update Weight Category")
            }

            fetchScoreCardWeight(false)
            setListWeightCategory(res.data)
            setShowModalAddCategory(false)
        }).catch((err) => {
            message.warning("Failed Save Weight Category")
        })
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'name',
            widht: '2%',
            align: 'center'
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: 'Weight',
            dataIndex: 'weight',
            key: 'weight'
        },
        {
            title: 'Total Category',
            key: 'total_category',
            render: (row) => (
                <Tag style={{ cursor: 'pointer' }} onClick={() => onShowCategoryModal(row)}>{row?.score_card_category?.length}</Tag>
            ),
            align: 'center'
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => editWeight(row)} />
                    </Space>
                </div>
            )
        },
    ];

    const columnCategory = [
        {
            title: "Description",
            dataIndex: "description",
            key: "description"
        },
        {
            title: "Weight",
            dataIndex: "weight",
            key: "weight"
        },
        {
            title: "Action",
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => editCategory(row)} />
                    </Space>
                </div>
            ),
            align: "center"
        },
    ]

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Bobot Score Card" breadcrumbs={[['Score Card']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{ float: 'left' }}>Score Card Weight List</Text>
                        <AddButton right onAdd={addWeight} title="New Weight" />
                    </div>
                }>
                    {
                        (loaded) ?
                            <DataTable size='small' bordered dataSource={listWeight} columns={columns} search={false} pagination={false} />
                            :
                            <Spinner />
                    }
                </Card>
            </Card>

            {
                showModal ?
                    <FormModal form={form} title={modalTitle} submitForm={(v) => saveWeight(v)} width={500} showModal={showModal} onCloseModal={() => setShowModal(false)}>
                        <Form.Item name="score_card_weight_id" hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item name="type" label="Type" rules={[{ required: true }]}>
                            <Input placeholder='Type' />
                        </Form.Item>
                        <Form.Item name="weight" label="Weight" rules={[{ required: true }]}>
                            <InputNumber min={0} max={100} placeholder='Weight' />
                        </Form.Item>
                    </FormModal> : null
            }

            {
                showModalCategory ?
                    <BasicModal showModal={showModalCategory} title={modalCategoryTitle} onCloseModal={() => setShowModalCategory(false)} widht={700}>
                        <Row justify="end" className="mb-3">
                            <Col>
                                <Button type="primary" onClick={addCategory}>Add Category</Button>
                            </Col>
                        </Row>
                        <DataTable pagination={false} size='small' bordered dataSource={listWeightCategory} columns={columnCategory} search={false} />
                    </BasicModal>
                    : null
            }

            {
                showModalAddCategory ?
                    <FormModal form={form} title={modalAddCategoryTitle} submitForm={(v) => saveWeightCategory(v)} width={500} showModal={showModalAddCategory} onCloseModal={() => setShowModalAddCategory(false)}>
                        <Form.Item name="score_card_category_weight_id" hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item name="score_card_weight_id" hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item name="description" label="Description" rules={[{ required: true }]}>
                            <Input placeholder='Description' />
                        </Form.Item>
                        <Form.Item name="weight" label="Weight" rules={[{ required: true }]}>
                            <InputNumber min={0} max={100} placeholder='Weight' />
                        </Form.Item>
                    </FormModal> : null
            }
        </div>
    )
}

export default Index