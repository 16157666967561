import { useState, useEffect } from 'react'
import { Card, Form, Input, message } from 'antd'
import { DataTable, EditButton, FormModal, PageTitle } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'

const Index = () => {
    const [form] = Form.useForm()
    const [employees, setEmmployees] = useState([])
    const [showModal, setShowModal] = useState(false)

    const fethEmployee = () => {
        SentralModel.action("ItInfra", "smailPin", null, null).then((res) => {
            setEmmployees(res.data)
        })
    }

    useEffect(() => {
        fethEmployee()

    }, [])

    const onShowEditModal = (v) => {
        setShowModal(true)
        form.setFieldsValue({
            employee_code: v.employee_code,
            pin: v.user.pin
        })
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'name',
            width: '5%'
        },
        {
            title: 'Employee Name',
            key: 'employee_name',
            dataIndex: 'name'
        },
        {
            title: 'PIN',
            key: 'pin',
            render: (row) => (
                row?.user?.pin
            )
        },
        {
            title: 'Action',
            key: 'action',
            render: (row) => (
                <EditButton onEdit={() => onShowEditModal(row)} />
            ),
            align: 'center'
        }
    ]

    const onFinish = (v) => {
        const specialChar = `/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;`; //eslint-disable-line
        const checkChar = specialChar.split("").some(char => v.pin.includes(char))
        if (v.pin.length !== 4) {
            message.warning("Invalid, Pin must be 4 character")
            return
        }
        if (isNaN(parseInt(v.pin)) || checkChar) {
            message.warning("Pin must be number")
            return
        }

        SentralModel.action("ItInfra", "updateSmailPin", { pin: v.pin }, v.employee_code).then((res) => {
            message.success("Success Update PIN")
            setShowModal(false)
            fethEmployee()
        }).catch((err) => {
            message.warning("Failed Update PIN")
        })
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="SMAIL Pin" breadcrumbs={[['SMAIL Pin']]}></PageTitle>
                <Card title="Employee">
                    {
                        employees &&
                        <DataTable columns={columns} dataSource={employees} />
                    }
                </Card>
            </Card>

            {
                showModal ?
                    <FormModal submitForm={onFinish} showModal={showModal} title="Edit SMAIL Pin" form={form} onCloseModal={() => setShowModal(false)}>
                        <Form.Item name="employee_code" hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item name="pin" label="PIN" rules={[{ required: true }]}>
                            <Input
                                placeholder='0000'
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </FormModal>
                    : null
            }
        </div>
    )
}

export default Index