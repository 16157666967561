import React, { useEffect, useState } from 'react';
import { Card, Typography, Space, Form, Input, message } from 'antd'
import { PageTitle, Spinner, DataTable, ViewButton, AddButton, EditButton, DeleteButton, FormModal } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import { checkRole } from '../../../helpers/roleData'

const { Text } = Typography
const Index = () => {
    const [chapters, setChapters] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [saving, setSaving] = useState(false)
    const [modalTitle, setModalTitle] = useState(null)

    const fetchChapters = () => {
        SentralModel.action('Documentation', 'getChapter', {}, 0).then(res => {
            setChapters(res.message)
        }).finally(() => {
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchChapters()
    }, []);

    const dataSource = [
        ...chapters.map(el => {
            return {
                id: el.chapter_id,
                chapter_no: el.chapter_no,
                title: el.title,
                total_article: el.total_article
            }
        })
    ];

    const columns = [
        {
            title: 'Chapter',
            dataIndex: 'chapter_no',
            key: 'chapter_no',
            width: '10%',
            render: (text) => (
                <div className="text-center">
                    {text}
                </div>
            )
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width: '70%'
        },
        {
            title: 'Total Article',
            dataIndex: 'total_article',
            key: 'total_article',
            width: '10%',
            render: (text) => (
                <div className="text-center">
                    {text}
                </div>
            )
        }
    ];

    if (checkRole('SUPERADMIN') || checkRole('ADMIN') || checkRole('HR')) {
        columns.push({
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <ViewButton to={'/documentation/regulation/detail/' + row.id} />
                        <EditButton onEdit={() => editChapter(row)} />
                        <DeleteButton onConfirm={() => deleteChapter(row.id)} />
                    </Space>
                </div>
            )
        })
    } else {
        columns.push({
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <ViewButton to={'/documentation/regulation/detail/' + row.id} />
                    </Space>
                </div>
            )
        })
    }

    const addChapter = () => {
        setModalTitle('Add Chapter')
        setDefaultValues({
            doc_regulation_chapter_id: 0,
            chapter_no: '',
            title: ''
        })
        setFormModal(true)
    }

    const editChapter = (v) => {
        setModalTitle('Edit Chapter')
        setDefaultValues({
            doc_regulation_chapter_id: v.id,
            chapter_no: v.chapter_no,
            title: v.title
        })
        setFormModal(true)
    }

    const deleteChapter = (id) => {
        message.loading('deleting chapter')
        SentralModel.deleteById('DocumentRegulationChapter', id).then((res) => {
            message.destroy()
            message.success('Chapter deleted', 2)
            let tmp = chapters
            let index = tmp.findIndex(el => el.doc_regulation_chapter_id === id)
            tmp.splice(index, 1);
            setChapters([])
            setChapters(tmp)
        })
    }

    const saveChapter = (v) => {
        setSaving(true)
        let payload = {
            doc_regulation_chapter_id: v.doc_regulation_chapter_id,
            chapter_no: v.chapter_no,
            title: v.title
        }
        SentralModel.save('DocumentRegulationChapter', payload, v.doc_regulation_chapter_id).then((res) => {
            setFormModal(false)
            fetchChapters();
            message.success('Chapter saved', 2);
        }).finally(() => {
            setSaving(false)
        })
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Company Regulations" breadcrumbs={[['Company Regulation']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{ float: 'left' }}>Regulation List</Text>
                        {
                            checkRole('ADMIN') || checkRole('HR') ?
                                <AddButton right onAdd={addChapter} title="New Chapter" />
                                : null
                        }
                    </div>}>

                    {
                        (loaded) ?
                            <DataTable size='small' bordered dataSource={dataSource} columns={columns} />
                            :
                            <Spinner />
                    }
                </Card>

                {
                    (formModal) ?
                        <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => saveChapter(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                            <Form.Item name="doc_regulation_chapter_id" hidden>
                                <Input placeholder="input placeholder" />
                            </Form.Item>
                            <Form.Item label="Chapter Number" name="chapter_no" rules={[{ required: true }]}>
                                <Input placeholder="Chapter Number" />
                            </Form.Item>
                            <Form.Item label="Title" name="title" rules={[{ required: true }]}>
                                <Input placeholder="Title" />
                            </Form.Item>
                        </FormModal>
                        : null
                }
            </Card>
        </div>
    );
}

export default Index;
