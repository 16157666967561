import React, { useState, useEffect } from 'react'
import { Card, Descriptions, Row } from 'antd'
import { PageTitle } from '../../../components/index'
import { SentralModel } from '../../../models/SentralModel'
import { toIdr } from '../../../utils/currencyHandler'
import { CustomModel } from '../../../models/CustomModel'

const MyKpi = () => {
    const [activeYear, setActiveYear] = useState('')
    const [appraisal, setAppraisal] = useState([])
    const [employee, setEmployee] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [salesDivision] = useState([21, 22, 28, 29, 30, 36])
    const [dataAppraisal, setDataAppraisal] = useState({})

    const fetchAppraisals = (salesDivision) => {
        setLoaded(false)
        SentralModel.action('Appraisal', 'getUserAppraisal').then(res => {
            setAppraisal(res.data)
            setEmployee(res.data.employee)
            setActiveYear(res.data.year)

            res.data.categories.map((ctg, key) => {
                return ctg.items.map((it, key) => {
                    let value = 0;
                    it.values.map((el) => {
                        return value = value + el.value
                    })

                    return Object.assign(it, { kpi_average: (value / res.data.evaluators.length)?.toFixed(2) })
                })
            })

            if (salesDivision.some(el => el === res.data.employee.division_id)) {
                if (res.data.employee.slp_code) {
                    CustomModel.boost('APISAP', 'data_appraisal/' + res.data.employee.employee_code, {}).then(res => {
                        setDataAppraisal(res.data)
                    })
                }
            }

            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchAppraisals(salesDivision)
    }, [salesDivision]);

    return (
        <Card className="content-container">

            <PageTitle title={'My KPI ' + activeYear} breadcrumbs={[['My KPI']]}></PageTitle>

            {
                loaded ?
                    <>
                        <div className='mb-5'>
                            <Descriptions title="KPI Information" bordered>
                                <Descriptions.Item labelStyle={{ fontWeight: 'bold', fontSize: 14 }} label="Name">{employee?.name}</Descriptions.Item>
                                <Descriptions.Item labelStyle={{ fontWeight: 'bold', fontSize: 14 }} label="Division">{employee?.division?.division_name}</Descriptions.Item>
                                <Descriptions.Item labelStyle={{ fontWeight: 'bold', fontSize: 14 }} label="KPI">{appraisal?.kpi_value ? appraisal?.kpi_value?.toFixed(2) : appraisal?.total_kpi?.toFixed(2)}</Descriptions.Item>
                            </Descriptions >
                        </div >

                        <Row>
                            <table className="table-collapse table-default" style={{ width: '100%' }}>
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th className="text-left">Component</th>
                                        <th className='text-center'>Kpi Score Average</th>
                                    </tr >
                                </thead >
                                <tbody>
                                    {
                                        appraisal?.categories?.map((el, i) => (
                                            (el.items.length > 0) ?
                                                <React.Fragment key={'category_' + i}>
                                                    <tr >
                                                        <th className="text-left" colSpan={2 + appraisal.evaluators.length}>{el.name}</th>
                                                    </tr>
                                                    {
                                                        el.items.map((item, j) => (
                                                            <>
                                                                {/* {el.kpi_item_category_id === 7 && j === 0 && appraisal.yearly_target ?
                                                                    <tr key={'a' + j} style={{ backgroundColor: '#f1c40f' }}>
                                                                        <td colSpan={2 + appraisal.evaluators.length}>Target : <strong>{toIdr(appraisal.yearly_target)}</strong> | Achievement (SMI, SKI, SST) : <strong>{toIdr(appraisal.yearly_achievement)} ({parseFloat((appraisal.yearly_achievement / appraisal.yearly_target) * 100).toFixed(2)})%</strong></td>
                                                                    </tr>
                                                                    : null} */}


                                                                {
                                                                    el.kpi_item_category_id === 3 && (item.kpi_item_id === 567) && dataAppraisal.cancel_so ?
                                                                        <tr key={'c' + j} style={{ backgroundColor: '#f1c40f' }}>
                                                                            <td colSpan={7}>Total SO Cancel : <strong>{dataAppraisal.cancel_so}</strong></td>
                                                                        </tr> : null
                                                                }

                                                                {el.kpi_item_category_id === 2 && j === 0 ?
                                                                    <tr key={'b' + j} style={{ backgroundColor: '#f1c40f' }}>
                                                                        <td colSpan={2 + appraisal.evaluators.length}>Total Leave : <strong>{appraisal.statistic.total_leave}</strong> | Total Sick : <strong>{appraisal.statistic.total_sick}</strong> | Total Alpha : <strong>{appraisal.statistic.total_absence}</strong></td>
                                                                    </tr>
                                                                    : null}

                                                                {el.kpi_item_category_id === 10 && j === 0 ?
                                                                    <tr key={'c' + j} style={{ backgroundColor: '#f1c40f' }}>
                                                                        <td colSpan={7}>Total Training : <strong>{appraisal.statistic.total_training}</strong></td>
                                                                    </tr>
                                                                    : null}

                                                                {(el.kpi_item_category_id === 3 || el.kpi_item_category_id === 7) && (item.kpi_item_id === 229 || item.kpi_item_id === 256 || item.kpi_item_id === 528 || item.kpi_item_id === 558) && dataAppraisal.yearly_target ?
                                                                    <tr key={'d' + j} style={{ backgroundColor: '#f1c40f' }}>
                                                                        <td colSpan={7}>Target Perbulan : <strong>{toIdr(dataAppraisal.monthly_target)}</strong> Achievement Perbulan (SMI, SKI, SST) : <strong>{toIdr(dataAppraisal.monthly_achievement ?? 0)}</strong>   Persentase : <strong>({parseFloat((dataAppraisal.monthly_achievement / dataAppraisal.monthly_target) * 100).toFixed(2)})%</strong></td>
                                                                    </tr>
                                                                    : null}

                                                                {el.kpi_item_category_id === 3 && (item.kpi_item_id === 230 || item.kpi_item_id === 257 || item.kpi_item_id === 529 || item.kpi_item_id === 555) && appraisal.yearly_target ?
                                                                    <tr key={'e' + j} style={{ backgroundColor: '#f1c40f' }}>
                                                                        <td colSpan={2 + appraisal.evaluators.length}>Penjualan Tahun Sebelumnya (SMI, SKI, SST) : <strong>{toIdr(appraisal.previous_year)}</strong> Penjualan Tahun Ini (SMI, SKI, SST) : <strong>{toIdr(appraisal.yearly_achievement)}</strong> Growth : <strong>({parseFloat(((appraisal.yearly_achievement - appraisal.previous_year) / appraisal.yearly_achievement) * 100).toFixed(2)})%</strong></td>
                                                                    </tr>
                                                                    : null}

                                                                {(el.kpi_item_category_id === 3 || el.kpi_item_category_id === 7) && (item.kpi_item_id === 232 || item.kpi_item_id === 259 || item.kpi_item_id === 553 || item.kpi_item_id === 564) && dataAppraisal.yearly_target ?
                                                                    <tr key={'f' + j} style={{ backgroundColor: '#f1c40f' }}>
                                                                        <td colSpan={7}>Penambahan Account Persemester (SMI, SKI, SST) : <strong>{dataAppraisal.monthly_account}</strong></td>
                                                                    </tr>
                                                                    : null}

                                                                {(el.kpi_item_category_id === 3 || el.kpi_item_category_id === 7) && (item.kpi_item_id === 233 || item.kpi_item_id === 260 || item.kpi_item_id === 552 || item.kpi_item_id === 561) && dataAppraisal.yearly_target ?
                                                                    <tr key={'g' + j} style={{ backgroundColor: '#f1c40f' }}>
                                                                        <td colSpan={7}>Total Quotation : <strong>{toIdr(dataAppraisal.quotation)}</strong>, Total Invoicing: <strong>{toIdr(dataAppraisal.invoicing)}</strong>, Persentase Quotation menjadi Invoice (SMI, SKI, SST) : <strong>{dataAppraisal.percentage_invoice}%</strong></td>
                                                                    </tr>
                                                                    : null}

                                                                {el.kpi_item_category_id === 3 && (item.kpi_item_id === 239 || item.kpi_item_id === 266 || item.kpi_item_id === 546) && appraisal.yearly_target ?
                                                                    <tr key={'h' + j} style={{ backgroundColor: '#f1c40f' }}>
                                                                        <td colSpan={2 + appraisal.evaluators.length}>Total Open DO (SMI, SKI, SST) : <strong>{appraisal.open_do}</strong></td>
                                                                    </tr>
                                                                    : null}

                                                                <tr key={'value_' + j}>
                                                                    <td className="text-center" style={{ verticalAlign: 'top' }}>{j + 1}</td>
                                                                    <td>{item.description} ({item.rate}%)</td>
                                                                    <td className="text-center" style={{ verticalAlign: 'top' }} >
                                                                        {
                                                                            item.kpi_average === null || item.kpi_average === 'NaN' ? '0' : item.kpi_average
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        ))
                                                    }
                                                </React.Fragment>
                                                : null
                                        ))
                                    }
                                </tbody>

                            </table >
                        </Row >
                    </>
                    :
                    null
            }
        </Card >
    )
}

export default MyKpi