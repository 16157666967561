import React, {useState, useEffect} from 'react';
import {Card, Row, Col, Typography} from 'antd'
import { PageTitle, PrintPdfButton, Spinner} from '../../../components'
import ApprovalLog from '../ApprovalLog'
import DetailHeader from '../DetailHeader'
import {useParams} from 'react-router-dom';
import { SentralModel } from '../../../models/SentralModel';
import {toFullDate} from '../../../utils/dateHandler'

const {Text} = Typography

const Detail = () => {
    const {resignId} = useParams()
    const [resign, setResign] = useState(null)

    const fetchResign = (id) => {
        SentralModel.get('SubmissionResign', {}, id).then((res)=>{
            setResign(res.data)
        })
    }

    useEffect(() => {
        fetchResign(resignId)
    }, [resignId]);

    const printData = () => {
        window.open(process.env.REACT_APP_API_URL + '/print/resign/' + resignId)
    }

    return (
        <Card className="content-container">
            <div>
                <PageTitle title="Resign" breadcrumbs={[['Resign', '/resign'], ['Detail']]}></PageTitle>
            </div>
            {
                (resign) ? 
                <div>
                    <Row className="mb-3">
                        <Col span={24} >
                            <PrintPdfButton right onPrint={printData} />
                        </Col>
                    </Row>
                    <DetailHeader 
                        name={resign.employee.name} 
                        date={resign.created_at}
                        status={resign.status}/>
                    <Card bordered  className="mb-4">
                        <Text strong>RESIGN DETAIL</Text>
                        <Row className="mb-4 mt-1">
                            <Col span={12}>
                                <table className="table-collapse table-default" style={{width:'100%'}}>
                                    <tbody>
                                        <tr>
                                            <th>Employee Name</th><td>{resign.employee.name}</td>
                                        </tr>
                                        <tr>
                                            <th>Division</th><td>{resign.employee.division.division_name}</td>
                                        </tr>
                                        <tr>
                                            <th>Position</th><td>{resign.employee.position.position_name}</td>
                                        </tr>
                                        <tr>
                                            <th>Exit Date</th> 
                                            <td>{toFullDate(resign.exit_date)}</td>
                                        </tr>
                                        <tr>
                                            <th>Reason</th><td>{resign.reason}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Card>
                    <Card bordered>
                        <ApprovalLog category="RESIGN" 
                            approvers={resign.approver} />
                    </Card>
                </div> : <Spinner/>
            }
            
        </Card>
    );
}

export default Detail;
