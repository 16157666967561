import React from 'react';
import {Card, Tabs} from 'antd'
import {FieldTimeOutlined,CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons'
import {DataTable, ShowButton} from '../../../components'
import moment from 'moment'

const {TabPane} = Tabs;

const Index = (props) => {
    const attendanceColumn = [{
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'name',
        },
        {
            title: 'Nama',
            dataIndex: 'name',
            key: 'name'
        },{
            title: 'CRF Code',
            dataIndex: 'crf_code',
            key: 'crf_code',
        },
        {
            title: 'Email / Ext',
            render: (row) => (
                <div>
                    {row.email} / {row.extension_no}
                </div>
            )
        },{
            title: 'Request Date',
            dataIndex: 'request_date',
            key: 'request_date',
            render: (text) => (
                <div className="text-center">
                    {moment(text).format('DD MMMM YYYY')}
                </div>
            )
        },{
            title:'Action',
            width:'10%',
            render:(row) => (
                <div className="text-center">
                    <ShowButton link to={'/submission-list/crf/'+row.id} />
                </div>
            )
        }
    ];

    const attendanceData = [
        ...props.data.map((el) => {
            return{
                id:el.request_id,
                name:el.name,
                crf_code: el.change_request_form_code,
                email: el.work_email,
                extension_no: el.extension_no,
                request_date: el.request_date,
                status: el.status,
                submitted_at: el.submitted_at
            }
        })
    ]

    return (
        <Card>
            <Tabs defaultActiveKey="waiting">
                <TabPane tab={<span><FieldTimeOutlined style={{color:'#4ca3ff'}}/> Waiting Approval</span>} key="waiting">
                    <DataTable size="small" columns={attendanceColumn} dataSource={attendanceData.filter(el => el.status==='WAITING')} bordered/>
                </TabPane>
                <TabPane tab={<span><CheckCircleOutlined style={{color:'green'}}/> Approved</span>} key="approved">
                    <DataTable size="small" columns={attendanceColumn} dataSource={attendanceData.filter(el => el.status==='APPROVED')} bordered/>
                </TabPane>
                <TabPane tab={<span><CloseCircleOutlined style={{color:'red'}}/> Rejected</span>} key="rejected">
                    <DataTable size="small" columns={attendanceColumn} dataSource={attendanceData.filter(el => el.status==='REJECTED')} bordered/>
                </TabPane>
            </Tabs>
        </Card>
    );
}

export default Index;
