import React, { useEffect, useState } from 'react'
import { Row, Col, Typography, Divider, Empty, Form, Input, Select, message } from 'antd'
import ProfileItem from './ProfileItem';
import ActionButton from './ActionButton';
import { AddButton, FormModal, Spinner } from '../../components';
import { SentralModel } from '../../models/SentralModel'
import { useParams } from 'react-router-dom'
import authData from '../../helpers/authData'

const { Title } = Typography
const { Option } = Select;

const Education = (props) => {
    const { employeeCode } = useParams()
    const [empEducations, setEmpEducations] = useState(props.data);
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false);
    const [defaultValues, setDefaultValues] = useState({});
    const [saving, setSaving] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [tiers, setTiers] = useState([]);
    const [selectedTier, setSelectedTier] = useState([]);
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState([]);

    const fetchEmpEducations = () => {
        setLoaded(false)
        SentralModel.list('EmployeeEducation', { filter: [['employee_code', props.emp_code]] }).then(res => {
            setEmpEducations(res.data)
        }).finally(() => {
            setLoaded(true)
        })
    }

    const fetchTiers = () => {
        SentralModel.list('GeneralDetail', { filter: [['general_code', 'ET']], orderBy: 'order_key', order: 'asc' }).then(res => {
            setTiers(res.data)
        }).finally(() => {
            setLoaded(true)
        })
    }

    const fetchCities = () => {
        SentralModel.list('City', { orderBy: 'city_name', order: 'asc' }).then(res => {
            setCities(res.data)
        }).finally(() => {
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchTiers()
        fetchCities()
    }, []);

    const addEducation = () => {
        setModalTitle('Add Education')
        setDefaultValues({
            emp_education_id: 0
        })
        setFormModal(true)
    }

    const editEducation = (v) => {
        setModalTitle('Edit Education')
        setDefaultValues({
            emp_education_id: v.emp_education_id,
            education_tier: v.education_tier.detail_code,
            name: v.name,
            city_id: v.city.city_id,
            year_in: v.year_in,
            year_out: v.year_out,
            majors: v.majors,
            pass_value: v.pass_value
        })
        setSelectedTier(v.education_tier.detail_code)
        setSelectedCity(v.city.city_id)
        setFormModal(true)
    }

    const saveEducation = (v) => {
        setSaving(true)
        let payload = {
            emp_education_id: v.emp_education_id,
            employee_code: props.emp_code,
            education_tier: selectedTier,
            name: v.name,
            city_id: selectedCity,
            year_in: v.year_in,
            year_out: v.year_out,
            majors: v.majors,
            pass_value: v.pass_value
        }
        SentralModel.save('EmployeeEducation', payload, v.emp_education_id).then((res) => {
            setFormModal(false)
            fetchEmpEducations()
            message.success('Education saved', 2);
        }).finally(() => {
            setSaving(false)
        })
    }

    const deleteEducation = (id) => {
        message.loading('deleting education')
        SentralModel.deleteById('EmployeeEducation', id).then((res) => {
            message.destroy()
            message.success('Education deleted', 2)
            let tmp = empEducations
            let index = tmp.findIndex(el => el.emp_education_id === id)
            tmp.splice(index, 1);
            setEmpEducations([])
            setEmpEducations(tmp)
        })
    }

    const changeTier = e => {
        setSelectedTier(e)
    }

    const changeCity = e => {
        setSelectedCity(e)
    }

    return (
        <div>
            <div className="text-right">
                {
                    authData.employee_code === employeeCode || employeeCode === undefined || authData.employee_code === 'SMI20220323229' || authData.employee_code === 'SMI20180322139' ?
                        <AddButton onAdd={addEducation}>Add data</AddButton> : null
                }
            </div>
            {
                loaded ?
                    (empEducations.length > 0) ?
                        empEducations.map((el, key) => (
                            <div key={key}>
                                <Divider orientation="left"><Title level={4}>{key + 1}.</Title></Divider>
                                {
                                    authData.employee_code === employeeCode || employeeCode === undefined || authData.employee_code === 'SMI20220323229' || authData.employee_code === 'SMI20180322139' ?
                                        <ActionButton editable deleteable onEdit={() => editEducation(el)} onDelete={() => deleteEducation(el.emp_education_id)} /> : null
                                }
                                <Row>
                                    <ProfileItem span={6} title="Education Level">{el.education_tier.description}</ProfileItem>
                                    <ProfileItem span={6} title="Institution">{el.name}</ProfileItem>
                                    <ProfileItem span={6} title="Major">{el.majors}</ProfileItem>
                                    <ProfileItem span={6} title="Year">{el.year_in} - {el.year_out}</ProfileItem>
                                </Row>
                            </div>
                        ))
                        : <Empty />
                    : <Spinner />
            }
            {
                (formModal) ?
                    <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => saveEducation(v)} width={800} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                        <Row>
                            <Col span={24} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                <Form.Item name="emp_education_id" hidden>
                                    <Input placeholder="input placeholder" />
                                </Form.Item>
                                <Form.Item label="Institution" name="name" rules={[{ required: true }]}>
                                    <Input placeholder="Institution" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                <Form.Item label="Education Level" name="education_tier" rules={[{ required: true }]}>
                                    <Select showSearch style={{ width: "100%" }} placeholder="Select Education Level" optionFilterProp="children" onChange={changeTier} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {
                                            tiers.map((el, key) => (
                                                <Option key={key} value={el.detail_code}>{el.description}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                <Form.Item label="City" name="city_id" rules={[{ required: true }]}>
                                    <Select showSearch style={{ width: "100%" }} placeholder="Select City" optionFilterProp="children" onChange={changeCity} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {
                                            cities.map((el, key) => (
                                                <Option key={key} value={el.city_id}>{el.city_name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                <Form.Item label="Year In" name="year_in" rules={[{ required: true }]}>
                                    <Input placeholder="Year In" />
                                </Form.Item>
                            </Col>
                            <Col span={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                <Form.Item label="Year Out" name="year_out" rules={[{ required: true }]}>
                                    <Input placeholder="Year Out" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                <Form.Item label="Majors" name="majors" rules={[{ required: false }]}>
                                    <Input placeholder="Majors" />
                                </Form.Item>
                            </Col>

                            <Col span={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                <Form.Item label="Pass Value" name="pass_value" rules={[{ required: false }]}>
                                    <Input placeholder="Pass Value" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </FormModal> :
                    null
            }
        </div>
    );
}

export default Education;
