import React, {useState, useEffect} from 'react';
import {Row, Col, Card, Form, Input, Typography, Select, DatePicker, Button} from 'antd';
import moment from 'moment'
import {SubmitButton, AddButton} from '../../../../components'
import { SentralModel } from '../../../../models/SentralModel';
import {DeleteOutlined} from '@ant-design/icons'
const {Text} = Typography
const {Option} = Select

function useForceUpdate(){
    // eslint-disable-next-line
    const [value, setValue] = useState(0);
    return () => setValue(value => ++value);
}

const RequestForm = (props) => {
    const forceUpdate = useForceUpdate()
    const [form] = Form.useForm()
    const [employees, setEmployees] = useState([])
    const [items, setItems] = useState([])
    const [stationeryItem, setStationeryItem] = useState([{item_id:'', quantity: null}])


    const fetchEmployee = () =>{
        SentralModel.list('Employee', {filter: [['is_active', '1']], orderBy: "name", order:"ASC"}).then((res)=>{
            setEmployees(res.data)
        })
    }

    const fetchItems = () =>{
        SentralModel.action('Stationery', 'getItemDistribution', {}, 0).then((res)=>{
            setItems(res.data)
        })
    }

    useEffect(() => {
        fetchEmployee()
        fetchItems()
    }, []);

    const addStationeryItem = () =>{
        let x=stationeryItem
        let key = stationeryItem.length
        x.push({item_id:'', quantity: null})
        setStationeryItem(x)
        forceUpdate()
        form.setFieldsValue({
            ['item_id_'+key] : null,
            ['stock_'+key] : null,
            ['quantity_'+key] : null,
        })
    }

    const submitDistribution = (v) =>{
        let data={
            date: moment(v.date).format('YYYY-MM-DD'),
            employee_code: v.employee_code,
            distribution_items: stationeryItem
        }
        props.onSubmit(data)
    }

    const removeItem = (key) =>{
        let item = stationeryItem
        item.splice(key,1)
        setStationeryItem(item)
        forceUpdate()
    }

    return (
        <Form form={form} size="middle" layout="vertical" onFinish={submitDistribution}>
            <Row justify="center">
                <Col span={20}>
                    <Row justify="space-between">
                        <Col span={11}>
                            <Form.Item name="date" label="Date" rules={[{required:true}]}>
                                <DatePicker style={{width:'100%'}}/>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item name="employee_code" label="Employee" rules={[{required:true}]}>
                                <Select showSearch allowClear placeholder="Select Employee" style={{ width: "100%" }} optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {
                                        employees.map((el, key)=>(
                                            <Option key={key} value={el.employee_code}>{el.name}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Card title={
                                <div className="full-width">
                                    <Text strong style={{float:'left'}}>Stationery Item</Text>
                                    <AddButton right onAdd={() => addStationeryItem()} title="Add Item"/>
                                </div>}>
                                
                                <table className="table-item" style={{width:'100%'}}>
                                    <thead>
                                        <tr>
                                            <th width="50%">Item</th>
                                            <th width="15%">Stock</th>
                                            <th width="15%">Quantity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            stationeryItem.map((el, key) =>(
                                                <tr key={'item'+key}>
                                                    <td>
                                                        <Form.Item name={`item_id_${key}`} rules={[{required:true}]}>
                                                            <Select showSearch allowClear placeholder="Item Name" onChange={(v) => {
                                                                let x= stationeryItem;
                                                                x[key].item_id = v
                                                                setStationeryItem(x)
                                                                form.setFieldsValue({
                                                                    ['stock_'+key] : items.find(element => element.item_id === v).quantity,
                                                                })
                                                            }} optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                                {
                                                                    items.map((el, key)=>(
                                                                        <Option key={key} value={el.item_id}>{el.item_name}</Option>
                                                                    ))
                                                                }
                                                            </Select>
                                                        </Form.Item>
                                                    </td>
                                                    <td>
                                                        <Form.Item name={`stock_${key}`}>
                                                            <Input type="number" placeholder="Stock" disabled/>
                                                        </Form.Item>
                                                    </td>
                                                    <td>
                                                        <Form.Item name={`quantity_${key}`} rules={[{required:true}]}>
                                                            <Input type="number" placeholder="Quantity" onChange={(v) => {
                                                                let x= stationeryItem;
                                                                x[key].quantity = v.target.value
                                                                setStationeryItem(x)
                                                            }}/>
                                                        </Form.Item>
                                                    </td>
                                                    <td className="text-center" style={{width:'4em'}}>
                                                        {
                                                            ((key+1)===stationeryItem.length && stationeryItem.length>1) ? 
                                                            <Button type="text" danger onClick={() => removeItem(key)} icon={<DeleteOutlined/>}/>
                                                            : null
                                                        }
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </Card>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col span={24} className="text-right">
                            <SubmitButton loading={props.saving}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
}

export default RequestForm;
