import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {Card, Form, Input, Typography, Space, message, InputNumber} from 'antd'
import {EditButton, DeleteButton, FormModal, AddButton, DataTable} from '../../../../components'
import {SentralModel} from '../../../../models/SentralModel'

const {Text} = Typography
const Index = (props) => {
    const {resignId} = useParams();
    const [inventoryForm] = Form.useForm()
    const [title, setTitle]= useState(null)
    const [formModal, setFormModal] = useState(false)
    const [saving, setSaving] = useState(false)

    const dataSource = [
        ...props.data.map( el => {
            return {
                id: el.sub_resign_inventory_id,
                sub_resign_id: el.sub_resign_id,
                goods: el.goods,
                qty: el.qty,
                serial_number: el.serial_number,
                receiver: el.receiver,
            }
        })
    ];

    const dataColumn = [
        {
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'no',
            width: '10%'
        },
        {
            title: 'Goods',
            dataIndex: 'goods',
            key: 'goods',
        },
        {
            title: 'Quantity',
            dataIndex: 'qty',
            key: 'qty',
        },
        {
            title: 'Serial Number',
            dataIndex: 'serial_number',
            key: 'serial_number',
        },
        {
            title: 'Receiver',
            dataIndex: 'receiver',
            key: 'receiver',
        },
        {
            title: 'Action',
            width:120,
            render:(row) =>(
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => editData(row)} />
                        <DeleteButton onConfirm={() => deleteData(row.id)} />
                    </Space>
                </div>
            )
        }
    ];

    const addData = () => {
        setTitle('Add Inventory')
        inventoryForm.setFieldsValue({
            sub_resign_inventory_id: 0,
            sub_resign_id: resignId,
            goods: '',
            qty: '',
            serial_number: '',
            receiver: '',
        })
        setFormModal(true)
    }

    const editData = (v) => {
        setTitle('Edit Inventory')
        inventoryForm.setFieldsValue({
            sub_resign_inventory_id: v.id,
            sub_resign_id: resignId,
            goods: v.goods,
            qty: v.qty,
            serial_number: v.serial_number,
            receiver: v.receiver,
        })
        setFormModal(true)
    }

    const saveData = (v) => {
        setSaving(true)
        let payload = {
            sub_resign_id: v.sub_resign_id,
            goods: v.goods,
            qty: v.qty,
            serial_number: v.serial_number,
            receiver: v.receiver,
        }
        SentralModel.save('SubmissionResignInventory', payload, v.sub_resign_inventory_id).then((res) => {
            setFormModal(false)
            props.onFetch();
            message.success('Inventory saved', 2);
        }).finally(() => {
            setSaving(false)
        })
    }

    const deleteData = (id) =>{
        message.loading('Deleting inventory')
        SentralModel.deleteById('SubmissionResignInventory', id).then((res) => {
            message.destroy();
            message.success('Inventory deleted', 2);
            props.onFetch();
        })
    }

    return (
        <div>
            <Card title={
                <div className="full-width">
                    <Text strong style={{float:'left'}}>Inventory List</Text>
                    <AddButton right onAdd={addData} title="New Inventory"/>
                </div> }>

                <DataTable size="small" columns={dataColumn} dataSource={dataSource} bordered/>
            </Card>

            {
                (formModal) ?
                <FormModal form={inventoryForm} title={title} submitForm={(v)=>saveData(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                    <Form.Item name="sub_resign_inventory_id" hidden>
                        <Input placeholder="Inventory ID" />
                    </Form.Item>
                    <Form.Item name="sub_resign_id" hidden>
                        <Input placeholder="Resign ID" />
                    </Form.Item>
                    <Form.Item label="Goods" name="goods" rules={[{required:true}]}>
                        <Input placeholder="Goods" />
                    </Form.Item>
                    <Form.Item label="Quantity" name="qty" rules={[{required:true}]}>
                        <InputNumber placeholder="Quantity" style={{width: '100%'}}/>
                    </Form.Item>
                    <Form.Item label="Serial Number" name="serial_number" rules={[{required:true}]}>
                        <Input placeholder="Serial Number" />
                    </Form.Item>
                    <Form.Item label="Receiver" name="receiver" rules={[{required:true}]}>
                        <Input placeholder="Receiver" />
                    </Form.Item>
                </FormModal>
                : null
            }
        </div>
    );
}

export default Index;
