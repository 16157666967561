import React from 'react';
import {Tag} from 'antd'
import {formatDateTime} from '../../utils/dateFormat'

const ApprovalLog = (props) => {
    const {approvers = []} = props

    const getStatus = (status) => {
        if(status==="WAITING"){
            return <Tag style={{color:'#808080',width:120}}>Waiting Approval</Tag>
        }else if(status==="APPROVED"){
            return <Tag color="green" style={{width:120}}>APPROVED</Tag>
        }else if(status==="REJECTED"){
            return <Tag color="red" style={{width:120}}>REJECTED</Tag>
        }
    }

    return (
        <div>
            <table className="table-collapse table-default" style={{width:'100%'}}>
                <thead>
                    <tr>
                        <th className="text-center" style={{width:'5%'}}>Level</th>
                        <th style={{width:'40%'}}>Name</th>
                        <th className="text-center" style={{width:'30%'}}>Status</th>
                        <th className="text-center" style={{width:'25%'}}>Submit Date</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        approvers.map((el, key) => (
                            <tr key={key}>
                                <td className="text-center">{el.level}</td>
                                <td>{el.employee.name} {props.category === 'LEAVE' && el.level === 1 && el.employee.name === props.responsible ? '(Responsible)' : ''}</td>
                                <td className="text-center" >
                                    {
                                        getStatus(el.status)
                                    }
                                    <br/>
                                    <span>{el.remarks}</span>
                                </td>
                                <td className="text-center" >{el.submitted_at == null ? '-' : formatDateTime(el.submitted_at)}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    );
}

export default ApprovalLog;
