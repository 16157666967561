import { useState, useEffect, useRef } from 'react';
import { Button, Card, Col, Row, Typography, Carousel, Space, Drawer, Form, Select } from 'antd';
import { FilterOutlined, RightOutlined, LeftOutlined } from '@ant-design/icons';
import { SentralModel } from '../../../models/SentralModel';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from 'moment';
import TableGantt from '../table';
import { Spinner } from '../../../components';

const { Title, Text, } = Typography
const { Option } = Select

Chart.register(ChartDataLabels);
Chart.defaults.set('plugins.datalabels', {
    color: 'white',
    font: {
        size: 20
    }
});

const DashboardActionPlanSales = () => {
    const ref = useRef()
    const [allData, setAllData] = useState([])
    const [dataFilter, setDataFiter] = useState([])
    const [actionPlans, setActionPlans] = useState([])
    const [division, setDivision] = useState([])
    const [salesEmployee, setSalesEmployee] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [isFilter, setIsFilter] = useState(false)
    const empStatus = ['ESPR', 'ESPB', 'ESKN']
    const idSales = [21, 22, 36]

    const fetchSales = () => {
        SentralModel.list('Employee', { filter: [['is_active', '=', 1]], fields: "employee_code,name,division_id,position_id,employee_status", orderBy: 'name', order: 'asc' }).then((res) => {
            let sales = []
            const v = res.data

            v.forEach((e) => {
                if (idSales.includes(e.division_id) && empStatus.includes(e.employee_status)) {
                    sales.push(e)
                }
            });

            setSalesEmployee(sales)
        })
    }

    const fetchDivision = () => {
        SentralModel.getData('division').then((res) => {
            const v = res.data
            let data = []
            v.map((el) => {
                if (el.division_id === 13 || el.division_id === 24 || el.division_id === 33 || el.division_id === 34) {
                    return data.push(el)
                }

                return null;
            })

            setDivision(data)
        })
    }

    const fetchActionPlan = () => {
        let payload = {
            employee_code: null,
            division_id: null,
            is_sales: true,
        }

        SentralModel.dashboardData('ActionPlan', 'getActionPlan', payload).then((res) => {
            const v = res.data
            let allData = []
            const emp = salesEmployee

            v.map((el) => {
                let range = []
                let done = []

                for (let i = parseInt(moment(el.start_date).format('M')); i <= parseInt(moment(el.end_date).format('M')); i++) {
                    range.push(i)
                }

                if (el.progress !== 0) {
                    for (let i = 0; i < Math.ceil((el.progress / (100 / range.length))); i++) {
                        done.push(range[i])
                    }
                }

                Object.assign(el, { range_date: range, date_realization: parseInt(moment(el.realization_date).format('M')), progress_average: (100 / range.length), done: done })

                return allData.push(el)
            })

            emp.map((el) => {
                const data = allData.filter((i) => i.created_by === el.employee_code)

                return Object.assign(el, { action_plans: data })
            })

            setActionPlans(emp)
            setAllData(v)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchSales()
        fetchDivision()
        fetchActionPlan()

        // eslint-disable-next-line
    }, [salesEmployee])

    const finishFilter = (v) => {
        const filter = allData.filter(e => e.division_id === v.division_id)
        setIsFilter(true)
        setDataFiter(filter)
        setDrawerOpen(false)
    }

    return (
        <div>
            <Card style={{ margin: '15px 10px 0 10px' }} className="content-container" title="Dashboard Action Plan Sales">
                {
                    loaded ?
                        <>
                            <div style={{ marginBottom: 15 }}>
                                <Row justify='space-between'>
                                    <Col span={17}>
                                        <Card>
                                            <Row>
                                                <Col span={4}>
                                                    <Button className='button-dept' style={{ width: "100%", maxWidth: 120, fontSize: 11, fontWeight: 'bold', backgroundColor: '#4cd137', border: '#4cd137', marginBottom: 10 }} type='primary' onClick={() => ref.current.goTo(0)}>ALL DATA</Button>
                                                </Col>
                                                {
                                                    actionPlans.map((el, key) => (
                                                        <>
                                                            <Col key={key} span={4}>
                                                                <Button style={{ width: "100%", maxWidth: 120, fontSize: 11, fontWeight: 'bold', marginBottom: 10 }} type='primary' onClick={() => ref.current.goTo(key + 1)}>{el.name.split(" ")[0].toUpperCase()}</Button>
                                                            </Col>
                                                        </>
                                                    ))
                                                }
                                            </Row>
                                        </Card>
                                    </Col>
                                    <Col>
                                        <Card title="Legend">
                                            <Row style={{ marginBottom: 7 }}>
                                                <Space>
                                                    <Space>
                                                        <div className='color-table-row' style={{ width: 20, height: 20 }}></div>
                                                        <Text>Timeline</Text>
                                                    </Space>
                                                    <Space>
                                                        <div className='color-table-progress' style={{ width: 20, height: 20 }}></div>
                                                        <Text>On Progress</Text>
                                                    </Space>
                                                </Space>
                                            </Row>
                                            <Row>
                                                <Space>
                                                    <Space>
                                                        <div className='color-table-done' style={{ width: 20, height: 20 }}></div>
                                                        <Text>Done</Text>
                                                    </Space>
                                                    <Space>
                                                        <div className='color-table-overdue' style={{ width: 20, height: 20 }}></div>
                                                        <Text>Overdue</Text>
                                                    </Space>
                                                </Space>
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <Carousel autoplay autoplaySpeed={5000} draggable ref={ref}>
                                    <div style={{ padding: '10px 15px' }}>
                                        <Card title={
                                            <Row justify="space-between">
                                                <Col>
                                                    <Title level={5}>ALL SALES PROJECT</Title>
                                                </Col>
                                                <Col>
                                                    <Space>
                                                        <Button onClick={() => setDrawerOpen(true)} type="primary" shape="round">Filter <FilterOutlined /></Button>
                                                        <Button onClick={() => setIsFilter(false)} type='primary' danger shape='round'>Reset Filter</Button>
                                                    </Space>
                                                </Col>
                                            </Row>
                                        }>
                                            {
                                                !isFilter ?
                                                    <TableGantt data={allData} />
                                                    :
                                                    <TableGantt data={dataFilter} />
                                            }
                                        </Card>
                                    </div>
                                    {
                                        actionPlans.map((el, key) => (
                                            <div key={el.employee_code} style={{ padding: '10px 15px' }}>
                                                <Card title={
                                                    <Row>
                                                        <Col>
                                                            <Title level={5}>{el.name.toUpperCase()}</Title>
                                                        </Col>
                                                    </Row>
                                                }>
                                                    {
                                                        el.action_plans?.length > 0 ?
                                                            <TableGantt data={el.action_plans} />
                                                            :
                                                            <div>
                                                                No Action Plans
                                                            </div>
                                                    }
                                                </Card>
                                            </div>
                                        ))
                                    }
                                </Carousel>
                            </div>
                            <div className='button-overflow-bottom'>
                                <Space>
                                    <Button type='primary' size='large' shape="circle" onClick={() => ref.current.prev()}><LeftOutlined /></Button>
                                    <Button type='primary' size='large' shape="circle" onClick={() => ref.current.next()}><RightOutlined /></Button>
                                </Space>
                            </div>
                        </>
                        : <Spinner />
                }

                <Drawer
                    title="Filter Data"
                    width={400}
                    placement="right"
                    closable={true}
                    onClose={() => setDrawerOpen(false)}
                    visible={drawerOpen}
                    key="right"
                >
                    <Form layout='vertical' onFinish={(v) => finishFilter(v)}>
                        <Form.Item label="Select Division" name="division_id">
                            <Select>
                                {
                                    division.map((el, key) => (
                                        <Option key={key} value={el.division_id}>
                                            {el.division_name}
                                        </Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Button type='primary' htmlType='submit'>Submit</Button>
                    </Form>
                </Drawer>
            </Card>
        </div >
    )
}

export default DashboardActionPlanSales