import React, { useState, useEffect } from 'react';
import { Card, Select, DatePicker, Form, Button, Drawer, Space } from 'antd';
import { FilterOutlined, FileExcelOutlined } from '@ant-design/icons'
import { PageTitle, DataTable, Spinner } from '../../../components';
import { toFullDate } from '../../../utils/dateHandler'
import { SentralModel } from '../../../models/SentralModel';
import moment from 'moment'
import currentUser from '../../../helpers/authData';

const { RangePicker } = DatePicker
const { Option } = Select

const Attendance = () => {
    const [form] = Form.useForm()
    const [loaded, setLoaded] = useState(true)
    const [showFilter, setShowFilter] = useState(false)
    const [divisions, setDivisions] = useState([])
    const [attendanceData, setAttendanceData] = useState([])
    const [selectedDivision, setSelectedDivision] = useState(null)
    const [selectedEmployee, setSelectedEmployee] = useState(null)
    const [selectedDateRange, setSelectedDateRange] = useState([])
    const [employees, setEmployees] = useState([])

    const fetchDivision = () => {
        SentralModel.list('Division', { orderBy: 'division_name', order: 'ASC' }, 0).then((res) => {
            setDivisions(res.data);
        })
    }

    const fetchEmployees = () => {
        SentralModel.list('Employee', { fields: 'employee_code,name,division_id', orderBy: 'name', order: 'ASC', filter: [['is_active', '=', '1']] }, 0).then((res) => {
            setEmployees(res.data);
        })
    }
    useEffect(() => {
        fetchDivision()
        fetchEmployees()
        if (currentUser.employee_code === 'SMI20131021081') {
            form.setFieldsValue({
                division_id: 17
            })
            setSelectedDivision(17);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filterData = (v) => {
        let ft = {
            division_id: v.division_id,
            employee_code: v.employee_code,
            date_range: (v.date_range) ? [moment(v.date_range[0]).format('YYYY-MM-DD'), moment(v.date_range[1]).format('YYYY-MM-DD')] : []
        }
        fetchReport(ft)
        setShowFilter(false)
    }

    const fetchReport = (ft) => {
        setLoaded(false)
        SentralModel.action('Report', 'attendance', ft, 0).then((res) => {
            setAttendanceData(res.data)
            setLoaded(true)
        })
    }


    const attendanceColumn = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'name',
        },
        {
            title: 'Employee Name',
            dataIndex: 'name',
            key: 'name',
        }, {
            title: 'Division',
            dataIndex: 'division_name',
            key: 'division_name',
        }, {
            title: 'Position',
            dataIndex: 'position_name',
            key: 'position_name',
        }, {
            title: 'Date',
            dataIndex: 'date',
            render: (text) => (
                toFullDate(text)
            )
        }, {
            title: 'In',
            align: 'center',
            dataIndex: 'in_time'
        }, {
            title: 'Out',
            align: 'center',
            dataIndex: 'out_time'
        }, {
            title: 'Description',
            align: 'center',
            dataIndex: 'description'
        },
    ];

    const changeDateRange = (v) => {
        if (v) {
            setSelectedDateRange([moment(v[0]).format('YYYY-MM-DD'), moment(v[1]).format('YYYY-MM-DD')])
        } else {
            setSelectedDateRange([])
        }
    }

    const exportReport = () => {
        window.open(process.env.REACT_APP_API_URL + '/report/attendance?division_id=' + selectedDivision + '&employee_code=' + selectedEmployee + '&from=' + selectedDateRange[0] + '&to=' + selectedDateRange[1])
    }

    return (
        <Card className="content-container">
            <PageTitle title="Attendance Report" breadcrumbs={[['Attendance Report']]}></PageTitle>

            <div className="text-right mb-2">
                <Space>
                    <Button type="primary" onClick={() => exportReport()} shape="circle" icon={<FileExcelOutlined />}></Button>
                    <Button shape="circle" icon={<FilterOutlined />} onClick={() => setShowFilter(true)}></Button>
                </Space>
            </div>
            {
                (loaded) ?
                    <DataTable size="small" columns={attendanceColumn} dataSource={attendanceData} bordered />
                    : <Spinner />
            }

            <Drawer
                title="Filter Report"
                width={400}
                placement="right"
                closable={false}
                onClose={() => setShowFilter(false)}
                visible={showFilter}>
                <Form form={form} layout="vertical" onFinish={filterData}>
                    {
                        currentUser.employee_code === 'SMI20131021081' ?
                            <Form.Item name="division_id" label="Division">
                                <Select
                                    disabled
                                    style={{ width: '100%' }}
                                    onChange={
                                        (v) => {
                                            setSelectedDivision(v)
                                        }
                                    } placeholder="Select Division" allowClear>
                                    {
                                        divisions.map((el, key) => (
                                            <Option
                                                key={key}
                                                value={el.division_id}
                                            >{el.division_name}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                            : <Form.Item name="division_id" label="Division">
                                <Select
                                    style={{ width: '100%' }}
                                    onChange={
                                        (v) => {
                                            setSelectedDivision(v)
                                        }
                                    } placeholder="Select Division" allowClear>
                                    {
                                        divisions.map((el, key) => (
                                            <Option
                                                key={key}
                                                value={el.division_id}
                                            >{el.division_name}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                    }
                    <Form.Item name="employee_code" label="Employee">
                        <Select
                            style={{ width: '100%' }}
                            onChange={
                                (v) => {
                                    setSelectedEmployee(v)
                                }
                            } placeholder="Select Employee" allowClear>
                            <Option value={null}>All Employee</Option>
                            {
                                employees.filter(el => el.division_id === selectedDivision).map((el, key) => (
                                    <Option
                                        key={key}
                                        value={el.employee_code}
                                    >{el.name}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name="date_range" label="Date Range" rules={[{ required: true, message: 'Date range is required' }]}>
                        <RangePicker onChange={(v) => changeDateRange(v)} />
                    </Form.Item>

                    <Form.Item >
                        <div className="text-right">
                            <Button htmlType="submit" type="primary" size="middle" icon={<FilterOutlined />}> Show</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Drawer>
        </Card>
    );
}

export default Attendance;
