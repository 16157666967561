import React, {useEffect, useState} from 'react';
import {Card, Typography, message, Space, Form, Input, Select} from 'antd'
import {EditButton, DeleteButton, PageTitle, Spinner, DataTable, CustomButton, AddButton, FormModal} from '../../../components'
import {SentralModel} from '../../../models/SentralModel'
import {ZoomInOutlined} from '@ant-design/icons'

const {Text} = Typography
const {Option} = Select;

const Index = () => {
    const [letters, setLetters] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [saving, setSaving] = useState(false)
    const [modalTitle, setModalTitle]= useState(null)
    const [documentTypes, setDocumentTypes] = useState([])
    const [divisions, setDivisions] = useState([])

    const fetchLetters = () => {
        SentralModel.list('Letter', {}).then(res=>{
            setLoaded(false)
            setLetters(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    const fetchDocumentTypes = () => {
        SentralModel.list('GeneralDetail', {filter: [['general_code', 'DT']], orderBy: 'order_key', order: 'asc'}).then(res=>{
            setLoaded(false)
            setDocumentTypes(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    const fetchDivisions = () => {
        SentralModel.list('Division', {orderBy: 'division_name', order: 'asc'}).then(res=>{
            setLoaded(false)
            setDivisions(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchLetters()
        fetchDocumentTypes()
        fetchDivisions()
    }, []);
    
    const dataSource = [
        ...letters.map( el => {
            return {
                id: el.letter_id,
                division_id: el.division_id,
                division_name: el.division.division_name,
                document_type: el.document_type,
                type: el.type.description,
                document_no: el.document_no,
                document_name: el.document_name,
                description: el.description,
            }
        })
    ];

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'no',
        },
        {
          title: 'Document Type',
          dataIndex: 'type',
          key: 'type',
        },
        {
          title: 'Document Number',
          dataIndex: 'document_no',
          key: 'document_no',
        },
        {
          title: 'Document Name',
          dataIndex: 'document_name',
          key: 'document_name',
        },
        {
          title: 'Division',
          dataIndex: 'division_name',
          key: 'division_name',
        },
        {
            title: 'Action',
            width:120,
            render:(row) =>(
                <div className="text-center">
                    <Space>
                        <CustomButton icon={<ZoomInOutlined />} link to={"/letter/detail/" + row.id}/>
                        <EditButton onEdit={() => editLetter(row)}/>
                        <DeleteButton onConfirm={() => deleteLetter(row.id)}/>
                    </Space>
                </div>
            )
        },
    ];

    const addLetter=()=>{
        setModalTitle('Add Letter')
        setDefaultValues({
            letter_id: 0,
            division_id: null,
            document_type: null,
            document_no: '',
            document_name: '',
            description: ''
        })
        setFormModal(true)
    }

    const editLetter = (v) =>{
        setModalTitle('Edit Letter')
        setDefaultValues({
            letter_id: v.id,
            division_id: v.division_id,
            document_type: v.document_type,
            document_no: v.document_no,
            document_name: v.document_name,
            description: v.description
        })
        setFormModal(true)
    }

    const saveLetter = (v) => {
        setSaving(true)
        let payload={
            letter_id: v.letter_id,
            division_id: v.division_id,
            document_type: v.document_type,
            document_no: v.document_no,
            document_name: v.document_name,
            description: v.description
        }
        SentralModel.action('Letter', 'saveLetter', payload, v.letter_id).then((res)=>{
            setFormModal(false)
            fetchLetters()
            message.success('Letter saved', 2);
        }).finally(() => {
            setSaving(false)
        })
    }

    const deleteLetter = (id) =>{
        message.loading('Deleting Letter')
        SentralModel.deleteById('Letter', id).then((res)=>{
            message.destroy()
            message.success('Letter deleted', 2)
            let tmp = letters
            let index = tmp.findIndex(el => el.letter_id === id)
            tmp.splice(index, 1);
            setLetters([])
            setLetters(tmp)
        })
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Letters" breadcrumbs={[['Letter']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>Letter List</Text>
                        <AddButton right onAdd={addLetter} title="New Letter"/>
                    </div> }>

                    {
                        (loaded) ?
                        <DataTable size='small' bordered dataSource={dataSource} columns={columns}/>
                        : <Spinner />
                    }
                </Card>
                {
                    (formModal) ?
                    <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v)=>saveLetter(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                        <Form.Item name="letter_id" hidden>
                            <Input placeholder="Letter ID" />
                        </Form.Item>
                        <Form.Item label="Document Type" name="document_type" rules={[{required:true}]}>
                            <Select placeholder="Select type" style={{ width: "100%" }}>
                                {
                                    documentTypes.map((el, key) => (
                                        <Option key={key} value={el.detail_code}>{el.description}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="Document Number" name="document_no" rules={[{required:true}]}>
                            <Input placeholder="Document Number" />
                        </Form.Item>
                        <Form.Item label="Document Name" name="document_name" rules={[{required:true}]}>
                            <Input placeholder="Document Name" />
                        </Form.Item>
                        <Form.Item label="Division" name="division_id" rules={[{required:true}]}>
                            <Select showSearch allowClear placeholder="Select division" style={{ width: "100%" }} optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {
                                    divisions.map((el, key) => (
                                        <Option key={key} value={el.division_id}>{el.division_name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="Description" name="description" rules={[{required:true}]}>
                            <Input.TextArea rows={4} placeholder="Description" />
                        </Form.Item>
                    </FormModal> :
                    null
                }
            </Card>
        </div>
    );
}

export default Index;
