import { useEffect, useState } from 'react';
import { Card, Typography, Space, Row, Col, Button } from 'antd'
import { FileExcelOutlined } from '@ant-design/icons'
import { ShowButton, PageTitle, Spinner, DataTable } from '../../../../components'
import { SentralModel } from '../../../../models/SentralModel'

const { Text } = Typography

const Index = () => {
    const [stocks, setStocks] = useState([])
    const [loaded, setLoaded] = useState(false)

    const fetchStocks = () => {
        SentralModel.list('StationeryStock').then(res => {
            setStocks(res.data)
        }).finally(() => {
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchStocks()
    }, []);

    const dataSource = [
        ...stocks.map(el => {
            return {
                id: el.stationery_stock_id,
                item_name: el.item.item_name,
                quantity: el.quantity,
                uom: el.item.uom
            }
        })
    ];

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%',
        },
        {
            title: 'Item Name',
            dataIndex: 'item_name',
            key: 'item_name',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            width: '5%',
            render: (text) => (
                <div className="text-right">
                    {text}
                </div>
            )
        },
        {
            title: 'UoM',
            dataIndex: 'uom',
            key: 'uom',
            width: '10%',
            render: (text) => (
                <div className="text-center">
                    {text}
                </div>
            )
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <ShowButton link to={'/stationery/stock/' + row.id} />
                    </Space>
                </div>
            )
        },
    ];

    const exportReport = () => {
        window.open(process.env.REACT_APP_API_URL + `/report/stationeryStock`)
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Stocks" breadcrumbs={[['Stationery'], ['Stock']]}></PageTitle>
                <Card title={
                    <Row justify='space-between'>
                        <Col>
                            <Text strong>Stock List</Text>
                        </Col>
                        <Col>
                            <Button type="primary" onClick={() => exportReport()} shape="circle" icon={<FileExcelOutlined />}></Button>
                        </Col>
                    </Row>}>

                    {
                        (loaded) ?
                            <DataTable size='small' bordered dataSource={dataSource} columns={columns} />
                            :
                            <Spinner />
                    }
                </Card>
            </Card>
        </div>
    );
}

export default Index;
