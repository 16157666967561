import React, { useEffect, useState } from 'react';
import { Card, Typography, Form, Input, Select, Checkbox, Switch, DatePicker, message, Space } from 'antd'
import { AddButton, EditButton, PageTitle, Spinner, FormModal, DataTable, DeleteButton } from '../../../components'
import { CheckCircleTwoTone, CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { SentralModel } from '../../../models/SentralModel';
import { toFullDate } from '../../../utils/dateHandler'
import moment from 'moment'

const { Text } = Typography
const { Option } = Select

const Index = () => {
    const [importantDateForm] = Form.useForm()
    const [loaded, setLoaded] = useState(false)
    const [importanDates, setImportantdates] = useState([])
    const [formModal, setFormModal] = useState(false)
    const [saving, setSaving] = useState(false)
    const [modalTitle, setModalTitle] = useState(null)
    const [showPublicHoliday, setShowPublicHoliday] = useState(true)
    const [showYearlyEvent, setShowYearlyEvent] = useState(true)
    const [repeatYearly, setRepeatYearly] = useState(false)
    const [massLeave, setMassLeave] = useState(false)
    const [showNote, setShowNote] = useState(false)

    var year = moment().year();

    const fetchImportantDates = (year) => {
        setLoaded(false)
        SentralModel.action('ImportantDate', 'getHolidays', { year: year }, 0).then((res) => {
            setImportantdates(res.data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchImportantDates(year)
    }, [year]);

    const columns = [{
        title: 'No',
        dataIndex: 'no',
        align: 'center',
        key: 'no',
    },
    {
        title: 'Event Name',
        dataIndex: 'description',
        key: 'description',
        width: '35%'
    }, {
        title: 'Event Type',
        dataIndex: 'category',
        align: 'center',
        key: 'category',
        width: '20%'
    }, {
        title: 'Event Date',
        dataIndex: 'date',
        width: '15%',
        render: (text) => (toFullDate(text))
    }, {
        title: 'Yearly',
        align: 'center',
        width: '10%',
        render: (row) => (
            (row.repeat_yearly === 1) ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <span>-</span>
        )
    }, {
        title: 'Mass Leave',
        align: 'center',
        width: '10%',
        render: (row) => (
            (row.mass_leave === 1) ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <span>-</span>
        )
    }, {
        title: 'Action',
        width: '10%',
        align: 'center',
        render: (row) => (
            <Space>
                <EditButton onEdit={() => editImportantDate(row)} />
                <DeleteButton onConfirm={() => deleteImportantDate(row.important_date_id)} />
            </Space>
        )
    },
    ];

    const addImportantDate = () => {
        setModalTitle('Add Important Date')
        importantDateForm.setFieldsValue({
            important_date_id: 0,
            description: null,
            category: null,
            remarks: null,
            date: moment()
        })
        setRepeatYearly(false)
        setMassLeave(false)
        setShowNote(false)
        setFormModal(true)
    }

    const editImportantDate = (v) => {
        importantDateForm.setFieldsValue({
            important_date_id: v.important_date_id,
            description: v.description,
            category: v.category,
            remarks: v.remarks,
            date: moment(v.date),
        })
        setRepeatYearly(v.repeat_yearly)
        setMassLeave(v.mass_leave)
        setFormModal(true)
        changeCategory(v.category)
    }

    const saveImportantDate = (v) => {
        setSaving(true)
        let data = {
            important_date_id: v.important_date_id,
            description: v.description,
            category: v.category,
            remarks: v.remarks,
            date: moment(v.date).format('YYYY-MM-DD'),
            repeat_yearly: (repeatYearly) ? 1 : 0,
            mass_leave: (massLeave) ? 1 : 0
        }
        SentralModel.action('ImportantDate', 'saveImportantDate', data, v.important_date_id).then(() => {
            message.success("Important date successfully updated")
            setSaving(false)
            fetchImportantDates(moment(v.date).format('YYYY'))
            setFormModal(false)
        }).catch(() => {
            setSaving(false)
        })
    }

    const deleteImportantDate = (v) => {
        SentralModel.action('ImportantDate', 'deleteImportantDate', { important_date_id: v }).then((res) => {
            message.success('Important date berhasil dihapus', 2)
            fetchImportantDates(year)
        }).catch(() => {
            message.error('Gagal menghapus important date', 2)
        })
    }

    const changeYear = (v) => {
        fetchImportantDates(v)
    }

    const changeCategory = (v) => {
        if (v === 'OTHER' || v === 'OFFICE EVENT') {
            setShowNote(true)
        } else {
            setShowNote(false)
        }
    }

    const getShownList = (data) => {
        let d = data
        if (!showPublicHoliday) {
            d = d.filter(el => {
                return el.category !== 'PUBLIC HOLIDAY'
            })
        }
        if (!showYearlyEvent) {
            d = d.filter(el => {
                return el.repeat_yearly !== 1
            })
        }
        return d
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Important Dates" breadcrumbs={[['Important Date']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{ float: 'left' }}>Important Dates</Text>
                        <Space style={{ float: 'right' }}>
                            <Select defaultValue={year} style={{ width: 150 }} onChange={changeYear}>
                                <Option value={year - 1}>{year - 1}</Option>
                                <Option value={year}>{year}</Option>
                                <Option value={year + 1}>{year + 1}</Option>
                            </Select>
                            <AddButton right onAdd={addImportantDate} title="New Important Date" />
                        </Space>
                    </div>}>
                    <div className="text-right mb-3">
                        <Space>
                            <span>
                                <Switch onChange={(v) => setShowPublicHoliday(v)} checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} defaultChecked={showPublicHoliday} /> Show public holiday
                            </span>
                            <span>
                                <Switch onChange={(v) => setShowYearlyEvent(v)} checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} defaultChecked={showYearlyEvent} /> Show yearly event
                            </span>
                        </Space>
                    </div>

                    {
                        (loaded) ?
                            <DataTable size="middle" bordered dataSource={getShownList(importanDates)} columns={columns} />
                        : <Spinner />
                    }
                </Card>
                {
                    (formModal) ?
                        <FormModal form={importantDateForm} defaultValues={null} title={modalTitle} submitForm={(v) => saveImportantDate(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                            <Form.Item name="important_date_id" hidden>
                                <Input placeholder="input placeholder" />
                            </Form.Item>
                            <Form.Item label="Event Name" name="description" required rules={[{ required: true }]}>
                                <Input placeholder="Event Name" />
                            </Form.Item>
                            <Form.Item label="Event Type" name="category" required rules={[{ required: true }]}>
                                <Select placeholder="Select" onChange={(v) => changeCategory(v)}>
                                    <Option value="PUBLIC HOLIDAY">PUBLIC HOLIDAY</Option>
                                    <Option value="OFFICE EVENT">OFFICE EVENT</Option>
                                    <Option value="OTHER">OTHER</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item label="Event Date" name="date" rules={[{ required: true }]}>
                                <DatePicker style={{ width: '100%' }} />
                            </Form.Item>
                            {
                                showNote ?
                                    <Form.Item label="Note" name="remarks" required rules={[{ required: true }]}>
                                        <Input placeholder="Note" />
                                    </Form.Item>
                                : null
                            }
                            <Form.Item >
                                <Checkbox checked={repeatYearly} onChange={(v) => setRepeatYearly(v.target.checked)}>Repeat Yearly</Checkbox>
                            </Form.Item>
                            <Form.Item >
                                <Checkbox checked={massLeave} onChange={(v) => setMassLeave(v.target.checked)}>Cuti Bersama</Checkbox>
                            </Form.Item>
                        </FormModal>
                    : null
                }
            </Card>
        </div>
    );
}

export default Index;
