import React, {useEffect, useState} from 'react';
import {Card, Row, Col, Tabs, Typography, Descriptions, Space} from 'antd'
import {FieldTimeOutlined,CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons'
import { PageTitle, NewButton, DataTable, ShowButton, Spinner} from '../../../components'
import authData from '../../../helpers/authData'
import {SentralModel} from '../../../models/SentralModel'
import {formatDateTime} from '../../../utils/dateFormat'
// import moment from 'moment'

const {Text} = Typography
const {TabPane} = Tabs;

const Index = () => {
    const [stationeries, setStationeries] = useState(null);
    const [stationeryInfo, setStationeryInfo] = useState(null);
    const [instruction, setInstruction] = useState('')
    const [available, setAvailable] = useState(false)

    const fetchStationery = () => {
        let payload={
            filter: [["employee_code", authData.employee_code]]
        }
        SentralModel.list('SubmissionStationery', payload).then(res=>{
            setStationeries(res.data)
        })
    }
    const fetchInstruction = () => {
        SentralModel.list('Instruction',{filter:[['code','SBOS']]}, null).then((res)=>{
            setInstruction(res.data.length ? res.data[0].content : '')
        })
    }

    const fetchStationeryInfo = () => {
        SentralModel.action('Submission', 'getMonthlyStationery', null, null).then((res)=>{
            setStationeryInfo(res.data[0])
        })
    }

    const checkApprovalSchema = () => {
        SentralModel.action('Submission','checkSchema', {category: 'STATIONERY', division_id: authData.division_id}).then((res)=>{
            setAvailable(res.message)
        })
    }

    useEffect(() => {
        fetchStationery()
        fetchStationeryInfo()
        fetchInstruction()
        checkApprovalSchema()
    }, []);
    
    const sttData = (stationeries) ? [
        ...stationeries.map(el => {
            return { 
                id: el.sub_stationery_id,
                status: el.status,
                approver: el.approver,
                items:el.items,
                created_at: formatDateTime(el.created_at)
            }
        })
    ] : [];

    const sttColumn = [{
            title: 'No',
            dataIndex: 'no',
            className:'text-top',
            key: 'name',
            align:'center',
            width:'70px'
        },{
            title: 'Item',
            render: (row) => (
                <ol>
                    {
                        row.items.map((el, i) => (
                            <li key={i}>{el.item.item_name}</li>
                        ))
                    }
                </ol>
            )
        },{
            title: 'Submit Date',
            dataIndex: 'created_at',
            key: 'created_at',
        },{
            title: 'Action',
            align:'center',
            width:'200px',
            render : (row) => (
                <Space>
                    <ShowButton link to={'/office-stationery/' + row.id} />
                </Space>
            )
        }
    ];

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Office Stationery" breadcrumbs={[['Office Stationery']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>Request Office Stationary (ATK)</Text>
                        <NewButton disabled={!available} right to="/office-stationery/create" title={(available) ? "Request Office Stationary" : 'Approval not available'}/>
                    </div>
                }>
                    <Row justify="space-between">
                        <Col span={4}>
                            <Descriptions size="middle" column={1} bordered layout="vertical">
                                <Descriptions.Item label={<Text strong>Stationery Request This Month</Text>}>
                                    {
                                        (stationeryInfo) ? stationeryInfo.total_submission : '0'
                                    }
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col span={19} className="instruction-box">
                            <div dangerouslySetInnerHTML={{ __html: instruction }}></div>
                        </Col>
                    </Row>
                </Card>
                <br/>
                {
                    (stationeries) ?
                    <Tabs defaultActiveKey="waiting" type="card">
                        <TabPane tab={<span><FieldTimeOutlined style={{color:'#4ca3ff'}}/> Waiting Approval</span>} key="waiting">
                            <DataTable size="small" columns={sttColumn} dataSource={sttData.filter(el => el.status==="WAITING")} bordered/>
                        </TabPane>
                        <TabPane tab={<span><CheckCircleOutlined style={{color:'green'}}/> Approved</span>} key="approved">
                            <DataTable size="small" columns={sttColumn} dataSource={sttData.filter(el => el.status==="APPROVED")} bordered/>
                        </TabPane>
                        <TabPane tab={<span><CloseCircleOutlined style={{color:'red'}}/> Rejected</span>} key="rejected">
                            <DataTable size="small" columns={sttColumn} dataSource={sttData.filter(el => el.status==="REJECTED")} bordered/>
                        </TabPane>
                    </Tabs>
                    : <Card><Spinner/></Card>
                }

                {/* {
                    (showLogs) ?
                    <Modal
                        title="Request Status"
                        visible={showLogs}
                        footer={null}
                        onCancel={() => setShowLogs(false)}>
                        <Timeline>
                            {
                                logsData.map((el, key) => (
                                    <Timeline.Item key={key} color={el.color}>
                                        {
                                            (el.status==="success" || el.status==="danger") ?
                                            <div>
                                                <span style={{fontWeight:'bold'}}>{moment(el.date).format('D MMMM YYYY, hh:mm A')}</span><br/>
                                                <span>{el.description}</span><br/>
                                                <span>{el.remarks}</span>
                                            </div> : 
                                            <div>
                                                <span className="text-muted" style={{fontWeight:'bold'}}>{el.description}</span>
                                            </div>
                                        }
                                    </Timeline.Item>
                                ))
                            }
                        </Timeline>
                    </Modal>
                    : null
                } */}
            </Card>
        </div>
    );
}

export default Index;
