import React, { useState } from 'react';
import { Card, message } from 'antd';
import { PageTitle } from '../../../../components'
import RequestForm from './RequestForm'
import { useHistory } from 'react-router-dom'
import { SentralModel } from '../../../../models/SentralModel'

const Create = () => {
    const history = useHistory()
    const [saving, setSaving] = useState(false)

    const submitCommission = (v) => {
        let atch = []
        let notNum = []

        v.items.forEach((e) => {
            if (e.attachment !== null) {
                atch.push(e.attachment)
            }

            if (e.quantity < 0 || (typeof e.quantity) !== "number") {
                notNum.push(e.quantity)
            }
        });

        setSaving(true)
        if (atch.length !== v.items.length) {
            message.warning("Attachment cannot be empty")
            setSaving(false)
        } else if (notNum.length > 0) {
            message.warning("Please check quantity")
        } else {
            SentralModel.action('Submission', 'saveCommission', v, 0).then(() => {
                message.destroy()
                message.success('Commission submitted', 1)
                setSaving(false)
                setTimeout(() => {
                    history.push('/commission');
                }, 1000);
            }).catch(() => {
                message.destroy()
                message.error('Terjadi kesalahan, silahkan coba lagi', 2)
                setSaving(false)
            })
        }
    }
    return (
        <Card className="content-container">
            <PageTitle title="Commission Submission" breadcrumbs={[['Commission', '/commission'], ['Create']]}></PageTitle>
            <RequestForm onSubmit={(v) => submitCommission(v)} saving={saving} />
        </Card>
    );
}

export default Create;
