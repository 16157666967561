import React, { useEffect, useState } from 'react'
import { Row, Typography, Divider, Empty, Form, Input, Select, message } from 'antd'
import ProfileItem from './ProfileItem';
import ActionButton from './ActionButton';
import { AddButton, FormModal, Spinner } from '../../components';
import { SentralModel } from '../../models/SentralModel'
import authData from '../../helpers/authData'
import { useParams } from 'react-router-dom';

const { Title } = Typography
const { Option } = Select;

const Bank = (props) => {
    const { employeeCode } = useParams()
    const [empBanks, setEmpBanks] = useState(props.data);
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false);
    const [defaultValues, setDefaultValues] = useState({});
    const [saving, setSaving] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [banks, setBanks] = useState([]);
    const [selectedBank, setSelectedBank] = useState([]);

    const fetchEmpBanks = () => {
        setLoaded(false)
        SentralModel.list('EmployeeBank', { filter: [['employee_code', props.emp_code]] }).then(res => {
            setEmpBanks(res.data)
        }).finally(() => {
            setLoaded(true)
        })
    }

    const fetchBanks = () => {
        SentralModel.list('Bank', { orderBy: "bank_name", order: "asc" }).then(res => {
            setBanks(res.data)
        }).finally(() => {
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchBanks()
    }, []);

    const addBank = () => {
        setModalTitle('Add Bank')
        setDefaultValues({
            emp_bank_id: 0
        })
        setFormModal(true)
    }

    const editBank = (v) => {
        setModalTitle('Edit Bank')
        setDefaultValues({
            emp_bank_id: v.emp_bank_id,
            bank_id: v.bank_id,
            account_no: v.account_no,
            account_name: v.account_name
        })
        setSelectedBank(v.bank_id)
        setFormModal(true)
    }

    const saveBank = (v) => {
        setSaving(true)
        let payload = {
            emp_bank_id: v.emp_bank_id,
            employee_code: props.emp_code,
            bank_id: selectedBank,
            account_no: v.account_no,
            account_name: v.account_name
        }
        SentralModel.save('EmployeeBank', payload, v.emp_bank_id).then((res) => {
            setFormModal(false)
            fetchEmpBanks()
            message.success('Bank saved', 2);
        }).finally(() => {
            setSaving(false)
        })
    }

    const deleteBank = (id) => {
        message.loading('deleting bank')
        SentralModel.deleteById('EmployeeBank', id).then((res) => {
            message.destroy()
            message.success('Bank deleted', 2)
            let tmp = empBanks
            let index = tmp.findIndex(el => el.emp_bank_id === id)
            tmp.splice(index, 1);
            setEmpBanks([])
            setEmpBanks(tmp)
        })
    }

    const changeBank = e => {
        setSelectedBank(e)
    }

    return (
        <div>
            <div className="text-right">
                {
                    authData.employee_code === employeeCode || employeeCode === undefined || authData.employee_code === 'SMI20220323229' || authData.employee_code === 'SMI20180322139' ?
                        <AddButton onAdd={addBank}>Add data</AddButton> :
                        null
                }
            </div>
            {
                loaded ?
                    (empBanks.length > 0) ?
                        empBanks.map((el, key) => (
                            <div key={key}>
                                <Divider orientation="left"><Title level={4}>{key + 1}.</Title></Divider>
                                {
                                    authData.employee_code === employeeCode || employeeCode === undefined || authData.employee_code === 'SMI20220323229' || authData.employee_code === 'SMI20180322139' ?
                                        <ActionButton editable deleteable onEdit={() => editBank(el)} onDelete={() => deleteBank(el.emp_bank_id)} />
                                        : null
                                }
                                <Row>
                                    <ProfileItem title="Bank Name">{el?.bank.bank_name}</ProfileItem>
                                    <ProfileItem title="Account Number">{el?.account_no}</ProfileItem>
                                    <ProfileItem title="Account Name">{el?.account_name}</ProfileItem>
                                </Row>
                            </div>
                        ))
                        : <Empty />
                    : <Spinner />
            }
            {
                (formModal) ?
                    <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => saveBank(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                        <Form.Item name="emp_bank_id" hidden>
                            <Input placeholder="input placeholder" />
                        </Form.Item>
                        <Form.Item label="Bank" name="bank_id" rules={[{ required: true }]}>
                            <Select showSearch style={{ width: "100%" }} placeholder="Select Bank" optionFilterProp="children" onChange={changeBank} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {
                                    banks.map((el, key) => (
                                        <Option key={key} value={el.bank_id}>{el.bank_name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="Account Number" name="account_no" rules={[{ required: true }]}>
                            <Input placeholder="Account Number" />
                        </Form.Item>
                        <Form.Item label="Account Name" name="account_name" rules={[{ required: true }]}>
                            <Input placeholder="Account Name" />
                        </Form.Item>
                    </FormModal> :
                    null
            }
        </div>
    );
}

export default Bank;
