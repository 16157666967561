import { useState, useEffect } from 'react'
import { SentralModel } from '../../../models/SentralModel'
import { BasicModal, DataTable, ShowButton, Spinner } from '../../../components'
import { Card, Descriptions, Tag } from 'antd'
import moment from 'moment'

const Index = () => {
    const [loaded, setLoaded] = useState(false)
    const [rewardPunishmentData, setRewardPunishmentData] = useState([])
    const [data, setData] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [modalTitle, setModalTitle] = useState('')

    const fetchRewardPunishment = () => {
        SentralModel.action("EmployeeRewardPunishment", "myFeedback", 0, 0).then((res) => {
            setRewardPunishmentData(res.data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchRewardPunishment()

    }, [])

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'name',
            width: '5%'
        },
        {
            title: 'Category',
            render: (row) => (
                <Tag color={row.category === "PUNISHMENT" ? "red" : "green"}>{row.category}</Tag>
            ),
            key: 'category',
            align: 'center',
            filters: [
                {
                    text: 'PUNISHMENT',
                    value: 'PUNISHMENT',
                },
                {
                    text: 'REWARD',
                    value: 'REWARD',
                },
            ],
            onFilter: (value, record) => record.category.indexOf(value) === 0,
        },
        {
            title: 'Sub Category',
            dataIndex: 'sub_category',
            key: 'sub_category',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: 'Send Date',
            render: (row) => (
                moment(row.created_at).format("dddd, DD MMMM YYYY")
            ),
            key: 'send_date'
        },
        {
            title: 'Action',
            render: (row) => (
                <ShowButton onShow={() => onShowModal(row)} />
            ),
            align: 'center',
            width: '10%'
        }
    ]

    const onShowModal = (v) => {
        setShowModal(true)
        setModalTitle("Feedback Detail")
        setData(v)
    }

    return (
        <div>
            {
                loaded ?
                    <DataTable size="small" columns={columns} dataSource={rewardPunishmentData} bordered search={false} />
                    : <Spinner />
            }

            {
                showModal ?
                    <BasicModal showModal={showModal} title={modalTitle} onCloseModal={() => setShowModal(false)} width={900}>
                        <div className="mb-3">
                            <Descriptions bordered title="Information">
                                <Descriptions.Item label="Category" span={2}>{data?.category}</Descriptions.Item>
                                <Descriptions.Item label="Sub Category">{data?.sub_category}</Descriptions.Item>
                                <Descriptions.Item label="Description" span={3}>{data?.description}</Descriptions.Item>
                            </Descriptions>
                        </div>
                        <Card title="Feedback" >
                            {
                                data.feedback.map((el, key) => (
                                    <Descriptions bordered key={key}>
                                        <Descriptions.Item span={3} label="Response Type">{el.response_type}</Descriptions.Item>
                                        <Descriptions.Item span={3} label="Feedback">{el.feedback}</Descriptions.Item>
                                    </Descriptions>
                                ))
                            }
                        </Card>
                    </BasicModal>
                    : null
            }
        </div>
    )
}

export default Index