import React, {useEffect, useState} from 'react';
import {Card, Typography, Form, Input, message, Space, Switch, Tag} from 'antd'
import {AddButton, EditButton, DeleteButton, PageTitle, Spinner, FormModal, DataTable} from '../../../../components'
import {SentralModel} from '../../../../models/SentralModel'

const {Text} = Typography
const Index = () => {
    const [vendors, setVendors] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [saving, setSaving] = useState(false)
    const [modalTitle, setModalTitle]= useState(null)
    const [status, setStatus] = useState(false)


    const fetchVendors = () =>{
        SentralModel.list('Vendor').then(res=>{
            setVendors(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchVendors()
    }, []);
    
    const dataSource = [
        ...vendors.map( el => {
            return {
                id: el.vendor_id,
                vendor_name: el.vendor_name,
                address: el.address,
                email: el.email,
                pic: el.pic,
                phone_1: el.phone_1,
                phone_2: el.phone_2,
                is_active: el.is_active
            }
        })
    ];

    const columns = [{
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'no',
        },
        {
          title: 'Name',
          dataIndex: 'vendor_name',
          key: 'vendor_name',
        },
        {
          title: 'Address',
          dataIndex: 'address',
          key: 'address',
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: 'PIC',
          dataIndex: 'pic',
          key: 'pic',
        },
        {
          title: 'Phone 1',
          dataIndex: 'phone_1',
          key: 'phone_1',
        },
        {
          title: 'Phone 2',
          dataIndex: 'phone_2',
          key: 'phone_2',
        },
        {
          title: 'Status',
          dataIndex: 'is_active',
          align:'center',
          key: 'is_active',
          width: '20%',
          render: (text) => (
            <Tag color={text === 1 ? "green" : "red"}>{(text === 1) ? 'Active' : 'Inactive'}</Tag>
          )
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => editVendor(row)}/>
                        <DeleteButton onConfirm={() => deleteVendor(row.id)}/>
                    </Space>
                </div>
            )
        },
    ];

    const addVendor=()=>{
        setModalTitle('Add Vendor')
        setDefaultValues({
            vendor_id: 0,
            vendor_name: '',
            address: '',
            email: '',
            pic: '',
            phone_1: '',
            phone_2: '',
            is_active: true
        })
        setStatus(true)
        setFormModal(true)
    }

    const editVendor = (v) =>{
        setModalTitle('Edit Vendor')
        setDefaultValues({
            vendor_id: v.id,
            vendor_name: v.vendor_name,
            address: v.address,
            email: v.email,
            pic: v.pic,
            phone_1: v.phone_1,
            phone_2: v.phone_2,
            is_active: parseInt(v.is_active)
        })
        setStatus(v.is_active)
        setFormModal(true)
    }

    const deleteVendor = (id) =>{
        message.loading('deleting item')
        SentralModel.deleteById('Vendor', id).then((res)=>{
            message.destroy()
            message.success('Vendor deleted', 2)
            let tmp = vendors
            let index = tmp.findIndex(el => el.vendor_id===id)
            tmp.splice(index, 1);
            setVendors([])
            setVendors(tmp)
        })
    }

    const saveVendor = (v) => {
        setSaving(true)
        let payload={
            vendor_id: v.id,
            vendor_name: v.vendor_name,
            address: v.address,
            email: v.email,
            pic: v.pic,
            phone_1: v.phone_1,
            phone_2: v.phone_2,
            is_active: status ? 1 : 0
        }
        SentralModel.save('Vendor', payload, v.vendor_id).then((res)=>{
            setFormModal(false)
            fetchVendors()
            message.success('Vendor saved',2);
        }).finally(() => {
            setSaving(false)
        })
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Vendors" breadcrumbs={[['Asset'],['Vendor']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>Vendor List</Text>
                        <AddButton right onAdd={addVendor} title="New Vendor"/>
                    </div> }>

                    {
                        (loaded) ?
                        <DataTable size='small' bordered dataSource={dataSource} columns={columns}/>
                        :
                        <Spinner/>
                    }
                </Card>
                {
                    (formModal) ?
                    <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v)=>saveVendor(v)} width={500} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                        <Form.Item name="vendor_id" hidden>
                            <Input/>
                        </Form.Item>
                        <Form.Item label="Name" name="vendor_name" required rules={[{required:true}]}>
                            <Input placeholder="Name" />
                        </Form.Item>
                        <Form.Item label="Address" name="address" required rules={[{required:true}]}>
                            <Input placeholder="Address" />
                        </Form.Item>
                        <Form.Item label="Email" name="email" required rules={[{required:true}]}>
                            <Input placeholder="Email" />
                        </Form.Item>
                        <Form.Item label="PIC" name="pic">
                            <Input placeholder="PIC" />
                        </Form.Item>
                        <Form.Item label="Phone 1" name="phone_1">
                            <Input placeholder="Phone 1" />
                        </Form.Item>
                        <Form.Item label="Phone 2" name="phone_2">
                            <Input placeholder="Phone 2" />
                        </Form.Item>
                        <Form.Item label="Status" name="is_active" rules={[{required:true}]}>
                            <Switch checkedChildren="Active" unCheckedChildren="Inactive" onChange={(v) => setStatus(v)} defaultChecked={defaultValues.is_active} />
                        </Form.Item>
                    </FormModal> :
                    null
                }
            </Card>
        </div>
    );
}

export default Index;
