import React, { useEffect, useState } from 'react';
import { Card, Typography, Space, Form, Input, Checkbox, message } from 'antd'
import { EditButton, PageTitle, Spinner, DataTable, FormModal } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'

const { Text } = Typography
const Index = () => {
    const [users, setUsers] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false)
    const [defaultValues, setDefaultValues] = useState(null)
    const [saving, setSaving] = useState(false)
    const [privilegeOptions, setPrivilegeOptions] = useState([])

    const fetchUsers = () => {
        setLoaded(false)
        SentralModel.list('UserData', { orderBy: 'email', order: 'asc' }).then(res => {
            setUsers(res.data)
        }).finally(() => {
            setLoaded(true)
        })
    }

    const fetchPrivileges = () => {
        SentralModel.list('Privilege', { filter: [['is_active', '1']], orderBy: 'privilege_id', order: 'asc' }).then(res => {
            let options = [
                ...res.data.map(el => {
                    return {
                        value: el.privilege_id,
                        label: el.privilege_name,
                    }
                })
            ]

            setPrivilegeOptions(options)
        })
    }

    useEffect(() => {
        fetchUsers()
        fetchPrivileges()
    }, []);

    const editUser = (v) => {
        setSaving(false)
        setDefaultValues({ id: v.id, privileges: v.privileges.map(el => el.privilege.privilege_id) })
        setFormModal(true)
    }

    const submitPrivileges = (v) => {
        message.loading('Saving Privileges', 0)
        setSaving(true)
        SentralModel.action('User', 'setPrivilege', v, 0).then(() => {
            setSaving(false)
            message.destroy()
            message.success('Privileges Updated', 2)
            fetchUsers();
            setFormModal(false)
        }).catch(() => {
            setSaving(false)
            message.destroy()
            message.error('Oops, something went wrong', 2)
        })
    }

    const columns = [{
        title: 'No',
        dataIndex: 'no',
        key: 'name',
    },
    {
        title: 'Name',
        dataIndex: 'employee',
        render: (text) => (
            (text) ? text.name : '-'
        )
    }, {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
    }, {
        title: 'Position',
        dataIndex: 'employee',
        render: (text) => (
            (text && text.position) ? text.position.position_name : '-'
        )
    }, {
        title: 'Division',
        dataIndex: 'employee',
        render: (text) => (
            (text && text.division) ? text.division.division_name : '-'
        )
    }, {
        title: 'Privileges',
        dataIndex: 'privileges',
        render: (text) => (
            text.map((el, key) => (
                <span key={key}>{el.privilege.privilege_name}{(key < text.length - 1) ? ',' : ''} </span>
            ))
        )
    }, {
        title: 'Action',
        width: 120,
        render: (row) => (
            <div className="text-center">
                <Space>
                    <EditButton onEdit={() => editUser(row)} />
                </Space>
            </div>
        )
    },
    ];

    const dataSource = [
        ...users
    ]

    const changePrivilege = (v) => {
        // console.log(v)
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Users" breadcrumbs={[['User']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{ float: 'left' }}>User List</Text>
                    </div>}>

                    {
                        (loaded) ?
                            <DataTable size='small' bordered dataSource={dataSource} columns={columns} />
                            :
                            <Spinner />
                    }
                </Card>
            </Card>
            {
                (formModal) ?
                    <FormModal defaultValues={defaultValues} title="Change Privileges" submitForm={(v) => submitPrivileges(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                        <Form.Item name="id" hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item name="privileges" label="Privileges">
                            <Checkbox.Group
                                options={privilegeOptions}
                                onChange={changePrivilege}
                            />
                        </Form.Item>
                    </FormModal> : null
            }
        </div>
    );
}

export default Index;
