import React, {useEffect, useState} from 'react';
import {Card, Typography, Space} from 'antd'
import {NewButton, EditButton, PageTitle, Spinner, DataTable} from '../../../components'
import {SentralModel} from '../../../models/SentralModel'

const {Text} = Typography
const Index = () => {
    const [instructions, setInstructions] = useState([])
    const [loaded, setLoaded] = useState(false)

    const fetchInstructions = () =>{
        SentralModel.list('Instruction').then(res=>{
            setInstructions(res.data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchInstructions()
    }, []);
    
    const dataSource = [
        ...instructions.map( el => {
            return {
                id: el.instruction_id,
                title: el.title,
                code: el.code
            }
        })
    ];

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            width: '70px',
            align: 'center',
            key: 'name',
        }, {
            title: 'Code',
            dataIndex: 'code',
            width: 120,
            key: 'code',
            render: (text) => (
                <div className='text-center'>
                    {text}
                </div>
            )
        }, {
          title: 'Title',
          dataIndex: 'title',
          key: 'title',
        }, {
            title: 'Action',
            width: 120,
            render:(row) =>(
                <div className="text-center">
                    <Space>
                        <EditButton link to={'/setting/instruction/'+row.id}/>
                    </Space>
                </div>
            )
        },
    ];

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Instructions" breadcrumbs={[['Instruction']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>Instruction List</Text>
                        <NewButton link right to="/setting/instruction/0" title="New Instruction"/>
                    </div> }>

                    {
                        (loaded) ?
                            <DataTable size='small' bordered dataSource={dataSource} columns={columns}/>
                        : <Spinner/>
                    }
                </Card>
            </Card>
        </div>
    );
}

export default Index;
