import React, { useState, useEffect } from 'react';
import { Card, Typography, Form, Input, Select, Row, Col, message } from 'antd'
import { PageTitle, PrintPdfButton, Spinner, SubmitButton } from '../../../components'
import { useParams } from 'react-router-dom';
import { SentralModel } from '../../../models/SentralModel';

const { Text } = Typography
const { TextArea } = Input;
const { Option } = Select;

const Exit = () => {
    const { resignId } = useParams();
    const [form] = Form.useForm();
    const [loaded, setLoaded] = useState(false);
    const [saving, setSaving] = useState(false);
    const [categories, setCategories] = useState([]);
    const [questions, setQuestions] = useState([]);

    const fetchQuestion = (resignId, form) => {
        SentralModel.list('ExitInterviewQuestion', { filter: [['is_active', 1]] }).then((question) => {
            setLoaded(false)
            setQuestions(question.data)

            const result = [];
            const map = new Map();
            for (const item of question.data) {
                if (!map.has(item.category)) {
                    map.set(item.category, true);
                    result.push(
                        item.category
                    );
                }
            }
            setCategories(result)

            SentralModel.list('SubmissionResignInterview', { filter: [['sub_resign_id', resignId]] })
                .then((answer) => {
                    let defaultValue = {};

                    question.data.forEach(element => {
                        defaultValue[element.question] = answer.data.find((el) => { return el.question.question === element.question })?.answer
                    });

                    form.setFieldsValue(defaultValue)
                })

        }).finally(() => {
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchQuestion(resignId, form)
    }, [resignId, form]);

    const saveExitInterview = (v) => {
        setSaving(true)

        let data = {
            sub_resign_id: resignId,
        }

        questions.forEach(element => {
            data[element.question] = v[element.question]
        });

        SentralModel.action('Resign', 'saveExitInterview', data, resignId).then((res) => {
            message.success('form saved', 2);
            setSaving(false)
        })
    }

    const printData = () => {
        window.open(process.env.REACT_APP_API_URL + '/print/exit-interview/' + resignId)
    }

    return (
        <Card className="content-container">
            <div>
                <PageTitle title="Exit Interview" breadcrumbs={[['Resign', '/resign'], ['Exit Interview']]}></PageTitle>
            </div>

            <Row className="mb-3">
                <Col span={24} >
                    <PrintPdfButton right onPrint={printData} />
                </Col>
            </Row>

            <Card>
                {
                    (loaded) ?
                        <Form size="middle" form={form} layout="vertical" onFinish={saveExitInterview}>
                            {
                                categories.map((cat, i) => (
                                    <div key={'category_' + i}>
                                        <Text strong style={{ display: 'block' }}>{cat === 'REASON' ? 'ALASAN KELUAR' : cat === 'ABOUT' ? 'TENTANG ANDA' : 'TENTANG PERUSAHAAN'}</Text>
                                        <hr />
                                        {
                                            questions.filter(question => question.category === cat).map((ques, j) => (
                                                <Form.Item key={'question_' + j} label={ques.question} name={ques.question} rules={[{ required: true }]}>
                                                    {
                                                        ques.multiple_choice === null ?
                                                            <TextArea rows={2} placeholder="Answer..." /> :
                                                            <Select placeholder="Select Answer...">
                                                                {
                                                                    ques.multiple_choice.split(',').map((el, key) => (
                                                                        <Option key={key} value={el}>{el}</Option>
                                                                    ))
                                                                }
                                                            </Select>
                                                    }
                                                </Form.Item>
                                            ))
                                        }
                                    </div>
                                ))
                            }
                            <Row justify="space-around">
                                <Col span={24} className="text-right">
                                    <SubmitButton loading={saving} />
                                </Col>
                            </Row>
                        </Form>
                        : <Spinner />
                }
            </Card>
        </Card>
    );
}

export default Exit;
